import React from "react";

import styled from "styled-components";
import {  useDispatch } from "react-redux";
import { useState } from "react";
// import { updateLoader } from "../../pages/myCompany/reducer";
import { Colors } from "../../utilities/colors";
// import Table from "../table/table";
// import AddContact from "../AddContact/AddContact";
// import { getCompanyData } from "../../services/apiCalls";
// import AlertModal from "../singleStudentUpload/alertModal";
// import { toast } from "react-toastify";
// import { updateModal as updateStudentModal } from "../../reducers/studentModalReducer";
// import userManagementService from "../../services/employeeManagement.service";
// import EventBus from "../../utilities/eventBus";
import Loader from "../../components/loader/loader";
import {  Row, Col } from "react-bootstrap";
// import Button from "../../components/button/button";
// import { ConvertDate } from "../../pages/employeeManagement/utils/date-converter";
// import { ReactComponent as GreenCircle } from "../../assets/svg/greenCircle.svg";
// import { ReactComponent as RedCircle } from "../../assets/svg/redCircle.svg";
// import { ReactComponent as RightIcon } from "../../assets/svg/right.svg";
// import { ReactComponent as CrossIcon } from "../../assets/svg/cross.svg";
// import { ReactComponent as ReminderIcon } from "../../assets/svg/reminder.svg";
// import { ReactComponent as ViewIcon } from "../../assets/svg/view.svg";
// import { ReactComponent as GreenViewIcon } from "../../assets/svg/greenview.svg";
// import DeleteIcon from "../../assets/icons/delete.png";
import DataTable from "react-data-table-component";
// import { getUsersByUniversityManagment } from "../../actions/employeeManagement";
import {
  DataTableWrapper,
  Heading,
  LoaderBackdrop,
  LoaderWrapper,
} from "../../pages/employeeManagement/commonStyles";
// import {
//   resetAll,
//   setPage,
//   setPerPage,
//   setRoles,
//   setSearch,
//   setSortDirection,
//   setSortField,
//   setRegistration,
//   setAssessment,
// } from "../../reducers/employeeManagementReducer";
const FlexWrapper = styled.div`
  display: flex;
`;
const SectionWrapper = styled.div`
  background: #fdfdfd;
  border: 1px solid rgba(228, 228, 228, 0.26);
  box-sizing: border-box;
  border-radius: 33px;
  padding: 25px;
  margin: 30px 0;
  overflow:scroll;
`;
const HeaderSection = styled(FlexWrapper)`
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.4px solid ${Colors.labelText};
  padding-bottom: 10px;
  > h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${(props) =>
      props.settings ? Colors.labelText : Colors.primaryText};
    margin: 0;
  }
`;
// const StatusSection = styled(FlexWrapper)`
//   align-items: center;
//   > p {
//     font-weight: 500;
//     font-size: 12px;
//     line-height: 16px;
//     color: #8f8f8f;
//     margin: 0 8px;
//   }
// `;
const Description = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #878787;
  margin: 20px 0;
`;
// const DividerLine = styled.hr`
//   border: 0.4px solid rgba(32, 32, 32, 0.22);
// `;
// const LinkText = styled.label`
//   font-style: normal;
//   font-weight: 500;
//   font-size: 14px;
//   display: block;
//   line-height: 19px;
//   text-decoration-line: underline;
//   color: ${Colors.primaryText};
// `;
const DescriptionSection = styled(FlexWrapper)`
  align-items: center;
  justify-content: space-between;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;
const TableWrapper = styled.div`
  margin: 10px 0;
`;
const AddButton = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  color: #d04c8d;
`;

const CardContainer = styled.div`
  margin-top: 2%;
`;
const CustomHeading = styled(Heading)`
  color: #acacac;
`;
const TableButtonsNew = styled.div`
  display: flex;
`;

const PencilButton = styled.button`
  background-color: transparent;
  border: 0px;
`;
const TrashButton = styled.button`
  background-color: transparent;
  border: 0px;
`;
const DataTableLoaderWrapper = styled.div`
  z-index: 100;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const columnStyling = {
  fontFamily: "General Sans",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "19px",
  minWidth: "92px",
  color: "#202020",
  "@media (max-width: 990px)": {
    display: "flex",
    fontSize: "14px",
    justifyContent: "flex-start",
    padding: "0.5rem 0 0 0rem",
  },
};

const Icon = styled.img`
  margin: auto;
  cursor: pointer;
  display: block;
`;

const customStyles = {
  style: {
    fontSize: "16px!important",
    width: "1px",
  },
};

// function createUserTableTheme(backgroundColor) {
//   createTheme("solarized", {
//     divider: {
//       default: backgroundColor || "#FFFFFF",
//     },
//   });
// }

const dataTableLoader = (
  <DataTableLoaderWrapper>
    <LoaderBackdrop />
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  </DataTableLoaderWrapper>
);

export default function CompanyEmployee(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const onChangeContent = () => {
    setOpen(true);
  };
  const onCloseModal = () => {
    setOpen(false);
  };
  const [modalMode, setModalMode] = useState("");

//   useEffect(() => {
//     createUserTableTheme();
//   });
//   const company = props.companyDetails;
//   const orgCode = props.orgCode;
//   const cache = useSelector((state) => state.studentModalReducer.cache);
//   const delmodal = useSelector((state) => state.studentModalReducer.delmodal);
//   const armodal = useSelector((state) => state.studentModalReducer.armodal);
//   const full_name = useSelector((state) => state.studentModalReducer.full_name);
//   const totalRows = useSelector(
//     (state) => state.employeeManagementReducer.totalRows
//   );
//   const data = useSelector((state) => state.employeeManagementReducer.data);
//   const [gridLoading, setGridLoading] = useState(true);
//   const [countRef, setCountRef] = useState(Math.random());
//   let fromUserManagement = true;
//   let backgroundColor = "#FFFFFF";

//   const getUsersAction = async (codeorg) => {
//     setLoading(true);
//     dispatch(getUsersByUniversityManagment(codeorg))
//       .unwrap()
//       .then(() => {
//         setLoading(false);
//         setGridLoading(false);
//       });
//   };

//   const handlePerRowsChange = (newPerPage, page) => {
//     dispatch(setPage(page));
//     dispatch(setPerPage(newPerPage));
//     getUsersAction(orgCode);
//     // setLoading(true);
//     // setLoading(false);
//   };

//   const handlePageChange = (page) => {
//     dispatch(setPage(page));
//     getUsersAction(orgCode);
//   };
//   const companyOrgs = useSelector((state) => state.company.companyData);

//   const company_status = companyOrgs[props.companyIdx].org_status;

//   const [loading, setLoading] = useState(false);

//   function onClose() {
//     setLoading(false);

//     if (delmodal) {
//       dispatch(
//         updateStudentModal({ delmodal: false, cache: null, full_name: "" })
//       );
//     }
//     if (armodal) {
//       dispatch(updateStudentModal({ armodal: false, cache: null }));
//     }
//   }

//   const onar = (row) => {
//     dispatch(updateStudentModal({ armodal: true, cache: row }));
//   };

//   const ondeletemployee = (value, row) => {
//     dispatch(
//       updateStudentModal({
//         delmodal: true,
//         cache: row,
//         full_name: row.full_name,
//       })
//     );
//   };
//   const sendReminder = async (email) => {
//     const res = await userManagementService.sendSingleReminder(orgCode, {
//       email: email,
//       type_of_reminder: "assessment",
//     });
//     if (res.status == 200) {
//       EventBus.dispatch("userAddEvent", { codeorg: orgCode });
//       toast.success(res.data.message);
//     }
//   };

//   useEffect(() => {
//     if (orgCode) {
//       EventBus.on(
//         "userSearchEvent",
//         ({ search, registration, is_assessment_done, codeorg }) => {
//           dispatch(setSearch(search));
//           dispatch(setRegistration(registration));
//           dispatch(setAssessment(is_assessment_done));

//           getUsersAction(codeorg);
//         }
//       );

//       EventBus.on(
//         "userActivateDeactivateEvent",
//         ({ userId, activate, codeorg }) => {
//           var status = activate ? "active" : "inactive";
//           userManagementService
//             .updateUniversityStudentById(userId, { change_status_to: status })
//             .then(() => {
//               getUsersAction(codeorg);
//             });
//         }
//       );

//       EventBus.on("userActivateDeactivateCancelEvent", ({ codeorg }) => {
//         setCountRef(Math.random());
//         getUsersAction(codeorg);
//       });

//       EventBus.on("userAddEvent", ({ codeorg }) => {
//         setCountRef(Math.random());
//         getUsersAction(codeorg);
//       });

//       return () => {
//         setCountRef(Math.random());
//         setLoading(null);
//         setGridLoading(null);
//         EventBus.remove("userSearchEvent");
//         EventBus.remove("userActivateDeactivateCancelEvent");
//         EventBus.remove("userActivateDeactivateEvent");
//         EventBus.remove("userAddEvent");
//       };
//     }
//   }, [orgCode]);

//   useEffect(() => {
//     dispatch(resetAll());
//     getUsersAction(orgCode);
//   }, [orgCode]);

//   const getdeleteContent = () => {
//     return (
//       <div
//         className="modal-buttons"
//         style={{ display: "flex", justifyContent: "center", margin: "30px 0" }}
//       >
//         <Button
//           name="No"
//           className="inverted"
//           style={{
//             marginRight: "20px",
//             fontSize: "24px",
//             height: "60px",
//             background: "white",
//             border: "1px solid #EE4BA0",
//             color: "#EE4BA0",
//           }}
//           onClick={() => {
//             dispatch(
//               updateStudentModal({
//                 delmodal: false,
//                 cache: null,
//                 full_name: "",
//               })
//             );
//           }}
//         />
//         <Button
//           name="Yes"
//           style={{ marginRight: "20px", fontSize: "24px", height: "60px" }}
//           onClick={async () => {
//             setLoading(true);
//             let result = {};
//             console.log(result);
//             result.status = "deleted";
//             let Keys = Object.keys(cache);
//             for (let i = 0; i < Keys.length; i++) {
//               if (Keys[i] != "status") {
//                 result[Keys[i]] = cache[Keys[i]];
//               }
//             }
//             console.log(result);
//             await userManagementService
//               .updateSingleUniversityStudentById(cache.id, result)
//               .then((res) => {
//                 if (res.status == 200) {
//                   EventBus.dispatch("userAddEvent", { codeorg: orgCode });
//                   setLoading(false);
//                   toast.success(
//                     "Employee " +
//                       full_name +
//                       " has been removed from " +
//                       company.name
//                   );
//                   dispatch(
//                     updateStudentModal({
//                       delmodal: false,
//                       cache: null,
//                       full_name: "",
//                     })
//                   );
//                 } else {
//                   // Iterate through the object
//                   if (res.status == 400) {
//                     toast.error("Please check your data.");
//                     setLoading(false);
//                   }
//                 }
//               })
//               .catch((error) => {
//                 setLoading(false);
//                 toast.error(error);
//               });
//           }}
//         />
//       </div>
//     );
//   };

//   const getarContent = () => {
//     return (
//       <>
//         {cache && armodal ? (
//           <CardContainer>
//             <Row style={{ paddingLeft: "9px" }}>
//               <Col md="6">Email address</Col>
//               <Col md="6">{cache.email}</Col>

//               <Col md="6">Full name</Col>
//               <Col md="6">{cache.full_name}</Col>

//               <Col md="6">Department</Col>
//               <Col md="6">{cache.department}</Col>
//               <Col md="6">Position</Col>
//               <Col md="6">{cache.position}</Col>
//             </Row>
//           </CardContainer>
//         ) : (
//           <></>
//         )}

//         <div
//           className="modal-buttons"
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             margin: "30px 0",
//           }}
//         >
//           <Button
//             name="Reject"
//             className="inverted"
//             style={{
//               marginRight: "20px",
//               fontSize: "24px",
//               height: "60px",
//               background: "white",
//               border: "1px solid #EE4BA0",
//               color: "#EE4BA0",
//             }}
//             onClick={async () => {
//               setLoading(true);
//               let result = {};
//               result.status = "deleted";
//               let Keys = Object.keys(cache);
//               for (let i = 0; i < Keys.length; i++) {
//                 if (Keys[i] != "status") {
//                   result[Keys[i]] = cache[Keys[i]];
//                 }
//               }
//               await userManagementService
//                 .updateSingleUniversityStudentById(cache.id, result)
//                 .then((res) => {
//                   if (res.status == 200) {
//                     EventBus.dispatch("userAddEvent", { codeorg: orgCode });
//                     setLoading(false);
//                     toast.success("Employee rejected successful");
//                     dispatch(
//                       updateStudentModal({ armodal: false, cache: null })
//                     );
//                   } else {
//                     // Iterate through the object
//                     if (res.status == 400) {
//                       toast.error("Please check your data.");
//                       setLoading(false);
//                     }
//                   }
//                 })
//                 .catch((error) => {
//                   setLoading(false);
//                   toast.error(error);
//                 });
//             }}
//           />
//           <Button
//             name="Approve"
//             style={{ marginRight: "20px", fontSize: "24px", height: "60px" }}
//             onClick={async () => {
//               setLoading(true);
//               let result = {};
//               result.status = "accepted";
//               let Keys = Object.keys(cache);
//               for (let i = 0; i < Keys.length; i++) {
//                 if (Keys[i] != "status") {
//                   result[Keys[i]] = cache[Keys[i]];
//                 }
//               }
//               await userManagementService
//                 .updateSingleUniversityStudentById(cache.id, result)
//                 .then((res) => {
//                   if (res.status == 200) {
//                     EventBus.dispatch("userAddEvent", { codeorg: orgCode });
//                     setLoading(false);
//                     toast.success("Employee approved successful");
//                     dispatch(
//                       updateStudentModal({ armodal: false, cache: null })
//                     );
//                   } else {
//                     // Iterate through the object
//                     if (res.status == 400) {
//                       toast.error("Please check your data.");
//                       setLoading(false);
//                     }
//                   }
//                 })
//                 .catch((error) => {
//                   setLoading(false);
//                   toast.error(error);
//                 });
//             }}
//           />
//         </div>
//       </>
//     );
//   };
const data =[
    {
        full_name:"Alia Zulaikha ",
        email:"aliazulaikha@qlco.com ",
        joined_date:"21/01/2020",
        position:"Hr Manager",

    },
    {
        full_name:"Alia Zulaikha ",
        email:"aliazulaikha@qlco.com ",
        joined_date:"21/01/2020",
        position:"Hr Manager",

    }
]
  const columns = [
    {
      name: "Contact person",
      selector: (row) => row.full_name + row.email,
      sortable: true,
      style: columnStyling,
      center: true,
      cell: (row, index) => {
        return (
          <>
            <Row>
              <Col lg="12">
                <span>{row.full_name}</span>
              </Col>
              <Col
                lg="12"
                style={{
                  color: "#BCBCBC",
                  fontSize: "14px",
                }}
              >
                <span>{row.email}</span>
              </Col>
              <Col
                lg="12"
                style={{
                  color: "#BCBCBC",
                  fontSize: "14px",
                }}
              ></Col>
            </Row>

            <br />
          </>
        );
      },
    },
    {
      name: "Job title",
      selector: (row) => row.position + row.department,
      sortable: true,
      style: columnStyling,
      center: true,
      cell: (row, index) => {
        return (
          <>
            <Row>
              <Col lg="12">
                <span>{row.position}</span>
              </Col>
              <Col
                lg="12"
                style={{
                  color: "#BCBCBC",
                  fontSize: "14px",
                }}
              >
                <span>{row.department}</span>
              </Col>
              <Col
                lg="12"
                style={{
                  color: "#BCBCBC",
                  fontSize: "14px",
                }}
              ></Col>
            </Row>

            <br />
          </>
        );
      },
    },
    {
      name: "Date joined",
      selector: (row) => row.joined_date,
      sortable: true,
      style: columnStyling,
    //   center: true,
      cell: (row, index) => {
        return (
          <>
            <Row>
              <Col lg="12">
                <span>{row.joined_date}</span>
              </Col>
            </Row>
            <br />
          </>
        );
      },
    },
    // {
    //   name: "Assessments",
    //   selector: (row) => row.assessment,
    //   sortable: true,
    //   style: columnStyling,
    //   center: true,
    //   cell: (row, index) => {
    //     return (
    //       <>
    //         <Row>
    //           <Col lg="12">
    //             <span style={{ marginRight: 10 }}>{row.assessment}</span>
    //           </Col>
    //         </Row>
    //         <br />
    //       </>
    //     );
    //   },
    // },
    // {
    //   name: "Reminder Last Sent",
    //   selector: (row) => row.reminder,
    //   sortable: true,
    //   style: columnStyling,
    //   center: true,
    //   cell: (row, index) => {
    //     return (
    //       <>
    //         <Row>
    //           <Col lg="12">
    //             <span style={{ marginLeft: 10 }}>
    //               {row.reminder == "-"
    //                 ? row.reminder
    //                 : ConvertDate(row.reminder, "dd/mm/yyyy")}
    //             </span>
    //           </Col>
    //         </Row>
    //         <br />
    //       </>
    //     );
    //   },
    // },
    // {
    //   name: "Actions",
    //   selector: (row) => row.status,
    //   sortable: false,
    //   style: columnStyling,
    //   center: true,
    //   cell: (row, index) => {
    //     if (!fromUserManagement) {
    //       return (
    //         <div>
    //           <span style={{ marginRight: 10 }}>
    //             {row.status == "active" ? <GreenCircle /> : <RedCircle />}
    //           </span>
    //           <span>{row.status == "active" ? "Active" : "Deactivated"}</span>
    //         </div>
    //       );
    //     }
    //     const CustomOption = (props) =>
    //       !props.isDisabled ? (
    //         <div
    //           {...props?.innerProps}
    //           style={{
    //             padding: 10,
    //             ...(props?.selectProps?.value?.value === props?.value
    //               ? {
    //                   pointerEvents: "none",
    //                   cursor: "none",
    //                   backgroundColor: "lightgray",
    //                   opacity: 0.8,
    //                 }
    //               : {}),
    //           }}
    //         >
    //           <span style={{ marginRight: 10 }}>
    //             {props.value == "active" ? <GreenCircle /> : <RedCircle />}
    //           </span>
    //           <span>{props.label}</span>
    //         </div>
    //       ) : null;
    //     const CustomInput = (props) => (
    //       <div
    //         style={{
    //           color: "inherit",
    //           gridArea: "1/1/2/3",
    //           maxWidth: "100%",
    //           overflow: "hidden",
    //           textOverflow: "ellipsis",
    //           whiteSpace: "nowrap",
    //           boxSizing: "border-box",
    //           fontSize: "medium",
    //         }}
    //       >
    //         <span>{props.selectProps.value.label}</span>
    //       </div>
    //     );

    //     return (
    //       <TableButtonsNew>
    //         <PencilButton
    //         //onClick={(value) => onChangeStudentEdit(value, row)}
    //         >
    //           {row.is_assessment_done == 1 ? <GreenViewIcon /> : <ViewIcon />}
    //         </PencilButton>
    //         <TrashButton>
    //           {/* <ReminderIcon onClick={() => sendReminder(row.email)} /> */}
    //         </TrashButton>
    //         <TrashButton onClick={(value) => ondeletemployee(value, row)}>
    //           {/* <Icon src={DeleteIcon} /> */}
    //         </TrashButton>
    //       </TableButtonsNew>
    //     );
    //   },
    // },
    // {
    //   name: "mynext Registration",
    //   selector: (row) => row.is_registered,
    //   sortable: false,
    //   style: columnStyling,
    //   center: true,
    //   cell: (row, index) => {
    //     return (
    //       <>
    //         {row.status == "pending" ? (
    //           <Row onClick={() => onar(row)}>
    //             <Col
    //               lg="12"
    //               style={{
    //                 width: "auto",
    //               }}
    //             >
    //               <span>
    //                 {" "}
    //                 <CrossIcon />
    //               </span>
    //               &nbsp;
    //               <span>
    //                 <RightIcon />{" "}
    //               </span>
    //             </Col>
    //           </Row>
    //         ) : (
    //           <Row>
    //             <Col
    //               lg="12"
    //               style={{
    //                 width: "auto",
    //               }}
    //             >
    //               <span>
    //                 {row.is_registered == 1 ? <RightIcon /> : <CrossIcon />}
    //               </span>
    //             </Col>
    //           </Row>
    //         )}
    //       </>
    //     );
    //   },
    // },
  ];

  return (
    <SectionWrapper style={{ background: props?.primaryColor }}>
      <HeaderSection settings={props.settings}>
        <h3 style={{ color: props?.secondaryColor }}>Employee</h3>
      </HeaderSection>
      <DescriptionSection>
        <Description>
          Edit or add Employee for your organization changes will be sent to
          talent corp for approval.
        </Description>
        {/* <LinkText>Close</LinkText> */}
      </DescriptionSection>
      {/* {loading ? ( */}
        {/* <Loader />
      ) : ( */}
        <DataTableWrapper className="users-management">
        {/* <DataTable
            columns={columns}
            data={data}
        /> */}
          <DataTable
            columns={columns}
            data={data}
            theme="solarized"
            sortServer
            // progressPending={gridLoading}
            customStyles={customStyles}
            progressComponent={
              <div
                style={{
                  minHeight: "586px",
                //   backgroundColor: `${backgroundColor} !important`,
                }}
              >
                <DataTableLoaderWrapper>
                  <LoaderWrapper>
                    <Loader />
                  </LoaderWrapper>
                </DataTableLoaderWrapper>
              </div>
            }
            noDataComponent={
              <div
                style={{
                  minHeight: "586px",
                //   backgroundColor: `${backgroundColor} !important`,
                }}
              >
                <DataTableLoaderWrapper>
                  <LoaderWrapper>
                    <CustomHeading>No Results Found</CustomHeading>
                  </LoaderWrapper>
                </DataTableLoaderWrapper>
              </div>
            }
            // pagination={fromUserManagement}
            // paginationServer={fromUserManagement}
            // paginationTotalRows={totalRows}
            // onChangeRowsPerPage={handlePerRowsChange}
            // onChangePage={handlePageChange}
          />
          {/* {loading && typeof data !== "undefined" && dataTableLoader} */}
        </DataTableWrapper>
      {/* )} */}
      {/* <TableWrapper>
        {company && company?.org_user?.length > 0 ? (
          <Table data={company && company.org_user} />
        ) : (
          ""
        )}
      </TableWrapper> */}
      {/*<AddButton onClick={onChangeContent}>
        + Add another contact person
      </AddButton>
        {open && <AddContact open={open} onClose={onCloseModal} companyIdx={props.companyIdx}/>}*/}
  
    </SectionWrapper>
  );
}

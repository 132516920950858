import { useState, useEffect } from "react";

import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import PaginationDivider from "../../components/paginationDivider/paginationDivider";
import StudyingImg from "../../assets/studing.png";
import UserCreationLayout from "../../layouts/userCreation/userCreationLayout";
import IntroSection from "../../components/introSection/introSection";
import AboutmeCards from "../../components/aboutmecards/aboutmeCards";
import { Colors } from "../../utilities/colors";
import ErrorField from "../../components/errorField/errorField";
import { talentStatus } from "./staticContent";
import { updateValues } from "../selfIntro/reducer";
import { onBoardFormSubmission } from "../../services/apiCalls";
import { removeKeys } from "../../utilities/commonFunctions";
import Loader from "../../components/loader/loader";
import Button from "../../components/button/button";
import { useTranslation } from "react-i18next";
// styling starts
const HeaderWrapper = styled.section``;

const LabelText = styled.label`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;
const CardHeader = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #000;
  margin-top: 50px;
`;

const SaveExitBtn = styled(LabelText)`
  color: #504f8c;
  text-decoration: underline;
  cursor: pointer;
  @media (max-width: 800px) {
    width:100%;
    text-align:center;
  }
`;
const Detailsection = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  margin-top: 10%;
  grid-row-gap: 80px;
  grid-column-gap: 20px;
  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  @media (max-width: 576px) {
    display:flex;
    flex-direction:column;
    justify-content:space-around;
    align-items: center;
    margin-top:48px;
  }
`;
const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const ButtonWrapper = styled(FlexWrapper)`
  justify-content: space-between;
  margin-top: 40px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
  @media (max-width: 1024px) {
    justify-content: space-between;
  }
`;

export default function Aboutme() {
  const [err, setErr] = useState(false);
  const [formErrors, setFormErrors] = useState("");
  const [loading, setLoading] = useState(false);

  const formValues = useSelector((state) => state.selfIntroOne.value);
  const value = formValues.talent_status;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!window.allow_user_continue) navigate("/self-intro");
  }, [window.allow_user_continue]);

  const changeStatus = (val, name) => {
    dispatch(updateValues({ ...formValues, talent_status: val }));
  };
  const onContinue = () => {
    if (value) {
      setErr(false);
      onNavigation();
    } else {
      setErr(true);
    }
  };

  const onNavigation = () => {
    window.allow_user_continue = true;
    switch (formValues.talent_status) {
      case "studying":
        navigate("/studying");
        break;
      case "employed":
        navigate("/employed");
        break;
      case "internship":
        navigate("/studying");
        break;
      case "unemployed":
        navigate("/employed");
        break;
      case "army":
        navigate("/army-personnel");
        break;
      
    }
  };
  useEffect(() => {
    if (formValues === "") {

    } else {
    }
  }, []);
  const onSubmit = async () => {
    setLoading(true);
    const req = removeKeys({ ...formValues });
    const { data } = await onBoardFormSubmission({
      ...req,
      step: formValues.profile_picture ? 4 : formValues.step === 3 ? 3 : 2,
      // step: 2
    });
    if (data && data.status === 1) {
      navigate("/dashboard");
      setLoading(false);
      setFormErrors("");
    } else {
      setLoading(false);
      setFormErrors(data && data.message);
    }
  };

  const onNavigate = (path) => {
    navigate(path);
  };

  const { t } = useTranslation();

  return (
    <UserCreationLayout sidebarImg={StudyingImg}>
      <IntroSection
        name={t(talentStatus.title)}
        description={t(talentStatus.description)}
      />
      <PaginationDivider step={2} />
      <HeaderWrapper>
        <CardHeader>{t(talentStatus.name)}</CardHeader>
      </HeaderWrapper>
      <Detailsection>
        {talentStatus.statuses.map((status, index) => (
          <AboutmeCards
            bg={status.value === value ? Colors.pinkShade : ""}
            key={status.label + index}
            cardName={t(status.label)}
            descriptions={t(status.descriptions)}
            cardImage={status.img}
            onSelect={() => changeStatus(status.value, status.label)}
          />
        ))}
      </Detailsection>
      {err && <ErrorField err={t(talentStatus.err)} />}
      {formErrors && <ErrorField err={t(formErrors)} />}
      {loading ? (
        <Loader />
      ) : (
        <>
          <ButtonWrapper>
            <Button width="198px" onClick={onContinue} name={t("Continue")} />

            <Button
              width="198px"
              onClick={() => onNavigate("/self-intro")}
              secondary={true}
              name={t("Back")}
            />
          </ButtonWrapper>
          {formValues.scope === "" ? (
            ""
          ) : (
            <SaveExitBtn onClick={onSubmit}>{t("Save and Exit")}</SaveExitBtn>
          )}
        </>
      )}
    </UserCreationLayout>
  );
}

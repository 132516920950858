import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import FormErrors from "../../../components/formErrors/formErrors";
import Input from "../../../components/input/input";
import {
  userDetailsUpdate,
  updateProfilePicture,
} from "../../../services/apiCalls";
import * as Yup from "yup";
import { isValidPhoneNumber } from "react-phone-number-input";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "styled-components";
import { toast } from "react-toastify";
import { updateTalentData } from "../../../pages/login/reducer";
import { useTranslation } from "react-i18next";

import {
  Image,
  ImageWrapper,
  Label,
  SelectorWrapper,
  StyledSimpleCardCustom,
  SubHeading,
  SubHeadingText,
  CancelAndExit,
} from "../../analyticalDashboardPage/commonStyles";
import Loader from "../../../components/loader/loader";
import Button from "../../../components/button/button";
import { onBoardFormGetData } from "../../../services/apiCalls";

const ResumeName = styled.div`
  font-size: 20px;
  font-family: "General Sans";
  font-weight: 500;
`;

const SubHeaderName = styled.div`
  font-size: 17px;
  font-family: "General Sans";
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  @media (max-width: 700px) {
    font-size: 14px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
`;

const TextChange = styled.div`
  font-family: General Sans;
  font-weight: 500;
  font-size: 14px;
  color: #d04c8d;
  cursor: pointer;
  margin-top: 24px;
`;

export default function SettingsProfile() {
  const talentData = useSelector((state) => state?.talent?.userData);

  const formSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .matches(/^[aA-zZ\s]+$/, "Name can only have alphabets"),
    phone: Yup.string()
      .required("Mobile Number is required")
      .test("mob_number", "Mobile Number is not valid", (value) =>
        isValidPhoneNumber(value)
      ),
    age: Yup.string().required("Age is required"),
    email: Yup.string().email().required("Email is required"),
  });
  const [loader, setLoader] = useState(true);
  const [selectedImage, setSelectedImage] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [isImageUpdating, setIsImageUpdating] = useState(false);
  const [imageUpdated, setImageUpdated] = useState(false);
  const [profileDetails, setProfileDetails] = useState(null);
  const [defaultValues, setDefaultValues] = useState({
    avatar: "",
    name: "",
    phone: "",
    email: "",
    age: "",
  });

  const dispatch = useDispatch();

  // Habib
  useEffect(() => {
    fetchOnBoardFormGetData();
    setIsImageUpdating(false);
  }, [imageUpdated, isImageUpdating]);

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    formState: { touchedFields },
    getValues,
    reset,
  } = useForm({
    criteriaMode: "all",
    resolver: yupResolver(formSchema),
    defaultValues: defaultValues,
  });
  const [loading, setLoading] = useState(false);

  const onCancel = () => {
    reset(defaultValues);
    setIsEditable(false);
    setLoading(false);
  };

  const fetchOnBoardFormGetData = async () => {
    const { data, status } = await onBoardFormGetData();
    if (data && data.status === 1) {
      const {
        profile_picture,
        full_name,
        display_name,
        mob_number,
        dob,
        email,
        domain,
      } = data.data;
      const currentYear = new Date().getFullYear();
      const age = dob ? currentYear - dob.slice(0, 4) : "";
      const formValues = {
        email: email || "",
        phone: mob_number || "",
        name: display_name || "",
        age: age || "",
        avatar: profile_picture || "",
        domain: domain || "",
      };
      setDefaultValues(formValues);
      setProfileDetails(data.data);
      reset(formValues);
      setSelectedImage(profile_picture);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  // Update user details
  const getUpdatedUserDetails = async ({ name, phone }) => {
    const { data, status } = await userDetailsUpdate({
      name: name,
      phone_number: phone,
    });

    if (status === 200) {
      if (data.status === 0) {
        toast.error(data.message);
      } else if (data.status === 1) {
        toast.success(data.message);
        fetchOnBoardFormGetData();
      }
    }
  };

  const onSubmit = (data) => {
    if (!errors) {
      return;
      setLoading(true);
    } else {
      setIsEditable(false);
      getUpdatedUserDetails(data);
      setLoading(false);
    }
  };

  // OnImage Change
  const imageChange = async (e) => {
    if (e.target.files[0].size <= 5000000) {
      // ARCHMAGE

      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("profile_picture", file);
      setLoading(true);
      const { data } = await updateProfilePicture(formData);
      if (data.status === 1) {
        setLoading(false);
        setImageUpdated(true);
        setIsImageUpdating(true);
        getProfileImagePath(data.data);
        onUploaded(data.data);
      }
    } else {
      toast.error(t("File size exceeds maximum limit."));
    }
  };
  const onUploaded = (data) => {
    const newVal = {
      ...talentData,
      profile_picture: data.profile_picture,
    };
    dispatch(updateTalentData(newVal));
  };

  const getProfileImagePath = (data) => {
    if (data) {
      setSelectedImage(data.profile_picture);
    }
  };

  const { t } = useTranslation();

  return (
    <>
      <form>
        <Row className="mb-4" style={{ boxSizing: "border-box" }}>
          <Col lg="3">
            <ImageWrapper>
              <Image src={selectedImage} alt="student_profile_photo" />
              <div className="changeImage">
                <input
                  accept="image/x-png,image/jpeg"
                  type="file"
                  className="position-absolute opacity-0"
                  onChange={imageChange}
                />
                {loading ? <Loader /> : t("Change Profile Photo")}
              </div>
              <p style={{ fontSize: "12px", color: "gray" }}>
                {t("The maximum image size limit is 5 MB")}
              </p>
            </ImageWrapper>
          </Col>
          <Col lg="8" style={{ alignSelf: "center" }}>
            <ResumeName>{profileDetails?.full_name}</ResumeName>
            <SubHeaderName>
              {/* {profileDetails?.curr_qualification_abbr} |{" "} */}
              {profileDetails?.scope_abbr} <br />{" "}
              {/* {profileDetails?.curr_country_abbr} */}
            </SubHeaderName>
            <SubHeaderName>
              {profileDetails?.state_abbr}, {""} {profileDetails?.city_abbr},{" "}
              {""} {profileDetails?.country_abbr}
            </SubHeaderName>
            {/* <hr /> */}
            <SubHeaderName>{profileDetails?.insti_name_abbr}</SubHeaderName>
          </Col>
        </Row>
      </form>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <Container className="py-4 container-br">
              <SubHeading>
                <SubHeadingText>{t("Profile Information")}</SubHeadingText>
                {/* {!isEditable && (
                  <div
                    className="changeText"
                    onClick={() => setIsEditable(true)}
                  >
                    Edit
                  </div>
                )} */}
              </SubHeading>
              <div>
                <Row>
                  <Col lg="5">
                    <div className="mb-4 mt-4">
                      <Label>{t("Display Name*")} </Label>
                      <SelectorWrapper>
                        {isEditable ? (
                          <>
                            <SelectorWrapper>
                              <Controller
                                control={control}
                                name="name"
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                  fieldState: { error },
                                }) => (
                                  <Input
                                    placeholder={t("Name")}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    className={error ? "invalid" : ""}
                                  />
                                )}
                              />
                              <FormErrors
                                errors={errors}
                                formControlName="name"
                              />
                            </SelectorWrapper>
                          </>
                        ) : (
                          <>
                            <StyledSimpleCardCustom className="inputReadOnly">
                              {getValues().name}
                            </StyledSimpleCardCustom>
                          </>
                        )}
                      </SelectorWrapper>
                    </div>
                  </Col>
                  <Col lg="2">
                    <div className="mb-4 mt-4">
                      <Label>{t("Age")}</Label>
                      <SelectorWrapper>
                        <>
                          <StyledSimpleCardCustom className="inputReadOnly">
                            {getValues().age}
                          </StyledSimpleCardCustom>
                        </>
                      </SelectorWrapper>
                    </div>
                  </Col>
                  <Col lg="5">
                    <div className="mb-4 mt-4">
                      <Label>{t("Email*")}</Label>
                      <SelectorWrapper>
                        <>
                          <StyledSimpleCardCustom className="inputReadOnly">
                            {getValues().email}
                          </StyledSimpleCardCustom>
                        </>
                      </SelectorWrapper>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <div className="mb-4 mt-4">
                      <Label>{t("Mobile Number*")}</Label>
                      <SelectorWrapper>
                        {isEditable ? (
                          <>
                            <SelectorWrapper>
                              <Controller
                                name="phone"
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                  fieldState: { error },
                                }) => (
                                  <PhoneInput
                                    value={value}
                                    defaultCountry="MY"
                                    withCountryCallingCode
                                    onChange={onChange}
                                    placeholder={t("Area code + Number")}
                                    className={error ? "invalid" : ""}
                                  />
                                )}
                              />
                              <FormErrors
                                errors={errors}
                                formControlName="phone"
                              />
                            </SelectorWrapper>
                          </>
                        ) : (
                          <>
                            <StyledSimpleCardCustom className="inputReadOnly">
                              {getValues().phone}
                            </StyledSimpleCardCustom>
                          </>
                        )}
                      </SelectorWrapper>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
            <Row>
              <ButtonGroup>
                <div lg="3">
                  {loading ? (
                    <Loader />
                  ) : (
                    isEditable && (
                      <Button name={t("Save")} type="submit" light />
                    )
                  )}
                </div>
                {isEditable && (
                  <div lg="3">
                    <CancelAndExit
                      type="button"
                      className="cancelBtn"
                      onClick={onCancel}
                    >
                      {t("Cancel")}
                    </CancelAndExit>
                  </div>
                )}
              </ButtonGroup>
            </Row>
          </Col>
        </Row>
      </form>
    </>
  );
}

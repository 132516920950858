import { proxy } from 'valtio'
let mycalendarProxy = proxy({
    joblist:[],
    applist:[],
    tags:[],
    setcheck:0
})
export {
    mycalendarProxy
}

import styled from "styled-components";

import Businessman from "../../../assets/icons/svg/businessman.svg";
import Salary from "../../../assets/icons/svg/salary.svg";
import Job from "../../../assets/icons/svg/calender-time.svg";
import Vacancies from "../../../assets/icons/svg/Internships.svg";
import Clock from "../../../assets/icons/svg/clock-watch.svg";
import Timer from "../../../assets/icons/svg/expire-frame.svg";
import { dateToDateMonthInWordsYear } from "../../../utilities/commonFunctions";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
const DetailsWrapper = styled.div`
  background: #f8f8f8;
  border-radius: 54px;
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  margin-right: 25px;
  margin-bottom: 18px;
  > h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin: 0px 6px;
    color: #525252;
    @media (max-width: 500px) {
      font-size: 15.8px;
    }
  }
`;
const Image = styled.img`
  max-height: 30px;
  max-width: 25px;
`;
const DetailsComponent = ({ image, text, specialAlignment, ClassName }) => {
  if (specialAlignment) {
    return (
      <DetailsWrapper style={{ display: "flex", alignSelf: "center" }}>
        <Image src={image} />
        <h6>{text}</h6>
      </DetailsWrapper>
    );
  } else {
    return (
      <DetailsWrapper>
        <Image src={image} />
        <h6>{text}</h6>
      </DetailsWrapper>
    );
  }
};

const getInternTypeName = (intern_type) =>{

  switch(intern_type){
    
    case 'Advertise':
    case 'advertise':
    case 'sip':
      return 'MySIP Advertised';

    case 'Direct Hiring':
      return 'MySIP Direct Hiring';
    
    case 'General Internship':
    case 'General':
    case 'general internship':
    case 'general':
      return 'General Internship'
  }
  
}

export default function InterShipCard({ data }) {
  const { t } = useTranslation();
  return (
    <>
      <Wrapper>
        <DetailsComponent
          image={Businessman}
          text={
            // data.intern_type === "sip" || data.intern_type === "SIP"
            //   ? t("National Structured Internship Programme (MySIP)")
            //   : t(data.intern_type)
            getInternTypeName(data.intern_type)
          }
        />
        <DetailsComponent
          image={Salary}
          text={`${
            data.payment_status === "unpaid"
              ? "0"
              : data &&
                data.min_compensation + " MYR - " + data.max_compensation
          } MYR`}
        />
        <DetailsComponent
          specialAlignment
          image={Vacancies}
          text={t("Positions")+` ${data && data.no_positions}`}
        />
        <DetailsComponent
          image={Job}
          text={t("Last date to apply :")+` ${
            data && dateToDateMonthInWordsYear(data.position_end_date)
          }`}
        />
        <DetailsComponent
          image={Clock}
          text={t("Working Hours:")+` ${data && data.working_hours} `+ t("hrs/ day")}
        />
      </Wrapper>
    </>
  );
}

import styled from "styled-components";

import { Colors } from "../../utilities/colors";

const CustomBtn = styled.button`
  background: ${(props) =>
    props.secondary
      ? Colors.light
      : "linear-gradient(90deg, #FA4AA1 0%, #504F8C 100%)"};
  border-radius: 128px;
  outline: none;
  border: ${(props) => (props.secondary ? "1px solid #D04C8D" : "0px")};
  font-weight: 600;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "18px")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "24px")};
  color: ${(props) => (props.secondary ? Colors.primaryText : Colors.light)};
  padding: 13px 0rem;
  margin: ${(props) => (props.margin ? props.margin : "15px 0")};
  width: 192px;
  cursor: pointer;
  &:hover {
    color: ${Colors.light};
    background: ${(props) =>
      props.secondary
        ? Colors.pink
        : " linear-gradient(90deg, #504F8C 0%, #FA4AA1 100%);"};
  }
  &:disabled {
    color: white !important;
    background: #6c757d !important;
    &:hover {
      cursor: default;
    }
  }

  @media (max-width: 599px) {
    width: ${(props) => (props.mobWidth ? props.mobWidth : "192px")};
  }
`;
export default function Button(props) {
  // styling starts
  // styling ends

  return (
    <CustomBtn secondary={props.secondary} margin={props.margin} {...props}>
      {props.name}
    </CustomBtn>
  );
}

import styled from "styled-components";

export default function PasswordErrorField(props) {
  const Label = styled.label`
    color: red;
    font-size: 12px;
    margin-top: -20px;
    display: block;
    font-weight: 500;
  `;
  return (
    <Label>
      {props?.err?.charAt(0)?.toUpperCase() +
        props?.err?.slice(1)?.replace(/-./g, " ")}
    </Label>
  );
}

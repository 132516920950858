import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import { useSelector, useDispatch } from "react-redux";
import rightarrow from "../../assets/icons/arrow_right.png";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import globe from "../../assets/globe.png";
import { useNavigate, useParams } from "react-router-dom";
import CompanyInformationSwiper from "../../components/companyBranding/companyInformationSwiper";
import CompanyPhotosSwiper from "../../components/companyBranding/companyPhotosSwiper";
import {
  getCompanyData,
  getEmployee,
  getCompanyBranding,
  getCompanyBrandingImages,
  getCompanyBrandingArticles,
} from "../../services/apiCalls";
import { useSnapshot } from "valtio";
import { companyProxy } from "../dashboard/companyproxy";
import TabBranding from "../../components/companyBranding/tabBranding";
import HamburgerLay from "../../components/hamburger/hamburger";
import Menus from "../../components/hamburger/menus";
import Loader from "../../components/loader/loader";

const PageWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 10px;
  padding: 2rem;
  margin: 2rem 3.5rem;

  @media (max-width: 500px) {
    padding: 0.5rem;
    margin: 1rem;
  }
`;
const CompanyWrapper = styled.div`
  display: flex;
  gap: 40px;
  @media (min-width: 768px) and (max-width: 1100px) {
    display: block;
  }
`;
const Image = styled.img`
  width: 310px;
  height: 271px;
  margin: 40px 0 0 10px;
  @media (max-width: 500px) {
    width: 143px;
    height: 126px;
    margin: 5px 0 0 5px;
  }
`;

const CompanyName = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #202020;
  margin: 0 0 0 20px;
  @media (max-width: 500px) {
    font-size: 20px;
    line-height: 27px;
    margin: 20px 0 0 -30px;
  }
  @media (min-width: 768px) and (max-width: 1100px) {
    margin-top: 20px;
  }
`;

const OutWrapper = styled.div`
  @media (max-width: 500px) {
    position: relative;
    top: 110px;
    right: 170px;
  }
`;
const CompDetailWrapper = styled.div`
  margin: 20px 0 10px 20px;
`;
const Wrapper = styled.div`
  display: flex;
  margin: 10px 0 20px 0;
  @media (min-width: 320px) and (max-width: 480px) {
    display: block;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    display: block;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    display: block;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    display: block;
  }
`;
const Location = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 23px;
  color: #525252;
  margin: 0 0 10px 20px;
  @media (max-width: 500px) {
    font-size: 14px;
    line-height: 19px;
    margin: 0 0 0 -30px;
    word-wrap: break-word;
    position: absolute;
  }
`;
const Website = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 23px;
  color: #525252;

  @media (min-width: 320px) and (max-width: 480px) {
    display: flex;
    position: relative;
    top: 128px;
    right: 220px;
  }
  img {
    margin: 0 0 0 50px;
  }
  span {
    position: relative;
    left: 24px;
  }
  @media (min-width: 481px) and (max-width: 1024px) {
    img {
      margin: 10px 22px;
    }
    span {
      position: relative;
      left: -10px;
    }
  }
`;
const CompanyDetail = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 23px;
  color: #525252;
  @media (max-width: 500px) {
    margin: 10px 0 0 -10px;
  }
`;
const CompanyDetails = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => (props.secondary ? props.secondary : "#D04C8D")};
  @media (max-width: 500px) {
    margin: 5px 0 0 -10px;
  }
`;
const CompanyDetails2 = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #d04c8d;
  @media (max-width: 500px) {
    margin: -20px 0 0 0;
  }
`;
const HrLine = styled.hr`
  border: 0.6px solid #c4c4c4;
  position: relative;
  @media (max-width: 500px) {
    position: relative;
    top: 110px;
  }
`;

const SectionWrapper = styled.div`
  background: ${(props) => (props.primary ? props.primary : "#FDFDFD")};
  border: 1px solid rgba(228, 228, 228, 0.26);
  border-radius: 10px;
  padding: 30px;
  margin: 40px 30px;

  @media (max-width: 500px) {
    margin: 140px 30px 40px 30px;
  }
  iframe {
    border-radius: 16px;
    margin: 10px 0 0 80px;
    @media (max-width: 500px) {
      margin: 20px 0 0 0;
    }
  }
`;

const Title = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${(props) => (props.secondary ? props.secondary : "#D04C8D")};
`;
const Content = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #202020;
  margin-top: 10px;
`;
const Video = styled.div`
  @media (max-width: 500px) {
    iframe {
      width: 200px;
    }
  }
`;
const DashboardWrapper = styled.div`
  @media (max-width: 1024px) {
    display: flex;
  }
`;

const HamburgerLayout = styled.div`
  @media (min-width: 1025px) {
    display: none;
  }
`;

const BreadCrumb = styled.div`
  display: flex;
  gap: 20px;
  margin: 0 0 -20px 70px;
`;

const CompanyBrand = () => {
  useSnapshot(companyProxy);
  const c = companyProxy;
  const { id } = useParams();
  const [editCompany, setEditCompany] = useState({});
  const test = useSelector((state) => state.talent);
  const [org_code, setOrgcode] = useState(null);
  const [primary, setPrimary] = useState("");
  const [secondary, setSecondary] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [images, setImages] = useState(undefined);
  const [articles, setArticles] = useState(undefined);
  const [about, setAbout] = useState("");
  const [profileDesc, setProfileDesc] = useState("");
  const [isOpenham, setOpenHam] = useState(false);
  const [loading,setLoading] = useState(true)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (org_code) {
      getData(org_code);
      getCompanyBrandingDetails(org_code);
      getCompanyBrandingImagesAPI();
      getCompanyBrandingArticlesAPI();
    }
  }, [org_code]);

  const getCompanyBrandingDetails = async (org_code) => {
    const details = await getCompanyBranding({
      code: org_code,
    });
    if (details?.status == 200 && details?.data) {
      setPrimary(details?.data?.primary_color);
      setSecondary(details?.data?.secondary_color);
      setVideoUrl(details?.data?.video_url);
      setAbout(details?.data?.about);
      setProfileDesc(details?.data?.video_description);
    }
  };

  const getCompanyBrandingImagesAPI = async () => {
    if (org_code) {
      const { data } = await getCompanyBrandingImages({
        code: org_code,
      });
      if (data && data.status === 1) {
        setImages(data.data);
      }
    }
  };

  const getCompanyBrandingArticlesAPI = async () => {
    if (org_code) {
      const { data } = await getCompanyBrandingArticles({
        code: org_code,
      });
      if (data && data.status === 1) {
        setArticles(data.data);
      }
    }
  };

  const getData = async () => {
    const { data } = await getCompanyData({
      code: org_code,
    });
    if (data && data.status === 1) {
      setEditCompany(data.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  function handleOnClick() {
    navigate("/companybrandingedit");
  }

  useEffect(() => {
    //setData(dt)
    companyProxy.joborg_code = id;
    if (companyProxy.joborg_code?.length > 0) {
      setOrgcode(companyProxy.joborg_code);
    }
  }, [companyProxy.joborg_code]);

  return (
    <div>
      <MainLayout>
        <DashboardWrapper>
          <HamburgerLayout>
            <HamburgerLay onToggle={() => setOpenHam(!isOpenham)} />
          </HamburgerLayout>
          {isOpenham && <Menus />}
        </DashboardWrapper>
        <BreadCrumb>
          <p style={{cursor:"pointer"}} onClick={() => navigate("/jobs")}>Jobs</p>
          <img
            src={rightarrow}
            alt="img"
            style={{ height: "14px", marginTop: "7px" }}
          />
          <p style={{cursor:"pointer"}} onClick={()=> navigate(-1)}>View Job</p>
          <img
            src={rightarrow}
            alt="img"
            style={{ height: "14px", marginTop: "7px" }}
          />
          <p style={{ color: "#D04C8D" ,cursor:"pointer"}}>View Company</p>
        </BreadCrumb>
        {loading ? (
          <Loader />
        ) : (
          <PageWrapper>
            <CompanyWrapper>
              <div>
                <Image src={editCompany?.logo_path} alt="image"></Image>
              </div>
              <div>
                <CompanyName>{editCompany?.name}</CompanyName>
                <Wrapper>
                  <Location>
                    📍
                    {editCompany?.bsn_city_abbr +
                      " " +
                      editCompany?.bsn_state_abbr +
                      "," +
                      editCompany?.bsn_country_abbr}{" "}
                  </Location>
                  <Website>
                    <img src={globe} alt="image" />
                    <span style={{}}>{editCompany?.website}</span>
                  </Website>
                </Wrapper>

                <OutWrapper>
                  <CompDetailWrapper>
                    <CompanyDetail>Motto</CompanyDetail>

                    <CompanyDetails2>{editCompany.moto}</CompanyDetails2>
                  </CompDetailWrapper>
                  <CompDetailWrapper>
                    <CompanyDetail>Type Of Organisation</CompanyDetail>
                    <CompanyDetails secondary={secondary}>
                      {editCompany.type_of_org_abbr}
                    </CompanyDetails>
                  </CompDetailWrapper>
                  <CompDetailWrapper>
                    <CompanyDetail>Sector</CompanyDetail>
                    <CompanyDetails secondary={secondary}>
                      {editCompany?.currentlySelectedcompanyData?.sector_abbr}
                    </CompanyDetails>
                  </CompDetailWrapper>
                  <CompDetailWrapper>
                    <CompanyDetail>Nature Of Business</CompanyDetail>
                    <CompanyDetails secondary={secondary}>
                      {editCompany?.currentlySelectedcompanyData?.bsn_nature}
                    </CompanyDetails>
                  </CompDetailWrapper>
                  <CompDetailWrapper>
                    <CompanyDetail>Company Size</CompanyDetail>
                    <CompanyDetails secondary={secondary}>
                      {editCompany?.currentlySelectedcompanyData?.org_size ||
                        0 + " "}{" "}
                      Employees
                    </CompanyDetails>
                  </CompDetailWrapper>
                </OutWrapper>
              </div>
            </CompanyWrapper>

            <HrLine />

            {/*Section*/}
            <SectionWrapper primary={primary}>
              <Title secondary={secondary}>About</Title>
              <hr />
              <Content>{about}</Content>
            </SectionWrapper>

            {/*Section2*/}
            <TabBranding />

            {/*Section3*/}
            <SectionWrapper primary={primary}>
              <Title secondary={secondary}>Profile Video</Title>
              <hr />
              <div className="row">
                <div className="col-xlg-6">
                  <Content>{profileDesc}</Content>
                </div>
                <Video className="col-xlg-6">
                  {
                    <iframe
                      src={videoUrl}
                      title="sample"
                      allowFullScreen
                      width="313px"
                      height="176px"
                    ></iframe>
                  }
                </Video>
              </div>
            </SectionWrapper>

            {/*Section4*/}
            <CompanyPhotosSwiper
              primary={primary}
              secondary={secondary}
              data={images}
            />

            {/*Section5*/}
            <CompanyInformationSwiper
              primary={primary}
              secondary={secondary}
              data={articles}
            />
          </PageWrapper>
        )}
      </MainLayout>
    </div>
  );
};
export default CompanyBrand;

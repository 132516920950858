import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import { Row } from "react-bootstrap";
import styled from "styled-components";
import { Colors } from "../../../utilities/colors";
import "./style.css";
import DeleteIcon from "../../../assets/icons/activeDelete.png";
import EditIcon from "../../../assets/svg/pencil.svg";
import { useSnapshot } from "valtio";
import { deviceStore } from "../../../utilities/devices";
const SectionDivisionWrapper = styled.div`
  padding: 1rem 1.5rem 1rem 1.5rem;
  background: #fdfdfd;
  border: 1px solid rgba(228, 228, 228, 0.26);
  box-sizing: border-box;
  border-radius: 5px;
  margin: 30px 0;
  overflow:auto;
  @media (max-width: 767px) {
    padding: 0.5rem;
  }
  
`;
const StyledText = styled.div`
  color: ${Colors.primaryText};
  font-size: 16px;
  font-family: "General Sans";
  font-weight: 500;
  font-style:"italic";
`;

const Degree = styled.div`
font-family: "General Sans",
font-size: "16px",
font-weight: 500,
`;

const Icon = styled.img`
  // margin: 0px 5px;
  // max-width: 20px;
  // max-height: 20px;
  // margin: 0px 5px;

`;

const EditIconW = styled.img`
color:rgb(208, 76, 141);
margin-right:25px;
`;
// const EditIc = styled.img`
// margin-right:25px;
// `;
const TimeLineData = ({ data, deleteEducation, editEducation }) => {
useSnapshot(deviceStore)

  return (
    <div>
      <Row>
        <SectionDivisionWrapper>
          <Timeline >
            {data && data.sort((a, b) => {
              return b.completion_year - a.completion_year
            }).length > 0 && data.map((m, i) => {
              if(deviceStore.checkbrowser){
                return <TimelineItem key={i}>
                <TimelineSeparator>
                  <TimelineDot style={{ background: i === 0 ? "#D04C8D" : '' }} />
                  {i < data.length - 1 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent style={{ display: 'flex', width: '1000px', justifyContent: 'space-between' }}>
                  <div className="col-md-4">
                    <Degree>
                      {m.degree}
                    </Degree>
                    <StyledText>
                      {m.institution}
                    </StyledText>
                  </div>
                  <div className="col-md-4" style={{ width: '300px' }}>
                    <p>{m.description}</p>
                  </div>
                  <div className="col-md-3">
                    <p>{m.completion_year}</p>
                  </div>
                  <div className="col-md-1">
                    <EditIconW onClick={() => editEducation(m.id)} src={EditIcon} />
                    <Icon onClick={() => deleteEducation(m.id)} src={DeleteIcon} />
                  </div>
                </TimelineContent>
              </TimelineItem>
              }else{
                return <TimelineItem key={i}>
                <TimelineSeparator>
                  <TimelineDot style={{ background: i === 0 ? "#D04C8D" : '' }} />
                  {i < data.length - 1 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent style={{ display: 'flex', width: '1000px', justifyContent: 'space-between' }}>
                <div className="col-12">
                  <div className="row">
                  <div className="col-12"><Degree>
                      {m.degree}-{m.completion_year}
                    </Degree></div>
                  <div className="col-12"><StyledText>
                      {m.institution}
                    </StyledText></div>
                  <div className="col-12">{m.description}</div>
                  <div className="col-12" style={{textAlign:"left"}}><EditIconW onClick={() => editEducation(m.id)} src={EditIcon} /><Icon onClick={() => deleteEducation(m.id)} src={DeleteIcon} /></div>
                </div>
                  </div>
                </TimelineContent>
              </TimelineItem>
              }
              
            })}
          </Timeline>
        </SectionDivisionWrapper>
      </Row>
    </div>
  );
};

export default TimeLineData;
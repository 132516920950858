import { useEffect, useState } from "react";

import styled from "styled-components";
import "react-phone-number-input/style.css";
import ErrorField from "../../../components/errorField/errorField";
import { Colors } from "../../../utilities/colors";
import { Row, Col } from "react-bootstrap";
import CustomButton from "../../../components/button/button";
import Input from "../../../components/input/input";
import { createTheme, IconButton, ThemeProvider } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ReactComponent as CalenderIcon } from "../../../assets/icons/svg/calendarIcon.svg";
import TextArea from "../../../components/description/description";
import EventsTable from "./events-table";
import {
  deleteEventDetails,
  getEventDetails,
  saveEventDetails,
  updateEventDetails,
} from "../../../services/apiCalls";
import { ConvertDate } from "../utils/date-converter";
import { useRequired } from "../utils/useRequired";
import CancelIcon from "../../../assets/icons/skillCancel.png";
import ConfirmationDialog from "../confirmation-dialog/confirmation-dialog";
import { useTranslation } from "react-i18next";

const materialTheme = createTheme({
  palette: {
    primary: {
      main: "#D44B9C",
    },
  },
  overrides: {
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: "#D44B9C",
      },
    },
  },
});

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const DatesWrapper = styled(FlexWrapper)`
  label {
    position: relative;
    width: -webkit-fill-available;
  }
`;

const FormGroup = styled.div`
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;

const CardContainer = styled.div`
  margin-top: 4%;
`;

const CalenderIconWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  cursor: pointer;
`;

const Flex = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const Anchor = styled.a`
  color: #504f8c;
`;

const TextInput = styled.div`
  color: ${Colors.inputTextDark};
`;

const initialState = {
  event_title: "",
  start_date: null,
  end_date: null,
  description: "",
};
const errors = {
  event_title: null,
  start_date: null,
  end_date: null,
  description: null,
};
const Events = ({ notification }) => {
  const [formState, setFormState] = useState(initialState);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [showAddEvent, setShowAddEvent] = useState(false);
  const [isSave, setIsSave] = useState(true);
  const { errorFields, validateForm, setErrorFields } = useRequired(
    formState,
    errors
  );
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState();

  useEffect(() => {
    fetchEventDetails();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
    setErrorFields({ ...errorFields, [name]: null });
  };

  const clearDate = (e, name) => {
    e.preventDefault();
    setFormState({ ...formState, [name]: null });
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      return;
    }
    let { start_date, end_date, event_title, description, id } = formState;
    start_date = ConvertDate(start_date, "yyyy-mm-dd");
    end_date = ConvertDate(end_date, "yyyy-mm-dd");
    if (isSave) {
      const payload = {
        data: [{ start_date, end_date, event_title, description }],
      };
      const { data, status } = await saveEventDetails(payload);
      checkStatus(data, status, "save");
    } else {
      updateEvent(id);
    }
  };

  const updateEvent = async (id) => {
    let { start_date, end_date, event_title, description } = formState;
    start_date = ConvertDate(start_date, "yyyy-mm-dd");
    end_date = ConvertDate(end_date, "yyyy-mm-dd");
    const payload = { start_date, end_date, event_title, description };
    const { data, status } = await updateEventDetails(id, payload);
    checkStatus(data, status, "update");
  };

  const editEvent = (idd) => {
    setIsSave(false);
    const { start_date, end_date, event_title, description, id } =
      selectedEvents.find((f) => f.id === idd);
    setFormState({ start_date, end_date, event_title, description, id });
    setShowAddEvent(true);
  };

  const deleteEvent = async (id) => {
    const { data, status } = await deleteEventDetails(id);
    checkStatus(data, status, "delete");
  };

  const checkStatus = (data, status, method) => {
    const methodTmp =
      method === "save" ? "saved" : method === "update" ? "updated" : "deleted";
    if (data.status === 1 && status === 200) {
      fetchEventDetails();
      if (method === "delete") {
        setShowConfirmation(false);
        notification("success", t("Event details deleted successfully"));
      } else if (method == "save") {
        notification("success", t("Event details saved successfully"));
      } else if (method == "update") {
        notification("success", t("Event details updated successfully"));
      }
    } else {
      if (method == "save") {
        notification("error", t("Unable to save event details"));
      } else if (method == "update") {
        notification("error", t("Unable to update event details"));
      } else if (method == "delete") {
        notification("error", t("Unable to delete event details"));
      }
    }
  };

  const fetchEventDetails = async () => {
    const { data, status } = await getEventDetails();
    if (
      status === 200 &&
      data.status === 1
      // && Object.keys(data.data).length > 0
    ) {
      setSelectedEvents(data.data);
      resetForm();
    }
  };

  const resetForm = () => {
    setFormState({ ...initialState });
    setShowAddEvent(false);
    setIsSave(true);
    setErrorFields({ ...errors });
  };

  const showConfirm = (id) => {
    setShowConfirmation(true);
    setDeleteId(id);
  };
  
  const { t } = useTranslation();

  return (
    <div>
      {!showAddEvent && (
        <Flex>
          <Anchor onClick={() => setShowAddEvent(true)}>{t("Add Other Event")}</Anchor>
        </Flex>
      )}
      {showAddEvent && (
        <div>
          <CardContainer>
            <Row>
              <Col md="4">
                <FormLabel>{t("Event Title")}*</FormLabel>
                <Input
                  placeholder={t("Enter Event Title")}
                  name="event_title"
                  value={formState.event_title}
                  onChange={handleChange}
                />
                {errorFields["event_title"] && (
                  <ErrorField err={t(errorFields["event_title"])} />
                )}
              </Col>
              <Col md="4">
                <FormLabel>{t("Duration")}*</FormLabel>
                <DatesWrapper>
                  <label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={materialTheme}>
                        <DatePicker
                          autoOk
                          disableFuture
                          variant="inline"
                          format="dd/MM/yyyy"
                          placeholder={t("Enter Duration Start Date")}
                          value={formState.start_date ?? null}
                          onChange={(val) =>
                            handleChange({
                              target: { name: "start_date", value: val },
                            })
                          }
                          views={["year", "month", "date"]}
                          fullWidth
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                    <CalenderIconWrapper>
                      <CalenderIcon />
                      {formState.start_date && (
                        <IconButton onClick={(e) => clearDate(e, "start_date")}>
                          <img src={CancelIcon} alt="" />
                        </IconButton>
                      )}
                    </CalenderIconWrapper>
                  </label>
                </DatesWrapper>
                {errorFields["start_date"] && (
                  <ErrorField err={t("Enter Duration Start Date")} />
                )}
              </Col>
              <Col md="4" className="eventWrapper">
                <div className="noHeader" style={{ paddingTop: "33px" }}></div>
                <DatesWrapper>
                  <label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={materialTheme}>
                        <DatePicker
                          autoOk
                          // disableFuture
                          variant="inline"
                          format="dd/MM/yyyy"
                          placeholder={t("Enter Duration End Date")}
                          value={formState.end_date ?? null}
                          onChange={(val) =>
                            handleChange({
                              target: { name: "end_date", value: val },
                            })
                          }
                          views={["year", "month", "date"]}
                          fullWidth
                          minDate={new Date(formState.start_date).setDate(
                            new Date(formState.start_date).getDate()
                          )}
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                    <CalenderIconWrapper>
                      <CalenderIcon />
                      {formState.end_date && (
                        <IconButton onClick={(e) => clearDate(e, "end_date")}>
                          <img src={CancelIcon} alt="" />
                        </IconButton>
                      )}
                    </CalenderIconWrapper>
                  </label>
                </DatesWrapper>
                {errorFields["end_date"] && (
                  <ErrorField err={t("Enter Duration End Date")} />
                )}
              </Col>
            </Row>
          </CardContainer>
          <CardContainer>
            <Row>
              <Col md="12">
                <FormLabel>{t("Description")}*</FormLabel>
                <TextArea
                  rows="4"
                  placeholder={t("Enter Description")}
                  name="description"
                  value={formState.description}
                  onChange={handleChange}
                  maxLength="250"
                />
                {errorFields["description"] && (
                  <ErrorField err={t(errorFields["description"])} />
                )}
              </Col>
            </Row>
          </CardContainer>
          <Row>
            <ButtonWrapper>
              <CustomButton
                onClick={handleSubmit}
                name={isSave ? t("Save") : t("Update")}
              />
              &nbsp;&nbsp;
              <CustomButton onClick={resetForm} secondary name={t("Cancel")} />
            </ButtonWrapper>
          </Row>
        </div>
      )}
      {selectedEvents.length > 0 ? (
        <FormGroup>
          <EventsTable
            deleteEvent={(id) => showConfirm(id)}
            editEvent={(id) => editEvent(id)}
            data={selectedEvents}
          />
        </FormGroup>
      ) : (
        <>
          <TextInput>{t("You have not added any custom event.")}</TextInput>
        </>
      )}
      <ConfirmationDialog
        id={deleteId}
        show={showConfirmation}
        message={t("Are you sure you want to delete this record?")}
        onAccept={(id) => deleteEvent(id)}
        closeModal={() => setShowConfirmation(false)}
      />
    </div>
  );
};
export default Events;

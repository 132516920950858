import "./dashboardPopup.css";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";

function DashboardPopup({ isOpen, setOpen, pop_status }) {

  const displayCheck = () => {
    localStorage.setItem("pop_status", 2);
    setOpen(false);
  };
  const { t } = useTranslation();
  
  return (
    <>
      {isOpen === true ? (
        <div className="modalHead">
          <div className="modalBackground" 
          onClick={() => setOpen(false)} 
          />
          <div className="modalContainer">
            <div className="titleCloseBtn">
              <p>{t("mynext Tutorial video")}</p>
              <p
                onClick={() => setOpen(false)}
                style={{
                  cursor: "pointer",
                }}
              >
                {t("Close")} X
              </p>
            </div>

            <ReactPlayer
              url="https://www.youtube.com/watch?v=9OdsiS0JXYg"
              className="react-player"
              playing={true}
              width="100%"
              height="100%"
            />
            <div className="bottom_tab">
              <label
                style={{
                  alignItems: "center",
                  fontSize: 14,
                  display: "flex",
                }}
              >
                <input
                  type="checkbox"
                  style={{ marginRight: "5px" }}
                  onClick={displayCheck}
                />
                {t("Don't display this again")}
              </label>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default DashboardPopup;

import { useState, useEffect } from "react";

import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import UserCreationLayout from "../../layouts/userCreation/userCreationLayout";
import IntroSection from "../../components/introSection/introSection";
import Button from "../../components/button/button";
import PaginationDivider from "../../components/paginationDivider/paginationDivider";
import UploadLogo from "../../components/uploadImage/uploadLogo";
import UploadIcon from "../../assets/uploadImg.png";
import CoverImage from "../../assets/uploadCover.png";
import UploadPlusIcon from "../../assets/uploader.png";
import { removeKeys } from "../../utilities/commonFunctions";
import { onBoardFormSubmission } from "../../services/apiCalls";
import { updateValues } from "../selfIntro/reducer";
import ErrorField from "../../components/errorField/errorField";
import { useTranslation } from "react-i18next";

// styling starts
const PageWrapper = styled.section`
  @media (max-width: 1010px) {
    width: 100%;
  }
`;
const LabelText = styled.label`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;
const Flexbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1090px) {
    flex-direction: column;
  }
`;
const SaveExitBtn = styled(LabelText)`
  color: #504f8c;
  text-decoration: underline;
  cursor: pointer;
  /* margin-left: 30px; */
  text-align: left;
  /* margin-left: 35px; */
  @media (max-width: 900px) {
    display: block;
    text-align: center;
  }
`;
const ImageUploader = styled(Flexbox)`
  margin: 20px auto;
  justify-content: center;
  width: 254px;
  height: 254px;
  position: relative;
`;
const Image = styled.img`
  border-radius: 50%;
  object-fit: contain;
  height: 100%;
  width: 100%;
`;
const PlusIcon = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
`;
const UploadWrapper = styled.div`
  width: 75%;

  @media (max-width: 1090px) {
    width: 100%;
  }
`;
const CenterAlignText = styled.div`
  text-align: center;
`;
const UploadText = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-decoration: ${(props) => (props.underline ? props.underline : "none")};
  color: ${(props) => (props.color ? props.color : "none")};
  margin: ${(props) => (props.margin ? props.margin : "35px 0")};
  text-align: ${(props) => (props.align ? props.align : "center")};
`;
const UnorderList = styled.ul`
  margin: 0;
  > li {
    text-align: initial;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #6b6b6b;
  }
`;
// styling ends

export default function Uploadprofilepic() {
  const formValues = useSelector((state) => state.selfIntroOne.value);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState("");
  const [openModal, setUploadModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onOpenUploader = () => {
    setUploadModal(true);
  };

  useEffect(() => {
    if (!window.allow_user_continue) navigate("/self-intro");
  }, [window.allow_user_continue]);

  const onBack = () => {
    switch (formValues.talent_status) {
      case "studying":
        navigate("/studying");
        break;
      case "employed":
        navigate("/employed");
        break;
      case "internship":
        navigate("/studying");
        break;
      case "unemployed":
        navigate("/employed");
        break;
      case "army":
        navigate("/army-personnel");
        break;
    }
  };

  const onContinue = () => {
    navigate("/overview");
  };

  const onContinueSubmit = () => {
    if (formValues.profile_picture) {
      navigate("/overview");
    } else {
      setFormErrors("Please upload a profile picture");
    }
  };

  const onSave = async () => {
    setLoading(true);
    const req = removeKeys({ ...formValues });
    const { data } = await onBoardFormSubmission({
      ...req,
      step: formValues.profile_picture ? 4 : 3,
    });
    if (data && data.status === 1) {
      dispatch(updateValues({ ...formValues }));
      navigate("/dashboard");
      setLoading(false);
      setFormErrors("");
    } else {
      setLoading(false);
      setFormErrors(data && data.message);
    }
  };

  const onCloseModal = () => {
    setUploadModal(false);
  };

  const { t } = useTranslation();

  return (
    <UserCreationLayout sidebarImg={CoverImage}>
      <PageWrapper>
        <IntroSection name={t("Tell us more about yourself")} margin="0" />
        <PaginationDivider step={3} />
        <UploadWrapper>
          <ImageUploader>
            <Image
              src={
                formValues.profile_picture
                  ? formValues.profile_picture
                  : UploadIcon
              }
              alt="image-uploader"
            />
            <PlusIcon
              onClick={onOpenUploader}
              src={UploadPlusIcon}
              alt="plus-logo"
            />
          </ImageUploader>
          <CenterAlignText>
            <UploadText margin="35px 0 10px 0" align="left" color="#6B6B6B">
              {t("Your Profile Picture will be visible in your CV when you apply for internships via the mynext portal so make sure:")}
            </UploadText>
            <UnorderList>
              <li>{t("It is a picture of yourself")}</li>
              <li>{t("It is clear and bright")}</li>
            </UnorderList>
            <UploadText color="#D04C8D">
              {t("You can change your profile picture at any time in the settings.")}
            </UploadText>
            <UploadText
              onClick={onContinue}
              color="#504F8C"
              underline="underline"
            >
              {t("Skip this step, I will upload it later")}
            </UploadText>
          </CenterAlignText>
          <Flexbox>
            <Button onClick={onContinueSubmit} width="50%" name={t("Continue")} />
            <Button onClick={onBack} width="50%" secondary name={t("Back")} />
          </Flexbox>
          {formErrors && <ErrorField err={formErrors} />}
          <SaveExitBtn onClick={onSave}>{t("Save and Exit")}</SaveExitBtn>
        </UploadWrapper>
      </PageWrapper>
      {openModal && <UploadLogo onClose={onCloseModal} open={openModal} />}
    </UserCreationLayout>
  );
}

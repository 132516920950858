import React from 'react';
import { messagesData } from './messages';
import { accessValueByKey } from './searchFunction';
import { t } from 'i18next';

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const addMessageToState = (message) => {
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  const handleShowOptions = () => {
    const message = createChatBotMessage(t("Sure! Here are my options."), 
    {
      widget: 'options',
    });
    addMessageToState(message);
  };

  const handleDontUnderstand = () => {
    const message = createChatBotMessage(t("Sorry, I don't know the answer to that. Try with a different keyword or select from my options."),
    {
      widget: 'options',
    });
    addMessageToState(message);
  }

  const handleListBenefits = () => {
    const message = createChatBotMessage(t("Mynext has many benefits for you such as:"),
    {
      widget: 'listComponent',
    });
    addMessageToState(message);
  }

  const handleRating = () => {
    const message = createChatBotMessage(t("How would you rate this conversation?"),
    {
      widget: 'chatbotRating',
    });
    addMessageToState(message);
  }

  const handleFeedback = () => {
    const message = createChatBotMessage(t("Thank you for your rating. If you have further comments or feedback, please type in the box below."),
    {
      widget: 'feedback',
    });
    addMessageToState(message);
  }

  const handleReceivedFeedback = () => {
    const message = createChatBotMessage(t("We appreciate your feedback! It helps us strive for better service. Thank you!"));
    addMessageToState(message);
  }

  const handleDisplayMessage = (key) => {
    const message = createChatBotMessage(t(accessValueByKey(messagesData.talent.messages, key)),
    {
      widget: 'resolution',
    });
    addMessageToState(message);
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleShowOptions,
            handleDontUnderstand,
            handleDisplayMessage,
            handleListBenefits,
            handleRating,
            handleFeedback,
            handleReceivedFeedback,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import styled from "styled-components";
import CloseIcon from "../../assets/closeIcon.png";

const CloseIconWrapper = styled.div`
  top: -20px;
  right: -25px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.18);
  border-radius: 50%;
  width: 66px;
  height: 66px;
  align-items: center;
  justify-content: center;
  display: flex;
  position: absolute;
`;

const ModalContentWrapper = styled.div`
  overflow-y: auto; /* Enable vertical scrolling if content exceeds the height */
`;
export default function CustomModal(props) {
  const closeIcon = (
    <CloseIconWrapper>
      <img src={CloseIcon} alt="close icon modal" />
    </CloseIconWrapper>
  );
  return (
    <Modal 
      styles={{
        modal: { maxWidth: "90%", margin: "auto" }, // Set the maximum width of the modal
      }}
      open={props.open}
      showCloseIcon={props.show}
      closeIcon={props.noCloseIcon ? "" : closeIcon}
      center
      closeOnOverlayClick={false}
      closeOnEsc={false}
      onClose={props.onCloseModal}
      classNames="modalContent"
    >
    <ModalContentWrapper>{props.children}</ModalContentWrapper>
   </Modal>
  );
}

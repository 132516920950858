export const isValidObject = (obj) => {
  if (obj !== null && typeof obj === "object" && Object.keys(obj).length > 0) {
    return true;
  } else {
    return false;
  }
};

export const isValidArray = (array) => {
  if (array !== null && array !== undefined && Array.isArray(array) && array.length > 0) {
    return true;
  } else {
    return false;
  }
};

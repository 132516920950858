import { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import UserCreationLayout from "../../layouts/userCreation/userCreationLayout";
import IntroSection from "../../components/introSection/introSection";
import InputGroup from "../../components/inputGroup/inputGroup";
import Button from "../../components/button/button";
import SocialButton from "../../components/socialButton/socialButton";
import EmailVerification from "../../components/emailVerification/emailVerification";
import LoginSuccessModal from "../../components/loginSuccessModal/loginSuccessModal";
import Loader from "../../components/loader/loader";
import GoogleLogo from "../../assets/google.png";
import AppleLogo from "../../assets/apple.png";
import RegisterCoverImg from "../../assets/registerCover.png";
import { Colors } from "../../utilities/colors";
import {
  validateInputs,
  validEmailRegex,
  checkBothPasswords,
  validPassword,
  removeKeys
} from "../../utilities/commonFunctions";
import { errorMessages } from "../../utilities/commonMessages";
import ErrorField from "../../components/errorField/errorField";
import {
  authSignUp,
  socialSignUp,
  onTriggerOtp,
  onOtpVerify,
  validateEmailAddress,
  onBoardFormGetData,
} from "../../services/apiCalls";
import { googleLoginObj, fbLoginObj } from "../../utilities/constants";
import CheckboxInput from "../../components/checkboxInput/checkboxInput";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { deviceStore } from "../../utilities/devices";
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import firebase from "firebase/app";
import "firebase/auth";
import {
  SignInWithApple,
  SignInWithAppleResponse,
  SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in';
import { ReactComponent as AppleIcon } from "../../assets/svg/apple.svg";
import axios from 'axios';
import { useGoogleLogin, hasGrantedAllScopesGoogle } from '@react-oauth/google';
import ReactPixel from 'react-facebook-pixel';
import { updateValues } from "../selfIntro/reducer";
import { useDispatch, useSelector } from "react-redux";

// styling starts
const LoginPageWrapper = styled.section``;
const Anchor = styled.a`
  text-decoration: none;
  color: #696f79;
`;

const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;
const HeaderWrapper = styled(FlexBox)`
  margin-bottom: 20px;
`;
const LabelText = styled.label`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${Colors.primary};
  margin-left: 10px;
  cursor: pointer;
  text-decoration: none;
`;
const Visibility = styled.div`
  position: absolute;
  display: inline;
  right: 14px;
  top: 2.5rem;
  cursor: pointer;
  svg {
    fill: gray;
  }
`;
const PasswordInput = styled.div`
  position: relative;
`;
const FormWrapper = styled.div`
  margin: 10px 0px;
  max-width: 440px;

  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;
const TermsConditionsWrapper = styled(FlexBox)`
  // > p {
  //   font-weight: 500;
  //   font-size: 16px;
  //   margin-left: 10px;
  //   line-height: 22px;
  //   color: #696f79;
  //   > a {
  //     margin-left: 5px;
  //     text-decoration: none;
  //     color: #696f79;
  //   }
  // }
  font-size: 16px;
  color: #696f79;
`;
const PasswordError = styled.label`
  color: ${Colors.red};
  font-size: 16px;
  margin-top: 5px;
  display: block;
`;
const SocialIconsWrapper = styled(FlexBox)`
  justify-content: center;
`;
// styling ends

export default function SignUpPage() {
  useSnapshot(deviceStore)
  const checkbrowser = deviceStore.checkbrowser
  const [userDetail, setUserDetail] = useState({
    email: "",
    password: "",
    confirm_password: "",
  });
  const [formErrors, setFormErrors] = useState("");
  const [fieldErrors, setErrors] = useState("");
  const [otpErrors, setOtpErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [showEmailVerification, setShowEmailVerification] = useState(false);
  const [showEmailVerificationNew, setShowEmailVerificationNew] = useState(false);
  const [otpVerification, setOtpVerification] = useState(false);
  const [openModal, setSuccessModal] = useState(false);
  const [radioCheck, setRadioCheck] = useState(true);
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShownConfirm, setPasswordShownConfirm] = useState(false);
  const [userGoogle, setUserGoogle] = useState([]);
  const dispatch = useDispatch();
  const formValues = useSelector((state) => state.selfIntroOne.value);

  const { email, password, confirm_password } = userDetail;
  const navigate = useNavigate();

  const fboptions = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };

  const loginUser = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      const hasAccess = hasGrantedAllScopesGoogle(
        tokenResponse,
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile',
      );
      setUserGoogle(tokenResponse)
      console.log(tokenResponse, hasAccess)
    },
  });

  useEffect(
    () => {
      if (userGoogle) {
        axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${userGoogle.access_token}`, {
            headers: {
              Authorization: `Bearer ${userGoogle.access_token}`,
              Accept: 'application/json'
            }
          })
          .then((res) => {
            console.log(res.data);
            const req = { ...googleLoginObj, username: res.data.email };
            setLoading(true);
            console.log(req);
            onSocialLogin(req);
            // setProfile();
          })
          .catch((err) => console.log(err));
      }
    },
    [userGoogle]
  );







  const logIn = async () => {
    const response = await GoogleAuth.signIn();
    if (response && response.email) {
      const req = { ...googleLoginObj, username: response.email };
      setLoading(true);
      onSocialLogin(req);
    }
  };
  const appleweblogIn = async () => {
    const provider = new firebase.auth.OAuthProvider('apple.com');
    provider.addScope('email');
    firebase.auth().signInWithPopup(provider).then((result) => {
      const user = result.user;
      if (user && user.email) {
        const req = { ...googleLoginObj, username: user.email };
        setLoading(true);
        onSocialLogin(req);
      } else {
        setLoading(false);
      }
    })
      .catch((error) => {
        const errorCode = error?.code;
        const errorMessage = error?.message;
        const email = error?.customData?.email;
      });

  };

  const appleioslogIn = async () => {
    let options = {
      clientId: 'my.mynext.talent',
      redirectURI: 'https://mynext-talent.firebaseapp.com/__/auth/handler',
      scopes: 'email name',
      state: '12345',
      nonce: 'nonce',
    };

    const res = await SignInWithApple.authorize(options);
    if (res && res.response && res.response.email) {
      const req = { ...googleLoginObj, username: res.response.email };
      setLoading(true);
      onSocialLogin(req);
    }
  }

  // email verification starts
  const onEmailVerification = async () => {
    if (userDetail.email && !fieldErrors) {
      setErrors("");
      let body = {
        email_address: userDetail.email,
      };
      const { data, status } = await validateEmailAddress(body);
      if (status === 200) {
        if (data.status === 1) {
          validateEmail();
          setOtpErrors("");
          toast.success(t("OTP has been sent successfully to your entered Email ID"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setOtpErrors(data.message);
          setShowEmailVerification(false);
          setShowEmailVerificationNew(false);
        }
      }
    } else {
      setErrors({ email: t("Please enter a valid email.") });
    }
  };
  const validateEmail = async () => {
    const { data, status } = await onTriggerOtp({ email_address: email });
    if (status === 200) {
      onEmailTriggered(data);
    }
  };
  const onEmailTriggered = ({ data, message, status }) => {
    if (status === 1) {
      setShowEmailVerification(true);
      setShowEmailVerificationNew(true);
      setOtpVerification(false);
    }
  };
  // email verification ends

  // otp verification starts
  const onOtpVerification = async (otp) => {
    if (otp) {
      const { data, status } = await onOtpVerify({
        email_address: email,
        otp_in: otp,
      });
      if (status === 200) {
        onOtpVerified(data);
      }
    }
  };
  const onOtpVerified = ({ data, message, status }) => {
    if (status === 1) {
      setOtpVerification(true);
      setShowEmailVerification(false);
      setShowEmailVerificationNew(true);
      setOtpErrors("");
    } else {
      setOtpVerification(false);
      setOtpErrors(message);
    }
  };
  // otp verification ends

  const radiohandler = () => {
    setRadioCheck(!radioCheck);
  };

  // input change function starts
  const inputChange = (event) => {
    const { name, value } = event.target;
    let emailTolowerCase = value;
    if (name === "email") {
      emailTolowerCase = value && value.toLowerCase();
    }
    fieldValidations(name, emailTolowerCase);
    const newUserDetail = { ...userDetail, [name]: emailTolowerCase };
    setUserDetail(newUserDetail);
  };
  // input change function ends

  // field validations
  const fieldValidations = (name, value) => {
    switch (name) {
      case "email":
        const errVal = validEmailRegex.test(value)
          ? ""
          : { [name]: t("The e-mail address entered is incorrect") };
        setErrors(errVal);
        break;
      case "password":
        const errValue = checkBothPasswords(value, confirm_password)
          ? ""
          : { confirm_password: errorMessages.passwords };
        setErrors(errValue);

        const valPass = validPassword.test(value)
          ? ""
          : { password: t("Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character") };
        setErrors(valPass);
        break;
      case "confirm_password":
        const errPwd = checkBothPasswords(password, value)
          ?
          validPassword.test(value)
            ? ""
            : { password: t("Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character") }
          :
          checkBothPasswords(password, value)
            ?
            { confirm_password: t("Entered passwords do not match") }
            :
            {
              confirm_password: t("Entered passwords do not match"),
              password: t("Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character")
            };
        setErrors(errPwd);
        break;
      default:
        break;
    }
  };

  const onSignUp = () => {
    const isValid = validateInputs(userDetail);
    if (isValid && otpVerification && radioCheck) {
      setFormErrors("");
      setLoading(true);
      onFormSubmission();
    } else {
      if (userDetail.email === "" || userDetail.password === "" || userDetail.confirm_password === "") {
        setFormErrors(t("The email and password are mandatory fields."));
      } else {
        setFormErrors(
          !radioCheck
            ? errorMessages.terms
            : !isValid
              ? errorMessages.required
              : errorMessages.otp
        );
      }

    }
  };

  const onFormSubmission = async () => {
    const { data, status } = await authSignUp(userDetail);
    if (status === 200) {
      var fbPixelTrackingID = process.env.REACT_APP_ENV === "UAT" ? process.env.REACT_APP_FACEBOOK_PIXEL_EVENT_UAT : process.env.REACT_APP_FACEBOOK_PIXEL_EVENT_PRODUCTION;
      await ReactPixel.init(fbPixelTrackingID, fboptions);
      await ReactPixel.track('InitiateCheckout');
      setLoading(false);
      userLoginFlow(data);
    }
  };

  const userLoginFlow = async ({ data, message, status }) => {
    console.log("DataLoginFlow",data)

    if (status === 0) {
      setFormErrors(message);
    } else {
      var fbPixelTrackingID = process.env.REACT_APP_ENV === "UAT" ? process.env.REACT_APP_FACEBOOK_PIXEL_EVENT_UAT : process.env.REACT_APP_FACEBOOK_PIXEL_EVENT_PRODUCTION;
      await ReactPixel.init(fbPixelTrackingID, fboptions);
      await ReactPixel.track("Complete Registration");
      setFormErrors("");
      setSuccessModal(true);
      localStorage.setItem("auth_token", data.token);
      navigateToOnBoard(data)

      // window.allow_user_continue = true;
      // setTimeout(() => {
      //   navigate("/self-intro");
      // }, 3000);
    }
  };
  const navigateToOnBoard = async (formData) => {
    const { data, status } = await onBoardFormGetData();
    if (data && data.status === 1) {
      console.log("This is form data login",formData );
      console.log("This is onBoardFormGetData",data )
      setLoading(false);
      const newObj = removeKeys(data.data);
      dispatch(updateValues({ ...formValues, ...newObj }));
      const { step } = formData.additional_info;
      if(step === 0){
        window.allow_user_continue = true;
        navigate("/dashboard");
      }
      if(data.is_deactivated === 1){
        setLoading(false);
        navigate("/self-intro");
      } else {
        switch (step) {
          case 2:
            window.allow_user_continue = true;
            navigate("/aboutme");
            break;
          case 3:
            window.allow_user_continue = true;
            navigate("/uploadpic");
            break;
          case 4:
            window.allow_user_continue = true;
            navigate("/dashboard");
            break;
          // case 4:
          //   window.allow_user_continue = true;
          //   break;
          //   navigate("/overview");
          default:
            // authSignin().then(() => {
              // navigate(location.state?.path || "/dashboard");
            // });
            navigate("/aboutme");
            break;
        }
      }
     
    }
    if (data && data.status === 0) {
      setLoading(false);
      navigate("/self-intro");
    }
  };
  const successResponseGoogle = (response) => {
    const { googleId, profileObj } = response;
    if (googleId) {
      const req = { ...googleLoginObj, username: profileObj.email };
      setLoading(true);
      onSocialLogin(req);
    }
  };

  const onSocialLogin = async (req) => {
    const { data, status } = await socialSignUp(req);
    if (status === 200) {
      var fbPixelTrackingID = process.env.REACT_APP_ENV === "UAT" ? process.env.REACT_APP_FACEBOOK_PIXEL_EVENT_UAT : process.env.REACT_APP_FACEBOOK_PIXEL_EVENT_PRODUCTION;
      await ReactPixel.init(fbPixelTrackingID, fboptions);
      await ReactPixel.track('InitiateCheckout');
      setLoading(false);
      userLoginFlow(data);
    }
  };

  const responseFacebook = (response) => {
    const { email } = response;
    if (email) {
      const req = { ...fbLoginObj, username: email };
      setLoading(true);
      onSocialLogin(req);
    }
  };

  const errorResponseGoogle = (response) => {
  };

  const onNavigate = (path) => {
    navigate(path);
  };
  const togglePasswordVisiblity = () => {
    if (otpVerification) {
      setPasswordShown(passwordShown ? false : true);
    }
  };

  const toggleConfirmPasswordVisiblity = () => {
    if (otpVerification) {
      setPasswordShownConfirm(passwordShownConfirm ? false : true);
    }
  };

  const { t } = useTranslation();

  return (
    <UserCreationLayout sidebarImg={RegisterCoverImg}>
      <LoginPageWrapper>
        <HeaderWrapper>
          <svg
            width="11"
            height="18"
            viewBox="0 0 11 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 1.68313L9.32219 0.105469L0 8.92969L9.33162 17.7539L11 16.1762L3.33676 8.92969L11 1.68313Z"
              fill="#D04C8D"
            />
          </svg>
          <LabelText onClick={() => onNavigate("/")}>{t("Back")}</LabelText>
        </HeaderWrapper>
        <IntroSection
          name={t("Register your Account!")}
          description={t("Please provide the following details to register yourself successfully")}
        />
        {/* <Divider /> */}
        <FormWrapper className="mt-3">
          <ReactTooltip
            id="emailId"
            place="top"
            effect="solid"
            globalEventOff="focusin"
          >
            {t("E-mail address that can be used to contact you.")}
          </ReactTooltip>
          <InputGroup
            name={t("Email address*")}
            err={fieldErrors && fieldErrors["email"]}
            dataId="emailId"
            input={{
              placeholder: t("Enter email address"),
              value: email,
              type: "email",
              name: "email",
              disabled: showEmailVerificationNew,
              onChange: inputChange,
              messageTip: t("E-mail address that can be used to contact you."),
              className: "w-100"
            }}
            needHelp
            helperFunc={onEmailVerification}
            helpText={
              otpVerification || showEmailVerification
                ? ""
                : t("Send verification code")
            }
          />

          {showEmailVerification && (
            <EmailVerification
              verifyCode={onOtpVerification}
              isSuccess={otpVerification}
              onSendNewCode={onEmailVerification}
              dataId="otpId"
            />
          )}
          <ReactTooltip
            id="otpId"
            place="top"
            effect="solid"
            globalEventOff="focusin"
          >
            {t("Enter verification code")}
          </ReactTooltip>
          {otpErrors && <ErrorField err={otpErrors} />}
          <PasswordInput>
            <InputGroup
              name={t("Create password*")}
              err={fieldErrors && fieldErrors["password"]}
              dataId="passId"
              input={{
                placeholder: t("Password"),
                value: password,
                type: passwordShown ? "text" : "password",
                name: "password",
                disabled: otpVerification ? false : true,
                onChange: inputChange,
                messageTip: t("Enter new password."),
                className: "w-100"
              }}
            />
            <Visibility onClick={togglePasswordVisiblity}>
              {passwordShown ? <FaEyeSlash /> : <FaEye />}
            </Visibility>
          </PasswordInput>
          <ReactTooltip
            id="passId"
            place="top"
            effect="solid"
            globalEventOff="focusin"
          >
            {t("Enter New password")}
          </ReactTooltip>
          <ReactTooltip
            id="conformId"
            place="top"
            effect="solid"
            globalEventOff="focusin"
          >
            {t("Confirm New password")}
          </ReactTooltip>
          <PasswordInput>
            <InputGroup
              name={t("Repeat password*")}
              err={fieldErrors && fieldErrors["confirm_password"]}
              dataId="conformId"
              input={{
                placeholder: t("Repeat password"),
                value: confirm_password,
                //type: "password",
                type: passwordShownConfirm ? "text" : "password",
                name: "confirm_password",
                disabled: otpVerification ? false : true,
                onChange: inputChange,
                messageTip: t("Confirm new password."),
                className: "w-100"
              }}
            />
            <Visibility onClick={toggleConfirmPasswordVisiblity}>
              {passwordShownConfirm ? <FaEyeSlash /> : <FaEye />}
            </Visibility>
          </PasswordInput>

          <TermsConditionsWrapper>
            <CheckboxInput
              checked={radioCheck}
              onChange={radiohandler}
            />
            <label>
              <Anchor target="_blank" href="https://www.mynext.my/terms">
                {t("I agree to the Terms & Conditions")}
              </Anchor>
            </label>
          </TermsConditionsWrapper>
          {formErrors && <PasswordError>{formErrors}</PasswordError>}
          {loading ? (
            <Loader />
          ) : (
            <>
              <Button
                disabled={fieldErrors}
                // onClick={onSignUp}
                onClick={onSignUp}
                name={t("Register Account")}
                className="w-100 cTAlHL"
              />
              {
                (["ios", "android"].indexOf(deviceStore.platform) == -1) ?
                  // <GoogleLogin className="w-100"
                  //   clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  //   render={(renderProps) => (
                  //     <SocialButton
                  //       icon={GoogleLogo}
                  //       onClick={renderProps.onClick}
                  //       disabled={renderProps.disabled}
                  //       name={t("Login with Google")}
                  //     />
                  //   )}
                  //   buttonText="Login"
                  //   onSuccess={successResponseGoogle}
                  //   onFailure={errorResponseGoogle}
                  //   cookiePolicy={"single_host_origin"}
                  // /> 
                  <SocialButton
                    onClick={loginUser}
                    // disabled={renderProps.disabled}
                    name={t("Sign Up with Google")}
                    icon={GoogleLogo}
                  />
                  : <>
                    {
                      (deviceStore.platform == 'ios') ?
                        <div>
                          <button type="button" class="btn btn-dark bbtn-lg btn-block w-100" onClick={appleioslogIn}>
                            <AppleIcon /> Sign up with Apple
                          </button>
                        </div>
                        : <></>
                    }
                    <SocialButton
                      onClick={logIn}
                      name={t("Sign Up with Google")}
                      icon={GoogleLogo}
                    />
                  </>
              }
              {
                (deviceStore.platform == 'web') ?
                  <div className="mt-2">
                    <button type="button" class="btn btn-dark bbtn-lg btn-block w-100" onClick={appleweblogIn}>
                      <AppleIcon /> Sign up with Apple
                    </button>
                  </div>
                  : <></>
              }
            </>

          )}
          {/* <SplitDivider /> */}
          {/* <SocialIconsWrapper> */}


        </FormWrapper>
      </LoginPageWrapper>
      {openModal && <LoginSuccessModal open={openModal} />}
    </UserCreationLayout>
  );
}

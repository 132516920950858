
import { Row } from "react-bootstrap";
import styled from "styled-components";
import { Tooltip } from "@mui/material"
import { useTranslation } from "react-i18next";
const Block = styled.div`
    background-color: #D6D6D6;
    border: 1px solid #D6D6D6;
    width: 30%;
    height: 8px;
    border-radius: 6px;
    margin-right: 3px;
`;

const LowBlock = styled(Block)`
    background-color: #FF4D4D ;
    border: 1px solid #FF4D4D ;
`;
const ModerateBlock = styled(Block)`
    background-color: #FFB422 ;
    border: 1px solid #FFB422 ;
`;
const HighBlock = styled(Block)`
    background-color: #4CD051 ;
    border: 1px solid #4CD051 ;
`;
const LevelTip = styled(Tooltip)`
`;

export default function LevelChart({ data }) {
    const { t, i18n } = useTranslation();
    return <>
        <Row style={{display:"flex", alignItems:"center", height:"100%"}}>
            {data == "Low" ? <LevelTip title={t("Low")} placement="top-start" arrow><LowBlock /></LevelTip> : <Block/>}
            {data == "Moderate" ? <LevelTip title={t("Moderate")} placement="top-start" arrow><ModerateBlock/></LevelTip> : <Block/>}
            {data == "High" ? <LevelTip title={t("High")} placement="top-start" arrow><HighBlock /></LevelTip> : <Block/>}
        </Row>
    </>;
}
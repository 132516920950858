import { Modal } from "react-bootstrap";

function AlertModal(props) {
  return (
    <div>
      <Modal show={props.show} onHide={props.close}  centered>    
        <div style={{padding:'40px 15px 10px',minHeight:'220px'}}>
          <div style={{textAlign: 'center'}}>{props.content}</div>
        </div>
      </Modal>
    </div>
  );
}

export default AlertModal;

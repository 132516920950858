
import styled from "styled-components";
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import './swiper.css';

const SectionWrapper = styled.div`
background: ${(props) => props.primary ? props.primary : '#FDFDFD'};
border: 1px solid rgba(228, 228, 228, 0.26);
border-radius: 10px;
padding:30px;
margin:40px 30px;

@media (max-width:500px){
    margin:80px 30px 40px 30px;
  }
  
  }
`

const Title = styled.div`
font-family: 'General Sans';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
color: ${(props) => props.secondary ? props.secondary : '#D04C8D'};
`
const Card1 = styled.div`
background: #FDFDFD;
border: 1px solid #D04C8D;
border-radius: 16px;
`
const CardTextWrapper = styled.div`
background: #D04C8D;
border-radius: 0px 0px 16px 16px;
padding:1px;
`
const CardText = styled.div`
font-family: 'General Sans';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 19px;
color: #202020;
text-align:center;
margin:20px;
`
const Contents = styled.div`
font-family: 'General Sans';
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 18px;
color: #202020;
padding:20px;
`

const responsive = {
    superLargeDesktop: {

        breakpoint: { max: 4000, min: 1201 },
        items: 3,
        slidesToSlide: 3,
    },
    LargeDesktop: {

        breakpoint: { max: 1200, min: 1025 },
        items: 2,
        slidesToSlide: 2,
    },
    desktop: {
        breakpoint: { max: 1024, min: 800 },
        items: 1,
    },
    tablet: {
        breakpoint: { max: 800, min: 464 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};
const Slider = (props) => {

    return (
        <SectionWrapper primary={props.primary}>


            <Title secondary={props.secondary}>Company Information</Title><hr />

            <Carousel
                showDots={true}
                responsive={responsive}
                arrows={false}
            >
                {props?.data?.length > 0 && props?.data?.map((list, index) =>


                    <div className="mt-3 mb-5" key={index}>
                        <div className="mt-3 me-3">
                            <Card1>
                                <Contents >
                                    {list?.description}<br></br>
                                    <a href={list?.article_url} target="_blank">Visit the Article Here</a>
                                </Contents>
                                <CardTextWrapper>
                                    <CardText>{"Article"}</CardText>
                                </CardTextWrapper>
                            </Card1>
                        </div>



                    </div>

                )}

            </Carousel>
        </SectionWrapper>

    )
}
export default Slider



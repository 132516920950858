import React, { useEffect, useState, useRef } from 'react';
import { fuse } from './searchFunction';

const MessageParser = ({ children, actions }) => {
  const parse = (message) => {
    var lowerCaseMsg = message.toLowerCase();
    var searchResult = fuse.search(lowerCaseMsg)[0]?.item?.key;
    if (lowerCaseMsg.includes("options") || lowerCaseMsg.includes("option") || lowerCaseMsg.includes("pilihan")) {
      actions.handleShowOptions();
    }
    else if (lowerCaseMsg.includes("benefits") || lowerCaseMsg.includes("kelebihan")) {
      actions.handleListBenefits();
    }
    else if (searchResult != undefined) {
      actions.handleDisplayMessage(searchResult);
    }
    else {
      actions.handleDontUnderstand(); //has widget
    };
  };
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions,
        });
      })}
    </div>
  );
};

export default MessageParser;
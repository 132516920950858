import React from "react";
import Select from "react-select";
import { Colors } from "../../utilities/colors";

class Dropdown extends React.Component {
  customStyles = {
    dropdownIndicator: this.dropdownIndicatorStyles,
    control: (base, state) => ({
      ...base,
      padding: "5.5px 5px",
      transition: "none",
      // textTransform: "capitalize",
      width: "100%",
      background: `${Colors.inputBg}`,
      minWidth: `${!this.props.minWidth ? "234px" : this.props.minWidth}`,
      border: "0px",
      outline: "none",
      boxShadow: " inset 0px 0px 4px rgba(0, 0, 0, 0)",
      borderRadius: "128px",
      "&:hover": {
        boxShadow: "0 0 0 1px #ff60b1",
        borderColor: "#ff60b1",
      },
    }),
    menu: (base) => ({
      ...base,
      marginTop: "0px",
      outline: "none",
      zIndex: "3",
      // textTransform: "capitalize",
      border: "0px solid white",
      transition: "none",
      minWidth: "234px",
    }),
    menuList: (base) => ({
      ...base,
      color: "#575757;",
      fontSize: "12px",
      minWidth: "234px",
      // textTransform: "capitalize",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: `14px`,
      fontFamily: `General Sans`,
      fontWeight: `500`,
      backgroundColor: state.isSelected ? `#D04C8D` : `transparent`,
      color: state.isSelected ? `white` : `#202020`,
      "&:hover": {
        backgroundColor: state.isSelected ? `#D04C8D` : `#FFE9F4`,
        Color: `#D04C8D`,
      },
    }),
    input: (base, state) => ({
      ...base,
      '[type="text"]': {
        color: `#575757; !important`,
      },
    }),
  };

  state = {
    selectedOption: null,
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };
  render() {
    const val = this.props.isMulti
      ? this.props.val
      : this.props.val
      ? { label: this.props.label, value: this.props.val, data: this.props }
      : undefined;
    return (
      <Select
        className={this.props.classname}
        // components={{ DropdownIndicator }}
        styles={this.customStyles}
        value={val ? val : ""}
        onInputChange={this.props.inputChange}
        onChange={this.props.change}
        options={this.props.options}
        isSearchable={this.props.search ? true : false}
        isLoading={this.props.load}
        onFocus={this.props.onFocus}
        isDisabled={this.props.disable}
        placeholder={this.props.placeholder}
        menuIsOpen={this.props.menuIsOpen}
        isMulti={this.props.isMulti}
      />
    );
  }
}

export default Dropdown;

import { HrLine, StudentModalHeader } from "../commonStyles";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import styled from "styled-components";
import PropTypes from "prop-types";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next"; //translation

const InterestProfileText = styled.div`
  font-family: General Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;

  color: #000000;

  span {
    color: #d04c8d;
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MotivationLevelModal(props) {
  const [usableData, setUsableData] = useState([]);
  //translation
  const { t, i18n } = useTranslation();
  const fethUpdateData = () => {
    var datasLabel = [];
    var mainData = [];

    Object.keys(props?.data).map((e, i) => {
      var x = Math.floor(Math.random() * 128 + 64);
      var y = Math.floor(Math.random() * 128 + 64);
      var z = Math.floor(Math.random() * 128 + 64);
      var dummyArr = [];
      dummyArr = [props?.data[e]["points"]];
      mainData.push({
        label: t(e),
        data: dummyArr,
        backgroundColor: "rgba(" + x + "," + y + "," + z + ",0.5)",
      });
      datasLabel.push(e);
    });

    setUsableData(mainData);
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "",
      },
    },
    scales: {
      y: {
        ticks: {
          display: false, //this will remove only the label
        },
      },
    },
  };

  /*
  const labels = [
    "Work Interests",
  ];
*/

  const data = {
    labels: [t("Work Interests")],
    datasets: usableData,
  };

  useEffect(() => {
    fethUpdateData();
  }, []);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const changeLanguageHandler = (lang) => {
    const languageValue = lang;
    i18n.changeLanguage(languageValue);
  };
  // end translation

  return (
    <>
      <StudentModalHeader>{t("Work Interests")}</StudentModalHeader>
      <HrLine />
      {/* <StudentModalHeader>English Proficiency</StudentModalHeader>
      <StudentModalChartWrapper>
        <MotivationLevelChart />
      </StudentModalChartWrapper> */}
      <Bar options={options} data={data} />
      <br />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            aria-label="visible arrows tabs example"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                "&.Mui-disabled": { opacity: 0.3 },
              },
            }}
          >
            {usableData.map((e, i) => {
              return <Tab label={e.label} {...a11yProps(i)} />;
            })}

            {/* <Tab label="Item Two" {...a11yProps(1)} />
            <Tab label="Item Three" {...a11yProps(2)} /> */}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <h4>
            {" "}
            <span style={{ color: "#d04c8d" }}>
              {" "}
              1){" "}
              {t(
                "Enjoy practical, hands-on work with materials and nature; problem-solving in real life Characteristics"
              )}{" "}
            </span>
          </h4>
          <h5>
            {" "}
            <span style={{ color: "#d04c8d" }}>{t("Characteristics")}</span>
          </h5>
          <ul>
            <li>{t("Practical")} </li>
            <li>{t("Using tools or machines")}</li>
            <li>{t("Outdoors work with nature or animals")}</li>
          </ul>
          <h4>
            {" "}
            <span style={{ color: "#d04c8d" }}>
              {" "}
              2) {t("Fields for Realistic people")}{" "}
            </span>
          </h4>
          <ul>
            <li>{t("Construction")}</li>
            <li>{t("Architecture")}</li>
            <li>{t("Hospitality and Catering")}</li>
            <li>{t("Professional sport")}</li>
            <li>{t("Armed Forces")}</li>
            <li>{t("Transportation - distribution")}</li>
            <li>{t("Engineering")}</li>
            <li>{t("Information technology")}</li>
            <li>{t("Agriculture")}</li>
            <li>{t("Forestry")}</li>
            <li>{t("Horticulture")}</li>
            <li>{t("Traditional crafts")}</li>
            <li>{t("Landscape design and building")}</li>
            <li>{t("Manufacturing")}</li>
          </ul>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <h4>
            {" "}
            <span style={{ color: "#d04c8d" }}>
              {" "}
              1){" "}
              {t(
                "Enjoy working with theory and abstract problem-solving, research and intellectual inquiry, investigating ideas"
              )}{" "}
            </span>
          </h4>
          <h5>
            {" "}
            <span style={{ color: "#d04c8d" }}>{t("Characteristics")}</span>
          </h5>
          <ul>
            <li>{t("Searching for information and facts")}</li>
            <li>{t("Intellectual inquiry")}</li>
            <li>{t("Working with data")} </li>
          </ul>
          <h4>
            {" "}
            <span style={{ color: "#d04c8d" }}>
              {" "}
              2) {t("Fields for Investigative people")}{" "}
            </span>
          </h4>
          <ul>
            <li>{t("Medicine")} </li>
            <li>{t("Health science - laboratory work")} </li>
            <li>{t("Environmental investigation")} </li>
            <li>{t("Engineering")} </li>
            <li>{t("Communication development")} </li>
            <li>{t("Innovative design")}</li>
            <li>{t("IT development")} </li>
            <li>{t("Mathematics")} </li>
            <li>{t("Science and Technology")} </li>
            <li>{t("Academic research")} </li>
          </ul>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <h4>
            {" "}
            <span style={{ color: "#d04c8d" }}>
              {" "}
              1){" "}
              {t(
                "Enjoy working in the arts and in creative work that involves designing new approaches, solutions or products"
              )}{" "}
            </span>
          </h4>
          <h5>
            {" "}
            <span style={{ color: "#d04c8d" }}>{t("Characteristics")}</span>
          </h5>
          <ul>
            <li>{t("Using imagination and intuition")}</li>
            <li>{t("Being creative")}</li>
            <li>{t("Innovation")}</li>
          </ul>
          <h4>
            {" "}
            <span style={{ color: "#d04c8d" }}>
              {" "}
              2) {t("Fields for Artistic people")}{" "}
            </span>
          </h4>
          <ul>
            <li>{t("Acting")}</li>
            <li>{t("Film and theatre")} </li>
            <li>{t("Writing - books, plays, film scripts, poetry")}</li>
            <li>{t("songs")}</li>
            <li>{t("Media")}</li>
            <li>{t("Clothing and Fashion design")} </li>
            <li>{t("Music composition")} </li>
            <li>{t("Music and singing performance")} </li>
            <li>{t("Painting, pottery, sculpture")} </li>
            <li>{t("Advertising")} </li>
            <li>{t("Television - set design, programming")}</li>
            <li>{t("Dance / Choreography")} </li>
            <li>{t("Fitness Industry")} </li>
          </ul>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <h4>
            {" "}
            <span style={{ color: "#d04c8d" }}>
              {" "}
              1){" "}
              {t(
                "Enjoy helping, serving and assisting other people and promoting others' welfare"
              )}{" "}
            </span>
          </h4>
          <h5>
            {" "}
            <span style={{ color: "#d04c8d" }}>{t("Characteristics")}</span>
          </h5>
          <ul>
            <li>{t("Caring for adults or children")} </li>
            <li>{t("Showing compassion and sympathy")} </li>
            <li>{t("Practical")} </li>
          </ul>
          <h4>
            {" "}
            <span style={{ color: "#d04c8d" }}>
              {" "}
              2) {t("Fields for Social people")}{" "}
            </span>
          </h4>
          <ul>
            <li>{t("Counselling")} </li>
            <li>{t("Education")} </li>
            <li>{t("Training and coaching")} </li>
            <li>{t("Health care and services")} </li>
            <li>{t("Human resources")} </li>
            <li>{t("Charity work")} </li>
            <li>{t("Medicine")} </li>
            <li>{t("Hospitality")} </li>
            <li>{t("Social work")} </li>
            <li>{t("Child care")} </li>
          </ul>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <h4>
            {" "}
            <span style={{ color: "#d04c8d" }}>
              {" "}
              1){" "}
              {t(
                "Enjoy leading, motivating and influencing others and taking a lead in starting and initiating projects"
              )}{" "}
            </span>
          </h4>
          <h5>
            {" "}
            <span style={{ color: "#d04c8d" }}>{t("Characteristics")}</span>
          </h5>
          <ul>
            <li>{t("Take initiative")} </li>
            <li>{t("Persuading")} </li>
            <li>{t("Decision-making")} </li>
          </ul>
          <h4>
            {" "}
            <span style={{ color: "#d04c8d" }}>
              {" "}
              2) {t("Fields for Enterprising people")}{" "}
            </span>
          </h4>
          <ul>
            <li>{t("Sales")} </li>
            <li>{t("Marketing")} </li>
            <li>{t("Business")} </li>
            <li>{t("Government and Public Administration")} </li>
            <li>{t("Management")} </li>
            <li>{t("Law and Security")} </li>
            <li>{t("Real Estate")} </li>
            <li>{t("Entrepreneurship")} </li>
            <li>{t("Finance")} </li>
            <li>{t("Politics")} </li>
          </ul>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <h4>
            {" "}
            <span style={{ color: "#d04c8d" }}>
              {" "}
              1){" "}
              {t(
                "Enjoy organising and managing processes, information and data accurately"
              )}{" "}
            </span>
          </h4>
          <h5>
            {" "}
            <span style={{ color: "#d04c8d" }}>{t("Characteristics")}</span>
          </h5>
          <ul>
            <li>{t("Administration")} </li>
            <li>{t("Methodical")} </li>
            <li>{t("Orderly")} </li>
          </ul>
          <h4>
            {" "}
            <span style={{ color: "#d04c8d" }}>
              {" "}
              2) {t("Fields for Conventional people")}{" "}
            </span>
          </h4>
          <ul>
            <li>{t("Administration")} </li>
            <li>{t("Financial management")} </li>
            <li>{t("Sales")} </li>
            <li>{t("Office work")} </li>
            <li>{t("Care services")} </li>
            <li>{t("Logistics")} </li>
            <li>{t("Retail")} </li>
            <li>{t("Catering")} </li>
            <li>{t("Transportation - driving")} </li>
            <li>{t("Information technology")} </li>
          </ul>
        </TabPanel>
      </Box>
    </>
  );
}

import { useEffect,useState } from "react";

import styled from "styled-components";
import Sidebar from "../../components/sidebar/sidebar";
import Footer from "../../components/footer/footer";
import TopNavbar from "../../components/navbar/navbar";
import { deviceStore } from "../../utilities/devices";
import { useSnapshot } from 'valtio'
import { SafeArea } from "capacitor-plugin-safe-area";
// styling starts
const LayoutWrapper = styled.section`
  display: flex;
  height: 100%;
  @media (max-width: 1024px) {
    display: block;
  }
`;
const NavbarSection = styled.div`
  width: 346px;
  height: 100vh;
  background: #ffffff;
  border-radius: 0px 10px 10px 0px;
  position: sticky;
  top: 0;
  @media (max-width: 1024px) {
    display: none;
  }
`;
const PageContentSection = styled.div`
  overflow: hidden;
  flex-grow: 2;
  height: 100%;
  &::-webkit-scrollbar {
    width: 2px;
  }
  // @media (max-width: 1090px) {
  //   padding: 1rem 1rem;
  // }
`;

const PageContentSection1 = styled.div`
  overflow-y: auto;
  flex-grow: 2;
  height: 89vh;
  &::-webkit-scrollbar {
    width: 2px;
  }
  // @media (max-width: 1090px) {
  //   padding: 1rem 1rem;
  // }
`;

// styling ends

export default function MainLayout({ children }) {
  useSnapshot(deviceStore)
  const [mobiletop, SetMobiletop] = useState({})
  let path = window.location.pathname
  // const { width } = useWindowDimensions();
  useEffect(() => {
    document.body.classList.add("bg-body-cover");
  }, []);

  useEffect(() => {
    const setupWithInsets = async function () {
      const insets = await SafeArea.getSafeAreaInsets()
      let el1 = document.getElementById('PageContentSection1')
      el1.style.height = (((window.innerHeight - insets.insets.top) / 100) * 89).toString() + 'px'
      SetMobiletop({height:(((window.innerHeight - insets.insets.top) / 100) * 89).toString() + 'px'})
    }

      if(deviceStore.platform!='android' && !deviceStore.checkbrowser){
        setupWithInsets();
      }
}, [deviceStore.platform])

  useSnapshot(deviceStore)
  const checkbrowser = deviceStore.checkbrowser
  // const enableToggle = width < 1200;

  return (
    <LayoutWrapper>
      <NavbarSection>
        <Sidebar />
      </NavbarSection>
      {
        (checkbrowser)?<>
        <PageContentSection>
        <TopNavbar />
        {children}
        {
          (path!='/assets')?<Footer />:<></>
        }
        
      </PageContentSection>
        </>:<PageContentSection1 id="PageContentSection1" style={mobiletop}>
        <TopNavbar />
        {children}
        {
          (path!='/assets')?<Footer />:<></>
        }
      </PageContentSection1>
      }
      
    </LayoutWrapper>
  );
}

import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import GraduateConfirmationModal from "./graduateConfirmationModal";
const GraduateModal = ()=>{
    const { t } = useTranslation();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const showConfirm = () => {
      setShowConfirmation(true);
    };
    return (
      <div>
        <button onClick={() => showConfirm()}>update profile</button>
        <GraduateConfirmationModal
          show={showConfirmation}
          title={t("PROFILE UPDATE")}
          message={t("Graduate Talent Search")}
          text={t("Would you like to make your profile visisble ?")}
          closeModal={() => setShowConfirmation(false)}
        />
      </div>
    );
}
export default GraduateModal
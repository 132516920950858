import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getTalentUserData,
  getAssesmentList,
} from "../../../services/apiCalls";

const initialState = {
  loading: false,
  data: {
    resume: null,
    printResume: false,
    dowloadResume: false,
  },
  profilePercentage: null,
};

export const fetchPercentage = createAsyncThunk(
  "users/fetchByIdStatus",
  async () => {
    var temp_array = [];
    var dataValue = await getTalentUserData();
    temp_array.push(dataValue.data.data.additional_info.percentage);
    const { data } = await getAssesmentList();
    data.data.map((comp) => temp_array.push(parseInt(comp.completed)));
    const percent3 =
      temp_array.reduce((partialSum, a) => partialSum + a, 0) / 5;
    return percent3;
  }
);

export const profileReducer = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfileLoading: (state, action) => {
      state.loading = action.payload;
    },
    setResumeData: (state, action) => {
      state.data.resume = action.payload;
    },
    printResume: (state, action) => {
      state.data.printResume = action.payload;
    },
    downloadResume: (state, action) => {
      state.data.dowloadResume = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchPercentage.fulfilled, (state, action) => {
      // Add user to the state array
      if (state.profilePercentage !== action.payload) {
        state.profilePercentage = action.payload;
      }
    });
  },
});

// Action creators for profile reducer
export const { setProfileLoading, setResumeData, printResume, downloadResume } =
  profileReducer.actions;

export default profileReducer.reducer;

import styled from "styled-components";

// styling starts
const HrLine = styled.hr`
  border: 1.5px solid rgba(235, 235, 235, 0.62);
  max-width: 450px;
  margin: 20px 0;
  background: ${(props) => (props.bg ? props.bg : "transparent")};
  @media (max-width: 900px) {
    max-width: 320px;
    min-width: 50px;
  }
`;
// styling ends

export default function Divider({ bg }) {
  return <HrLine bg={bg} />;
}

import { useEffect, useState } from "react";
import styled from "styled-components";
import "react-phone-number-input/style.css";
import { newInternStaticObj } from "./staticContent";
import LocationTable from "../locationTable/locationTable";
import Dropdown from "../../../components/dropdown/dropdown";
import ErrorField from "../../../components/errorField/errorField";
import { Colors } from "../../../utilities/colors";
import { Row, Col } from "react-bootstrap";
import {
  deleteLocation,
  getLocations,
  getAllCountries,
  getStates,
  getCities,
  saveLocation,
  updateLocation,
} from "../../../services/apiCalls";
import CustomButton from "../../../components/button/button";
import { AiOutlinePlus } from "react-icons/ai";
import { useRequired } from "../utils/useRequired";
import ConfirmationDialog from "../confirmation-dialog/confirmation-dialog";
import { useTranslation } from "react-i18next";

// styling starts
const FormGroup = styled.div`
  width: 100%;
`;

const ButtonWrapper = styled.div`
  button {
    @media (max-width: 1366px) {
      margin: 15px 0 0px;
      min-width: 0;
    }
    @media (max-width: 600px) {
      max-width:100%;
    }
    @media (min-width: 1200px) {
      min-width: 234px;
    }
  }
`;

const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;

const Button = styled.button`
  background-color: transparent;
  border: 1px solid #acacac;
  padding: 5px;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  color: #acacac;
`;

const initialState = {
  country: "132",
  state: "",
  city: "",
};

const errors = {
  country: null,
  state: null,
  city: null,
};

const CvLocation = ({ notification }) => {
  const [showAddLocation, setShowAddLocation] = useState(false);
  const [formState, setFormState] = useState(initialState);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [isSave, setIsSave] = useState(true);
  const { errorFields, validateForm, setErrorFields } = useRequired(
    formState,
    errors
  );
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [selectedCountry, setSelectedCountry] = useState({});
  const [selectedState, setSelectedState] = useState({});
  const [selectedCity, setSelectedCity] = useState({});
  const [statesDisable, setStatesDisable] = useState(false);
  const [citiesDisable, setCitiesDisable] = useState(false);

  useEffect(() => {
    fetchCountries();
    fetchLocations();
  }, []);

  const handleClose = () => {
    setFormState({ ...initialState });
    setShowAddLocation(false);
    setSelectedCountry(null);
    setSelectedState(null);
    setSelectedCity(null);
    setFormState({ ...initialState });
    setStatesDisable(true);
    setCitiesDisable(true);
  };

  const fetchCountries = async () => {
    const { data, status } = await getAllCountries();
    if (status === 200) {
      if (data.status === 1) {
        const location = data.data;
        const country = location.map((x) => {
          return { ...x, label: x.Name, value: x.Id };
        });
        setCountries(country);
        if (country[131] !== undefined) {
          setSelectedCountry(country[131]);
          setFormState((x) => {
            return { ...x, country: country[131].value };
          });
          fetchStates(132);
        }
      }
    }
  };

  const fetchStates = async (countryId) => {
    const { data, status } = await getStates(countryId);
    if (status === 200) {
      if (data.status === 1) {
        setStatesDisable(false);
        setCitiesDisable(true);
        const stateData = data.data;
        const state = stateData.map((x) => {
          return { ...x, label: x.Name, value: x.Id };
        });
        setStates(state);
      }
    }
  };

  const fetchCities = async (countryId, stateId) => {
    const { data, status } = await getCities(countryId, stateId);
    if (status === 200) {
      if (data.status === 1) {
        setStatesDisable(false);
        setCitiesDisable(false);
        const cityData = data.data;
        const city = cityData.map((x) => {
          return { ...x, label: x.Name, value: x.Id };
        });
        setCities(city);
      }
    }
  };

  const dropdownChange = async (name, val) => {
    switch (name) {
      case "country":
        setSelectedCountry(val);
        setSelectedState(null);
        setSelectedCity(null);
        setFormState((x) => {
          return { ...x, [name]: val.value };
        });
        setErrorFields({ ...errorFields, [name]: null });
        formState.state = "";
        formState.city = "";
        fetchStates(val.value);
        break;
      case "state":
        setSelectedState(val);
        setSelectedCity(null);
        setFormState((x) => {
          return { ...x, [name]: val.value };
        });
        setErrorFields({ ...errorFields, [name]: null });
        formState.city = "";
        formState.state = "";
        fetchCities(formState.country, val.value);
        break;
      case "city":
        setSelectedCity(val);
        setFormState((x) => {
          return { ...x, [name]: val.value };
        });
        setErrorFields({ ...errorFields, [name]: null });
        break;
      default:
        setFormState((x) => {
          return { ...x, [name]: val };
        });
        setErrorFields({ ...errorFields, [name]: null });
    }
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      return;
    }
    let { country, state, city, id } = formState;

    if (isSave) {
      const payload = {
        data: [{ country, state, city }],
      };
      var temp_arr = [];
      selectedLocations.map((loc) => temp_arr.push(loc.city));
      var payload_city_id = payload.data[0].city.toString();
      if (temp_arr.includes(payload_city_id)) {
        notification("error", t("Location already added."));
      } else {
        const { data, status } = await saveLocation(payload);
        checkStatus(data, status, "save");
        setSelectedCountry(null);
        setSelectedState(null);
        setSelectedCity(null);
        setFormState({ ...initialState });
        setStatesDisable(true);
        setCitiesDisable(true);
      }
    } else {
      updateLocations(id);
    }
  };

  const updateLocations = async (id) => {
    let { country, state, city } = formState;
    const payload = { country, state, city };
    var temp_arr = [];
    selectedLocations.map((loc) => temp_arr.push(loc.city));
    var payload_city_id = payload.city.toString();
    if (temp_arr.includes(payload_city_id)) {
      notification("error", t("Location already added."));
    } else {
      const { data, status } = await updateLocation(id, payload);
      checkStatus(data, status, "update");
      setSelectedCountry(null);
      setSelectedState(null);
      setSelectedCity(null);
      setFormState({ ...initialState });
      setStatesDisable(true);
      setCitiesDisable(true);
    }
  };

  const editLocation = (idd) => {
    setIsSave(false);
    const { country, state, city, id, city_abbr, country_abbr, state_abbr } =
      selectedLocations.find((f) => f.id === idd);
    setSelectedCountry({ label: country_abbr, value: country });
    setSelectedState({ label: state_abbr, value: state });
    setSelectedCity({ label: city_abbr, value: city });
    fetchStates(country);
    fetchCities(country, state);
    setStatesDisable(false);
    setCitiesDisable(false);
    setFormState({ country, state, city, id });
    setShowAddLocation(true);
  };

  const deleteLocationn = async (id) => {
    const { data, status } = await deleteLocation(id);
    checkStatus(data, status, "delete");
  };

  const checkStatus = (data, status, method) => {
    const methodTmp =
      method === "save" ? "saved" : method === "update" ? "updated" : "deleted";
    if (data?.status === 1 && status === 200) {
      fetchLocations();
      if (method === "delete") {
        setShowConfirmation(false);
        notification("success", t("Location details deleted successfully"));
      } else if (method == "save") {
        notification("success", t("Location details saved successfully"));
      }  else if (method == "update") {
        notification("success", t("Location details updated successfully"));
      }
    } else {
      if (method == "save") {
        notification("error", t("Unable to save location details"));
      } else if (method == "update") {
        notification("error", t("Unable to update location details"));
      } else if (method == "delete") {
        notification("error", t("Unable to delete location details"));
      }
    }
  };

  const fetchLocations = async () => {
    const { data, status } = await getLocations();
    if (status === 200 && data.status === 1) {
      // if (data.data.length > 0) {
      setSelectedLocations(data.data);
      resetForm();
      // }
    }
  };

  const resetForm = () => {
    setFormState({ ...initialState });
    setShowAddLocation(false);
    setIsSave(true);
    setErrorFields({ ...errors });
  };

  const showConfirm = (id) => {
    setShowConfirmation(true);
    setDeleteId(id);
  };

  const { t } = useTranslation();

  return (
    <div>
      {!showAddLocation && (
        <Button onClick={() => setShowAddLocation(true)}>
          <AiOutlinePlus />
          {t("Add Location")}
        </Button>
      )}
      {showAddLocation && (
        <div>
          <Row>
            <FormLabel>{t(newInternStaticObj.country)}</FormLabel>
            <Dropdown
              bgGrey
              // dataId='country'
              search={true}
              options={countries}
              placeholder={t("Select Country")}
              //val={formState.country}
              val={selectedCountry?.value}
              label={selectedCountry?.label}
              change={(val) => dropdownChange("country", val)}
            />
            {errorFields["country"] && (
              <ErrorField err={t(errorFields["country"])} />
            )}
          </Row>
          <Row>
            <FormLabel>{t(newInternStaticObj.state)}</FormLabel>
            <Dropdown
              bgGrey
              search={true}
              //  dataId='state'
              options={states}
              //val={formState.state}
              val={selectedState?.value}
              label={selectedState?.label}
              placeholder={t("Select State")}
              disable={statesDisable}
              change={(value) => dropdownChange("state", value)}
            />
            {errorFields["state"] && <ErrorField err={t(errorFields["state"])} />}
          </Row>
          <Row>
            <FormLabel>{t(newInternStaticObj.city)}</FormLabel>
            <Dropdown
              bgGrey
              search={true}
              options={cities}
              // dataId='city'
              //val={formState.city}
              val={selectedCity?.value}
              label={selectedCity?.label}
              placeholder={t("Select City")}
              disable={citiesDisable}
              change={(value) => dropdownChange("city", value)}
            />
            {errorFields["city"] && <ErrorField err={t(errorFields["city"])} />}
          </Row>
          <Row>
            <Col xs={6} xl="6" lg="12" >
              <ButtonWrapper>
                <CustomButton
                  onClick={handleSubmit}
                  name={t("Save")}
                  width="100%"
                  minWidth="0"
                  //   margin="15px 7px 0 0px;"
                />
              </ButtonWrapper>
            </Col>

            <Col xs={6} xl="6" lg="12">
              <ButtonWrapper>
                <CustomButton
                  onClick={handleClose}
                  secondary
                  name={t("Cancel")}
                  minWidth="0"
                  width="100%"
                  //   margin="15px 0px 0 7px;"
                />
              </ButtonWrapper>
            </Col>
          </Row>
        </div>
      )}
      {selectedLocations.length > 0 && (
        <FormGroup>
          <LocationTable
            editLocation={(id) => editLocation(id)}
            deleteLocation={(id) => showConfirm(id)}
            data={selectedLocations}
          />
        </FormGroup>
      )}
      <ConfirmationDialog
        id={deleteId}
        show={showConfirmation}
        message={t("Are you sure you want to delete this record?")}
        onAccept={(id) => deleteLocationn(id)}
        closeModal={() => setShowConfirmation(false)}
      />
    </div>
  );
};

export default CvLocation;

export const googleLoginObj = {
  username: "",
  en_type: "Rw==",
};

export const fbLoginObj = {
  username: "",
  en_type: "Rg==",
};

export const onBoardForm = {
  step: 2,
  id_type: 1,
  id_number: "",
  full_name: "",
  display_name: "",
  gender: "Male",
  dob: "",
  mob_code: "N/A",
  mob_number: "",
  nationality: 1,
  race: "",
  country: "",
  curr_country: "",
  state: "",
  city: "",
  postalcode: "",
  disability_status: 0,
  disability_code: "",
  profile_picture: "",
  talent_status: "",
  curr_employer: "",
  sector: "",
  dur_year: "",
  dur_month: "",
  position: "",
  salary: "",
  high_qualification: "",
  insti_country_status: 1,
  insti_name: "",
  insti_country: 132,
  scope: "",
  grade_status: "CGPA",
  grade: "",
  english_tests: "MUET",
  english_score: "",
  curr_tc_program: 1,
  programmee: "",
  last_employer: "",
  scholar_status: 0,
  scholar_data: "",
  curr_qualification: "",
  insti_location_status: 1,
  university: "",
  univ_enroll_date: "",
  exp_graduate_date: "",
  curr_study_year: "",
  campus: "",
  faculty: "",
  study_program: "",
  intern_dur_year: "",
  intern_dur_month: "",
  intern_position: "",
  intern_allowance: "",
  is_army: 0,
  army_id_number: "",
  army_service: "",
  army_corp_regiment: "",
  army_last_rank: "",
  army_is_active: null
};

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Colors } from "../../../utilities/colors";
import {
  filePathToFileName,
  downloadPdf,
  localTime
} from "../../../utilities/commonFunctions";
import PDF from "../../../assets/icons/svg/pdf.svg";
import { Row, Col } from "react-bootstrap";
import { TablePagination } from "@mui/base";
import moment from "moment";
import { useTranslation } from "react-i18next";

const AcadamicWrapper = styled.div`
  font-family: General Sans;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    color: ${Colors.labelText};
  }
  p {
    margin: 0;
    margin-left: 5px;
    font-weight: normal !important;
    font-size: 16px;
    line-height: 22px;
    color: ${Colors.labelText};
  }
`;
const InternTitle = styled.div`
  font-family: General Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
`;
const InternDescription = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: normal !important;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 3em;
  color: #202020;
`;

const ListsWrapper = styled.ul`
  font-family: General Sans;
  margin-bottom: 3em;
  > li {
    padding: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    color: #202020;
    margin-bottom: 10px;
  }
`;

const AttachedDocument = styled.div`
  font-family: General Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 33px;
  text-decoration-line: underline;
  color: #d04c8d;
  @media (max-width: 500px) {
    word-break: break-word;
  }
`;

const IconSingleSection = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${(props) => (props.margin ? props.margin : "0px")};
  @media (max-width: 500px) {
    display: -webkit-box;
    align-items: unset;
  }
`;

const OtherText = styled.p`
  margin: 0 0 0 5px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #525252;
`;
const ViewCard = styled.div`
  margin: 2rem 0;
`;

const ParagraphTag = styled.div`
  font-size: 16px;
  font-family: "General Sans";
  font-weight: 400;
  margin-bottom: 5px;
`;
const FlexWrapper = styled.div`
  display: flex;
`;
const AcadamicRequirement = styled.div`
  margin-bottom: 3em;
`;

export default function ViewDetails({ data }) {
  const [showConfirmationModalAddNotes, setShowConfirmationModalAddNotes] =
    useState(false);
  const [page, setPage] = React.useState(0);
  const [theArray, setTheArray] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const emptyRowsAdd =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - theArray.length) : 0;
  const blue = {
    200: "#A5D8FF",
    400: "#3399FF",
  };

  const grey = {
    50: "#F3F6F9",
    100: "#E7EBF0",
    200: "#E0E3E7",
    300: "#CDD2D7",
    400: "#B2BAC2",
    500: "#A0AAB4",
    600: "#6F7E8C",
    700: "#3E5060",
    800: "#2D3843",
    900: "#1A2027",
  };

  const Root = styled("div")(
    ({ theme }) => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid ${grey[200]};
    text-align: left;
    padding: 6px;
  }

  th {
    background-color: ${grey[100]};
  }
  `
  );

  const CustomTablePagination = styled(TablePagination)(
    ({ theme }) => `
  & .MuiTablePaginationUnstyled-spacer {
    display: none;
  }
  & .MuiTablePaginationUnstyled-toolbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }
  & .MuiTablePaginationUnstyled-selectLabel {
    margin: 0;
  }
  & .MuiTablePaginationUnstyled-select {
    padding: 2px;
    border: 1px solid ${grey[200]};
    border-radius: 50px;
    background-color: transparent;
    &:hover {
      background-color: ${grey[50]};
    }
    &:focus {
      outline: 1px solid ${blue[200]};
    }
  }
  & .MuiTablePaginationUnstyled-displayedRows {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }
  & .MuiTablePaginationUnstyled-actions {
    padding: 2px;
    border: 1px solid ${grey[200]};
    border-radius: 50px;
    text-align: center;
  }
  & .MuiTablePaginationUnstyled-actions > button {
    margin: 0 8px;
    border: transparent;
    border-radius: 2px;
    background-color: transparent;
    &:hover {
      background-color: ${grey[50]};
    }
    &:focus {
      outline: 1px solid ${blue[200]};
    }
  }
  `
  );
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    setTheArray(data?.internship_notes ?? []);
  }, []);
  const { t } = useTranslation();
  return (
    <ViewCard>
      <InternTitle>{t("Internship Description")}</InternTitle>
      <InternDescription
        dangerouslySetInnerHTML={{ __html: data?.description }}
      />
      <InternTitle>{t("Academic Requirements")}</InternTitle>
      <AcadamicRequirement>
        <Row>
          <Col lg="3" xs="12" className="p-0">
            <AcadamicWrapper>
              <h5> {t("Scope of Study :")}</h5>
            </AcadamicWrapper>
          </Col>

          <Col lg="8" xs="12" className="p-0">
            <>
              {data &&
                data.scopes.map((x, index) => (
                  <div key={index + x.scope}>
                    <AcadamicWrapper>
                      <p>
                        {x.scope_abbr}
                        {index === data.scopes.length - 1 ? "" : ","}
                      </p>
                    </AcadamicWrapper>
                  </div>
                ))}
            </>
          </Col>
        </Row>
        <Row>
          <Col lg="3" xs="12" className="p-0">
            <AcadamicWrapper>
              <h5> {t("Minimum required CGPA :")}</h5>
            </AcadamicWrapper>
          </Col>
          <Col lg="8" xs="12" className="p-0">
            <FlexWrapper>
              <AcadamicWrapper>
                <p>{data && data.req_gpa} </p>
              </AcadamicWrapper>
            </FlexWrapper>
          </Col>
        </Row>
        <Row>
          <Col lg="3" xs="12" className="p-0">
            <AcadamicWrapper>
              <h5> {t("Minimum education level required :")}</h5>
            </AcadamicWrapper>
          </Col>
          <Col lg="8" xs="12" className="p-0">
            <FlexWrapper>
              <AcadamicWrapper>
                <p>{data && data.min_degree} </p>
              </AcadamicWrapper>
            </FlexWrapper>
          </Col>
        </Row>
        <Row>
          <Col lg="3" xs="12" className="p-0">
            <AcadamicWrapper>
              <h5> {t("Minimum year of study :")}</h5>
            </AcadamicWrapper>
          </Col>
          <Col lg="8" xs="12" className="p-0">
            <FlexWrapper>
              <AcadamicWrapper style={{marginLeft:"5px"}}>{data && data.grade}</AcadamicWrapper>
            </FlexWrapper>
          </Col>
        </Row>
        <Row>
          <Col lg="3" xs="12" className="p-0">
            <AcadamicWrapper>
              <h5> {t("Skills :")}</h5>
            </AcadamicWrapper>
          </Col>
          <Col lg="8" xs="12" className="p-0">
            <>
              {data &&
                data.skills.map((x, index) => (
                  <div key={index + x.scope}>
                    <AcadamicWrapper>
                      <p>
                        {x.skill}
                        {index === data.scopes.length - 1 ? "" : ","}
                      </p>
                    </AcadamicWrapper>
                  </div>
                ))}
            </>
          </Col>
        </Row>
      </AcadamicRequirement>
      <InternTitle>{t("Required Documents")}</InternTitle>
      <ListsWrapper>
        {data &&
          data.documents.map((x, index) => (
            <li key={index + x.doc_type}>{x.doc_type}</li>
          ))}
      </ListsWrapper>
      <InternTitle>{t("Optional Documents")}</InternTitle>
      <InternDescription>{data && data.optional_documents ? data.optional_documents : "N/A"} </InternDescription>
      <InternDescription>
        <InternTitle>{t("Location")}</InternTitle>
        {data &&
          data.locations.map((x, index) => (
            <ParagraphTag key={x.state + index}>
              {x.city_abbr}, {x.state_abbr}, {x.country_abbr}
            </ParagraphTag>
          ))}
      </InternDescription>

      <InternTitle>{t("Attached Documents")}</InternTitle>
      <InternDescription>
        {data &&
          data.file_paths.map((file, index) => (
            <IconSingleSection key={file.id + index}>
              <img src={PDF} alt="PDF" />
              <OtherText>
                <AttachedDocument
                  onClick={() => downloadPdf(file.file_path)}
                  className="pointer-link"
                >
                  {filePathToFileName(file?.file_path)}
                </AttachedDocument>
              </OtherText>
            </IconSingleSection>
          ))}
      </InternDescription>
      <InternTitle>{t("Notes")}</InternTitle>
      <div className="container mb-5">
        <Root sx={{ width: 200, maxWidth: "50%" }}>
          <table aria-label="custom pagination table">
            <thead>
              <tr>
                <th
                  style={{
                    background: "rgb(250, 74, 161)",
                    color: "#fff",
                  }}
                >
                  {t("Notes")}
                </th>
                <th
                  style={{
                    background: "rgb(250, 74, 161)",
                    color: "#fff",
                  }}
                >
                  {t("Created")}
                </th>
              </tr>
            </thead>
            <tbody>
              {theArray?.length > 0
                ? (rowsPerPage > 0 && theArray?.length
                  ? theArray.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                  : theArray
                ).map((row) => (
                  <tr key={row.notes}>
                    <td>{row.notes}</td>
                    <td style={{ width: 200 }} align="right">
                      {moment(new Date(row.created_at)).format(
                        "DD/MM/YYYY"
                      )} &nbsp;
                      {moment(localTime(row.created_at + " " + row.entry_time + "Z")).format(
                        "LT"
                      )}
                    </td>
                  </tr>
                ))
                : ""}

              {emptyRowsAdd > 0 && (
                <tr style={{ height: 41 * emptyRowsAdd }}>
                  <td colSpan={3} />
                </tr>
              )}
            </tbody>
            {theArray?.length > 0 ? (
              <tfoot>
                <tr>
                  <CustomTablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={3}
                    count={theArray?.length ?? "0"}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    componentsProps={{
                      select: {
                        "aria-label": "rows per page",
                      },
                      actions: {
                        showFirstButton: true,
                        showLastButton: true,
                      },
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </tr>
              </tfoot>
            ) : (
              <div className="container col-lg-12">{t("No Comments")}</div>
            )}
          </table>
        </Root>
      </div>
      {data.visibility_status ? (
        <>
          <InternTitle>{t("Contact")}</InternTitle>
          <InternDescription>
            <ParagraphTag>{data && data.contact_name}</ParagraphTag>
            <ParagraphTag>{data && data.contact_email}</ParagraphTag>
            <ParagraphTag>{data && data.contact_mob}</ParagraphTag>
          </InternDescription>
        </>
      ) : (
        ""
      )}
    </ViewCard>
  );
}

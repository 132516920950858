import React from 'react';
import { Chart as ChartJS, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { cloneDeep } from 'lodash';
import { Utils } from '../../utilities/utils';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

const defaultOptions = {
    maxBarThickness: 20,
    layout: {
        padding: {
            left: 0,
            right: 0,
            top: 20,
            bottom: 20,
        },
        margin: {
            left: 0,
            right: 0,
            top: 30,
            bottom: 0,
        }
    },
    plugins: {
        legend: {
            display: false,
            position: 'bottom',
            align: 'center',
        },
        datalabels: {
            display: false,
        },
        tooltip: {
            enabled: true,
            yAlign: 'bottom',
            xAlign: 'center',
            displayColors: false,
            padding: 10,
            callbacks: {
                label: (context) => {
                    return context.formattedValue;
                },
                title: (context) => {
                    return '';
                }
            }
        }
    },
    scales: {
        y: {
            beginsAtZero: true,
            display: true,
            grid: {
                display: true,
                drawBorder: false,
                borderDash: [8, 4],
            },
            ticks: {
                font: {
                    family: 'Inter',
                    size: '14px',
                    style: 'normal',
                    weight: 400,
                    lineHeight: '16px',
                },
                color: '#615E83',
            },
        },
        x: {
            display: true,
            beginsAtZero: true,
            grid: {
                display: false,
                drawBorder: false,
            },
            ticks: {
                font: {
                    family: 'Inter',
                    size: '14px',
                    style: 'normal',
                    weight: 400,
                    lineHeight: '16px',
                },
                color: '#615E83',
            }
        },
    },
    responsive: true,
    title: {
        display: false,
    },
};

const circleDataLabels = {
    afterDatasetDraw: (chartInstance) => {
        var ctx = chartInstance.ctx;
        const legendItems = chartInstance.legend.legendItems;
        chartInstance.data.datasets.forEach((dataset, datasetIndex) => {
            const backgroundColors = dataset.backgroundColor;
            const legendItem = legendItems[datasetIndex];
            var datasetMeta = chartInstance.getDatasetMeta(datasetIndex);
            datasetMeta.data.forEach((segment, segmentIndex) => {
                if (legendItem.hidden) {
                    return;
                }
                
                var posX = segment.tooltipPosition().x,
                    posY = segment.tooltipPosition().y;
                ctx.beginPath();
                ctx.arc(posX, posY, 15, 0, 2 * Math.PI);
                ctx.strokeStyle = typeof backgroundColors === 'string' ? backgroundColors : backgroundColors[segmentIndex];
                ctx.fillStyle = "#FFFDFD";
                ctx.lineWidth = 5;
                ctx.shadowBlur = 7;
                ctx.shadowOffsetX = 0;
                ctx.shadowOffsetY = 0;
                ctx.shadowColor = typeof backgroundColors === 'string' ? backgroundColors : backgroundColors[segmentIndex];
                ctx.stroke();
                ctx.fill();
                ctx.lineWidth = null;
                ctx.shadowBlur = null;
                ctx.shadowOffsetX = null;
                ctx.shadowOffsetY = null;
                ctx.textAlign = 'center';
                ctx.font = "normal normal 400 14px/16px Oswald";
                ctx.textBaseline = "middle";
                ctx.fillStyle = "#000000";
                ctx.fillText(segment.$context.raw, posX, posY);
            });
        });

    }
};

export default function VerticalBarChartDataLabels({ data, gradients = [], optionsOverride }) {
    const [refGot, setRefGot] = React.useState(false);
    const legendRef = React.useRef(null);
    let finalOptions = defaultOptions;
    const chartRef = React.useRef(null);
    data.datasets[0].dataBackup = cloneDeep(data.datasets[0].data);
    const data2 = cloneDeep(data);
    const [chartData, setChartData] = React.useState(data2);
    const htmlLegendPlugin4 = {
        id: 'htmlLegend',
    };
    const plugins = [circleDataLabels, htmlLegendPlugin4];
    React.useEffect(() => {
        setRefGot(true);
        htmlLegendPlugin4.afterUpdate = (function (chart, args, options) {
            const ul = Utils.getOrCreateLegendList(chart, this.legendRef);

           
            while (ul.firstChild) {
                ul.firstChild.remove();
            }

            
            const items = chart.options.plugins.legend.labels.generateLabels(chart);
            const chartDataArr = chart.data.datasets[0].data;
            const chartDataArrSum = chartDataArr.reduce(function (a, b) { return a + b; }, 0);
            items.forEach((item, index) => {
                const li = document.createElement('li');
                li.style.alignItems = 'center';
                li.style.cursor = 'pointer';
                li.style.display = 'flex';
                li.style.flexDirection = 'row';
                li.style.marginLeft = '10px';

                li.onclick = () => {
                    const { type } = chart.config;
                    if (type === 'pie' || type === 'doughnut') {
                        
                        chart.toggleDataVisibility(item.index);
                    } else {
                        chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                    }
                    chart.update();
                };
                
            });
        }).bind({
            legendRef: legendRef.current,
        });
        const chart = chartRef.current;
        const ctx = chart && chart.ctx;

        if (ctx && gradients.length) {
            setChartData({
                ...data,
                datasets: data.datasets.map((dataset, index) => {
                    
                    const linearGradients = gradients.map((gradient) => {
                        const [gradientStart, gradientEnd] = gradient;
                        const linearGradient = ctx.createLinearGradient(chart.width / 2, 0, chart.width / 2, chart.height);
                        linearGradient.addColorStop(0, gradientStart);
                        linearGradient.addColorStop(1, gradientEnd);
                        return linearGradient;
                    })
                    return ({
                        ...dataset,
                        backgroundColor: linearGradients,
                        hoverBackgroundColor: linearGradients,
                    })
                }),
            });
        }
        chartRef.current.update();
    }, []);
    if (optionsOverride) {
        finalOptions = { ...defaultOptions, ...finalOptions };
    }
    
    return <>
        <Bar data={chartData}
            ref={chartRef}
            height="240px"
            options={finalOptions} plugins={plugins} />
        <div id="legend-container" ref={legendRef}></div>
    </>
}
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userData: 0,
};

export const percentageReducer = createSlice({
  name: "percentage",
  initialState,
  reducers: {
    updatePercentageData: (state, action) => {
      state.userData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updatePercentageData } = percentageReducer.actions;

export default percentageReducer.reducer;

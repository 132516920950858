import React, { useState } from "react";
import styled from "styled-components";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import MyCalendarView from '../../components/myCalendar/myCalendar'
import Input from "../../components/input/input";
import Button from "../../components/button/button";
import { Colors } from "../../utilities/colors";
import './myCalendar.css';
import { useSnapshot } from "valtio";
import { useEffect } from "react";
import { getApplications,getTalentUserData } from "../../services/apiCalls";
import { mycalendarProxy } from "./mycalendarproxy";
import Dropdown from "../../components/dropdown/dropdown1";
import HamburgerLay from "../../components/hamburger/hamburger";
import Menus from "../../components/hamburger/menus";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";

const DashboardWrapper = styled.div`
  @media (max-width: 1024px) {
    display: flex;
  }
`;

const HamburgerLayout = styled.div`
  @media (min-width: 1025px) {
    display: none;
  }
`;
const HeaderText = styled.h2`
  // margin-top:-20px;
  margin-bottom:1%;
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: #000000;
  padding:0 3rem;
  @media (max-width: 500px) {
  margin:20px 0 0 5px;
    }
`;

const CompanySection = styled.div`
  display: flex;
  margin-bottom: 20px;
  marginleft: "20px";
  padding:0 3rem;
  @media (max-width: 500px) {
    padding:0 1rem;
    display:block;
  }
`;

const SectionWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  padding:0 3rem;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 500px){
    padding:0 1rem;
  }
`;

const InputBox = styled.div`
  margin-top: 20px;
  margin-right: 20px;
  width: 90%;
  height: 54px;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export default function MyCalendar() {
  useSnapshot(mycalendarProxy);
  const styles = {
    control: (base, state) => ({
      ...base,
      height: "45px",
      padding: "5.5px 5px",
      transition: "none",
      textTransform: "capitalize",
      width: "100%",
      background: `${Colors.light}`,
      border: "0px",
      outline: "none",
      boxShadow: " inset 0px 0px 4px rgba(0, 0, 0, 0)",
      borderRadius: "128px",
      "&:hover": {
        boxShadow: "0 0 0 1px #ff60b1",
        borderColor: "#ff60b1",
      },
    }),
    menu: (base) => ({
      ...base,
      marginTop: "0px",

      outline: "none",
      zIndex: "3",
      textTransform: "capitalize",
      border: "0px solid white",
      transition: "none",
      // minWidth: "234px",
    }),
    menuList: (base) => ({
      ...base,
      color: "#575757;",
      fontSize: "12px",
      // minWidth: "234px",
      textTransform: "capitalize",
    }),
    option: (provided, state) => ({
      ...provided,
      width: "100%",
      height: "100%",
      fontSize: `14px`,
      fontFamily: `General Sans`,
      fontWeight: `500`,
      backgroundColor: state.isSelected ? `#D04C8D` : `transparent`,
      color: state.isSelected ? `white` : `#202020`,
      "&:hover": {
        backgroundColor: state.isSelected ? `#D04C8D` : `#FFE9F4`,
        Color: `#D04C8D`,
      },
    }),
    input: (base, state) => ({
      ...base,
      '[type="text"]': {
        color: `#575757; !important`,
      },
    }),
  };
  const [isOpenham, setOpenHam] = useState(false);
  const [task, setTask] = useState([]);
  const { t } = useTranslation();

  const [filapplications, setFilapplications] = useState({
    label: "All",
    value: "-1",
  });
  const [tags, setTags] = useState({ label: "Tags", value: "-1" });

  const [search, setSearch] = useState("");
  useEffect(async () => {
    chkser();
  }, [filapplications, tags]);

  const chkser = () => {
    if (search.length == 0) {
      setTask(mycalendarProxy.applist);
      checkfilapp(mycalendarProxy.applist);
    } else {
      let s = search.toLowerCase();
      let a = [];
      for (let i = 0; i < mycalendarProxy.applist.length; i++) {
        if (
          mycalendarProxy.applist[i]["name"].toLowerCase().indexOf(s) != -1 ||
          (mycalendarProxy.applist[i]["engage_jobposition"] == undefined &&
            mycalendarProxy.applist[i]["jobtitle"].toLowerCase().indexOf(s) != -1) ||
          (mycalendarProxy.applist[i]["engage_jobposition"] &&
            mycalendarProxy.applist[i]["engage_jobposition"]
              .toLowerCase()
              .indexOf(s) != -1)
        ) {
          a.push(mycalendarProxy.applist[i]);
        }
      }
      setTask(a);
      checkfilapp(a);
    }

    if (tags.value != "tags") {
    }
  };

  const checkfilapp = (al) => {
    if (filapplications.value != "-1") {
      let a = [];
      for (let i = 0; i < al.length; i++) {
        if (al[i]["status"] == filapplications.value) {
          a.push(al[i]);
        }
      }
      setTask(a);
      chktag(a);
    } else {
      chktag(al);
    }
  };

  const chktag = (al) => {
    if (tags.value != "-1") {
      let a = [];
      for (let i = 0; i < al.length; i++) {
        if (
          al[i]["engage_tag"] &&
          al[i]["engage_tag"].toLowerCase().indexOf(tags.value) != -1
        ) {
          a.push(al[i]);
        }
      }
      setTask(a);
    }
  };

  const getapp = async (id) => {
    const { data } = await getApplications(id);
    if (data.status == 1) {
      
      let t = []
      for (let i = 0; i < data.data.length; i++) {
        let a = data.data[i]["interview_startdatetime"]
        let b = data.data[i]["interview_enddatetime"]
        if (
          a &&
          a.length > 0 &&
          b &&
          b. length &&
          ["pending","shortlist","engage"].indexOf(data.data[i]['status'])==-1
        ){
          t.push(data.data[i])
        }
      }
      setTask(t);
      mycalendarProxy.applist = t;
      let l = [{ label: "Tags", value: "-1" }];
      let ll = [];
      for (let i = 0; i < t.length; i++) {
        if (
          t[i]["engage_tag"] &&
          t[i]["engage_tag"].length > 0
        ) {
          let a = t[i]["engage_tag"].split(",");
          for (let j = 0; j < a.length; j++) {
            if (ll.indexOf(a[j].toLowerCase().trim()) == -1) {
              l.push({ label: a[j].trim(), value: a[j].toLowerCase().trim() });
            }
          }
        }
      }
      mycalendarProxy.tags = l;
    }
  };

  const getUser = async () => {
    const { data } = await getTalentUserData();

    if (data && data.status === 1) {
      let id = data.data.id;
      await getapp(id);
    }
  };

  useEffect(async () => {
    await getUser()
    
  }, [ mycalendarProxy.setcheck]);
  
  // redirect to dashboard if has not completed assessment
  const completion = useSelector((state) => {
    return state.profile.profilePercentage
  });

  // if (completion!=null & completion<100){
  //   Navigate('/dashboard');
  // }

    return (
        <MainLayout>
          <DashboardWrapper>
            <HamburgerLayout>
              <HamburgerLay onToggle={() => setOpenHam(!isOpenham)} />
            </HamburgerLayout>
            {isOpenham && <Menus />}
          </DashboardWrapper>
            <HeaderText>{t("My Calendar")}</HeaderText>
            <CompanySection>
        <InputBox>
          <Input
            color="#fff"
            //width="70%"
            placeholder={t("Search Keywords for Job Title or Name")}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </InputBox>
        <div className="hideSearchBars" style={{ marginLeft: "0px" }}>
          <Button
            onClick={chkser}
            style={{ cursor: "pointer" }}
            name={t("Search")}
          />
        </div>
      </CompanySection>
      <SectionWrapper>
        <Dropdown
          className="drop"
          name="Engage"
          search={true}
          options={[
            { label: t("All"), value: "-1" },
            { label: t("Application"), value: "pending" },
            { label: t("Shortlist"), value: "shortlist" },
            { label: t("Engage"), value: "engage" },
            { label: t("Interview"), value: "interview" },
            { label: t("Offered"), value: "offered" },
            { label: t("To Hired"), value: "hire" },
            { label: t("Reject"), value: "reject" },
          ]}
          placeholder="Please Select some options"
          minWidth="auto"
          width="200px"
          isMulti={false}
          val={filapplications?.value}
          label={filapplications?.label}
          id="drop"
          change={(e) => {
            setFilapplications(e);

            chkser();
          }}
        />
        <Dropdown
          name="Engage"
          search={true}
          options={mycalendarProxy.tags}
          placeholder="Please Select some options"
          minWidth="auto"
          width="200px"
          isMulti={false}
          val={tags?.value}
          label={tags?.label}
          id="drop"
          change={(e) => {
            setTags(e);
            chkser();
          }}
        />
       
      </SectionWrapper>
            <MyCalendarView tasks={task}/>
        </MainLayout>
    )
}

import { Device } from '@capacitor/device';
import { proxy } from 'valtio'
let deviceStore = proxy({
    platform : "web",
    checkbrowser:true,
    isBrowser: async ()=>{
        const info = await Device.getInfo();
        deviceStore.platform = info.platform
        if((info.platform=='android' || info.platform=='ios') && info.name.toLowerCase().indexOf("ipad")==-1 && window.innerWidth <= 1024){
        deviceStore.checkbrowser = false
        }else{
            deviceStore.checkbrowser = true
        }
        
    }
})

export {
    deviceStore
}

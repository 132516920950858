import firebase from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyC8Pqv2N5oMBdvRAiaigolKRweIJ7sY6DE",
    authDomain: "mynext-talent.firebaseapp.com",
    projectId: "mynext-talent",
    storageBucket: "mynext-talent.appspot.com",
    messagingSenderId: "1094491164888",
    appId: "1:1094491164888:web:f9b3db6ebef87b757147ad",
    measurementId: "G-4Z6VJB7PZH"
  };

export const app = firebase.initializeApp(firebaseConfig);
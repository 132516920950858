import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import Dropdown from "../../../components/dropdown/dropdown";
import { getCompensationList, saveCompensation, getCompensationDetails } from "../../../services/apiCalls";
import { Colors } from "../../../utilities/colors";
import CustomButton from "../../../components/button/button";
import { useTranslation } from "react-i18next";

const TextInput = styled.div`
  color:${Colors.inputTextDark}
  paddingTop:'8px';
;`;

const ButtonWrapper = styled.div`
  button {
    @media (max-width: 1200px) {
      margin: 15px 0 0px;
    }
  }
`;
const initialState = {
  id: null,
  compensation_id: null,
  display_name: "",
};
const Compensation = ({ notification }) => {
  const [compensationList, setCompensationList] = useState();
  const [selectedComp, setSelectedComp] = useState(initialState);
  const [showSave, setShowSave] = useState(false);
  const [isPatch, setIsPatch] = useState(false);
  const initialRef = useRef();

  useEffect(() => {
    fetchCompensationDetails();
  }, []);

  const saveComp = async () => {
    if (!isPatch) {
      const { data, status } = await saveCompensation({
        compensation: selectedComp?.value,
      });
      setIsPatch(true);
      checkStatus(data, status, "save");
    } else {
      const { data, status } = await saveCompensation(
        { compensation: selectedComp?.value },
        selectedComp?.id
      );
      checkStatus(data, status, "update");
    }
  };

  const checkStatus = (data, status, method) => {
    const methodTmp =
      method === "save" ? "saved" : method === "update" ? "updated" : "deleted";
    if (data?.status === 1 && status === 200) {
      setShowSave(false);
      if (method === "save") {
        notification("success", t("Compensation details saved successfully"));
      } else if (method === "update") {
        notification("success", t("Compensation details updated successfully"));
      } else if (method === "delete") {
        notification("success", t("Compensation details deleted successfully"));
      }
    } else {
      if (method === "save") {
        notification("error", t("Unable to save compensation details"));
      } else if (method === "update") {
        notification("error", t("Unable to update compensation details"));
      } else if (method === "delete") {
        notification("error", t("Unable to delete compensation details"));
      }
    }
  };

  const getDisplayName = (list, id) => {
    return list.find((f) => f.value === id)?.label;
  };

  const fetchCompensationDetails = async () => {
    const [compListResult, compDataResult] = await Promise.all([
      getCompensationDetails(),
      getCompensationList(),
    ]);
    let result = [];
    const { id } = compDataResult?.data?.data;
    if (compListResult.status === 200) {
      result = compListResult.data.data.map((m) => {
        return { ...ButtonWrapper, label: t(m.Name), value: m.Id, id: m.Id };
      });
      setCompensationList(result);
    } else {
      notification("error", t("Unable to fetch compensation master data"));
    }
    if (compDataResult.status === 200) {
      if (
        compDataResult.data &&
        compDataResult.data.data &&
        Object.keys(compDataResult.data.data).length !== 0
      ) {
        const { id, compensation_id } = compDataResult.data.data;
        const display_name = result.find(
          (f) => f.value === compensation_id
        )?.label;
        setSelectedComp({
          value: compensation_id,
          label: display_name,
          id: id,
        });
        setIsPatch(true);
        initialRef.current = {
          value: compensation_id,
          label: display_name,
          id: id,
        };
      }
    } else {
      notification("error", t("Unable to fetch compensation details"));
    }
  };

  const handleChange = (data) => {
    setSelectedComp({ value: data.value, label: data.label, id: data.id });
    setShowSave(true);
  };

  const resetForm = () => {
    setSelectedComp(initialRef.current);
    setShowSave(false);
  };
  
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Col md="6">
          <TextInput>{t("Select Expected Compensation")}</TextInput>
        </Col>
        <Col md="6">
          <Dropdown
            minWidth="auto"
            search={true}
            options={compensationList}
            placeholder={t("Select")}
            label={t(selectedComp?.label)}
            val={selectedComp?.value}
            id={selectedComp?.id}
            change={(value) => handleChange(value)}
          />
        </Col>
      </Row>
      {showSave && (
        <Row>
          <Col lg="12" xl="6">
            <ButtonWrapper>
              <CustomButton
                width="100%"
                onClick={saveComp}
                name={isPatch ? t("Update") : t("Save")}
                minWidth="0"
              />
            </ButtonWrapper>
          </Col>

          <Col lg="12" xl="6">
            <ButtonWrapper>
              <CustomButton
                width="100%"
                onClick={resetForm}
                secondary
                name={t("Cancel")}
                minWidth="0"
              />
            </ButtonWrapper>
          </Col>
        </Row>
      )}
    </>
  );
};
export default Compensation;

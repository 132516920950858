import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modalIsOpen: false,
  cache: null,
};

export const detailModalReducer = createSlice({
  name: "detailModal",
  initialState,
  reducers: {
    updateModal: (state, action) => {
      if (typeof action.payload === "boolean") {
        state.modalIsOpen = action.payload;
        state.cache = null;
      } else {
        state.modalIsOpen = action.payload.modalIsOpen;
        state.cache = action.payload.cache;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateModal } = detailModalReducer.actions;

export default detailModalReducer.reducer;

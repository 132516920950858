
import React from "react";
import Loader from "../loader/loader";

export default function ChartLoader(){
 
    return (
        <div style={{
            
            display:"inline-block",
            height:"200px",
            width:"200px",
            display:"flex",
            padding:"40",
            position: "relative",

            
            margin: "auto",}}>
          <Loader style={{
            height:100,
            width:100,
            position: "absolute",
            top: "50%",
            left: "50%",
            margin: "-25px 0 0 -25px",
            alignContent:"center",
            justifyContent:"center",
            padding:"30"
          }} color="red" size="lg"/>
          </div>
    )
}
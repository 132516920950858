import * as yup from "yup";
import {
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import { validateObf } from "../../services/apiCalls";

export const armyStaticContent = {
  title: "Tell us more about yourself",
  workDuration: "Years of Experience (Tenure in Years)*",
  job: "Position/Job Title*",
  allowance: "Salary (RM per month before tax)*",
  academicTitle: "Highest Academic Qualification*",
  highestQualification: "Country of Highest Institution*",
  scope: "Scope of Study*",
  countries: [
    { label: "Malaysia", value: 1 },
    { label: "Other Countries", value: 0 },
  ],
  englishTestTitle: "English Equivalent Tests*",
  tests: [
    "MUET",
    "CEFR",
    "TOEFL",
    "IELTS",
    "Others",
    "I have not taken any tests",
  ],
  description:
    "We will need some basic information about you before we get started",
  army: "Are you an Army Personnel?",
  is_army_option: [
    { label: "No", value: 0 },
    { label: "Yes", value: 1 }
  ],
  service_type: "Service Type",
  corps_regiment: "Corps/Regiment Service Type",
  last_rank: "Last Rank in Service",
  army_active_label: "Are you still in service or retired?",
  army_is_active: [
    { label: "Retired", value: 0 },
    { label: "Still In Service", value: 1 }
  ],
  pension_status_options: [
    { label: "Pensioner", value: 1 },
    { label: "Non-Pensioner", value: 0 }
  ],
  gradeTitle: "Please indicate your current grade*",
  grades: ["CGPA", "Grades", "Others", "No Current Grade"],
}

const tests = ["MUET", "CEFR", "TOEFL", "IELTS", "Others"];

export const armySchemaIos = yup.object({
  display_name: yup.string().max(100, "maximum 100 characters are allowed"),
  gender: yup.string().required("Gender is required"),
  dob: yup
    .string()
    .required("Date of birth is required")
    .test(
      "dob",
      "age should be greater than 16 and lesser than 100",
      (value) => {
        const age = Math.floor(
          (new Date() - new Date(value).getTime()) / 3.15576e10
        );
        if (age < 16 || age > 100) {
          return false;
        }
        return true;
      }
    ),
  mob_number: yup
    .string()
    .required("Mobile Number is required")
    .test("mob_number", "Mobile Number is not valid", (value) =>
      isPossiblePhoneNumber(value)
    ),
  nationality: yup.number(),
  full_name: yup
    .string()
    .required("Full name is required")
    .max(100, "maximum 100 characters are allowed"),
  race: yup.string().when("nationality", (nationality) => {
    if (nationality === 1) return yup.string();
  }),
  country: yup.string().when("nationality", (nationality) => {
    if (nationality === 0) return yup.string();
  }),
  curr_country: yup.string(),
  state: yup.string().when("$statesDisable", (statesDisable) => {
    if (!statesDisable) {
      return yup.string().required("state is required");
    }
  }),
  city: yup.string().when("$citiesDisable", (citiesDisable) => {
    if (!citiesDisable) {
      return yup.string().required("city is required");
    }
  }),
  postalcode: yup.string().when("postcode", (postcode) => {
    if (postcode !== null) {
      return yup
        .string()
        .required("postal code is required")
        .test("id_number", "Invalid postal code", (value) =>
          /^(?=.*\d.*)[A-Za-z0-9]{3,10}$/g.test(value)
        );
    }
  }),
  disability_status: yup.number(),

  is_army: yup.number(),
  army_id_number: yup.string().required("Army ID is required"),
  army_service: yup.string().required("Service type is required"),
  army_corp_regiment: yup.string().required("Corps/Regiment type is required"),
  army_last_rank: yup.string().required("Last rank in service is required"),
  army_is_active: yup.number("Service status is required").typeError("Service status is required").required("Service status is required"),
  dur_year: yup.number("Please input a number").typeError("Must be a number").required("Years of experience is required"),
  dur_month: yup.number("Please input a number").typeError("Must be a number").required("Months of experience is required"),
  position: yup.string().required("Position/job title is required"),
  salary: yup.number("Salary is required").typeError("Must be a number").required("Salary is required"),
  high_qualification: yup.number("Highest academic qualification is required").typeError("Highest academic qualification is required").required("Highest academic qualification is required"),
  insti_country_status: yup
    .number()
    .when("high_qualification", (high_qualification) => {
      if ((high_qualification !== 10) || (high_qualification !== 11) || (high_qualification !== 12))
        return yup.string().required("Country of highest institution is required");
    }),
  insti_country: yup
    .number()
    .when("insti_country_status", (insti_country_status) => {
      if (insti_country_status == 0)
        return yup.string().required("Country of highest institution is required");
    }),
  insti_name: yup
    .string()
    .when("high_qualification", (high_qualification) => {
      if ((high_qualification !== 10) && (high_qualification !== 11) && (high_qualification !== 12))
        return yup.string().required("Highest institution is required");
    }),
  english_tests: yup
    .string()
    .required("Test selection is required"),
  english_score: yup.string().when("english_tests", (english_tests) => {
    if (english_tests === "IELTS") {
      return yup
        .string()
        .required("Test score is required")
        .test(
          "english_score",
          "Score should be between 0 and 9",
          (val) => val === undefined || val === null || (val <= 9 && val >= 0)
        );
    } else if (english_tests === "TOEFL") {
      return yup
        .string()
        .required("Test score is required")
        .test(
          "english_score",
          "Score should be between 0 and 30",
          (val) => val === undefined || val === null || (val <= 30 && val >= 0)
        );
    } else if (english_tests === "Others") {
      return yup
        .string()
        .required("Test score and type is required")
    } else {
      if (tests.indexOf(english_tests) !== -1)
        return yup.string().required("Test score is required");
    }
  }),
  scope: yup
    .string()
    .when("high_qualification", (high_qualification) => {
      if (high_qualification !== 10 && high_qualification !== 11 && high_qualification !== 12)
        return yup.string().required("Scope of study is required");
    }),
  grade_status: yup
    .string()
    .when("high_qualification", (high_qualification) => {
      if ((high_qualification !== 10) && (high_qualification !== 11) && (high_qualification !== 12))
        return yup.string().required("Grade is required");
    }),
  grade: yup.string().when("grade_status", (grade_status) => {
    if (grade_status === "CGPA") {
      return yup
        .string()
        .required("CGPA is required")
        .test(
          "grade",
          "CGPA should be between 1 and 10 and precision up to 2 decimal places",
          (val) => val === undefined || val === null || (val <= 10 && val >= 1)
        );
    }
    else if (grade_status === "Grades") {
      return yup
        .string()
        .required("Grade is required");
    }
    else if (grade_status === "Others") {
      return yup
        .string()
        .required("Grade is required");
    }
    else if (grade_status === "No Current Grade") {
      if (tests.indexOf(grade_status) !== -1)
        return yup.string().required("Grade is required");
    }
  }),
  pension_status: yup.number("Pension status is required").typeError("Pension status is required").required("Pension status is required"),
  perhebat_training_status: yup.number("PERHEBAT training status is required").typeError("PERHEBAT training status is required").required("PERHEBAT training status is required"),
  retirement_year: yup.string().required("Retirement year is required")
});


export const armySchema = yup.object({
  id_type: yup.string().required(),
  id_number: yup.string().when("id_type", (id_type) => {
    if (id_type === 1) {
      return yup
        .string()
        .required("Identification card number is required")
        .test(
          "id_number",
          "Invalid identification card format",
          (value) => /[a-zA-Z\d]{6}-\d{2}-\d{4}/gm.test(value),
          "ic"
        )
        .test(
          "id_number",
          "Identification card number already linked with a different account",
          async (value) => {
            let code = "1";
            let req = {
              data: value,
            };
            const { data, status } = await validateObf(req, code);
            if (data && data.status === 1) {
              return true;
            } else {
              return false;
            }
          }
        );
    }
    if (id_type === 0) {
      return yup
        .string()
        .min(1, "Passport number should be at least 1 character")
        .max(
          20,
          "Passport number should not be more than 20 characters"
        )
        .required("Passport number is required");
    }
  }),
  display_name: yup.string().max(100, "maximum 100 characters are allowed"),
  gender: yup.string().required("Gender is required"),
  dob: yup
    .string()
    .required("Date of birth is required")
    .test(
      "dob",
      "age should be greater than 16 and lesser than 100",
      (value) => {
        const age = Math.floor(
          (new Date() - new Date(value).getTime()) / 3.15576e10
        );
        if (age < 16 || age > 100) {
          return false;
        }
        return true;
      }
    ),
  mob_number: yup
    .string()
    .required("Mobile Number is required")
    .test("mob_number", "Mobile Number is not valid", (value) =>
      isPossiblePhoneNumber(value)
    ),
  nationality: yup.number().required(),
  full_name: yup
    .string()
    .required("Full name is required")
    .max(100, "maximum 100 characters are allowed"),
  race: yup.string().when("nationality", (nationality) => {
    if (nationality === 1) return yup.string().required("Race is required");
  }),
  country: yup.string().when("nationality", (nationality) => {
    if (nationality === 0) return yup.string().required("country is required");
  }),
  curr_country: yup.string().required("country is required"),
  state: yup.string().when("$statesDisable", (statesDisable) => {
    if (!statesDisable) {
      return yup.string().required("state is required");
    }
  }),
  city: yup.string().when("$citiesDisable", (citiesDisable) => {
    if (!citiesDisable) {
      return yup.string().required("city is required");
    }
  }),
  postalcode: yup.string().when("postcode", (postcode) => {
    if (postcode !== null) {
      return yup
        .string()
        .required("postal code is required")
        .test("id_number", "Invalid postal code", (value) =>
          /^(?=.*\d.*)[A-Za-z0-9]{3,10}$/g.test(value)
        );
    }
  }),
  disability_status: yup.number().required(),
  disability_code: yup.string().when("disability_status", {
    is: 1,
    then: yup
      .string()
      .required("disability code is required")
      .test(
        "disability_code",
        "Must be exactly 12 digit numbers",
        (val) => val === undefined || val === null || val.toString().length === 12
      ),
  }),
  is_army: yup.number(),
  army_id_number: yup.string().required("Army ID is required"),
  army_service: yup.string().required("Service type is required"),
  army_corp_regiment: yup.string().required("Corps/Regiment type is required"),
  army_last_rank: yup.string().required("Last rank in service is required"),
  army_is_active: yup.number("Service status is required").typeError("Service status is required").required("Service status is required"),
  dur_year: yup.number("Please input a number").typeError("Must be a number").required("Years of experience is required"),
  dur_month: yup.number("Please input a number").typeError("Must be a number").required("Months of experience is required"),
  position: yup.string().required("Position/job title is required"),
  salary: yup.number("Salary is required").typeError("Must be a number").required("Salary is required"),
  high_qualification: yup.number("Highest academic qualification is required").typeError("Highest academic qualification is required").required("Highest academic qualification is required"),
  insti_country_status: yup
    .number()
    .when("high_qualification", (high_qualification) => {
      if ((high_qualification !== 10) || (high_qualification !== 11) || (high_qualification !== 12))
        return yup.string().required("Country of highest institution is required");
    }),
  insti_country: yup
    .number()
    .when("insti_country_status", (insti_country_status) => {
      if (insti_country_status == 0)
        return yup.string().required("Country of highest institution is required");
    }),
  insti_name: yup
    .string()
    .when("high_qualification", (high_qualification) => {
      if ((high_qualification !== 10) && (high_qualification !== 11) && (high_qualification !== 12))
        return yup.string().required("Highest institution is required");
    }),
  english_tests: yup
    .string()
    .required("Test selection is required"),
  english_score: yup.string().when("english_tests", (english_tests) => {
    if (english_tests === "IELTS") {
      return yup
        .string()
        .required("Test score is required")
        .test(
          "english_score",
          "Score should be between 0 and 9",
          (val) => val === undefined || val === null || (val <= 9 && val >= 0)
        );
    } else if (english_tests === "TOEFL") {
      return yup
        .string()
        .required("Test score is required")
        .test(
          "english_score",
          "Score should be between 0 and 30",
          (val) => val === undefined || val === null || (val <= 30 && val >= 0)
        );
    } else if (english_tests === "Others") {
      return yup
        .string()
        .required("Test score and type is required")
    } else {
      if (tests.indexOf(english_tests) !== -1)
        return yup.string().required("Test score is required");
    }
  }),
  scope: yup
    .string()
    .when("high_qualification", (high_qualification) => {
      if (high_qualification !== 10 && high_qualification !== 11 && high_qualification !== 12)
        return yup.string().required("Scope of study is required");
    }),
  grade_status: yup
    .string()
    .when("high_qualification", (high_qualification) => {
      if ((high_qualification !== 10) && (high_qualification !== 11) && (high_qualification !== 12))
        return yup.string().required("Grade is required");
    }),
  grade: yup.string().when("grade_status", (grade_status) => {
    if (grade_status === "CGPA") {
      return yup
        .string()
        .required("CGPA is required")
        .test(
          "grade",
          "CGPA should be between 1 and 10 and precision up to 2 decimal places",
          (val) => val === undefined || val === null || (val <= 10 && val >= 1)
        );
    }
    else if (grade_status === "Grades") {
      return yup
        .string()
        .required("Grade is required");
    }
    else if (grade_status === "Others") {
      return yup
        .string()
        .required("Grade is required");
    }
    else if (grade_status === "No Current Grade") {
      if (tests.indexOf(grade_status) !== -1)
        return yup.string().required("Grade is required");
    }
  }),
  pension_status: yup.number("Pension status is required").typeError("Pension status is required").required("Pension status is required"),
  perhebat_training_status: yup.number("PERHEBAT training status is required").typeError("PERHEBAT training status is required").required("PERHEBAT training status is required"),
  retirement_year: yup.string().required("Retirement year is required")
});
import { useState, useEffect } from "react";

import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { IoIosArrowBack } from "react-icons/io";
import { useTranslation } from "react-i18next";

import UserCreationLayout from "../../layouts/userCreation/userCreationLayout";
import IntroSection from "../../components/introSection/introSection";
import Divider from "../../components/divider/divider";
import Input from "../../components/input/input";
import Button from "../../components/button/button";
import { Colors } from "../../utilities/colors";
import ErrorField from "../../components/errorField/errorField";
import { resetPassword } from "../../services/apiCalls";
import Loader from "../../components/loader/loader";
import { FaEye, FaEyeSlash } from "react-icons/fa";

// styling starts
const Label = styled.label`
  font-weight: 500;
  display: block;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (max-width: 650px) {
    display: block;
  }
`;
const LabelText = styled.label`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;
const PasswordInput = styled.div`
  position: relative;
`;
const Visibility = styled.div`
  position: absolute;
  display: inline;
  right: 14px;
  top: 9px;
  cursor: pointer;
  svg {
    fill: gray;
  }
`;
const SignUpBtn = styled(LabelText)`
  color: ${Colors.primary};
  text-decoration: underline;
  margin-left: 10px;
  cursor: pointer;
`;

const FormWrapper = styled.div`
  margin: 10px 0px;
  max-width: 440px;

  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;

const FormLabel = styled(Label)`
  font-size: 16px;
  line-height: 22px;
  color: #202020;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const GroupWrapper = styled.div`
  margin-bottom: 40px;
`;
// styling ends

const schema = yup
  .object({
    code: yup.string().required(""),
    password: yup.string().required("Please enter your password."),
    confirm_password: yup
      .string()
      .required("Please re type your password.")
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.password === value;
      }),
  })
  .required();

export default function Confirmpass() {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      code: "",
      password: "",
      confirm_password: "",
    },
    resolver: yupResolver(schema),
  });

  const [formErrors, setFormErros] = useState("");
  const [loading, setLoader] = useState(false);
  const formValues = watch();
  const [newPasswordShow, setNewPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);

  const navigate = useNavigate();
  const onNavigate = (path) => {
    navigate(path);
  };
  const location = useLocation();

  useEffect(() => {
    if (location.state.name) {
      setValue("code", location.state.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const togglePasswordVisiblity = (index) => {
    if (index === 0) {
      setNewPasswordShow(newPasswordShow ? false : true);
    } else {
      setConfirmPasswordShow(confirmPasswordShow ? false : true);
    }
  };
  const onSubmit = async () => {
    setLoader(true);
    const { data } = await resetPassword({ ...formValues });
    if (data && data.status === 1) {
      toast.success(t("password changed successfully"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoader(false);
      navigate("/");
    } else {
      setLoader(false);
      toast.error(data && t(data.message), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const { t } = useTranslation();
  return (
    <UserCreationLayout>
      <HeaderWrapper onClick={() => onNavigate("/forget")}>
        <IoIosArrowBack color={Colors.primary} size={20} />
        <SignUpBtn>{t("Back")}</SignUpBtn>
      </HeaderWrapper>
      <IntroSection
        name={t("Reset Password")}
        description={t(
          "Please provide the following details to reset your password."
        )}
      />
      <Divider />
      <FormWrapper>
        <GroupWrapper>
          <FormLabel>{t("New Password*")}</FormLabel>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <PasswordInput>
                <Input
                  placeholder={t("Enter New Password")}
                  type={newPasswordShow ? "text" : "password"}
                  {...field}
                />
                <Visibility onClick={() => togglePasswordVisiblity(0)}>
                  {newPasswordShow ? <FaEyeSlash /> : <FaEye />}
                </Visibility>
              </PasswordInput>
            )}
          />
          {errors.password && <ErrorField err={t(errors.password.message)} />}
        </GroupWrapper>
        <GroupWrapper>
          <FormLabel>{t("Confirm New Password*")}</FormLabel>
          <Controller
            name="confirm_password"
            control={control}
            render={({ field }) => (
              <PasswordInput>
                <Input
                  placeholder={t("Password")}
                  type={confirmPasswordShow ? "text" : "password"}
                  {...field}
                />
                <Visibility onClick={() => togglePasswordVisiblity(1)}>
                  {confirmPasswordShow ? <FaEyeSlash /> : <FaEye />}
                </Visibility>
              </PasswordInput>
            )}
          />
          {errors.confirm_password && (
            <ErrorField err={t(errors.confirm_password.message)} />
          )}
        </GroupWrapper>
        {formErrors && <ErrorField err={t(formErrors)} />}
        {loading ? (
          <Loader />
        ) : (
          <Button
            onClick={handleSubmit(onSubmit)}
            name={t("Create New Password")}
            className="w-100"
          />
        )}
      </FormWrapper>
    </UserCreationLayout>
  );
}

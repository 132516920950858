import { createSlice } from "@reduxjs/toolkit";

import { onBoardForm } from "../../utilities/constants";

const initialState = {
  value: onBoardForm,
  successData: {},
  isUpdate: false,
  talentStatus: 0,
  formOptions: {},
};

export const selfIntroStepOneFormReducer = createSlice({
  name: "selfIntroOne",
  initialState,
  reducers: {
    updateValues: (state, action) => {
      state.value = action.payload;
    },
    updateStatus: (state, action) => {
      state.talentStatus = action.payload;
    },
    updateSuccessObj: (state, action) => {
      state.successData = action.payload;
    },
    updateFormOptions: (state, action) => {
      state.formOptions = action.payload;
    },
    updateSelfIntroStepOneForm: (state, action) => {
      state.isUpdate = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateValues,
  updateSuccessObj,
  updateStatus,
  updateSelfIntroStepOneForm,
  updateFormOptions
} = selfIntroStepOneFormReducer.actions;

export default selfIntroStepOneFormReducer.reducer;

import { Modal } from "react-bootstrap";


export default function JobPortalModal(props) {
    return (
        <div >
            <Modal show={props.show} onHide={props.close} centered size="lg" contentClassName="modal-height">
                {/* <div style={{ padding: "40px 15px 10px", minHeight: "220px" }}>
                   
                </div> */}
                 <div style={{ position: "relative"}}>{props.content}</div>
            </Modal>
        </div>
    );
}

import styled from "styled-components";

import UserCreationLayout from "../../layouts/userCreation/userCreationLayout";
import IntroSection from "../../components/introSection/introSection";

import Logo from "../../assets/icons/svg/mynextLogo.svg";

// styling starts
const LoginPageWrapper = styled.section``;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

// styling ends

export default function TermsPage() {
  return (
    <UserCreationLayout>
      <LoginPageWrapper>
        <HeaderWrapper>
          <img src={Logo} alt="logo my next" />
        </HeaderWrapper>
        <IntroSection
          name="Terms and conditions"
          description="A proprietary national smart system designed for professional development."
        />
      </LoginPageWrapper>
    </UserCreationLayout>
  );
}

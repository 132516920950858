import React from "react";

import "./list.css";
import { t } from "i18next";

const ListComponent = (props) => {
  const options = [
    {
      text: "Provides a solution for undergraduate & postgraduates seeking internship opportunities in companies across Malaysia offered on one platform.",
      id: 1,
    },
    { 
      text: "Access to the mynext Profiling Suite, which includes assessments related to work values, work interests, personality type, motivations, and employability",
      id: 2
    },
    { 
      text: "Access to the English proficiency test which measures your level of English competency based on grammar and comprehension which are graded on levels from 1-5",
      id: 3
    },
    { 
      text: "Provides access to suitable paying internship opportunities with the integration of the Structured Internship Programme (SIP) framework, offering reasonable minimum allowances for interns.",
      id: 4
    },
    { 
      text: "Opportunities to gain first-hand industrial and working experience in related academic fields of study by crafting a personal industry-standard CV.",
      id: 5
    },
    { 
      text: "Allows the exploration of career paths beyond the field of study by matching internships to your personal profiling (strengths and weaknesses)",
      id: 6
    },
  ];

  const listMarkup = options.map((option) => (
    <li key={option.id} className="list-item">
      {t(option.text)}
    </li>
  ));

  return <div className="list-container"><ul className="no-bullets">{listMarkup}</ul></div>;
};

export default ListComponent;
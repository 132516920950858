import { onApiCall } from "./CommonApi";

export const authSignin = (request) => {
  return onApiCall({
    url: `auth/api/talent/login/`,
    method: "POST",
    data: request,
  });
};

export const forceLogout = (request) => {
  return onApiCall({
    url: `/auth/api/force-logout/`,
    method: "POST",
    data: { email: request },
  });
};

export const authSignUp = (request) => {
  return onApiCall({
    url: `auth/api/talent/register/`,
    method: "POST",
    data: request,
  });
};

export const socialSignUp = (request) => {
  return onApiCall({
    url: `auth/api/talent/social-login/`,
    method: "POST",
    data: request,
  });
};

export const onTriggerOtp = (request) => {
  return onApiCall({
    url: `base/api/email-validation-trigger/`,
    method: "POST",
    data: request,
  });
};

export const forgotPasswordEmail = (request) => {
  return onApiCall({
    url: `base/api/forgot-password/`,
    method: "POST",
    data: request,
  });
};
export const resetOtpVerify = (request) => {
  return onApiCall({
    url: `base/api/forgot-password/`,
    method: "POST",
    data: request,
  });
};
export const forgotPasswordValidation = (request) => {
  return onApiCall({
    url: `base/api/forgot-password-validation/`,
    method: "POST",
    data: request,
  });
};
export const resetPassword = (request) => {
  return onApiCall({
    url: `base/api/reset-password/`,
    method: "POST",
    data: request,
  });
};

export const changePassword = (formData) => {
  return onApiCall({
    url: `/talent/api/change-password/`,
    method: "POST",
    data: formData,
  });
};

export const validateEmailAddress = (request) => {
  return onApiCall({
    url: `base/api/user-validation/`,
    method: "POST",
    data: request,
  });
};

export const onOtpVerify = (request) => {
  return onApiCall({
    url: `base/api/email-validation/`,
    method: "POST",
    data: request,
  });
};

export const userDetailsUpdate = (formData) => {
  return onApiCall({
    url: `/talent/api/talent-user-update/`,
    method: "POST",
    data: formData,
  });
};

export const authLogout = (formdata) => {
  return onApiCall({
    url: `auth/api/talent/logout/`,
    method: "GET",
    data: formdata,
  });
};

export const getCountries = (formdata) => {
  return onApiCall({
    url: `talent/api/onboard-form-step-1-scroll-list/`,
    method: "GET",
    data: formdata,
  });
};

export const getCurrentCountries = (formdata, params) => {
  return onApiCall({
    url: `base/api/scroll-list/${params.hint}/${params.loc}/${params.key_term}/`,
    method: "POST",
    data: formdata,
  });
};

// Master Data Services
export const getAllCountries = () => {
  return onApiCall({
    url: `/api/master/countries/`,
    method: "GET",
  });
};
export const getStates = (countryId) => {
  return onApiCall({
    url: `/api/master/states/${countryId}`,
    method: "GET",
  });
};
export const getCities = (countryId, stateId) => {
  return onApiCall({
    url: `/api/master/cities/${countryId}/${stateId}/`,
    method: "GET",
  });
};
export const getEthinicities = () => {
  return onApiCall({
    url: `/api/master/ethinicities/`,
    method: "GET",
  });
};

export const getUniversity = () => {
  return onApiCall({
    url: `/api/master/universities/`,
    method: "GET",
  });
};

export const getCampus = (universityId) => {
  return onApiCall({
    url: `/api/master/campus/`,
    method: "GET",
    params: {
      UniversityId: universityId,
    },
  });
};

export const getFaculty = (campusId) => {
  return onApiCall({
    url: `/api/master/faculty/`,
    method: "GET",
    params: {
      CollegeId: campusId,
    },
  });
};
export const getStudyPrograms = (facultyId) => {
  return onApiCall({
    url: `/api/master/study-program/`,
    method: "GET",
    params: {
      FacultyId: facultyId,
    },
  });
};

export const getAcademicQualifications = () => {
  return onApiCall({
    url: `/api/master/academic-qualifications/`,
    method: "GET",
  });
};

export const getSector = () => {
  return onApiCall({
    url: `/api/master/sector/`,
    method: "GET",
  });
};

export const getCompensationDetails = () => {
  return onApiCall({
    url: `/api/master/compensation-details/`,
    method: "GET",
  });
};
export const getEmploymentStatusOptions = () => {
  return onApiCall({
    url: `/api/master/employment-status-options/`,
    method: "GET",
  });
};
export const getEnglishEquivalentSubtestTypes = () => {
  return onApiCall({
    url: `/api/master/english-equivalent-subtest-types/`,
    method: "GET",
  });
};

export const getEnglishEquivalentTestTypes = () => {
  return onApiCall({
    url: `/api/master/english-equivalent-test-types/`,
    method: "GET",
  });
};
export const getGrades = () => {
  return onApiCall({
    url: `/api/master/grades/`,
    method: "GET",
  });
};

export const getProgrameTypes = () => {
  return onApiCall({
    url: `/api/master/programme-types/`,
    method: "GET",
  });
};

export const getJobTypeDetails = () => {
  return onApiCall({
    url: `/api/master/job-type-details/`,
    method: "GET",
  });
};
export const getPaidOrUnpaid = () => {
  return onApiCall({
    url: `/api/master/paid-or-unpaid/`,
    method: "GET",
  });
};

export const getPersonCurrentStatus = () => {
  return onApiCall({
    url: `/api/master/person-current-status/`,
    method: "GET",
  });
};

export const getProgrammeTypes = () => {
  return onApiCall({
    url: `/api/master/programme-types/`,
    method: "GET",
  });
};

export const getScholarshipSubTypes = () => {
  return onApiCall({
    url: `/api/master/scholarship-subtypes/`,
    method: "GET",
  });
};

export const getScholarshipTypes = () => {
  return onApiCall({
    url: `/api/master/scholarship-types/`,
    method: "GET",
  });
};

export const getScopeOfStudies = () => {
  return onApiCall({
    url: `/api/master/scope-of-studies/`,
    method: "GET",
  });
};

export const getAllSkills = (searchKey) => {
  return onApiCall({
    url: `/api/master/skills`,
    method: "GET",
    params: {
      keyword: searchKey,
    },
  });
};

export const getSkillsCategory = () => {
  return onApiCall({
    url: `/api/master/skills-category/`,
    method: "GET",
  });
};

export const getStatusDetails = () => {
  return onApiCall({
    url: `/api/master/status-details/`,
    method: "GET",
  });
};

export const getYearOfStudies = () => {
  return onApiCall({
    url: `/api/master/year-of-studies/`,
    method: "GET",
  });
};
//---
export const getInstitutions = (formdata, params) => {
  return onApiCall({
    url: `base/api/scroll-list/${params.hint}/${params.loc}/${params.key_term}/`,
    method: "POST",
    data: formdata,
  });
};

export const onBoardStepOneForm = (formdata) => {
  return onApiCall({
    url: `talent/api/obf-step-1-validation/`,
    method: "POST",
    data: formdata,
  });
};

export const onBoardStepTwoForm = (formdata) => {
  return onApiCall({
    url: `talent/api/obf-step-2-validation/`,
    method: "POST",
    data: formdata,
  });
};

export const getFormOptions = (formdata) => {
  return onApiCall({
    url: `talent/api/onboard-form-step-2-scroll-list/`,
    method: "GET",
    data: formdata,
  });
};

export const getOption = (formdata, param) => {
  return onApiCall({
    url: `base/api/scroll-list/hint/loc/${param}/`,
    method: "POST",
    data: formdata,
  });
};

export const uploadObject = (formdata) => {
  return onApiCall({
    url: `base/api/file-obj-upload/`,
    method: "POST",
    data: formdata,
    isFileUpload: true,
  });
};

export const onBoardFormSubmission = (formdata) => {
  return onApiCall({
    url: `talent/api/onboard-form/`,
    method: "POST",
    data: formdata,
  });
};

export const onBoardFormGetData = (formdata) => {
  return onApiCall({
    url: `talent/api/onboard-detail-form/`,
    method: "GET",
    data: formdata,
  });
};

export const onBoardGetDataStudying = (formdata) => {
  return onApiCall({
    url: `base/api/scroll-list/loc/${formdata}/`,
    method: "GET",
    data: formdata,
  });
};

export const onAboutCountryandStateLIst = (formdata) => {
  return onApiCall({
    url: `base/api/scroll-list/${formdata.locations}/${formdata.divi}/`,
    method: "GET",
    data: formdata,
  });
};

export const getTalentUserData = (formdata) => {
  return onApiCall({
    url: `auth/api/talent/token-object/`,
    method: "GET",
    data: formdata,
  });
};

export const getAssesmentList = (formdata) => {
  return onApiCall({
    url: `talent/api/assessment-list/`,
    method: "GET",
    data: formdata,
  });
};

export const validateObf = (formdata, id) => {
  return onApiCall({
    url: `base/api/obf-validation/${id}/`,
    method: "POST",
    data: formdata,
  });
};

export const getInternLists = (formdata) => {
  return onApiCall({
    url: `internship/api/internship-list/`,
    method: "POST",
    data: formdata,
  });
};

export const saveEmployeeDetails = (formdata) => {
  return onApiCall({
    url: `mycv/employment-details/`,
    method: "POST",
    data: formdata,
  });
};

export const getEmployeeDetails = () => {
  return onApiCall({
    url: `mycv/employment-details/`,
    method: "GET",
  });
};

export const updateEmployeeDetails = (id, formdata) => {
  return onApiCall({
    url: `mycv/employment-details/${id}`,
    method: "PUT",
    data: formdata,
  });
};

export const deleteEmployeeDetails = (id) => {
  return onApiCall({
    url: `mycv/employment-details/${id}`,
    method: "DELETE",
  });
};

export const addEducationDetails = (formdata) => {
  return onApiCall({
    url: `mycv/institution-details/`,
    method: "POST",
    data: formdata,
  });
};

export const getEducationDetails = () => {
  return onApiCall({
    url: `mycv/institution-details/`,
    method: "GET",
  });
};

export const deleteEducationDetails = (id) => {
  return onApiCall({
    url: `mycv/institution-details/${id}`,
    method: "DELETE",
  });
};

export const updateEducationDetails = (id, formdata) => {
  return onApiCall({
    url: `mycv/institution-details/${id}`,
    method: "PUT",
    data: formdata,
  });
};

export const saveInternshipDetails = (formdata) => {
  return onApiCall({
    url: `mycv/internship-details/`,
    method: "POST",
    data: formdata,
  });
};

export const getInternshipDetails = () => {
  return onApiCall({
    url: `mycv/internship-details/`,
    method: "GET",
  });
};

export const updateInternshipDetails = (id, formdata) => {
  return onApiCall({
    url: `mycv/internship-details/${id}`,
    method: "PUT",
    data: formdata,
  });
};

export const deleteInternshipDetails = (id) => {
  return onApiCall({
    url: `mycv/internship-details/${id}`,
    method: "DELETE",
  });
};

export const saveEventDetails = (formdata) => {
  return onApiCall({
    url: `mycv/event-details/`,
    method: "POST",
    data: formdata,
  });
};

export const getEventDetails = () => {
  return onApiCall({
    url: `mycv/event-details/`,
    method: "GET",
  });
};

export const updateEventDetails = (id, formdata) => {
  return onApiCall({
    url: `mycv/event-details/${id}`,
    method: "PUT",
    data: formdata,
  });
};

export const deleteEventDetails = (id) => {
  return onApiCall({
    url: `mycv/event-details/${id}`,
    method: "DELETE",
  });
};

export const saveLocation = (formdata) => {
  return onApiCall({
    url: `mycv/location/`,
    method: "POST",
    data: formdata,
  });
};

export const getLocations = () => {
  return onApiCall({
    url: `mycv/location/`,
    method: "GET",
  });
};

export const updateLocation = (id, formdata) => {
  return onApiCall({
    url: `mycv/location/${id}`,
    method: "PUT",
    data: formdata,
  });
};

export const deleteLocation = (id) => {
  return onApiCall({
    url: `mycv/location/${id}`,
    method: "DELETE",
  });
};

export const saveSummary = (formdata) => {
  return onApiCall({
    url: `mycv/summary-cv/`,
    method: "POST",
    data: formdata,
  });
};

export const updateSummary = (formdata, id) => {
  return onApiCall({
    url: `mycv/summary-cv/${id}`,
    method: "PUT",
    data: formdata,
  });
};

export const getSummary = () => {
  return onApiCall({
    url: `mycv/summary-cv/`,
    method: "GET",
  });
};

export const saveInternshipPref = (formdata) => {
  return onApiCall({
    url: `mycv/internship-preference/`,
    method: "POST",
    data: formdata,
  });
};

export const getInternshipPref = () => {
  return onApiCall({
    url: `mycv/internship-preference/`,
    method: "GET",
  });
};

export const updateInternshipPref = (id, formdata) => {
  return onApiCall({
    url: `mycv/internship-preference/${id}`,
    method: "PUT",
    data: formdata,
  });
};

export const getSkills = () => {
  return onApiCall({
    url: `mycv/skills/`,
    method: "GET",
  });
};
export const getSkillss = (pk) => {
  return onApiCall({
    url: `company/api/skills/${pk}/`,
    method: "GET",
  });
};
export const getLocationss = (pk) => {
  return onApiCall({
    url: `company/api/location/${pk}`,
    method: "GET",
  });
};

export const saveSkills = (formdata) => {
  return onApiCall({
    url: `mycv/skills/`,
    method: "POST",
    data: formdata,
  });
};

export const deleteSkills = (id) => {
  return onApiCall({
    url: `mycv/skills/${id}`,
    method: "DELETE",
  });
};

export const onUpdateCompanyDatas = (formdata) => {
  return onApiCall({
    url: `admin/api/company-update/${formdata.code}/`,
    method: "PUT",
    data: formdata.data,
  });
};
export const getCompanyData = (formdata) => {
  return onApiCall({
    url: `company/api/company-view/${formdata.code}/`,
    method: "GET",
    data: formdata,
  });
};

export const ongettingInternInfo = (request) => {
  return onApiCall({
    url: `internship/api/internship-view/${request.id}`,
    method: "GET",
    data: request,
  });
};

export const saveCompensation = (formdata) => {
  return onApiCall({
    url: `mycv/compensation/`,
    method: "POST",
    data: formdata,
  });
};

export const updateCompensation = (formdata, id) => {
  return onApiCall({
    url: `mycv/compensation/${id}`,
    method: "PUT",
    data: formdata,
  });
};

export const getCompensationList = () => {
  return onApiCall({
    url: `mycv/compensation/`,
    method: "GET",
  });
};

export const getProfileDetails = () => {
  return onApiCall({
    url: `mycv/overview/`,
    method: "GET",
  });
};

export const getTalentprofileview = () => {
  return onApiCall({
    url: `/talent/api/talent-profile-view/`,
    method: "GET",
  });
};

export const updateProfilePicture = (formdata) => {
  return onApiCall({
    url: `/talent/api/change-avatar/`,
    method: "PUT",
    data: formdata,
  });
};

export const uploadInternshipFiles = (formdata, id) => {
  return onApiCall({
    url: `/internship/internship-file-apply/${id}`,
    method: "POST",
    data: formdata,
  });
};

export const internshipApply = (formdata, id) => {
  return onApiCall({
    url: `/internship/internship-apply/${id}`,
    method: "POST",
    data: formdata,
  });
};

export const internshipStatus = () => {
  return onApiCall({
    url: `/internship/internship-status/`,
    method: "GET",
  });
};

//fetch user resume full data
export const getResumeData = async () => {
  return await onApiCall({
    url: `mycv/overall-mycv`,
    method: "GET",
  });
};

export const downloadCV = () => {
  return onApiCall({
    url: `mycv/resume/`,
    method: "GET",
    isPdf: true,
  });
};

export const getlistJobs = (code, peram) => {
  return onApiCall({
    url: `company/api/jobs/${code}/${peram}`,
    method: "GET",
  });
};

export const updateJobs = (formdata) => {
  return onApiCall({
    url: `company/api/jobs-update/`,
    method: "put",
    data: formdata,
  });
};

export const getEmployee = () => {
  return onApiCall({
    url: `talent/api/getemployee/`,
    method: "GET",
  });
};

export const changEmployee = (path, id, data) => {
  return onApiCall({
    url: `talent/api/changeemployee/${path}/${id}/`,
    method: "POST",
    data: data,
  });
};

export const getJobs = (param, status) => {
  let url = `talent/api/getjobs/${status}/${param}`;
  return onApiCall({
    url: url,
    method: "GET",
  });
};

export const getJobsbyid = (code) => {
  return onApiCall({
    url: `talent/api/getjobbyid/${code}/`,
    method: "GET",
  });
};

export const getjobdocument = (pk) => {
  return onApiCall({
    url: `company/api/jobdocument/${pk}/`,
    method: "GET",
  });
};

export const getAppliedJobDocuments = (formdata) => {
  return onApiCall({
    url: `company/api/job-documents-view/${formdata.job_id}/`,
    method: "GET",
  });
};

export const onJobDocUpload = (request) => {
  return onApiCall({
    url: `/company/api/job-documents-create/${request.job_document_id}/`,
    method: "POST",
    data: request.data,
  });
};

export const onJobDocUpdate = (request) => {
  return onApiCall({
    url: `/company/api/job-documents-update/${request.job_document_id}/${request.id}/`,
    method: "PUT",
    data: request.data,
  });
};

export const onJobDocDelete = (request) => {
  return onApiCall({
    url: `/company/api/job-documents-delete/${request.id}/`,
    method: "DELETE",
  });
};

export const applyJob = (id) => {
  return onApiCall({
    url: `talent/api/applyjob/${id}/`,
    method: "GET",
  });
};
export const getScopeOfStudyMasterList = () => {
  return onApiCall({
    url: `/api/master/scope-of-studies/`,
    method: "GET",
  });
};
export const getTalentOceanReport = (param) => {
  return onApiCall({
    url: `/api/dashboard/coaching/ocean-report/${param}/`,
    method: "GET",
  });
};
export const getDataWorkValues = (param) => {
  return onApiCall({
    url: `api/dashboard/coaching/assessment-results/work-values/${param}`,
    method: "GET",
  });
};
export const getDataWorkInterest = (param) => {
  return onApiCall({
    url: `/api/dashboard/coaching/assessment-results/interest-riasec/${param}`,
    method: "GET",
  });
};
export const getDataWorkPersonality = (param) => {
  return onApiCall({
    url: `/api/dashboard/coaching/assessment-results/five-factor/${param}`,
    method: "GET",
  });
};
export const getDataWorkEmployability = (param) => {
  return onApiCall({
    url: `/api/dashboard/coaching/assessment-results/employability/${param}`,
    method: "GET",
  });
};

export const getDataWorkFutureWorks = (param) => {
  return onApiCall({
    url: `/api/dashboard/coaching/assessment-results/century-skills/${param}`,
    method: "GET",
  });
};

export const getDataWorkEnglishTest = (param) => {
  return onApiCall({
    url: `/api/dashboard/coaching/assessment-results/english-test/${param}`,
    method: "GET",
  });
};

export const getTalentData = (param) => {
  return onApiCall({
    url: `/api/dashboard/coaching/assessment-results/interest-riasec/${param}`,
    method: "GET",
  });
};
export const getMyCyOverall = (param) => {
  return onApiCall({
    url: `/api/mycv/overall-mycv-company/${param}/`,
    method: "GET",
  });
};

export const getCompanyBranding = (formdata) => {
  return onApiCall({
    url: `company/api/company-branding-view/${formdata.code}/`,
    method: "GET",
  });
};

export const getCompanyBrandingImages = (formdata) => {
  return onApiCall({
    url: `company/api/company-branding-image-view/${formdata.code}/`,
    method: "GET",
  });
};

export const getCompanyBrandingArticles = (formdata) => {
  return onApiCall({
    url: `company/api/company-branding-article-view/${formdata.code}/`,
    method: "GET",
  });
};

export const getApplications = (id) => {
  return onApiCall({
    url: `talent/api/getapplications/${id}/`,
    method: "GET",
  });
};

export const changApplications = (path, id, data) => {
  return onApiCall({
    url: `company/api/changeapplication/${path}/${id}/`,
    method: "POST",
    data: data,
  });
};

export const blogList = (code) => {
  return onApiCall({
    url: `/talent/api/blogs/`,
    method: "GET",
  });
};
export const viewBlog = (slug) => {
  return onApiCall({
    url: `/talent/api/blogs/${slug}/`,
    method: "GET",
  });
};

export const getmycvuserdatails = () => {
  return onApiCall({
    url: `/talent/api/getmycvuserdatails/`,
    method: "GET",
  });
};

export const updategetmycvuserdatails = (peram, data) => {
  return onApiCall({
    url: `/talent/api/updategetmycvuserdatails/${peram}/`,
    method: "POST",
    data: data,
  });
};

export const getjobscountapi = () => {
  let url = `talent/api/getjobscount/`;
  return onApiCall({
    url: url,
    method: "GET",
  });
};

export const getfavouritesjobs = () => {
  let url = `talent/api/getfavouritesjobs/`;
  return onApiCall({
    url: url,
    method: "GET",
  });
};

export const savefavouritesjobs = (peram, job_id) => {
  let url = `talent/api/favouritesjobs/${peram}/${job_id}/`;
  return onApiCall({
    url: url,
    method: "GET",
  });
};

export const getInternCompanyLists = (formdata) => {
  return onApiCall({
    url: `internship/api/internshipcompany-list/`,
    method: "POST",
    data: formdata,
  });
};

export const getOrCreateInternshipForm = (id) => {
  return onApiCall({
    url: `talent/api/get_or_create_form/`,
    method: "GET",
    params: {
      internship_id: id,
    },
  });
};

export const getInternshipEvaluationQA = () => {
  return onApiCall({
    url: `talent/api/questions_and_answers/`,
    method: "GET",
  });
};

export const getInternshipSkills = (id) => {
  return onApiCall({
    url: `talent/api/internship_skills_and_soft_skills/${id}/`,
    method: "GET",
  });
};

export const createInternshipEvaluationForm = (formData) => {
  return onApiCall({
    url: `talent/api/create_intern_evaluation_form/`,
    method: "POST",
    data: formData,
  });
};

export const updateInternshipEvaluationForm = (formData, formID) => {
  return onApiCall({
    url: `talent/api/update_intern_evaluation_form/${formID}/`,
    method: "PUT",
    data: formData,
  });
};

export const getArmyDetails = () => {
  return onApiCall({
    url: `api/master/army-details/`,
    method: "GET",
  })
}



import React from "react";
import { useTranslation } from "react-i18next";
export default function NoDataChartContainer() {
  const { t, i18n } = useTranslation();
  return (
    <div style={{
      minHeight: "200px",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "40",
      position: "relative",
      textAlign: "center",
      margin: "auto",
      font: 'normal normal 400 22px/ 24px Inter',
      whiteSpace: 'nowrap',
      color: '#615E83',
    }}>
      <p style={{ margin: 0 }}>{t("No Data Found")}</p>
    </div >
  )
}
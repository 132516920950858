// import ReactTooltip from 'react-tooltip';

import styled from "styled-components";

import Input from "../input/input";
import { Colors } from "../../utilities/colors";
import ErrorField from "../errorField/errorField";
// styling starts
const GroupWrapper = styled.div`
  margin-bottom: 20px;
`;
const Label = styled.label`
  font-weight: 500;
  display: block;
`;
const FormLabel = styled(Label)`
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.labelText};
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const UserHelpText = styled(Label)`
  color: ${Colors.inputHighlightText};
  font-size: 12px;
  line-height: 16px;
  margin-top: 10px;
  cursor: pointer;
  text-align: right;
  text-decoration: none;
`;

const LinkText = styled(UserHelpText)`
  cursor: pointer;
  text-decoration: underline;
`;
const FormlabWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

// styling ends

export default function InputGroup({
  name,
  input,
  needHelp,
  helpText,
  helperFunc,
  err,
  changeaddress,
  changeEmail,
  value,
  dataId,
}) {
  return (
    <GroupWrapper>
      <FormlabWrapper>
        <FormLabel>
          {name}
          <LinkText onClick={changeEmail}>{changeaddress}</LinkText>{" "}
        </FormLabel>
      </FormlabWrapper>
      <div data-tip data-for={dataId ? dataId : null}>
        <Input {...value} {...input} />
      </div>
      {err && <ErrorField err={err} />}
      {needHelp && <UserHelpText onClick={helperFunc}>{helpText}</UserHelpText>}
    </GroupWrapper>
  );
}

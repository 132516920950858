export const Colors = {
  primary: "#D44B9C",
  greyText: "#9d9d9d",
  labelText: "#202020",
  //labelText: " #696f79",
  inputBg: "#F3F3F3",
  inputText: "#bcbcbc",
  inputHighlightText: "#DA4B9D",
  light: "#fff",
  primaryText: "#D04C8D",
  dark: "#000",
  lightGray: "#ACACAC",
  pink: "#D04C8D",
  blogTextBox: "#F3F3F3",
  pinkShade: "#d04c8d87",
  inputTextDark: "rgba(133, 133, 133, 1)",
  red:"red",
};

import { useState } from "react";

import styled from "styled-components";
import OtpInput from "react-otp-input";
import { useTranslation } from "react-i18next";

import { Colors } from "../../utilities/colors";
import { useSnapshot } from "valtio";
import { deviceStore } from "../../utilities/devices";

// styling starts
const GroupWrapper = styled.div`
  margin-bottom: 20px;
`;
const Label = styled.label`
  font-weight: 500;
  display: block;
`;
const FormLabel = styled(Label)`
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.labelText};
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;
const LabelHighlightText = styled(Label)`
  color: ${Colors.inputHighlightText};
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  margin-left: 10px;
  > span {
    margin-right: 10px;
  }
`;
const FlexBox = styled.div`
  display: flex;
`;
const InputWrapper = styled(FlexBox)`
  justify-content: space-between;
`;

const focusInput = {
  border: `1px solid ${Colors.primary}`,
};
const VerifyCodeText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #504f8c;
  margin: 0;
  text-decoration: none;
  align-self: end;
  cursor: ${(props) => (props.cursor ? "default" : "pointer")};
  text-decoration: underline;
`;
const OtpError = styled.label`
  color: ${Colors.inputTextDark};
  font-size: 16px;
  margin-top: 5px;
  display: block;
`;
// styling ends

export default function EmailVerification({ onSendNewCode, verifyCode, isSuccess, dataId }) {
  useSnapshot(deviceStore)
  const checkbrowser = deviceStore.checkbrowser
  let Input = {
    background: Colors.inputBg,
    padding: "12px 20px",
    outline: "none",
    width: "-webkit-fill-available",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "22px",
    color: Colors.primary,
    boxSizing: "border-box",
    borderRadius: "10px",
    maxWidth: "54px",
    marginRight: "10px",
    border: `2px solid transparent`,
  };
  if(!checkbrowser){
    Input['padding'] = '12px 10px'
    Input['maxWidth'] = (window.innerWidth/8).toFixed(2)+'px'
  }
  const [otp, setOtp] = useState("");
  const [otpErr, setOtpErr] = useState("");
  const { t } = useTranslation();

  const handleChange = (otp) => {
    setOtp(otp);
    if (otp.length === 5) {
      verifyCode(otp);
    }
  };

  const onVerify = () => {
    if (otp.length === 5) {
      verifyCode(otp);
      setOtpErr("");
    } else {
      setOtpErr(
        t("Please enter the verification code received on your e-mail address.")
      );
    }
  };

  const onRequestNewCode = () => {
    setOtp("");
    onSendNewCode();
  };

  return (
    <GroupWrapper>
      <FormLabel>
        {t("Verification code")}{" "}
        <LabelHighlightText onClick={onRequestNewCode}>
          {t("Send new code")}
        </LabelHighlightText>
      </FormLabel>
      <InputWrapper>
        <FlexBox data-tip data-for={dataId ? dataId : null}>
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={5}
            focusStyle={focusInput}
            inputStyle={Input}
            shouldAutoFocus
            isInputNum
            separator={<span></span>}
            isDisabled={isSuccess}
          />
        </FlexBox>
        <VerifyCodeText
          cursor={isSuccess}
          onClick={isSuccess ? () => {} : onVerify}
        >
          {isSuccess ? t("Verified") : t("Verify code")}
        </VerifyCodeText>
      </InputWrapper>
      {otpErr && <OtpError>{t(otpErr)}</OtpError>}
    </GroupWrapper>
  );
}

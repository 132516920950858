import { useEffect, useState } from "react";
import CreatingRealValueModalContent from "./creatingRealValueModalContent/creatingRealValueModalContent";
import FellowshipModalContent from "./fellowshipModalContent/fellowshipModalContent";
import MasteryModalContent from "./masteryModalContent/masteryModalContent";
import QualityOfLifeModalContent from "./qualityOfLifeModalContent/qualityOfLifeModalContent";
import { Bar } from "react-chartjs-2";
import ReactSpeedometer from "react-d3-speedometer";
import { useTranslation } from "react-i18next";
import { HrLine, StudentModalHeader, StudentModalChartWrapper } from "../commonStyles";

export default function EmployabilityFactorModal(props) {
  const [mode] = useState("mastery");
  const [usableData, setUsableData] = useState([]);
  const [meterOne, setMeterOne] = useState([]);
  //const labels = ["Ideas and Opportunities", "Into Action", "Resources"];
  const { t } = useTranslation();

  const fethUpdateData = () => {
    var datasMeters = [];
    var mainData = [];
    console.log(props?.data?.data, "26586");
    Object.keys(props?.data?.data?.domains).forEach((v, ist) => {
      var x = Math.floor(Math.random() * 128 + 64);
      var y = Math.floor(Math.random() * 128 + 64);
      var z = Math.floor(Math.random() * 128 + 64);
      var bar_color = "rgba(" + x + "," + y + "," + z + ",0.5)";

      datasMeters.push(parseInt(props?.data?.data?.domains[v]["points"]));
      Object.keys(props?.data?.data?.domains[v]["values"]).forEach(
        (e, i) => {
          mainData.push({
            data: [
              parseInt(
                props?.data?.data?.domains[v]["values"][e]["points"]
              ),
            ],
            label: t(e),
            backgroundColor: bar_color,
          });
        }
      );
    });

    setUsableData(mainData);
    setMeterOne(datasMeters);
  };
  useEffect(() => {
    fethUpdateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getModalContent = () => {
    switch (mode) {
      case "mastery":
        return <MasteryModalContent data={props.data?.data?.domains} />;
      case "creatingRealValue":
        return <CreatingRealValueModalContent />;
      case "qualityOfLife":
        return <QualityOfLifeModalContent />;
      case "fellowship":
        return <FellowshipModalContent />;
      default:
        break;
    }
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "",
      },
    },
    scales: {
      y: {
        max: 100,
      },
    },
    aspectRatio: 1.5,
  };

  const data = {
    labels: [t("employability")],
    datasets: usableData,
  };
  return (
    <>
      <StudentModalHeader>{t("Employability")}</StudentModalHeader>
      <HrLine />
      <br />
      <br />

      <StudentModalChartWrapper>
        <div className="row">
          <div className="col-md-4">
            {/* <HalfGaugeChart
              range={[0, 50]}
              gradient={color[0] ?? []}
              currentValue={40}
            />{" "} */}
            <div className="">
              <ReactSpeedometer
                currentValueText={t("Ideas and Opportunities")}
                valueTextFontSize="14px"
                height={150}
                width={180}
                minValue={0}
                maxValue={1}
                value={meterOne[0] / 100 ?? "0"}
                startColor="#FFB1C1"
                endColor="#9AD0F5"
                valueFormat=".0%"
                customSegmentStops={[0, 0.33, 0.67, 1.0]}
                customSegmentLabels={[
                  {
                    text: t("low"),
                    position: "OUTSIDE",
                    color: "#555",
                  },
                  {
                    text: t("moderate"),
                    position: "OUTSIDE",
                    color: "#555",
                  },
                  {
                    text: t("high"),
                    position: "OUTSIDE",
                    color: "#555",
                  },
                ]}
              />
            </div>
          </div>
          <div className="col-md-4">
            {/* <HalfGaugeChart
              range={[0, 50]}
              gradient={color[1] ?? []}
              currentValue={20}
            />{" "} */}
            <div className="mx-2">
              <ReactSpeedometer
                currentValueText={t("Into Action")}
                valueTextFontSize="14px"
                width={180}
                height={150}
                minValue={0}
                maxValue={1}
                value={meterOne[1] / 100 ?? "0"}
                startColor="#FFB1C1"
                endColor="#9AD0F5"
                valueFormat=".0%"
                customSegmentStops={[0, 0.33, 0.67, 1.0]}
                customSegmentLabels={[
                  {
                    text: t("low"),
                    position: "OUTSIDE",
                    color: "#555",
                  },
                  {
                    text: t("moderate"),
                    position: "OUTSIDE",
                    color: "#555",
                  },
                  {
                    text: t("high"),
                    position: "OUTSIDE",
                    color: "#555",
                  },
                ]}
              />
            </div>
          </div>
          <div className="col-md-4">
            {/* <HalfGaugeChart
              range={[0, 50]}
              gradient={color[2] ?? []}
              currentValue={11}
            /> */}
            <div className="mx-2">
              <ReactSpeedometer
                currentValueText={t("Resources")}
                valueTextFontSize="14px"
                width={180}
                height={150}
                minValue={0}
                maxValue={1}
                value={meterOne[2] / 100 ?? "0"}
                startColor="#FFB1C1"
                endColor="#9AD0F5"
                valueFormat=".0%"
                customSegmentStops={[0, 0.33, 0.67, 1.0]}
                customSegmentLabels={[
                  {
                    text: t("low"),
                    position: "OUTSIDE",
                    color: "#555",
                  },
                  {
                    text: t("moderate"),
                    position: "OUTSIDE",
                    color: "#555",
                  },
                  {
                    text: t("high"),
                    position: "OUTSIDE",
                    color: "#555",
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <Bar options={options} data={data} />
        </div>
      </StudentModalChartWrapper>
      {getModalContent()}
    </>
  );
}

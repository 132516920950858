import { useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Colors } from "../../utilities/colors";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { deviceStore } from "../../utilities/devices";


const PercentageBar = styled.div`
  height: 5px;
  background: #d8d7d7;
  border-radius: 102px;
  width: 160px;
  margin-top: 10px;
  margin-right: 10px;
`;
const CompletionBar = styled.div`
  width: ${(props) => (props.width ? props.width + "%" : "30%")};
  border-radius: 102px;
  background: ${Colors.primaryText};
  height: 100%;
`;
const PercentageText = styled.h3`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  font-family: "General Sans";
  margin: 0;
  color: ${Colors.primaryText};
`;
const PercentageCompletedText = styled.p`
  font-weight: normal;
  font-size: 10px;
  line-height: 9px;
  margin: 0;
  text-align: center;
  color: ${Colors.primaryText};
`;
export default function ProfileCompletionBar() {
  useSnapshot(deviceStore)
  const checkbrowser = deviceStore.checkbrowser
  const dispatch = useDispatch();
  const percent = useSelector((state) => state.percentage.userData);
  const [assesmentLists, setAssesmentList] = useState([]);
  const percent2 = useSelector((state) => {
    return state.profile.profilePercentage;
  });

  const { t } = useTranslation();

  let BarWrapper = styled.div`
  display: flex;
  position: absolute;
  right: 3rem;
  top: 11.5rem;
`;
  if (!checkbrowser) {
    BarWrapper = styled.div`
  display: flex;
`;
  }
  function finalPercentage(percent){ //gives 100% if assessment completion is more than 100%
    if (percent > 100){
      return 100
    } else {
      return percent
    }
  };

  return (
    <BarWrapper>
      <PercentageBar>
        <CompletionBar width={Math.round(finalPercentage(percent2))} />
      </PercentageBar>
      <div>
        <PercentageText>{Math.round(finalPercentage(percent2))} %</PercentageText>
        <PercentageCompletedText>
          {t("Profile Completion")}
        </PercentageCompletedText>
      </div>
    </BarWrapper>
  );
}

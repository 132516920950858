import axios from "axios";
const httpClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 36000,
});
let count = 0;

// instance.defaults.headers.common["Authorization"] = AUTH_TOKEN;
// Add a request interceptor
httpClient.interceptors.request.use(
  function (config) {
    count++;
    if (document.getElementById("cv-loader")) {
      document.getElementById("cv-loader").style.display = "block";
    }
    // Do something before request is sent
    const token = localStorage.getItem("auth_token");
    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    if (document.getElementById("cv-loader")) {
      document.getElementById("cv-loader").style.display = "none";
    }
    count--;
    return Promise.reject(error);
  }
);

// Add a response interceptor
httpClient.interceptors.response.use(
  function (response) {
    count--;
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (count === 0) {
      if (document.getElementById("cv-loader")) {
        document.getElementById("cv-loader").style.display = "none";
      }
    }
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // Error
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response.status === 401) {
        localStorage.removeItem("auth_token");
        // localStorage.removeItem("pop_status");
      }
      count--;
    } else if (error.request) {
      count--;
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
    } else {
      // Something happened in setting up the request that triggered an Error
      count--;
    }
    if (document.getElementById("cv-loader")) {
      document.getElementById("cv-loader").style.display = "none";
    }
    return Promise.reject(error);
  }
);

export default httpClient;

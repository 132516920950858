import * as yup from "yup";
import {
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import { validateObf } from "../../services/apiCalls";


export const selfIntoStaticContent = {
  title: "Tell us more about yourself",
  description:
    "We will need some basic information about you before we get started",
  identification: "Identification*",
  identificationIos: "Identification(optional)",
  identifications: [
    { label: "Malaysian IC", value: 1 },
    { label: "Passport Number", value: 0 },
  ],
  identificationNum: "YYMMDD-XX-XXXX",
  fullName: "Full Name (as per passport or IC)*",
  displayName: "Preferred Display Name",
  genderTitle: "Gender*",
  genders: ["Male", "Female"],
  dob: "Date of Birth*",
  mblTitle: "Mobile Number*",
  nationality: "Nationality*",
  nationalityIos: "Nationality(optional)",
  malaysian: "Malaysian",
  nonMalaysian: "Non Malaysian",
  currentlyLive: "Current country of residence*",
  state: "State*",
  city: "City*",
  postcode: "Postcode*",
  isDisability: [
    { label: "No", value: 0 },
    { label: "Yes", value: 1 },
  ],
  pku: "Are you registered with Department of Social Welfare Malaysia as a person with Disabilities (OKU)?*",
  pkuIos: "Are you registered with Department of Social Welfare Malaysia as a person with Disabilities (OKU)?(optional)",
  army: "Are you an Army Personnel?",
  is_army_option:[
    {label: "No", value: 0},
    {label: "Yes", value: 1}
  ],
  service_type: "Service Type",
  corps_regiment: "Corps/Regiment Service Type",
  last_rank: "Last Rank in Service",
  army_active_label: "Are you still in service or retired?",
  army_is_active:[
    {label: "Retired", value: 0},
    {label: "Still In Service", value: 1}
  ]
};

export const aboutMeStepOneFields = {
  id_type: "",
  id_number: "",
  display_name: "",
  gender: "",
  dob: "",
  mob_code: "N/A",
  mob_number: "",
  nationality: "",
  race: "",
  full_name: "",
  country: "",
  curr_country: "",
  state: "",
  city: "",
  postalcode: "",
  disability_status: "",
  disability_code: "",
  is_army: 0,
  army_id_number: "",
  army_service: "",
  army_corp_regiment: "",
  army_last_rank: "",
  army_is_active: null,
  step: 2,
};

export const errorMessages = {
  id_type: "Please choose any one identification.",
  nationality: "Nationality is a required field.",
  disability_status: "Please select any one field.",
};
export const schemaIos = yup.object({
  // id_type: yup.string(),
  // id_number: yup.string().when("id_type", (id_type) => {
  //   if (id_type === 1) {
  //     return yup
  //       .string()
  //       .required("Identification card number is required")
  //       .test(
  //         "id_number",
  //         "Invalid identification card format",
  //         (value) => /[a-zA-Z\d]{6}-\d{2}-\d{4}/gm.test(value),
  //         "ic"
  //       )
  //       .test(
  //         "id_number",
  //         "Identification card number already linked with a different account",
  //         async (value) => {
  //           let code = "1";
  //           let req = {
  //             data: value,
  //           };
  //           const { data, status } = await validateObf(req, code);
  //           if (data && data.status === 1) {
  //             return true;
  //           } else {
  //             return false;
  //           }
  //         }
  //       );
  //   }
  //   if (id_type === 0) {
  //     return yup
  //       .string()
  //       .min(1, "Passport number should be at least 1 character")
  //       .max(
  //         20,
  //         "Passport number should not be more than 20 characters"
  //       )
  //       .required("Passport number is required");
  //   }
  // }),
  display_name: yup.string().max(100, "maximum 100 characters are allowed"),
  gender: yup.string().required("Gender is required"),
  dob: yup
    .string()
    .required("Date of birth is required")
    .test(
      "dob",
      "age should be greater than 16 and lesser than 100",
      (value) => {
        const age = Math.floor(
          (new Date() - new Date(value).getTime()) / 3.15576e10
        );
        if (age < 16 || age > 100) {
          return false;
        }
        return true;
      }
    ),
  mob_number: yup
    .string()
    .required("Mobile Number is required")
    .test("mob_number", "Mobile Number is not valid", (value) =>
      isPossiblePhoneNumber(value)
    ),
  nationality: yup.number(),
  full_name: yup
    .string()
    .required("Full name is required")
    .max(100, "maximum 100 characters are allowed"),
  race: yup.string().when("nationality", (nationality) => {
    if (nationality === 1) return yup.string();
  }),
  country: yup.string().when("nationality", (nationality) => {
    if (nationality === 0) return yup.string();
  }),
  curr_country: yup.string(),
  //   curr_country: yup.string().when("country", country=> {
  //   if (country !== null) {
  //     return yup
  //       .string()
  //       .required("country is required ")
  //       .test(
  //         "id_number",
  //         "Invalid postal code",
  //         (value) => /[^a-zA-Z0-9]/gm.test(value)
  //       );
  //   }
  // }),
  state: yup.string().when("$statesDisable", (statesDisable) => {
    if (!statesDisable) {
      return yup.string().required("state is required");
    }
  }),
  city: yup.string().when("$citiesDisable", (citiesDisable) => {
    if (!citiesDisable) {
      return yup.string().required("city is required");
    }
  }),

  // postalcode: yup.string().required("Postal-code is required"),
  postalcode: yup.string().when("postcode", (postcode) => {
    if (postcode !== null) {
      return yup
        .string()
        .required("postal code is required")
        .test("id_number", "Invalid postal code", (value) =>
          /^(?=.*\d.*)[A-Za-z0-9]{3,10}$/g.test(value)
        );
    }
  }),
  disability_status: yup.number(),
  // disability_code: yup.string().when("disability_status", {
  //   is: 1,
  //   then: yup
  //     .string()
  //     .required("disability code is required")
  //     .test(
  //       "disability_code",
  //       "Must be exactly 12 digit numbers",
  //       (val) => val === undefined || val === null || val.toString().length === 12
  //     ),
  // }),
});


export const schema = yup.object({
  id_type: yup.string().required(),
  id_number: yup.string().when("id_type", (id_type) => {
    if (id_type === 1) {
      return yup
        .string()
        .required("Identification card number is required")
        .test(
          "id_number",
          "Invalid identification card format",
          (value) => /[a-zA-Z\d]{6}-\d{2}-\d{4}/gm.test(value),
          "ic"
        )
        .test(
          "id_number",
          "Identification card number already linked with a different account",
          async (value) => {
            let code = "1";
            let req = {
              data: value,
            };
            const { data, status } = await validateObf(req, code);
            if (data && data.status === 1) {
              return true;
            } else {
              return false;
            }
          }
        );
    }
    if (id_type === 0) {
      return yup
        .string()
        .min(1, "Passport number should be at least 1 character")
        .max(
          20,
          "Passport number should not be more than 20 characters"
        )
        .required("Passport number is required");
    }
  }),
  display_name: yup.string().max(100, "maximum 100 characters are allowed"),
  gender: yup.string().required("Gender is required"),
  dob: yup
    .string()
    .required("Date of birth is required")
    .test(
      "dob",
      "age should be greater than 16 and lesser than 100",
      (value) => {
        const age = Math.floor(
          (new Date() - new Date(value).getTime()) / 3.15576e10
        );
        if (age < 16 || age > 100) {
          return false;
        }
        return true;
      }
    ),
  mob_number: yup
    .string()
    .required("Mobile Number is required")
    .test("mob_number", "Mobile Number is not valid", (value) =>
      isPossiblePhoneNumber(value)
    ),
  nationality: yup.number().required(),
  full_name: yup
    .string()
    .required("Full name is required")
    .max(100, "maximum 100 characters are allowed"),
  race: yup.string().when("nationality", (nationality) => {
    if (nationality === 1) return yup.string().required("Race is required");
  }),
  country: yup.string().when("nationality", (nationality) => {
    if (nationality === 0) return yup.string().required("country is required");
  }),
  curr_country: yup.string().required("country is required"),
  //   curr_country: yup.string().when("country", country=> {
  //   if (country !== null) {
  //     return yup
  //       .string()
  //       .required("country is required ")
  //       .test(
  //         "id_number",
  //         "Invalid postal code",
  //         (value) => /[^a-zA-Z0-9]/gm.test(value)
  //       );
  //   }
  // }),
  state: yup.string().when("$statesDisable", (statesDisable) => {
    if (!statesDisable) {
      return yup.string().required("state is required");
    }
  }),
  city: yup.string().when("$citiesDisable", (citiesDisable) => {
    if (!citiesDisable) {
      return yup.string().required("city is required");
    }
  }),

  // postalcode: yup.string().required("Postal-code is required"),
  postalcode: yup.string().when("postcode", (postcode) => {
    if (postcode !== null) {
      return yup
        .string()
        .required("postal code is required")
        .test("id_number", "Invalid postal code", (value) =>
          /^(?=.*\d.*)[A-Za-z0-9]{3,10}$/g.test(value)
        );
    }
  }),
  disability_status: yup.number().required(),
  disability_code: yup.string().when("disability_status", {
    is: 1,
    then: yup
      .string()
      .required("disability code is required")
      .test(
        "disability_code",
        "Must be exactly 12 digit numbers",
        (val) => val === undefined || val === null || val.toString().length === 12
      ),
  }),
  //is_army: ,
  
});


// import * as yup from "yup";
// import {
//   isValidPhoneNumber,
//   isPossiblePhoneNumber,
// } from "react-phone-number-input";
// import { validateObf } from "../../services/apiCalls";
// export const selfIntoStaticContent = {
//   title: "Tell us more about yourself",
//   description:
//     "We will need some basic information about you before we get started",
//   identification: "Identification*",
//   identifications: [
//     { label: "Malaysian IC", value: 1 },
//     { label: "Passport Number", value: 0 },
//   ],
//   identificationNum: "YYMMDD-XX-XXXX",
//   fullName: "Full Name (as per passport or IC)*",
//   displayName: "Preferred Display Name",
//   genderTitle: "Gender*",
//   genders: ["Male", "Female"],
//   dob: "Date of Birth*",
//   mblTitle: "Mobile Number*",
//   nationality: "Nationality*",
//   malaysian: "Malaysian",
//   nonMalaysian: "Non Malaysian",
//   currentlyLive: "Current country of residence*",
//   state: "State*",
//   city: "City*",
//   postcode: "Postcode*",
//   isDisability: [
//     { label: "No", value: 0 },
//     { label: "Yes", value: 1 },
//   ],
//   pku: "Are you registered with Department of Social Welfare Malaysia as a person with Disabilities (OKU)?*",
// };

// export const aboutMeStepOneFields = {
//   id_type: "",
//   id_number: "",
//   display_name: "",
//   gender: "",
//   dob: "",
//   mob_code: "N/A",
//   mob_number: "",
//   nationality: "",
//   race: "",
//   full_name: "",
//   country: "",
//   curr_country: "",
//   state: "",
//   city: "",
//   postalcode: "",
//   disability_status: "",
//   disability_code: "",
//   step: 2,
// };

// export const errorMessages = {
//   id_type: "Please choose any one identification.",
//   nationality: "Nationality is a required field.",
//   disability_status: "Please select any one field.",
// };

// export const schema = yup.object({
//   id_type: yup.string().required(),
//   id_number: yup.string().when("id_type", (id_type) => {
//     if (id_type === 1) {
//       return yup
//         .string()
//         .required("Identification card number is required")
//         .test(
//           "id_number",
//           "Invalid identification card format",
//           (value) => /[a-zA-Z\d]{6}-\d{2}-\d{4}/gm.test(value),
//           "ic"
//         )
//         .test(
//           "id_number",
//           "Identification card number already linked with a different account",
//           async (value) => {
//             let code = "1";
//             let req = {
//               data: value,
//             };
//             const { data, status } = await validateObf(req, code);
//             if (data && data.status === 1) {
//               return true;
//             } else {
//               return false;
//             }
//           }
//         );
//     }
//     if (id_type === 0) {
//       return yup
//         .string()
//         .min(1, "Passport number should be at least 1 character")
//         .max(
//           20,
//           "Passport number should not be more than 20 characters"
//         )
//         .required("Passport number is required");
//     }
//   }),
//   display_name: yup.string().max(100, "maximum 100 characters are allowed"),
//   gender: yup.string().required("Gender is required"),
//   dob: yup
//     .string()
//     .required("Date of birth is required")
//     .test(
//       "dob",
//       "age should be greater than 16 and lesser than 100",
//       (value) => {
//         const age = Math.floor(
//           (new Date() - new Date(value).getTime()) / 3.15576e10
//         );
//         if (age < 16 || age > 100) {
//           return false;
//         }
//         return true;
//       }
//     ),
//   mob_number: yup
//     .string()
//     .required("Mobile Number is required")
//     .test("mob_number", "Mobile Number is not valid", (value) =>
//       isPossiblePhoneNumber(value)
//     ),
//   nationality: yup.number().required(),
//   full_name: yup
//     .string()
//     .required("Full name is required")
//     .max(100, "maximum 100 characters are allowed"),
//   race: yup.string().when("nationality", (nationality) => {
//     if (nationality === 1) return yup.string().required("Race is required");
//   }),
//   country: yup.string().when("nationality", (nationality) => {
//     if (nationality === 0) return yup.string().required("country is required");
//   }),
//   curr_country: yup.string().required("country is required"),
//   //   curr_country: yup.string().when("country", country=> {
//   //   if (country !== null) {
//   //     return yup
//   //       .string()
//   //       .required("country is required ")
//   //       .test(
//   //         "id_number",
//   //         "Invalid postal code",
//   //         (value) => /[^a-zA-Z0-9]/gm.test(value)
//   //       );
//   //   }
//   // }),
//   state: yup.string().when("$statesDisable", (statesDisable) => {
//     if (!statesDisable) {
//       return yup.string().required("state is required");
//     }
//   }),
//   city: yup.string().when("$citiesDisable", (citiesDisable) => {
//     if (!citiesDisable) {
//       return yup.string().required("city is required");
//     }
//   }),

//   // postalcode: yup.string().required("Postal-code is required"),
//   postalcode: yup.string().when("postcode", (postcode) => {
//     if (postcode !== null) {
//       return yup
//         .string()
//         .required("postal code is required")
//         .test("id_number", "Invalid postal code", (value) =>
//           /^(?=.*\d.*)[A-Za-z0-9]{3,10}$/g.test(value)
//         );
//     }
//   }),
//   disability_status: yup.number().required(),
//   disability_code: yup.string().when("disability_status", {
//     is: 1,
//     then: yup
//       .string()
//       .required("disability code is required")
//       .test(
//         "disability_code",
//         "Must be exactly 12 digit numbers",
//         (val) => val === undefined || val === null || val.toString().length === 12
//       ),
//   }),
// });

import { useEffect, useState } from "react";

import styled from "styled-components";
import "react-phone-number-input/style.css";
import ErrorField from "../../../components/errorField/errorField";
import { Colors } from "../../../utilities/colors";
import { Row, Col } from "react-bootstrap";
import CustomButton from "../../../components/button/button";
import Input from "../../../components/input/input";
import { createTheme, IconButton, ThemeProvider } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ReactComponent as CalenderIcon } from "../../../assets/icons/svg/calendarIcon.svg";
import InternshipTable from "./internship-table";
import {
  deleteInternshipDetails,
  getInternshipDetails,
  saveInternshipDetails,
  updateInternshipDetails,
} from "../../../services/apiCalls";
import { ConvertDate } from "../utils/date-converter";
import { useRequired } from "../utils/useRequired";
import CancelIcon from "../../../assets/icons/skillCancel.png";
import ConfirmationDialog from "../confirmation-dialog/confirmation-dialog";
import { useTranslation } from "react-i18next";
const materialTheme = createTheme({
  palette: {
    primary: {
      main: "#D44B9C",
    },
  },
  overrides: {
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: "#D44B9C",
      },
    },
  },
});

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const DatesWrapper = styled(FlexWrapper)`
  label {
    position: relative;
    width: -webkit-fill-available;
  }
`;

const FormGroup = styled.div`
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;

const CardContainer = styled.div`
  margin-top: 4%;
`;

const CalenderIconWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  cursor: pointer;
`;

const TextInput = styled.div`
  color: ${Colors.inputTextDark};
`;

const Flex = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const Anchor = styled.a`
  color: #504f8c;
`;

const initialState = {
  job_title: "",
  organization: "",
  location: "",
  start_date: null,
  end_date: null,
  grade: "",
};
const errors = {
  job_title: null,
  organization: null,
  location: null,
  start_date: null,
  end_date: null,
};
const Internship = ({ notification }) => {
  const [showAddInternship, setShowAddInternship] = useState(false);
  const [formState, setFormState] = useState(initialState);
  const [selectedInternship, setSelectedInternship] = useState([]);
  const [isSave, setIsSave] = useState(true);
  const { errorFields, validateForm, setErrorFields } = useRequired(
    formState,
    errors
  );
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState();

  useEffect(() => {
    fetchInternshipDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
    setErrorFields({ ...errorFields, [name]: null });
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      return;
    }
    let { start_date, end_date, job_title, organization, location, id, grade } =
      formState;
    start_date = ConvertDate(start_date, "yyyy-mm-dd");
    end_date = ConvertDate(end_date, "yyyy-mm-dd");
    if (isSave) {
      const payload = {
        data: [
          { start_date, end_date, job_title, organization, location, grade },
        ],
      };
      const { data, status } = await saveInternshipDetails(payload);
      checkStatus(data, status, "save");
    } else {
      updateInternship(id);
    }
  };

  const updateInternship = async (id) => {
    let { start_date, end_date, job_title, organization, location, grade } =
      formState;
    start_date = ConvertDate(start_date, "yyyy-mm-dd");
    end_date = ConvertDate(end_date, "yyyy-mm-dd");
    const payload = {
      start_date,
      end_date,
      job_title,
      organization,
      location,
      grade,
    };
    const { data, status } = await updateInternshipDetails(id, payload);
    checkStatus(data, status, "update");
  };

  const editInternship = (idd) => {
    setIsSave(false);
    const {
      job_title,
      organization,
      location,
      start_date,
      end_date,
      id,
      grade,
    } = selectedInternship.find((f) => f.id === idd);
    setFormState({
      id,
      job_title,
      organization,
      location,
      start_date,
      end_date,
      grade,
    });
    setShowAddInternship(true);
  };

  const deleteInternship = async (id) => {
    const { data, status } = await deleteInternshipDetails(id);
    checkStatus(data, status, "delete",true);
  };

  const checkStatus = (data, status, method) => {
    const methodTmp =
      method === "save" ? "saved" : method === "update" ? "updated" : "deleted";
    if (data.status === 1 && status === 200) {
      fetchInternshipDetails();
      if (method === "delete") {
        setShowConfirmation(false);
      }
      if (method === "save") {
        notification("success", t("Internship details saved successfully"));
      } else if (method === "update") {
        notification("success", t("Internship details updated successfully"));
      } else if (method === "delete") {
        notification("success", t("Internship details deleted successfully"));
      }
    } else {
      if (method === "save") {
        notification("error", t("Unable to save internship details"));
      } else if (method === "update") {
        notification("error", t("Unable to update internship details"));
      } else if (method === "delete") {
        notification("error", t("Unable to delete internship details"));
      }
    }
  };

  const fetchInternshipDetails = async () => {
    const { data, status } = await getInternshipDetails();
    if (status === 200 && data.status === 1) {
      // if (data.data.length > 0) {
        setSelectedInternship(data.data);
        resetForm();
      // }
    } else {
      notification("error", t("Unable to fetch Internship details"));
    }
  };

  const resetForm = () => {
    setFormState({ ...initialState });
    setShowAddInternship(false);
    setIsSave(true);
    setErrorFields({ ...errors });
  };

  const clearDate = (e, name) => {
    e.preventDefault();
    setFormState({ ...formState, [name]: null });
  };

  const showConfirm = (id) => {
    setShowConfirmation(true);
    setDeleteId(id);
  };

  
  const { t } = useTranslation();

  return (
    <div>
      {!showAddInternship && (
        <Flex>
          <Anchor onClick={() => setShowAddInternship(true)}>
            {t("Add Internship")}
          </Anchor>
        </Flex>
      )}
      {showAddInternship && (
        <div>
          <CardContainer>
            <Row>
              <Col md="4">
                <FormLabel>{t("Job Title")}*</FormLabel>
                <Input
                  placeholder={t("Enter Job Title")}
                  name="job_title"
                  value={formState.job_title}
                  onChange={handleChange}
                />
                {errorFields["job_title"] && (
                  <ErrorField err={t(errorFields["job_title"])} />
                )}
              </Col>
              <Col md="4" xs="12">
                <FormLabel>{t("Organization")}*</FormLabel>
                <Input
                  placeholder={t("Enter Organization")}
                  name="organization"
                  value={formState.organization}
                  onChange={handleChange}
                />
                {errorFields["organization"] && (
                  <ErrorField err={t(errorFields["organization"])} />
                )}
              </Col>
              <Col md="4">
                <FormLabel>{t("Location")}*</FormLabel>
                <Input
                  placeholder={t("Enter Location")}
                  name="location"
                  value={formState.location}
                  onChange={handleChange}
                />
                {errorFields["location"] && (
                  <ErrorField err={t(errorFields["location"])} />
                )}
              </Col>
            </Row>
          </CardContainer>
          <CardContainer>
            <Row>
              <Col md="4">
                <FormLabel>{t("Start Date")}*</FormLabel>
                <DatesWrapper>
                  <label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={materialTheme}>
                        <DatePicker
                          autoOk
                          disableFuture
                          variant="inline"
                          format="dd/MM/yyyy"
                          placeholder={t("Enter Start Date")}
                          value={
                            formState.start_date
                              ? new Date(formState.start_date)
                              : null
                          }
                          onChange={(val) =>
                            handleChange({
                              target: { name: "start_date", value: val },
                            })
                          }
                          views={["year", "month", "date"]}
                          fullWidth
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                    <CalenderIconWrapper>
                      <CalenderIcon />
                      {formState.start_date && (
                        <IconButton onClick={(e) => clearDate(e, "start_date")}>
                          <img src={CancelIcon} alt="" />
                        </IconButton>
                      )}
                    </CalenderIconWrapper>
                  </label>
                </DatesWrapper>
                {errorFields["start_date"] && (
                  <ErrorField err={t(errorFields["start_date"])} />
                )}
              </Col>
              <Col md="4">
                <FormLabel>{t("End Date")}*</FormLabel>
                <DatesWrapper>
                  <label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={materialTheme}>
                        <DatePicker
                          autoOk
                          // disableFuture
                          variant="inline"
                          format="dd/MM/yyyy"
                          placeholder={t("Enter End Date")}
                          value={
                            formState.end_date
                              ? new Date(formState.end_date)
                              : null
                          }
                          onChange={(val) =>
                            handleChange({
                              target: { name: "end_date", value: val },
                            })
                          }
                          minDate={
                            new Date(formState.start_date).setDate(
                              new Date(formState.start_date ).getDate() )
                          }
                          views={["year", "month", "date"]}
                          fullWidth
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                    <CalenderIconWrapper>
                      <CalenderIcon />
                      {formState.end_date && (
                        <IconButton onClick={(e) => clearDate(e, "end_date")}>
                          <img src={CancelIcon} alt="" />
                        </IconButton>
                      )}
                    </CalenderIconWrapper>
                  </label>
                </DatesWrapper>
                {errorFields["end_date"] && (
                  <ErrorField err={t(errorFields["end_date"])} />
                )}
              </Col>
              <Col md="4">
                <FormLabel>{t("Grade")}</FormLabel>
                <Input
                  placeholder={t("Enter Grade")}
                  name="grade"
                  value={formState.grade}
                  onChange={handleChange}
                />
              </Col>
            </Row>
          </CardContainer>
          <Row>
            <ButtonWrapper>
              <CustomButton
                onClick={handleSubmit}
                name={isSave ? t("Save") : t("Update")}
              />
              &nbsp;&nbsp;
              <CustomButton onClick={resetForm} secondary name={t("Cancel")} />
            </ButtonWrapper>
          </Row>
        </div>
      )}
      {selectedInternship.length > 0 ? (
        <FormGroup>
          <InternshipTable
            editInternship={(id) => editInternship(id)}
            deleteInternship={(id) => showConfirm(id)}
            data={selectedInternship}
          />
        </FormGroup>
      ) : (
        <>
          <TextInput>{t("You have not added any internship.")}</TextInput>
        </>
      )}
      <ConfirmationDialog
        id={deleteId}
        show={showConfirmation}
        message={t("Are you sure you want to delete this record?")}
        onAccept={(id) => deleteInternship(id)}
        closeModal={() => setShowConfirmation(false)}
      />
    </div>
  );
};
export default Internship;

import { useEffect, useState } from "react";

import styled from "styled-components";
import "react-phone-number-input/style.css";
import ErrorField from "../../../components/errorField/errorField";
import { Colors } from "../../../utilities/colors";
import { Row, Col } from "react-bootstrap";
import CustomButton from "../../../components/button/button";
import Input from "../../../components/input/input";
import { createTheme, IconButton, ThemeProvider } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ReactComponent as CalenderIcon } from "../../../assets/icons/svg/calendarIcon.svg";
import EmploymentTable from "./employement-table";
import { ConvertDate } from "../utils/date-converter";
import {
  deleteEmployeeDetails,
  getEmployeeDetails,
  saveEmployeeDetails,
  updateEmployeeDetails
} from "../../../services/apiCalls";
import { useRequired } from "../utils/useRequired";
import CancelIcon from "../../../assets/icons/skillCancel.png";
import ConfirmationDialog from "../confirmation-dialog/confirmation-dialog";
import CheckboxInput from "../../../components/checkboxInput/checkboxInput";
import { useTranslation } from "react-i18next";

const materialTheme = createTheme({
  palette: {
    primary: {
      main: "#D44B9C"
    }
  },
  overrides: {
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: "#D44B9C"
      }
    }
  }
});

const TextInput = styled.div`
  color: ${Colors.inputTextDark};
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Flex = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const DatesWrapper = styled(FlexWrapper)`
  label {
    position: relative;
    width: -webkit-fill-available;
  }
`;

const FormGroup = styled.div`
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;

const Anchort = styled.a`
  text-decoration: none;
  color: #696f79;
`;

const CardContainer = styled.div`
  margin-top: 4%;
`;

const CalenderIconWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  cursor: pointer;
`;
const Anchor = styled.a`
  color: #504f8c;
`;

const initialState = {
  role: "",
  organization: "",
  location: "",
  start_date: null,
  end_date: null
};

const errors = {
  role: null,
  organization: null,
  location: null,
  start_date: null
  // end_date: null
};

const CheckBoxWrapper = styled.div`
  display:flex;
  margin-top:40px;
  @media (max-width: 412px) {
    margin-top:20px;
  }
`;

const Employement = ({ notification }) => {
  const [formState, setFormState] = useState(initialState);
  const [selectedEmpList, setSelectedEmpList] = useState([]);
  const [showAddEmp, setShowAddEmp] = useState(false);
  const [isSave, setIsSave] = useState(true);
  const { errorFields, validateForm, setErrorFields } = useRequired(formState, errors);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [isCurrentlyWorking, setIsCurrentlyworking] = useState(false);

  useEffect(() => {
    fetchEmployeeDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
    setErrorFields({ ...errorFields, [name]: null });
  };

  const radiohandler = () => {
    var temp_status = isCurrentlyWorking;
    setIsCurrentlyworking(!isCurrentlyWorking);
    if (!temp_status) {
      setFormState({ ...formState, ['end_date']: null });
    }
  };

  const handleSubmit = async () => {
    // if(disableEndDate === false){
    if (validateForm()) {
      return;
    }
    // }
    if ((formState.end_date === null && isCurrentlyWorking === false) || (formState.end_date === "" && isCurrentlyWorking === false)) {
      setErrorFields({ ...errorFields, ['end_date']: "End date is required" });
      return;
    }
    let { start_date, end_date, role, organization, location, id } = formState;
    start_date = ConvertDate(start_date, "yyyy-mm-dd");
    end_date = ConvertDate(end_date, "yyyy-mm-dd");
    if (isSave) {
      const payload = {
        data: [{ start_date, end_date, role, organization, location }]
      };
      const { data, status } = await saveEmployeeDetails(payload);
      checkStatus(data, status, "save");
    } else {
      updateEmployee(id);
    }
  };

  const updateEmployee = async (id) => {
    let { start_date, end_date, role, organization, location } = formState;
    start_date = ConvertDate(start_date, "yyyy-mm-dd");
    end_date = ConvertDate(end_date, "yyyy-mm-dd");
    const payload = { start_date, end_date, role, organization, location };
    const { data, status } = await updateEmployeeDetails(id, payload);
    checkStatus(data, status, "update");
  };

  const editEmployee = (idd) => {
    setIsSave(false);
    const { role, organization, location, start_date, end_date, id } = selectedEmpList.find(
      (f) => f.id === idd
    );
    var temp_end_date = end_date;
    if (end_date === "1970-01-01") {
      temp_end_date = null;
      setIsCurrentlyworking(true);
    }
    setFormState({ id, role, organization, location, start_date, end_date: temp_end_date });
    setShowAddEmp(true);
  };

  const deleteEmployee = async (id) => {
    const { data, status } = await deleteEmployeeDetails(id);
    checkStatus(data, status, "delete", true);
  };

  const checkStatus = (data, status, method) => {
    const methodTmp = method === "save" ? "saved" : method === "update" ? "updated" : "deleted";
    if (data.status === 1 && status === 200) {
      fetchEmployeeDetails();
      if (method === "delete") {
        setShowConfirmation(false);
        fetchEmployeeDetails();
        notification("success", t("Employment details deleted successfully"));
      } else if (method == "save") {
        notification("success", t("Employment details saved successfully"));
      } else if (method == "update") {
        notification("success", t("Employment details updated successfully"));
      }
    } else {
      if (method == "save") {
        notification("error", t("Unable to save employment details"));
      } else if (method == "update") {
        notification("error", t("Unable to update employment details"));
      } else if (method == "delete") {
        notification("error", t("Unable to delete employment details"));
      }
    }
  };

  const fetchEmployeeDetails = async () => {
    const { data, status } = await getEmployeeDetails();
    if (status === 200 && data.status === 1) {
      setSelectedEmpList(data.data);
      resetForm();
    } else {
      notification("error", t("Unable to fetch Employment details"));
    }
  };

  const resetForm = () => {
    setFormState({ ...initialState });
    setShowAddEmp(false);
    setIsSave(true);
    setErrorFields({ ...errors });
  };

  const clearDate = (e, name) => {
    e.preventDefault();
    setFormState({ ...formState, [name]: null });
  };

  const showConfirm = (id) => {
    setShowConfirmation(true);
    setDeleteId(id);
  };
  const [radioCheck, setRadioCheck] = useState(false);
  const [disableEndDate, setDisableEndDate] = useState(false);
  
  const { t } = useTranslation();

  return (
    <div>
      {!showAddEmp && (
        <Flex>
          <div></div>
          <Anchor onClick={() => setShowAddEmp(true)}>{t("Add Employment Detail")}</Anchor>
        </Flex>
      )}
      {showAddEmp && (
        <div>
          <CardContainer>
            <Row>
              <Col md='4'>
                <FormLabel>{t("Role")}*</FormLabel>
                <Input
                  placeholder={t('Enter Role')}
                  name='role'
                  value={formState.role}
                  onChange={handleChange}
                />
                {errorFields["role"] && <ErrorField err={t(errorFields["role"])} />}
              </Col>
              <Col md='4'>
                <FormLabel>{t("Organization")}*</FormLabel>
                <Input
                  placeholder={t('Enter Organization')}
                  name='organization'
                  value={formState.organization}
                  onChange={handleChange}
                />
                {errorFields["organization"] && <ErrorField err={t(errorFields["organization"])} />}
              </Col>
              <Col md='4'>
                <FormLabel>{t("Location")}*</FormLabel>
                <Input
                  placeholder={t('Enter Location')}
                  name='location'
                  value={formState.location}
                  onChange={handleChange}
                />
                {errorFields["location"] && <ErrorField err={t(errorFields["location"])} />}
              </Col>
            </Row>
          </CardContainer>
          <CardContainer>
            <Row>
              <Col md='4'>
                <FormLabel>{t("Start Date")}*</FormLabel>
                <DatesWrapper>
                  <label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={materialTheme}>
                        <DatePicker
                          autoOk
                          disableFuture
                          variant='inline'
                          format='dd/MM/yyyy'
                          placeholder={t('Enter Start Date')}
                          // value={formState.start_date ?? null}
                          onChange={(val) =>
                            handleChange({
                              target: { name: "start_date", value: val }
                            })
                          }
                          views={["year", "month", "date"]}
                          fullWidth
                          // minDate={new Date().setDate(
                          //   new Date().getDate()
                          // )}
                          value={
                            formState.start_date
                              ? new Date(formState.start_date)
                              : null
                          }
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                    <CalenderIconWrapper>
                      <CalenderIcon />
                      {formState.start_date && (
                        <IconButton onClick={(e) => clearDate(e, "start_date")}>
                          <img src={CancelIcon} alt='' />
                        </IconButton>
                      )}
                    </CalenderIconWrapper>
                  </label>
                </DatesWrapper>
                {errorFields["start_date"] && <ErrorField err={t(errorFields["start_date"])} />}
              </Col>
              <Col md='4'>
                <FormLabel>{t("End Date")}*</FormLabel>
                <DatesWrapper>
                  <label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={materialTheme}>
                        <DatePicker
                          autoOk
                          disableFuture
                          variant='inline'
                          format='dd/MM/yyyy'
                          placeholder={t('Enter End Date')}
                          // value={formState.end_date ?? null}
                          onChange={(val) =>
                            handleChange({
                              target: { name: "end_date", value: val }
                            })
                          }
                          disabled={isCurrentlyWorking}
                          views={["year", "month", "date"]}
                          fullWidth
                          minDate={
                            new Date(formState.start_date).setDate(
                              new Date(formState.start_date).getDate())
                          }
                          value={
                            formState.end_date
                              ? new Date(formState.end_date)
                              : null
                          }
                        // disabled={disableEndDate}
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                    <CalenderIconWrapper>
                      <CalenderIcon />
                      {formState.end_date && (
                        <IconButton onClick={(e) => clearDate(e, "end_date")}>
                          <img src={CancelIcon} alt='' />
                        </IconButton>
                      )}
                    </CalenderIconWrapper>
                  </label>
                </DatesWrapper>
                {errorFields["end_date"] && <ErrorField err={t(errorFields["end_date"])} />}

              </Col>
              <Col md="4">
                <CheckboxInput
                  checked={isCurrentlyWorking}
                  onChange={radiohandler}
                />
                <label>
                  {t("Currently Working")}
                </label>
              </Col>
            </Row>
          </CardContainer>
          <Row>
            <ButtonWrapper>
              <CustomButton onClick={handleSubmit} name={isSave ? t("Save") : t("Update")} />
              &nbsp;&nbsp;
              <CustomButton onClick={resetForm} secondary name={t('Cancel')} />
            </ButtonWrapper>
          </Row>
        </div>
      )}
      {selectedEmpList.length > 0 ? (
        <FormGroup>
          <EmploymentTable
            deleteEmployee={(id) => showConfirm(id)}
            editEmployee={(id) => editEmployee(id)}
            data={selectedEmpList}
          />
        </FormGroup>
      ) : (
        <>
          <TextInput>{t("You have not added your employment detail(s).")} </TextInput>
          <TextInput>{t("Click on Add Employment to add your employment detail(s).")}</TextInput>
        </>
      )}
      <ConfirmationDialog
        id={deleteId}
        show={showConfirmation}
        message={t('Are you sure you want to delete this record?')}
        onAccept={(id) => deleteEmployee(id)}
        closeModal={() => setShowConfirmation(false)}
      />
    </div>
  );
};
export default Employement;

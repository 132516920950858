// required fields starts
import moment from "moment";

export const validateInputs = (obj) => {
  //   const items = Object.keys(obj);
  const values = Object.values(obj);
  const isValid = values.every((val) => val.length > 1);
  //   const errors = {};
  //   items.forEach((key) => {
  //     const value = obj[key];
  //     if (!value) {
  //       errors[key] = "This field is required";
  //     }
  //   });
  return isValid;
};
// required fields ends

// email validation starts
export const validEmailRegex = RegExp(
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
// email validation ends

export const validPassword = RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/
);

export const checkBothPasswords = (pwdOne, pwdTwo) => {
  let errVal = true;

  if (pwdTwo.length > 0) {
    errVal = pwdOne === pwdTwo ? true : false;
  }
  return errVal;
};

export const removeKeys = (data) => {
  let obj = {};
  let arr = Object.keys(data);
  for (let i = 0; i < arr.length; i++) {
    let key = arr[i];
    let value = data[key];
    if (value || value === 0) {
      obj[key] = value;
    }
  }
  return obj;
};
export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export function isEmpty(obj) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

export const dateToDateMonthInWordsYear = (date) => {
  return moment(date).format("DD MMMM YYYY");
};

export const filePathToFileName = (filePath) => {
  return filePath?.split("/").reverse()[0];
};

export const debounce = (fn, delay) => {
  let timer;
  return function (...args) {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      timer = null;
      fn(...args);
    }, delay);
  };
};

export const dateDifference = (date) => {
  let date1 = new Date(date);
  let date2 = new Date();
  return parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
};

export const downloadPdf = (file) => {
  fetch(file, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  }).then((response) => response.blob().then((blob) => {
      let filename = file;
      filename = filename.split("/");
      filename = filename[filename.length - 1];
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = filename;
      a.click();
    })
  );
};

export const getToday = () => {
  return moment().format("YYYY-MM-DD");
};
export const countPlaces = (num) => {
  var sep = String(23.32).match(/\D/)[0];
  var b = String(num).split(sep);
  return b[1] ? b[1].length : 0;
};

export const localTime = (time) => {
  return time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric',second:'numeric', hour12: true });
};

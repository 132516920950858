import { useState, useEffect } from "react";

import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import CoverImage from "../../assets/loginCover.png";
import { getFormOptions, getCountries } from "../../services/apiCalls";
import { updateFormOptions } from "../../pages/selfIntro/reducer";
import { Col, Container, Row } from "react-bootstrap";
// styling starts

const CoverImageWrapper = styled.div`
  position: relative;
  line-height: 0px;
  width: 100%;
  @media (max-width: 992px) {
    width: unset;
  }
  @media (max-width: 599px) {
    width: 100%;
  }
`;
const Image = styled.img`
  width: 100%;
  border-radius: 0px 42px 42px 0px;
  @media (max-width: 992px) {
    // height: 60vh;
    display: none;
  }
`;
const GradientCover = styled.div`
  position: absolute;
  top: 0;
  left: -20px;
  bottom: 0px;
  right: 0;
  // background: linear-gradient(
  //   90deg,
  //   rgba(87, 80, 141, 0.4) 0%,
  //   rgba(245, 75, 161, 0.4) 100%
  // );
  border-radius: 0px 42px 42px 0px;
  @media (max-width: 900px) {
    border-radius: none;
  }
  @media (min-width: 1200px) and (max-width: 1440px) {
    border-radius: 0px 60px 60px 0px;
  }
  @media (min-width: 2000px) {
    border-radius: 0px 100px 100px 0px;
  }
  @media (min-width: 1024px) and (max-width: 1200px) {
    border-radius: 0px 50px 50px 0px;
  }
`;

const PageContentSection = styled.div`
  overflow-y: auto;
  flex-grow: 2;
  padding: 3.5rem 2rem;
  &::-webkit-scrollbar {
    width: 0px;
  }
`;
// styling ends

export default function UserCreationLayout({ children, sidebarImg }) {
  const [loading, setLoading] = useState(true);
  // const options = useSelector((state) => state.selfIntroOne.formOptions);
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.classList.remove("bg-body-cover");
    window.scroll({ top: 0, behavior: "smooth" });
    if (window.location.pathname !== "/") {
      getDatas();
    }
  }, []);

  const onDataConstruct = (data) => {
    let obj = {};
    let arr = Object.keys(data);
    for (let i = 0; i < arr.length; i++) {
      let key = arr[i];
      obj[key] = data[key].map((y) => {
        return { label: y, value: y };
      });
    }
    getCountry(obj);
  };

  const getDatas = async () => {
    const { data } = await getFormOptions();
    if (data && data.status === 1) {
      onDataConstruct(data.data);
    }
  };

  const imageLoaded = () => {
    setLoading(false);
  };

  const getCountry = async (obj) => {
    const { data, status } = await getCountries();
    if (status === 200) {
      setDatas(data, obj);
    }
  };

  const setDatas = (data, obj) => {
    if (data.status === 1) {
      const { location } = data.data;
      const country = location.map((x) => {
        return { ...x.country, label: x.country, value: x.country };
      });
      dispatch(updateFormOptions({ ...obj, country: country }));
    }
  };

  return (
    <Container fluid className="p-0 maxH-90">
      <Row>
        <Col lg="4" md="12" className="p-0">
          <CoverImageWrapper>
            <Image
              onLoad={imageLoaded}
              src={sidebarImg ? sidebarImg : CoverImage}
              alt="Cover image"
            />
            <GradientCover />
          </CoverImageWrapper>
        </Col>
        <Col lg="8" md="12">
          <PageContentSection className="P-mange-mobile">
            {children}
          </PageContentSection>
        </Col>
      </Row>
    </Container>
  );
}

import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { StudentAd2Card } from "./ad2Card";
import { AD2_CONSTANTS } from "./ad2Constants";
import { EmployabilitySpeedometer } from "./employabilitySpeedometer";
import Loader from "../../components/loader/loader";
export function Ad2EmployabilityFactors({data, onViewMoreClick}) {
  const chartData = data;
  console.log(chartData,"datadata")
  const status = chartData && chartData.status;
  const total = chartData && chartData.totalScore;
  const { t, i18n } = useTranslation();
  const cardsData = [
    {
      title: t("Ideas & Opportunities"),
      description: t(AD2_CONSTANTS.IDEAS_AND_OPPORTUNITIES_DESCRIPTION),
      value:
        chartData &&
        chartData?.["domains"]?.["Ideas and Opportunities"] &&
        chartData?.["domains"]?.["Ideas and Opportunities"]?.points,
    },
    {
      title: t("Resources"),
      description: t(AD2_CONSTANTS.RESOURCES_DESCRIPTION),
      value:
        chartData &&
        chartData?.["domains"]?.["Resources"] &&
        chartData?.["domains"]?.["Resources"]?.points,
    },
    {
      title: t("Into Action"),
      description: t(AD2_CONSTANTS.INTO_ACTION_DESCRIPTION),
      value:
        chartData &&
        chartData?.["domains"]?.["Into Action"] &&
        chartData?.["domains"]?.["Into Action"]?.points,
    },
  ];
  return (
    <>
      {chartData?.["domains"]?.["Resources"]?.points && chartData?.["domains"]?.["Resources"]?.points ? (
        <StudentAd2Card
          title={t("Employability")}
          data={chartData}
          status={status}
          onViewMoreClick={onViewMoreClick}
        >
          {cardsData.map((item, index) => (
            <Col md="4" key={index}>
              <EmployabilitySpeedometer {...item} />
            </Col>
          ))}
        </StudentAd2Card>
        ) : ( 
        <Loader></Loader> 
      )} 
    </>
  );
}

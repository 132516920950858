import { GrClose } from 'react-icons/gr';
import { Modal } from 'react-bootstrap';

function AlertModal(props) {
  return (
    <div>
      <Modal show={props.show} onHide={props.close} centered>
        <div className="post-modal">
          <div className=" d-flex justify-content-end align-items-center p-4 modal_header" style={{cursor:"pointer"}}>
            <GrClose onClick={props.close} />
          </div>
          <div>{props.content}</div>
        </div>
      </Modal>
    </div>
  );
}

export default AlertModal;

import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import CustomButton from "../../components/button/button";
import { useTranslation } from "react-i18next";
import AlertCloseModal from "../../components/alertCloseModal/alertCloseModal";
import Dropdown from "../../components/dropdown/dropdown";
import Input from "../../components/input/input";
import AlertInputModal from "../../components/alertInputModal/alertInputModal";
// import { Label } from "react-bootstrap";
const Title = styled.p`
  position: absolute;
  width: 142px;
  height: 22px;
  top: 25px;
  color: #d04c8d;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
`;
const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50px;
  height: 150px;
`;

const Messages = styled.div`
  position: absolute;
  height: 22px;
  font-family: "General Sans";
  font-weight: 600;
  top: 54px;
  left: 22px;
`;
export const Label = styled.div`
  font-family: General Sans;
  font-weight: 500;
  font-size: 16px;
  color: #979797;
  margin-bottom: 0.625rem;
`;
const AddCompanyConfirmationModal = ({
  id,
  show,
  onAccept,
  closeModal,
  message,
  title,
  data,
  org_name
}) => {
  const { t } = useTranslation();
  let content = (
    <>
      <Title>{title}</Title>
      <Messages>{message}</Messages>
      {/* <Message>{text}</Message> */}

      <div style={{ position: "relative" ,top: "-60px"}}>
        <Row>
          <Col lg="6" />
          <Col
            lg="12"
            className="d-flex justify-content align-items-center"
            style={{ position: "relative",justifyContent:"space-between",margin: "10px" }}
          >
            <Label style={{ color: "black" }}>Organisation</Label>
            <Input
            disabled
            value={org_name}
              placeholder={"Enter Organisation"}
              style={{position:"relative",width:"52%"}}/>
          </Col>
        </Row>
        <Row>
          <Col lg="6" />
          <Col
            lg="12"
            className="d-flex justify-content align-items-center"
            style={{ position: "relative",justifyContent:"space-between",margin: "10px" }}
          >
            <Label style={{ color: "black" }}>Organisation Email Address</Label>
            <Input
            disabled
            value={data.email}
              placeholder={"Enter The Mail"}
              style={{position:"relative",width:"52%"}}
            />
          </Col>
        </Row>
        <Row>
          <Col lg="6" />
          <Col
            lg="12"
            className="d-flex justify-content align-items-center"
            style={{ position: "relative",justifyContent:"space-between",margin: "10px" }}
          >
            <Label style={{ color: "black" }}>Job or Title</Label>
            <Input
            disabled
            value={data.position}
              placeholder={"Enter The Job"}
              style={{position:"relative",width:"52%"}}
            />
          </Col>
        </Row>
        <Row>
          <Col lg="6" />
          <Col
            lg="12"
            className="d-flex justify-content align-items-center"
            style={{ position: "relative",justifyContent:"space-between",margin: "10px" }}
          >
            <Label style={{ color: "black" }}>Department</Label>
            <Input
            disabled
            value={data.department}
              style={{position:"relative",width:"52%"}}
              placeholder={"Enter The Department"}
            />
          </Col>
        </Row>
        <Row>
        <Col md="6" />
            <Col
                md="12"
                className="d-flex justify-content-center align-items-center"
            >
               
                 <CustomButton
                    width="192px"
                 
                    name={t("Confirm")}
                    onClick={() => onAccept("confirm2")}
                />
            </Col>
        </Row>
      </div>
    </>
  );
  return <AlertInputModal show={show} close={()=>closeModal("confirm2")} content={content} />;
};

export default AddCompanyConfirmationModal;

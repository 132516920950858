// import Cookies from 'js-cookie';  #unused variable#
import { Navigate, useLocation } from "react-router-dom";

function AuthRoute({ children }) {
  const currentUser = localStorage.getItem("auth_token");
  console.log("this routing", currentUser);
  const location = useLocation();
  return currentUser ? (
    children
  ) : (
    <Navigate to="/" replace state={{ redirectTo: location }} />
  );
}

export default AuthRoute;

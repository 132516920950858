import { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import SettingsChangePassword from "./settingsChangePassword/settingsChangePassword";
import SettingsProfile from "./settingsProfile/settingsProfile";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import Menus from "../../components/hamburger/menus";
import HamburgerLay from "../../components/hamburger/hamburger";
import { resetFilter } from "../internship/reducer"; //INIVOS
import { useDispatch } from "react-redux"; //INIVOS

const Card = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 2rem;
  @media (max-width: 767px) {
    padding: 0rem 0.8rem;
  }
  .w-50 {
    width: 50%;
  }
  .content-br {
    padding-bottom: 1.5rem;
    border-bottom: 0.4px solid rgba(32, 32, 32, 0.22);
    margin-bottom: 1rem;
  }
  .cancelBtn {
    border-radius: 128px;
    border: 1px solid #d04c8d;
    color: #d04c8d;
  }
  .container-br {
    margin-bottom: 19px;
    border: 1px solid rgba(228, 228, 228, 0.26);
    border-radius: 5px;
  }
  .inputReadOnly {
    // background-color: #f3f3f3;
    width: -webkit-fill-available;
    margin: 0;
    min-width: fit-content;
    color: #3d3d3d;
  }
  .inputRole {
    background: #ffffff;
    border-radius: 5px;
    color: #3d3d3d;
    width: fit-content;
    margin: 0;
    min-height: 34px;
    margin-right: 4px;
  }
  input[type="file"] {
    cursor: pointer;
  }
`;
const PageWrapper = styled.div`
  min-height: 80vh;
  padding: 0rem 3rem;
  @media (max-width: 767px) {
    padding: 0rem 0.8rem;
  }
`;


const DashboardWrapper = styled.div`
  @media (max-width: 1024px) {
    display: flex;
  }
`;

const HamburgerLayout = styled.div`
  @media (min-width: 1025px) {
    display: none;
  }
`;

export default function SettingsPage() {
  const {
    formState: { errors },
    control,
    handleSubmit,
    formState: { touchedFields },
  } = useForm({
    criteriaMode: "all",
  });

  const [usernameEditable, setUsernameEditable] = useState(false);


//INIVOS
  const dispatch = useDispatch();

useEffect(() => {
  dispatch(
    resetFilter()
  );
})
//INIVOS

  const [isOpenham, setOpenHam] = useState(false);

  let [inputValue, setInputValue] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  let inputChangeEvent = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <>
      <MainLayout>
        <PageWrapper>
          <DashboardWrapper>
            <HamburgerLayout>
              <HamburgerLay onToggle={() => setOpenHam(!isOpenham)} />
            </HamburgerLayout>
            {isOpenham && <Menus />}
          </DashboardWrapper>
          <Row>
            <Card>
              <SettingsProfile />
              <SettingsChangePassword />
            </Card>
          </Row>
        </PageWrapper>
      </MainLayout>
    </>
  );
}

import React from "react";
import EmployeeConfirmationModal from "./employeeConfirmationModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
const EmployeePage = ()=>{
    const { t } = useTranslation();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const showConfirm = () => {
      setShowConfirmation(true);
    };
    return (
      <div>
        <button onClick={() => showConfirm()}>update profile</button>
        <EmployeeConfirmationModal
          show={showConfirmation}
          title={t("PROFILE UPDATE")}
          message={t("Have You Been Employeed ?")}
          text={t("Would you like to update Your Profile ?")}
          closeModal={() => setShowConfirmation(false)}
        />
      </div>
    );
}

export default EmployeePage
import { proxy } from 'valtio'

let companyProxy = proxy({
    showcompanysidemenu:false,
    employeedata:{},
    org_code:"",
    org_name:"",
    empmodal1:false,
    empmodal2:false,
    empmodal3:false,
    empmodal4:false,
    joborg_code:""
})

export {
    companyProxy
}
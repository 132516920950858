import React from 'react'
import './ToggleSwitch.css';

const Toggle = (props) => {
    let d = props.data
    return (
        <div className="toggles"  >
            <input  type="checkbox" id="switch" className="checkbox" checked={d=="N"} onClick={props.onClick}  />
            
            <label for="switch" className="sliders"></label>
        </div>
    )
}
export  default Toggle
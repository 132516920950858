import styled from "styled-components";
import Divider from "../divider/divider";
import { useTranslation } from "react-i18next";

// styling starts
const LabelWrapper = styled.label`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;
const SplitWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 25px 0px 70px 20px;

  // @media (max-width: 1024px) {
  //   width: auto;
  // }
`;
const Label = styled.label`
  margin: 0px 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: ${(props) => (props.color ? props.color : "#504f8c")};
  display: block;
  text-align: center;
  position: relative;
  margin-top: 5px;
  position: absolute;
  bottom: ${(props) => (props.bottom ? props.bottom : "-35px")};
  @media (max-width: 412px) {
    padding-left: ${(props) => (props.paddingleft ? "20px" :"0px")}
  }
 
`;
const Marker = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 2px solid ${(props) => (props.color ? props.color : "#504f8c")};
  background: ${(props) => (props.bg ? props.bg : "#e6e6e6")};
`;
const DiviWrapper = styled.div`
  width: 100%;

  @media (max-width: 1200px) {
    max-width: 20%;
  }
`;
// sttyling ends

export default function PaginationDivider({ step }) {
  const stepOneColor = "#504F8C";
  const stepTwoColor = step === 1 ? "#DFDFDF" : "#504F8C";
  const stepThreeColor = step >= 3 ? "#504F8C" : "#DFDFDF";
  const stepFourColor = step === 4 ? "#504F8C" : "#DFDFDF";
  const stepOneBg = step === 1 ? "#fff" : "#504F8C";
  const stepTwoBg = step === 1 ? "#DFDFDF" : step === 2 ? "#fff" : "#504F8C";
  const stepThreeBg = step > 3 ? "#504F8C" : step === 3 ? "#fff" : "#DFDFDF";
  const stepFourBg = step === 4 ? "#fff" : "#DFDFDF";
  const { t } = useTranslation();
  return (
    <SplitWrapper>
      <LabelWrapper to="/studying">
        <Marker bg={stepOneBg} color={stepOneColor} />
        <Label color={stepOneColor}>
          {t("Personal Information")}
        </Label>
      </LabelWrapper>
      <DiviWrapper>
        <Divider bg={step === 1 ? "#DFDFDF" : "#504F8C"} />
      </DiviWrapper>
      <LabelWrapper to="/studying">
        <Marker bg={stepTwoBg} color={stepTwoColor} />
        <Label bottom="-40px" color={stepTwoColor}>
          {t("Current")}<br/>{t("Status")}&nbsp;&nbsp;{t("Detail")}
        </Label>
      </LabelWrapper>
      <DiviWrapper>
        <Divider bg={stepTwoBg} />
      </DiviWrapper>
      <LabelWrapper to="/studying">
        <Marker bg={stepThreeBg} color={stepThreeColor} />
        <Label bottom="-40px" color={stepThreeColor}>
          {t("Upload")}<br/>{t("Profile")}&nbsp;&nbsp;{t("Picture")}
        </Label>
      </LabelWrapper>
      <DiviWrapper>
        <Divider bg={stepThreeBg} />
      </DiviWrapper>
      <LabelWrapper to="/studying">
        <Marker bg={stepFourBg} color={stepFourColor} />
        <Label bottom="-50px" color={stepFourColor}>
          {t("Overview")} <br /> <br />
        </Label>
      </LabelWrapper>
    </SplitWrapper>
  );
}

import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
// import MainLayout from "../../layouts/mainLayout/mainLayout";
import Menus from "../../components/hamburger/menus";
import HamburgerLay from "../../components/hamburger/hamburger";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import DashboardPopup from "../../components/dashboardPopup/dashboardPopup";
import { Colors } from "../../utilities/colors";
import TextField from "../../components/input/input";
import CustomButton from "../../components/button/button";
import Applied from "../../assets/Saly.png";
import Saved from "../../assets/Saved.png";
import Offered from "../../assets/Offered.png";
import FilterdImage from "../../assets/Filter.png";
import Loader from "../../components/loader/loader";
import { ReactComponent as FilterIcon } from "../../assets/icons/svg/filter.svg";
import { Row, Col } from "react-bootstrap";
import Dropdown from "../../components/dropdown/dropdown";
import DateFnsUtils from "@date-io/date-fns";
import { ReactComponent as CalenderIcon } from "../../assets/icons/svg/calendarIcon.svg";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { createTheme, IconButton } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import CancelIcon from "../../assets/icons/skillCancel.png";
import ErrorField from "../../components/errorField/errorField";
import AlertModalReview from "../../components/alertModalNotes/alertModalReview";
import { fetchPercentage } from "../../Redux/Reducers/ProfileReducer/ProfileReducer";
import moment from "moment";
import {
  formatDate,
  getToday,
  debounce,
} from "../../utilities/commonFunctions";

import {
  getInternLists,
  internshipStatus,
  onBoardFormGetData,
  getScopeOfStudies,
  getAcademicQualifications,
  getStates,
} from "../../services/apiCalls";

import { staticState } from "./staticContent";
import JobsCard from "./jobCard/JobCard";
import InfiniteScroll from "react-infinite-scroll-component";
import { mynextTalent } from "../mynextConstant";
import HeaderGreeting from "../../components/headerGreeting/headerGreeting";
import { TablePagination } from "@mui/base";
import { updateFilter, filterInternshipStatus } from "./reducer";
import { set } from "date-fns";
import { useTranslation } from "react-i18next";
import { deviceStore } from "../../utilities/devices";

const materialTheme = createTheme({
  palette: {
    primary: {
      main: "#D44B9C",
    },
  },
  overrides: {
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: "#D44B9C",
      },
    },
  },
});

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const blue = {
  200: "#A5D8FF",
  400: "#3399FF",
};

const DashboardWrapper = styled.div`
  @media (max-width: 1024px) {
    display: flex;
  }
`;
const PageWrapper = styled.div`
  min-height: 80vh;
  padding: 0rem 3rem;
  @media (max-width: 767px) {
    padding: 0rem 0.8rem;
  }
`;
const HeaderText = styled.h2`
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: ${Colors.dark};
  margin: 0 0 1.5rem 0;
`;

const FilterButton = styled.div`
  background-color: white;
  padding: 0.5rem;
  border-radius: 10px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const CategoriesContainer = styled.div`
  margin: 15px 0px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  @media (max-width: 599px) {
    grid-template-columns: unset !important;
  }
  @media (max-width: 1199px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const StyledText = styled.div`
  font-size: 24px;
  color: #d04c8d;
  font-family: "General Sans";
  font-weight: 500;
  padding: 0 1rem;
`;

const StyledCardText = styled.div`
  font-size: 24px;
  color: #d04c8d;
  font-family: "General Sans";
  font-weight: 500;
`;

const CategoryCount = styled.div`
  font-size: 52px;
  color: #bab6b6;
  font-family: "General Sans";
  font-weight: 500;
`;

const Cards = styled.div`
  background-color: white;
  width: 100%;
  border-radius: 10px;
  padding: 25px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  &:hover {
    box-shadow: 0px 5px 5px #a39c9c;
  }
`;

const SubheaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    margin: 1.5rem 0;
  }
  .view {
    margin: 0px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-decoration-line: underline;
    color: rgb(208, 76, 141);
    cursor: pointer;
  }
`;

const SubHeaderText = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  color: ${Colors.dark};
  span {
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    padding-left: 0.5rem;
    color: ${Colors.inputTextDark};
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RecommendedJobsCard = styled.div`
  margin-top: 0.825rem;
  background-color: white;
  border-radius: 10px;
  padding: 1rem;
  label {
    position: relative;
  }
`;

const Wrapper = styled.div`
  padding: 1rem;
`;
const FilterInputTitle = styled.p`
  font-weight: 500;
  font-size: 18px;
  font-family: "General Sans";
  margin-bottom: 0.5rem;
`;

const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  @media (max-width: 500px) {
    display: block;
  }
`;
const CalenderIconWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  cursor: pointer;
`;
const Section = styled.div`
  margin-bottom: 50px;
`;

const HamburgerLayout = styled.div`
  @media (min-width: 1025px) {
    display: none;
  }
`;

const Root = styled("div")(
  ({ theme }) => `
table {
font-family: IBM Plex Sans, sans-serif;
font-size: 0.875rem;
border-collapse: collapse;
width: 100%;
}

td,
th {
border: 1px solid ${grey[200]};
text-align: left;
padding: 6px;
}

th {
background-color: ${grey[100]};
}
`
);

const CustomTablePagination = styled(TablePagination)(
  ({ theme }) => `
& .MuiTablePaginationUnstyled-spacer {
display: none;
}
& .MuiTablePaginationUnstyled-toolbar {
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 10px;

@media (min-width: 768px) {
  flex-direction: row;
  align-items: center;
}
}
& .MuiTablePaginationUnstyled-selectLabel {
margin: 0;
}
& .MuiTablePaginationUnstyled-select {
padding: 2px;
border: 1px solid ${grey[200]};
border-radius: 50px;
background-color: transparent;
&:hover {
  background-color: ${grey[50]};
}
&:focus {
  outline: 1px solid ${blue[200]};
}
}
& .MuiTablePaginationUnstyled-displayedRows {
margin: 0;

@media (min-width: 768px) {
  margin-left: auto;
}
}
& .MuiTablePaginationUnstyled-actions {
padding: 2px;
border: 1px solid ${grey[200]};
border-radius: 50px;
text-align: center;
}
& .MuiTablePaginationUnstyled-actions > button {
margin: 0 8px;
border: transparent;
border-radius: 2px;
background-color: transparent;
&:hover {
  background-color: ${grey[50]};
}
&:focus {
  outline: 1px solid ${blue[200]};
}
}
`
);

export default function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openFilter, setOpenFilter] = React.useState(false);
  const [spinner, toggleSpinner] = React.useState(false);
  const [isOpenham, setOpenHam] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [internshipStatusData, setInternshipStatusData] = useState();
  const [status, setStatus] = useState("accepted");
  const [greet, setGreet] = useState("");
  const [totalCounts, setTotalCounts] = useState(0);
  const [allCount, setAllCount] = useState(0);
  const [countPerPage, setCountPerPage] = useState(0);
  const [searchval, setSearchFilter] = useState("");
  const [options1, setOptions1] = useState({});
  const [scopeOfStudies, setScopeOfStudies] = useState([]);
  const [selectedScope, setSelectedScope] = useState({});
  const [scopeName, setScopeName] = useState("0");
  const [academicQualification, setAcademicQualification] = useState();
  const [selectedAcademicQualification, setSelectedAcademicQualification] =
    useState();
  const [minEducation, setMinEducation] = useState();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [states, setStates] = useState([]);
  const [location, setLocation] = useState();
  const [selectedState, setSelectedState] = useState();
  const [fromDateError, setFromDateError] = useState(false);
  const [userScope, setUserScope] = useState("");
  const [showConfirmationModalAddNotes, setShowConfirmationModalAddNotes] =
    useState(false);
  const [favouriteStatus, setFavouriteStatus] = useState("");

  const [page, setPage] = React.useState(0);
  const [theArray, setTheArray] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const emptyRowsAdd =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - theArray.length) : 0;

  const [recommended, setRecommended] = useState(false);

  // redirect to dashboard if has not completed assessment
  const completion = useSelector((state) => {
    return state.profile.profilePercentage;
  });

  // if ((completion != null) & (completion < 100)) {
  //   navigate("/dashboard");
  // }

  const handleActionAddNotes = (e, datas) => {
    e.stopPropagation();
    setTheArray(datas ?? []);
    setShowConfirmationModalAddNotes(true);
  };
  const onOpenFilerSearch = () => {
    //onResetFilter();
    setOpenFilter(!openFilter);
  };

  useEffect(() => {
    dispatch(fetchPercentage());
    getInternshipStatus();
    fetchFilterDetails();
    fetchAcademicQualifications();
    fetchStates(mynextTalent.defaultCountryId);
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const talentUser = useSelector((state) => state.talent.userData);

  const fetchAcademicQualifications = async () => {
    const { data, status } = await getAcademicQualifications();
    if (data.data.length > 0) {
      const academic = data.data.map((x) => {
        return { ...x, label: x.Name, value: x.Id };
      });
      setAcademicQualification(academic);
    }
  };

  const fetchStates = async (countryId) => {
    const { data, status } = await getStates(countryId);
    if (status === 200) {
      const location = data.data;
      const states = location.map((x) => {
        return { ...x, label: x.Name, value: x.Id };
      });
      setStates(states);
    }
  };

  const fetchFilterDetails = async () => {
    const [userData, scopesResult] = await Promise.all([
      onBoardFormGetData(),
      getScopeOfStudies(),
    ]);
    let result = [];
    if (scopesResult.status === 200) {
      if (scopesResult.data && scopesResult.data.status === 1) {
        const scopeOfStudy = scopesResult.data.data.map((x) => {
          return { ...x, label: x.Name, value: x.Id };
        });

        setScopeOfStudies(scopeOfStudy);
      }
    }
    if (userData.status === 200) {
      if (userData.data && userData.data.status === 1) {
        const scopeData = userData.data.data;
        setScopeName(scopeData.scope_abbr);

        const selScope = {
          label: scopeData.scope_abbr,
          value: scopeData.scope,
        };

        if (filterData.scope == null) {
          dispatch(
            updateFilter({ scope: scopeData.scope, selScope: selScope })
          );
        }
      }
    }
  };

  const { filterData } = useSelector((state) => state.internshipFilter);

  useEffect(() => {
    setUserScope(filterData.scope);
    setSelectedScope(filterData.selScope);
    setSelectedAcademicQualification(filterData.selMinEducation);
    setSelectedState(filterData.selLocation);
    setLocation(filterData.location);
    setMinEducation(filterData.minEducation);
    setRecommended(filterData.recommended);
    setStatus(filterData.status);
    setFromDate(filterData.fromDate);
    setToDate(filterData.toDate);

    if (filterData.status != null) {
      getDatas(searchval, 1, filterData.status);
    } else if (filterData.scope != null) {
      getDatas(searchval, 1, "", "", filterData.scope); //INIVOS
    }
  }, [filterData]);

  const fetchData = () => {
    if (total > currentPage) {
      setCurrentPage(currentPage + 1);
      //INIVOS
      if (filterData.status != null) {
        getDatas(searchval, currentPage + 1, filterData.status);
      } else {
        getDatas("", currentPage + 1, "", "", filterData.scope);
      }
    }
  };

  const getFilteredStatus = (name) => {
    setLoading(true);
    setTotalCounts(0);
    setAllCount(0);
    setStatus(name);
    setDataList("");
    setUserScope("");
    setFromDate("");
    setToDate("");
    setSearchFilter(""); //INIVOS+
    dispatch(
      updateFilter({
        status: name,
        recommended: true,
        scope: "",
        fromDate: "",
        toDate: "",
        minEducation: "",
        location: "",
      })
    );
  };

  const viewAllInternships = () => {
    setLoading(true);
    viewAll(status);
  };

  const viewAll = (status) => {
    if (status !== "accepted") {
      getDatas(searchval, 1, status, totalCounts, "");
    } else {
      getDatas(searchval, 1, status, totalCounts, userScope);
    }
  };

  const getInternshipStatus = async () => {
    const { data } = await internshipStatus();
    setInternshipStatusData(data);
    setFavouriteStatus(data?.favourite);
  };

  const dateChange = (name, val) => {
    if (name === "fromDate") {
      setFromDate(formatDate(val));
    }
    if (name === "toDate") {
      setToDate(formatDate(val));
    }
  };

  const clearDate = (name, val) => {
    if (name === "fromDate") {
      setFromDate("");
    }
    if (name === "toDate") {
      setToDate("");
    }
  };

  const dropdownChange = (name, value) => {
    setRecommended(true);
    switch (name) {
      case "scope":
        setScopeName(value.label);
        setUserScope(value.value);
        setSelectedScope(value);
        break;
      case "statesMalaysia":
        setLocation(value.label);
        setSelectedState(value);
        break;
      case "minEducation":
        setMinEducation(value.label);
        setSelectedAcademicQualification(value);
        break;
      default:
    }
  };

  const onFilterSearch = () => {
    setLoading(true);

    dispatch(
      updateFilter({
        scope: userScope,
        selScope: selectedScope,
        selMinEducation: selectedAcademicQualification,
        recommended: recommended,
        fromDate: fromDate,
        toDate: toDate,
        minEducation: minEducation,
        location: location,
        selLocation: selectedState,
      })
    );
    setStatus("accepted");
  };

  const onResetFilter = () => {
    setRecommended(true);
    setLocation("");
    setMinEducation("");
    setFromDate("");
    setToDate("");
    setUserScope("");
    setScopeName("0");
    setSelectedScope(null);
    setSelectedState(null);
    setSelectedAcademicQualification(null);
    setSearchFilter("");
    setLoading(true);
    setAllCount(0);
    getFilteredStatus(null); //INIVOS
  };

  const getDatas = async (
    name,
    pageNumber,
    status,
    totalCounts,
    onBoardUserScope
  ) => {
    let req = {};
    let filter = {};
    req["start_date"] = filterData.fromDate || "";
    req["end_date"] = filterData.toDate || "";
    if (req["start_date"] || req["end_date"]) {
      if (!req["start_date"]) {
        setFromDateError(true);
        return false;
      }
      if (!req["end_date"]) {
        req["end_date"] = getToday();
      }
      filter = { period: { ...req } };
    }

    const body = {
      ...staticState,
      page_number: pageNumber || 1,
      count_per_page: totalCounts ? totalCounts : 10,
    };
    const { data } = await getInternLists({
      ...body,
      search: name ? name : "",
      scope: filterData.scope ? filterData.scope : onBoardUserScope,
      location: filterData.location ? filterData.location : "",
      education: filterData.minEducation ? filterData.minEducation : "",
      filters: {
        ...filter,
        status: status ? status : "accepted",
      },
    });
    setLoading(false);
    if (data && data.status === 1) {
      if (pageNumber === undefined) {
        setDataList(data.data);
      } else if (
        (totalCounts || location || minEducation || userScope) &&
        (pageNumber <= 1 || pageNumber === undefined)
      ) {
        setDataList(data.data);
      } else {
        setDataList(
          dataList.length ? [...dataList, ...data.data] : [...data.data]
        );
      }

      if (data?.pagination?.total_pages === data?.pagination?.current_page) {
        setAllCount(data?.pagination?.total_counts);
      } else {
        setAllCount(
          data?.pagination?.count_per_page * data?.pagination?.current_page
        );
      }

      setTotalCounts(data?.pagination?.total_counts);
      setTotal(data?.pagination?.total_pages);
      setCurrentPage(data?.pagination?.current_page);
      setCountPerPage(data?.pagination?.count_per_page);
      setLoading(false);
      if (filteredDataList.length > 0) { // Add this check
        handleScroll();
      }
    } else {
      setLoading(true);
    }
  };

  const handleScroll = () => {
    const element = document.getElementsByClassName("infinite-scroll-component");
    if (element&& element.length > 0) {
      // 👇 Will scroll smoothly to the top of the next section
      element[0].scrollIntoView({ behavior: "smooth" });
    }
  };

  const debounceChange = useCallback(debounce(getDatas, 1000), []);

  const onSearch = (e) => {
    setSearchFilter(e.target.value);
    debounceChange(e.target.value, 1, status);
  };

  const closeConfirmationModalAddNotes = () => {
    setShowConfirmationModalAddNotes(false);
  };

  const { t } = useTranslation();

  const content3 = () => {
    return (
      <>
        <div className="container mb-5">
          <Root sx={{ width: 200, maxWidth: "50%" }}>
            <table aria-label="custom pagination table">
              <thead>
                <tr>
                  <th
                    style={{
                      background: "rgb(250, 74, 161)",
                      color: "#fff",
                    }}
                  >
                    {t("Notes")}
                  </th>
                  <th
                    style={{
                      background: "rgb(250, 74, 161)",
                      color: "#fff",
                    }}
                  >
                    {t("Created")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {theArray?.length > 0
                  ? (rowsPerPage > 0 && theArray?.length
                      ? theArray.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : theArray
                    ).map((row) => (
                      <tr key={row.notes}>
                        <td>{row.notes}</td>
                        <td style={{ width: 200 }} align="right">
                          {moment(new Date(row.created_at)).format(
                            "DD/MM/YYYY hh:mm:ssA"
                          )}
                        </td>
                      </tr>
                    ))
                  : ""}

                {emptyRowsAdd > 0 && (
                  <tr style={{ height: 41 * emptyRowsAdd }}>
                    <td colSpan={3} />
                  </tr>
                )}
              </tbody>
              {theArray?.length > 0 ? (
                <tfoot>
                  <tr>
                    <CustomTablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: "All", value: -1 },
                      ]}
                      colSpan={3}
                      count={theArray?.length ?? "0"}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      componentsProps={{
                        select: {
                          "aria-label": "rows per page",
                        },
                        actions: {
                          showFirstButton: true,
                          showLastButton: true,
                        },
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </tr>
                </tfoot>
              ) : (
                <div className="container col-lg-12">No Comments</div>
              )}
            </table>
          </Root>
        </div>
      </>
    );
  };

  const setStateDatas = (data) => {
    if (data.status === 1) {
      const location = data.data;
      const states = location.map((x) => {
        return { label: x, value: x };
      });
      setStates(states);
    }
  };

  const loadViewAll = () => {
    setLoading(true);
    setAllCount(0);
    getFilteredStatus(null); //INIVOS
  }
  const ITEMS_PER_PAGE = 5; // Number of cards to show per page
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const handlePageChange = (page) => {
    setCurrentPageNumber(page);
  };

 //
  const handleLoadMore = () => {
    if (currentPageNumber < total) {
      setCurrentPageNumber((prevPage) => prevPage + 1);
    }
  };

  // Filter the dataList based on the current page number and items per page
  const filteredDataList = dataList.slice(0, currentPageNumber * ITEMS_PER_PAGE);


  return (
    <>
      {isOpen && <DashboardPopup setOpen={setOpen} isOpen={isOpen} />}

      <MainLayout>
        <PageWrapper>
          <DashboardWrapper>
            <HamburgerLayout>
              <HamburgerLay onToggle={() => setOpenHam(!isOpenham)} />
            </HamburgerLayout>
            {isOpenham && <Menus />}
            <HeaderGreeting
              userName={talentUser && talentUser.display_name}
            ></HeaderGreeting>
          </DashboardWrapper>
          <Row>
            <Col xl="11" xs="9">
              <TextField
                placeholder={t("Search Keywords for Job Title or Company Name")}
                value={searchval}
                onChange={onSearch}
              />
            </Col>
            <Col xl="1" xs="3">
              <FilterButton onClick={onOpenFilerSearch}>
                <FilterIcon />
              </FilterButton>
            </Col>
          </Row>

          {openFilter && (
            <RecommendedJobsCard>
              <StyledText>{t("Filter")}</StyledText>
              <Row>
                <Col xl="5" lg="12" md="5">
                  <Wrapper>
                    <FilterInputTitle>{t("Scope of Study")}</FilterInputTitle>
                    <Dropdown
                      search={true}
                      options={scopeOfStudies}
                      placeholder={t("Select Scope of Study")}
                      //val={userScope}
                      label={selectedScope?.label}
                      val={selectedScope?.value}
                      // onFocus={(val) => getOptions("SOS")}
                      change={(value) => dropdownChange("scope", value)}
                    />
                  </Wrapper>
                  <Wrapper>
                    <FilterInputTitle>{t("Location")}</FilterInputTitle>
                    <Dropdown
                      search={true}
                      options={states}
                      placeholder={t("Select Location")}
                      //val={stateValue}
                      label={selectedState?.label}
                      val={selectedState?.value}
                      change={(value) =>
                        dropdownChange("statesMalaysia", value)
                      }
                    />
                  </Wrapper>
                </Col>
                <Col xl="5" lg="12" md="5">
                  <Wrapper>
                    <FilterInputTitle>
                      {t("Minimum Education Level Required")}
                    </FilterInputTitle>
                    <Dropdown
                      search={true}
                      options={academicQualification}
                      placeholder={t("Select Education Level")}
                      label={selectedAcademicQualification?.label}
                      val={selectedAcademicQualification?.value}
                      change={(value) => dropdownChange("minEducation", value)}
                    />
                  </Wrapper>
                  <Wrapper>
                    <FilterInputTitle>{t("Duration")}</FilterInputTitle>
                    <FlexDiv>
                      <Row>
                        <Col xl="6">
                          <label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <ThemeProvider theme={materialTheme}>
                                <DatePicker
                                  autoOk
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  placeholder={t("From")}
                                  views={["year", "month", "date"]}
                                  value={fromDate ? new Date(fromDate) : null}
                                  onChange={(val) =>
                                    dateChange("fromDate", val)
                                  }
                                  fullWidth
                                />
                              </ThemeProvider>
                            </MuiPickersUtilsProvider>
                            <CalenderIconWrapper>
                              {fromDate ? (
                                <IconButton
                                  onClick={(val) => clearDate("fromDate", val)}
                                >
                                  <img src={CancelIcon} alt="" />
                                </IconButton>
                              ) : (
                                <CalenderIcon />
                              )}
                            </CalenderIconWrapper>
                          </label>
                          <div className="m-2">
                            {fromDateError && (
                              <ErrorField
                                err={t("From date is mandatory")}
                                className="m-2"
                              />
                            )}
                          </div>
                        </Col>
                        <Col xl="6">
                          <label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <ThemeProvider theme={materialTheme}>
                                <DatePicker
                                  autoOk
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  placeholder={t("To")}
                                  views={["year", "month", "date"]}
                                  value={toDate ? new Date(toDate) : null}
                                  minDate={new Date(fromDate).setDate(
                                    new Date(fromDate).getDate()
                                  )}
                                  onChange={(val) => dateChange("toDate", val)}
                                  fullWidth
                                />
                              </ThemeProvider>
                            </MuiPickersUtilsProvider>
                            <CalenderIconWrapper>
                              <CalenderIcon />
                            </CalenderIconWrapper>
                          </label>
                        </Col>
                      </Row>
                    </FlexDiv>
                  </Wrapper>
                </Col>
                <Col xl="2" lg="12" md="2">
                  <ImageWrapper>
                    <img src={FilterdImage} alt="" />
                  </ImageWrapper>
                </Col>
              </Row>
              <ButtonWrapper>
                <CustomButton
                  name={t("Search")}
                  style={{ margin: "0 0.5rem 1rem", minWidth: "120px" }}
                  onClick={onFilterSearch}
                />
                <CustomButton
                  name={t("Reset")}
                  style={{ margin: "0 0.5rem 1rem", minWidth: "120px" }}
                  secondary
                  onClick={(e) => {
                    onResetFilter(e);
                  }}
                />
              </ButtonWrapper>
            </RecommendedJobsCard>
          )}
          <CategoriesContainer>
            <Cards onClick={() => getFilteredStatus("applied")}>
              <div>
                <StyledCardText>{t("Applied")}</StyledCardText>
                <CategoryCount>{internshipStatusData?.applied}</CategoryCount>
              </div>
              <div>
                <img src={Applied} alt="" />
              </div>
            </Cards>
            <Cards onClick={() => getFilteredStatus("favourite")}>
              <div>
                <StyledCardText>{t("Favourites")}</StyledCardText>
                <CategoryCount>{internshipStatusData?.favourite}</CategoryCount>
              </div>
              <div>
                <img src={Saved} alt="" />
              </div>
            </Cards>
            <Cards onClick={() => getFilteredStatus("offered")}>
              <div>
                <StyledCardText>{t("Offered")}</StyledCardText>
                <CategoryCount>{internshipStatusData?.offered}</CategoryCount>
              </div>
              <div>
                <img src={Offered} alt="" />
              </div>
            </Cards>
          </CategoriesContainer>
          <SubheaderDiv>
            <SubHeaderText className="d-xs-block">
              {filterData.status === "applied"
                ? t("Applied Internships")
                : filterData.status === "favourite"
                ? favouriteStatus >= 1
                  ? t("Favourite Internships")
                  : ""
                : filterData.status === "offered"
                ? t("Offered Internships")
                : t("Internships")}{" "}
              {filterData.recommended ? "" : t("Recommended")}({allCount}/
              {totalCounts})
              <span
                className={
                  ["applied", "offered", "favourite"].includes(status)
                    ? "d-none"
                    : "d-block"
                }
              >
                {filterData.recommended
                  ? ""
                  : t("based on Scope of Study") + ` (${scopeName})`}
              </span>
            </SubHeaderText>
            <p className="view w-120" onClick={loadViewAll}>
              {t("View all")}
            </p>
          </SubheaderDiv>
          {loading ? (
            <Loader />
          ) : (
            // "Load More" pagination for mobile devices 
            <Section>
            {deviceStore.platform === 'ios' || deviceStore.platform === 'android' ? (
               <div>
               {filteredDataList.length > 0 ? (
                 filteredDataList.map((list, index) => (
                   <JobsCard
                     key={list + index}
                     datas={list}
                     getDatas={getDatas}
                     getInternshipStatus={getInternshipStatus}
                     handleActionAddNotes={handleActionAddNotes}
                   />
                 ))
               ) : (
                 <p className="noDataFound">{t("No data found")}</p>
               )}
     
               {currentPageNumber < total && (
                <SubheaderDiv><p className="view w-120" onClick={handleLoadMore}>{t('Load More')}</p></SubheaderDiv>
                 
               )}
             </div>
            ) : (
              // InfiniteScroll for web
              <InfiniteScroll
                dataLength={dataList?.length}
                next={fetchData}
                hasMore={currentPage < total}
                loader={<Loader />}
                scrollThreshold={0.5}
              >
                {dataList.length > 0 ? (
                  dataList.map((list, index) => (
                    <JobsCard
                      key={list + index}
                      datas={list}
                      getDatas={getDatas}
                      getInternshipStatus={getInternshipStatus}
                      handleActionAddNotes={handleActionAddNotes}
                    />
                  ))
                ) : (
                  <p className="noDataFound">{t("No data found")}</p>
                )}
              </InfiniteScroll>
            )}
          </Section>
          )}
        </PageWrapper>
        <AlertModalReview
          show={showConfirmationModalAddNotes}
          close={closeConfirmationModalAddNotes}
          content={content3()}
        />
      </MainLayout>
    </>
  );
}

import { useState } from "react";
import { CONSTANTS } from "../constants/constants";
// import Cookies from "js-cookie";
import styled from "styled-components";
import { ReactComponent as Help } from "../../assets/icons/svg/helpIcon.svg";
import { Dropdown, Nav, Navbar } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { IoIosLogOut } from "react-icons/io";
import ConfirmationDialog from "../../pages/myCV/confirmation-dialog/confirmation-dialog";
import { useGoogleLogout } from "react-google-login";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import avatarLogo from "../../assets/avatar.png";
import englishFlag from "../../assets/en.png";
import malayFlag from "../../assets/my.png";


import { deviceStore } from "../../utilities/devices";
import { useSnapshot } from 'valtio'

const HeaderWrapper = styled.div`
  display: flex;
  -webkit-justify-content: flex-end;
  align-items: flex-end;
  -webkit-align-items: flex-end;
  margin:0 50px;
  > img {
    margin-right: 10px;
    max-width: 30px;
    cursor: pointer;
    &:last-child() {
      margin-right: 0px;
    }
  }
`;

const ProfileLogo = styled.div`
  width: 32px;
  height: 32px;
  background: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  img {
    height: 32px;
    width: 32px;
  }
`;

const FlagLogo = styled.div`
  width: 32px;
  height: 32px;
  background: rgb(208, 76, 141);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  img {
    height: 32px;
    width: 32px;
  }
`;

const DropdownList = styled.span`
  padding-left: 10px;
`;

export default function TopNavbar() {
  useSnapshot(deviceStore)
  const checkbrowser = deviceStore.checkbrowser
  const talentData = useSelector((state) => state?.talent?.userData);

  const [logoutConfirmation, setLogoutConfirmation] = useState(false);

  const { signOut, loaded } = useGoogleLogout({
    onFailure: () => { },
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    onLogoutSuccess: () => { },
  });

  const onUserLogoutConfirmation = () => {
    setLogoutConfirmation(true);
  };

  const onUserLogout = async () => {
    // Cookies.remove('token');
    localStorage.removeItem("auth_token");
    if (localStorage.getItem("pop_status") === "1") {
      localStorage.removeItem("pop_status");
    }
    // localStorage.removeItem("pop_status");

    signOut();
    window.location.assign("/");
  };

  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const changeLanguageHandler = (language) => {
    setSearchParams({ lang: language }, { replace: true });
    sessionStorage.setItem("lang", language);
  };



  return (
    <>
      {(checkbrowser) ? <HeaderWrapper>
        <Navbar>
          <Nav className="margin_right_63">
            <Nav.Link>
              <Dropdown className="dropdown__toggle">
                <Dropdown.Toggle variant="">
                  <FlagLogo>
                    <img
                      src={i18n.language === "my" ? malayFlag : englishFlag}
                      alt="profile-logo"
                    />
                  </FlagLogo>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    href="#"
                    onClick={() => changeLanguageHandler("en")}
                  >
                    <img
                      width={32}
                      height={32}
                      src={englishFlag}
                      alt="profile-logo"
                    />
                    <DropdownList>{"English"}</DropdownList>
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={() => changeLanguageHandler("my")}
                  >
                    <img
                      width={32}
                      height={32}
                      src={malayFlag}
                      alt="profile-logo"
                    />
                    <DropdownList>{"Malay"}</DropdownList>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav.Link>
            {process.env.NODE_ENV !== "production" && (
              <Nav.Link className="d-none">
                Version: {CONSTANTS.version}
              </Nav.Link>
            )}
            {/* <Nav.Link>
              <Search />
            </Nav.Link>
          )}
          {/* <Nav.Link>
            <Search />
          </Nav.Link>
          <Nav.Link>
            <Notification />
          </Nav.Link>*/}
            <Nav.Link href="https://www.mynext.my/faqs" target="_blank">
              <Help width={32}
                height={32} />
            </Nav.Link>
            <Nav.Link>
              <Dropdown className="dropdown__toggle">
                <Dropdown.Toggle variant="">
                  <ProfileLogo>
                    <img
                      src={
                        talentData && talentData.profile_picture
                          ? talentData.profile_picture
                          : avatarLogo
                      }
                      alt="profile-logo"
                    />
                  </ProfileLogo>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#" onClick={onUserLogoutConfirmation}>
                    <IoIosLogOut />
                    <DropdownList>Logout</DropdownList>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav.Link>
          </Nav>
        </Navbar>
      </HeaderWrapper> : <HeaderWrapper>
        <Navbar>
          <Nav >
            <Nav.Link>
              <Dropdown className="dropdown__toggle">
                <Dropdown.Toggle variant="">
                  <FlagLogo>
                    <img
                      src={
                        i18n.language == "my" ? malayFlag : englishFlag
                      }
                      alt="profile-logo"
                    />
                  </FlagLogo>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#" onClick={() => changeLanguageHandler("en")}>
                    <img
                      width={32}
                      height={32}
                      src={englishFlag}
                      alt="profile-logo"
                    />
                    <DropdownList>{"English"}</DropdownList>
                  </Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => changeLanguageHandler("my")}>
                    <img
                      width={32}
                      height={32}
                      src={malayFlag}
                      alt="profile-logo"
                    />
                    <DropdownList>{"Malay"}</DropdownList>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav.Link>
            {process.env.NODE_ENV !== "production" && (
              <Nav.Link className="d-none">
                Version: {CONSTANTS.version}
              </Nav.Link>
            )}
            {/* <Nav.Link>
              <Search />
            </Nav.Link>
          )}
          {/* <Nav.Link>
            <Search />
          </Nav.Link>
          <Nav.Link>
            <Notification />
          </Nav.Link>*/}
            <Nav.Link href="https://www.mynext.my/faqs" target="_blank">
              <Help width={32}
                height={32} />
            </Nav.Link>
            <Nav.Link>
              <Dropdown className="dropdown__toggle">
                <Dropdown.Toggle variant="">
                  <ProfileLogo>
                    <img
                      src={
                        talentData && talentData.profile_picture
                          ? talentData.profile_picture
                          : avatarLogo
                      }
                      alt="profile-logo"
                    />
                  </ProfileLogo>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#" onClick={onUserLogoutConfirmation}>
                    <IoIosLogOut />
                    <DropdownList>Logout</DropdownList>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav.Link>
          </Nav>
        </Navbar>
      </HeaderWrapper>}
      <ConfirmationDialog
        id={0}
        show={logoutConfirmation}
        message={t("Are you sure you want to logout?")}
        onAccept={() => onUserLogout()}
        closeModal={() => setLogoutConfirmation(false)}
      />
    </>
  );
}

import styled from "styled-components";

const Label = styled.label`
color: red;
font-size: 12px;
margin-top: ${(props) => (props.mt ? props.mt : "5px")};
display: block;
font-weight: 500;
`;
export default function ErrorField(props) {
  return (
    <Label>
      {props?.err?.charAt(0)?.toUpperCase() +
        props?.err?.slice(1)?.replace(/-./g, " ")}
    </Label>
  );
}

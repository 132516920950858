import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import InternshipConfirmationModal from "./intermShipModal";
const InternModal =()=>{
    const { t } = useTranslation();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const showConfirm = () => {
      setShowConfirmation(true);
    };
    return (
      <div>
        <button onClick={() => showConfirm()}>update profile</button>
        <InternshipConfirmationModal
          show={showConfirmation}
          title={t("PROFILE UPDATE")}
          message={t("Have You Been Completed the Internship ?")}
          text={t("Would you like to update Your Profile ?")}
          closeModal={() => setShowConfirmation(false)}
        />
      </div>
    );
}

export default InternModal
import Hamburger from 'hamburger-react';
import { deviceStore } from "../../utilities/devices";
import { useSnapshot } from 'valtio'
import Sidebar from '../sidebar/sidebar';



export default function HamburgerDiv(props) 
{
  useSnapshot(deviceStore)
  const checkbrowser = deviceStore.checkbrowser
    return (
    <>{
        (checkbrowser)?<Hamburger {...props} />:<>
        <Sidebar />
        </>
    }
    
    </>);
    
}

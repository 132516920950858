import CvCard from "../../../components/cvcards/cvcard";
import RadioInput from "../../../components/radioinput/radioinput";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ReactComponent as CalenderIcon } from "../../../assets/icons/svg/calendarIcon.svg";
import { createTheme, IconButton, ThemeProvider } from "@material-ui/core";
import { Colors } from "../../../utilities/colors";
import { useEffect, useRef, useState } from "react";
import CustomButton from "../../../components/button/button";
import { ConvertDate } from "../utils/date-converter";
import {
  getInternshipPref,
  saveInternshipPref,
  updateInternshipPref,
} from "../../../services/apiCalls";
import "./style.css";
import CancelIcon from "../../../assets/icons/skillCancel.png";
import { useTranslation } from "react-i18next";
const materialTheme = createTheme({
  palette: {
    primary: {
      main: "#D44B9C",
    },
  },
  overrides: {
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: "#D44B9C",
      },
    },
  },
});
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const CalenderIconWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  cursor: pointer;
`;
const DatesWrapper = styled(FlexWrapper)`
  label {
    position: relative;
    width: -webkit-fill-available;
  }
`;
const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;

const initialState = {
  start_date: null,
  end_date: null,
  title: "",
};

const InternshipPreference = ({ notification }) => {
  const [formState, setFormState] = useState(initialState);
  const [showSave, setShowSave] = useState(false);
  const [isPatch, setIsPatch] = useState(false);
  const initialRef = useRef();
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const { t } = useTranslation();

  useEffect(() => {
    fetchInternPref();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChange = (e) => {
    setShowSave(true);
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleSubmit = async () => {
    let { start_date, end_date, title, id } = formState;
    if (start_date === null) {
      start_date = "";
    } else {
      start_date = ConvertDate(start_date, "yyyy-mm-dd");
    }
    if (end_date === null) {
      end_date = "";
    } else {
      end_date = ConvertDate(end_date, "yyyy-mm-dd");
    }
    const payload = {
      data: [{ start_date, end_date, title, available: true }],
    };
    if (!isPatch) {
      const { data, status } = await saveInternshipPref(payload);
      checkStatus(data, status, "save");
    } else {
      updateIntern(id);
      // const { data, status } = await updateInternshipPref(id, payload);
      // checkStatus(data, status, "update");
    }
  };

  const updateIntern = async (id) => {
    let { start_date, end_date, title } = formState;
    if (start_date === null) {
      start_date = "";
    } else {
      start_date = ConvertDate(start_date, "yyyy-mm-dd");
    }
    if (end_date === null) {
      end_date = "";
    } else {
      end_date = ConvertDate(end_date, "yyyy-mm-dd");
    }
    const payload = { start_date, end_date, title };
    const { data, status } = await updateInternshipPref(id, payload);
    checkStatus(data, status, "update");
  };

  const checkStatus = (data, status, method) => {
    const methodTmp =
      method === "save" ? "saved" : method === "update" ? "updated" : "deleted";
    if (data.status === 1 && status === 200) {
      fetchInternPref();
      if (method === "save") {
        notification("success", t("Internship preference saved successfully"));
      } else if (method === "update") {
        notification(
          "success",
          t("Internship preference updated successfully")
        );
      } else if (method === "delete") {
        notification(
          "success",
          t("Internship preference deleted successfully")
        );
      }
    } else {
      if (method === "save") {
        notification("error", t("Unable to save internship preference"));
      } else if (method === "update") {
        notification("error", t("Unable to update internship preference"));
      } else if (method === "delete") {
        notification("error", t("Unable to delete internship preference"));
      }
    }
  };
  const resetForm = () => {
    const { title, start_date, end_date } = initialRef.current;
    setFormState({ title, start_date, end_date });
    setIsPatch(false);
    setShowSave(false);
  };

  const fetchInternPref = async () => {
    const { data, status } = await getInternshipPref();
    if (status === 200 && data.status === 1) {
      if (data.data.length > 0) {
        setIsPatch(true);
        initialRef.current = data.data[0];
        setFormState(data.data[0]);
        setShowSave(false);
      }
    } else {
      notification("error", t("Unable to fetch Internship preference details"));
    }
  };

  const clearDate = (e, name) => {
    e.preventDefault();
    setFormState({ ...formState, [name]: null });
  };
  return (
    <>
      <Row>
        {/* <Col md="6">
          <CvCard
            title={t("Internship Preference")}
            titleColor={Colors.primaryText}
            showLock={false}
          >
            <RadioInput
              label={t("I am looking for an internship opportunity")}
              onChange={() =>
                handleChange({
                  target: {
                    name: "title",
                    value: "I am looking for an internship opportunity",
                  },
                })
              }
              value={formState.title}
              checked={
                formState.title === "I am looking for an internship opportunity"
              }
              margin="15px"
            />
            <RadioInput
              label={t("University has an internship program")}
              onChange={() =>
                handleChange({
                  target: {
                    name: "title",
                    value: "University have an internship program",
                  },
                })
              }
              value={formState.title}
              checked={
                formState.title === "University have an internship program"
              }
              margin="15px"
            />
          </CvCard>
        </Col> */}
        <Col md="6" xs="12">
          {innerWidth > 800 && <hr className="vr" />}
          <CvCard
            title={t("Duration")}
            titleColor={Colors.primaryText}
            showLock={false}
          >
            <Row className="durationWrapper">
              <Col md="6" className="mb-2 date">
                <DatesWrapper>
                  <label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={materialTheme}>
                        <DatePicker
                          autoOk
                          variant="inline"
                          format="dd/MM/yyyy"
                          placeholder={t("Enter Start Date")}
                          value={formState.start_date ?? null}
                          onChange={(val) =>
                            handleChange({
                              target: { name: "start_date", value: val },
                            })
                          }
                          views={["year", "month", "date"]}
                          fullWidth
                          disabled={!formState.title}
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                    <CalenderIconWrapper>
                      <CalenderIcon />
                      {formState.start_date && (
                        <IconButton onClick={(e) => clearDate(e, "start_date")}>
                          <img src={CancelIcon} alt="" />
                        </IconButton>
                      )}
                    </CalenderIconWrapper>
                  </label>
                </DatesWrapper>
              </Col>
              <Col md="6" className="mb-2 date">
                <DatesWrapper>
                  <label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={materialTheme}>
                        <DatePicker
                          autoOk
                          variant="inline"
                          format="dd/MM/yyyy"
                          placeholder={t("Enter End Date")}
                          value={formState.end_date ?? null}
                          onChange={(val) =>
                            handleChange({
                              target: { name: "end_date", value: val },
                            })
                          }
                          minDate={new Date(formState.start_date).setDate(
                            new Date(formState.start_date).getDate()
                          )}
                          views={["year", "month", "date"]}
                          fullWidth
                          disabled={!formState.title}
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                    <CalenderIconWrapper>
                      <CalenderIcon />
                      {formState.end_date && (
                        <IconButton onClick={(e) => clearDate(e, "end_date")}>
                          <img src={CancelIcon} alt="" />
                        </IconButton>
                      )}
                    </CalenderIconWrapper>
                  </label>
                </DatesWrapper>
              </Col>
            </Row>
          </CvCard>
        </Col>
      </Row>
      {showSave && (
        <Row>
          <ButtonWrapper>
            <CustomButton
              onClick={handleSubmit}
              name={isPatch ? t("Update") : t("Save")}
            />
            &nbsp;&nbsp;
            <CustomButton onClick={resetForm} secondary name={t("Cancel")} />
          </ButtonWrapper>
        </Row>
      )}
    </>
  );
};
export default InternshipPreference;

import React, { useState, useEffect } from "react";

// import { useState, useEffect, useRef } from "react";

import styled from "styled-components";

// import Input from "../../components/input/input";

import { Link, useParams } from "react-router-dom";
import { viewBlog } from "../../services/apiCalls";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import { useTranslation } from "react-i18next";
import { AspectRatio } from "react-aspect-ratio";
import "react-aspect-ratio/aspect-ratio.css";

const BlogCardWrapper = styled.div`
  position: relative;
  background: #fdfdfd;
  border-radius: 30px;

  margin-bottom: 20px;
  min-height: 80vh;
  margin: 0rem 3rem;
`;

const BlogTitle = styled.div`
  font-weight: 500;

  opacity: 0.75;
  font-size: 40px;
  font-style: italic;
  padding: 4rem 0 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const BlogDescription = styled.div`
  font-size: 20px;
  padding: 1rem 3rem;
  line-height: 36px;

  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
`;
const Uploader = styled.img`
  cursor: pointer;
  max-width: 100%;
  background-size: cover;
  height: 50vh;
  width: 100%;

  // @media (max-width: 500px) {
  //   max-width: 280px;
  // }
`;

export default function BlogView(props) {
  const [request, setRequest] = useState({
    title: "",
    description: "",
    image: "",
    status: "public",
  });

  const [showImg, setShowImg] = useState("");

  const params = useParams();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (params.slug) {
      getBlog(params.slug);
    }
  }, [params.slug]);

  const getBlog = async (slug) => {
    const { data, status } = await viewBlog(slug);

    const resultDesc = data.description.replace(/((&nbsp;))*/gim, "");
    const newDetails = {
      ...request,
      title: data.title,
      description: resultDesc,
      image: data.image,
      status: data.status,
    };
    if (data.image) {
      setShowImg(data.image);
    }
    setRequest(newDetails);
  };

  return (
    <MainLayout>
      <div
        className="d-flex mb-5"
        style={{
          fontSize: "1.1rem",
          fontWeight: "400",
          marginLeft: "15px",
        }}
      >
        <Link
          to="/dashboard"
          style={{
            textDecoration: "none",
            color: "#ACACAC",
            marginLeft: "15px",
          }}
        >
          Blogs &nbsp; {">"}
        </Link>
        <div className="mx-2" style={{ color: "#ACACAC" }}></div>
        <span style={{ textDecoration: "none", color: "#D04C8D" }}>
          {t("Blog View")}
        </span>
      </div>

      <BlogCardWrapper>
        <BlogTitle>{request.title}</BlogTitle>
        {showImg && (
            <AspectRatio ratio="16/9"><Uploader src={showImg} alt="uploader logo" /></AspectRatio>
        )}

        <BlogDescription
          dangerouslySetInnerHTML={{ __html: request.description }}
        />
      </BlogCardWrapper>
    </MainLayout>
  );
}

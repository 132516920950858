import { useState, useEffect } from "react";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import { useTranslation } from "react-i18next";

const HeaderText = styled.h2`
font-weight: 500;
font-size: 28px;
line-height: 38px;
color: ${Colors.dark};
margin: 0 0 1.5rem 0;
@media (max-width: 767px) {
  font-size: 24px;
}
`;

export default function HeaderGreeting(props) {
    // styling starts
    // styling ends

    const [greet, setGreet] = useState("");
    const { t } = useTranslation();

    useEffect(() => {
        getTimeZone();
    }, []);
    const getTimeZone = () => {
        var today = new Date();
        var curHr = today.getHours();

        if (curHr < 12) {
            setGreet("Good Morning");
        } else if (curHr < 18) {
            setGreet("Good Afternoon");
        } else {
            setGreet("Good Evening");
        }
    };

    return (
        <HeaderText {...props}>
            {t(greet)}, {props.userName}
        </HeaderText>
    );
}

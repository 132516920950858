import styled from "styled-components";

import DeleteIcon from "../../../assets/icons/activeDelete.png";
import { Colors } from "../../../utilities/colors";
import { ConvertDate } from "../utils/date-converter";
import EditIcon from "../../../assets/svg/pencil.svg";
import { Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const SectionDivisionWrapper = styled.div`
  padding: 1rem 1.5rem 1rem 1.5rem;
  background: #fdfdfd;
  border: 1px solid rgba(228, 228, 228, 0.26);
  box-sizing: border-box;
  border-radius: 5px;
  margin: 30px 0;
  overflow:auto;
  @media (max-width: 767px) {
    padding: 0.5rem;
  }
`;
const TableTop = styled.table`
  overflow-x: scroll;
  width:100%;
`;
const TableHead = styled.th`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.labelText};
  padding: 15px 10px;
`;
const TableData = styled.td`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  padding: 15px 10px;
  border-top:1px solid #ddd;
  color: #525252;
  text-transform: capitalize;
`;

const Icon = styled.img`
  margin: 0px 5px;
`;
const EditIconW = styled.img`
color:rgb(208, 76, 141);
margin: 0px 5px;
`;

export default function EmploymentTable(props) {
    const { data, deleteEmployee, editEmployee } = props;
    const { t } = useTranslation();

    return (
        <Row>
            <SectionDivisionWrapper>
                <TableTop>
                    <thead>
                        <tr>
                            <TableHead>{t("Role")}</TableHead>
                            <TableHead>{t("Organization")}</TableHead>
                            <TableHead>{t("Location")}</TableHead>
                            <TableHead>{t("Start Date")}</TableHead>
                            <TableHead>{t("End Date")}</TableHead>
                            <TableHead></TableHead>
                            <TableHead></TableHead>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((x) => (
                            <tr key={x.id}>
                                <TableData>{x.role}</TableData>
                                <TableData>{x.organization}</TableData>
                                <TableData>{x.location}</TableData>
                                <TableData>{ConvertDate(x.start_date, 'dd/mm/yyyy')}</TableData>
                                {ConvertDate(x.start_date, 'dd/mm/yyyy') > ConvertDate(x.end_date, 'dd/mm/yyyy') ?
                                    <TableData>{"-"}</TableData>
                                    :
                                    <TableData>{ConvertDate(x.end_date, 'dd/mm/yyyy')}</TableData>
                                }
                                <TableData>
                                    <EditIconW onClick={() => editEmployee(x.id)} src={EditIcon} />
                                </TableData>
                                <TableData>
                                    <Icon onClick={() => deleteEmployee(x.id)} src={DeleteIcon} />
                                </TableData>
                            </tr>
                        ))}
                    </tbody>
                </TableTop>
            </SectionDivisionWrapper>
        </Row>
    );
}

import { Modal } from "react-bootstrap";

function JobAlertModal(props) {
  return (
    <div>
      <Modal show={props.show} onHide={props.close} centered size="lg" contentClassName="modal-height">
      
       
        
        <div style={{ padding: "40px 15px 10px", minHeight: "220px" }}>
          <div style={{ textAlign: "center",position:"relative",margin:"50px",top:"-20px" }}>{props.content}</div>
        </div>
        
      </Modal>
    </div>
  );
}

export default JobAlertModal;

import UserCreationLayout from "../../layouts/userCreation/userCreationLayout";
import employecover from "../../assets/employecover.png";
import unEmployeeCover from "../../assets/unemployeecover.png";
import IntroSection from "../../components/introSection/introSection";
import PaginationDivider from "../../components/paginationDivider/paginationDivider";
import { useSelector, useDispatch } from "react-redux";
import { armySchema, armySchemaIos, armyStaticContent } from "./armyPersonnelStatic";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { deviceStore } from "../../utilities/devices";
import { Col, Row } from "react-bootstrap";
import RadioInput from "../../components/radioinput/radioinput";
import Button from "../../components/button/button";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  getArmyDetails,
  onBoardStepTwoForm,
  onBoardFormSubmission,
  getAcademicQualifications,
  getAllCountries,
  getOption,
  getScopeOfStudies,
  getUniversity,
  getGrades,
} from "../../services/apiCalls";
import Dropdown from "../../components/dropdown/dropdown";
import { updateValues } from "../selfIntro/reducer";
import Input from "../../components/input/input";
import Loader from "../../components/loader/loader";
import { useTranslation } from "react-i18next";
import { removeKeys, formatDate } from "../../utilities/commonFunctions";
import ErrorField from "../../components/errorField/errorField";
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { createTheme, IconButton } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import moment from "moment";
import CancelIcon from "../../assets/icons/skillCancel.png";
import { ReactComponent as CalenderIcon } from "../../assets/icons/svg/calendarIcon.svg";

const materialTheme = createTheme({
  palette: {
    primary: {
      main: "#D44B9C",
    },
  },
  overrides: {
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: "#D44B9C",
      },
    },
  },
});

const CalenderIconWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  cursor: pointer;
`;

const FormWrapper = styled.div`
  margin: 30px 0 0 0;
  max-width: 100%;
  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;

const EnglishTestWrapper = styled.div`
  margin-bottom: 10px;
`;

const RadioWrapperHorizontal = styled.div`
  display: flex;
  align-items: center;
  gap: 20px; /* Adjust the gap between radio buttons as needed */
`;

const FormGroup = styled.div`
  margin-bottom: 25px;
`;

const LabelText = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${Colors.labelText};
  margin-bottom: 10px;
  display: block;
`;

const RadioWrapperArmy = styled.div`
  display: flex;
  gap: 230px;
  align-items: center;

  @media (max-width: 599px) {
    justify-content: space-between;
  }
  @media (max-width: 990px) {
    justify-content: space-between;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonWrapper = styled(FlexWrapper)`
  justify-content: space-between;
  margin-top: 40px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
  @media (max-width: 1024px) {
    justify-content: space-between;
  }
`;
const SaveButton = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #504f8c;
  margin: 0;
  text-align: left;
  cursor: pointer;
  @media (max-width: 900px) {
    text-align: center;
  }
`;

const ScholarshipWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 599px) {
    margin-bottom: 20px;
  }
`;

const DatesWrapper = styled(FlexWrapper)`
  label {
    position: relative;
    width: -webkit-fill-available;
  }
`;

const DividerLine = styled.hr`
  border: 0.5px solid #ebebeb;
  margin: 30px 0;
`;

const GradeWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media (max-width: 800px) {
    display: grid;
    grid-template-columns: 1fr 12fr;
    grid-row-gap: 10px;
  }
`;

export default function ArmyPersonnel() {
  const formValues = useSelector((state) => state.selfIntroOne.value);
  const value = formValues.talent_status;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showArmySection, setShowArmySection] = useState(false);
  const [armyService, setArmyService] = useState([]);
  const [armyCorpRegiment, setArmyCorpRegiment] = useState([]);
  const [armyLastRank, setArmyLastRank] = useState([]);
  const { t } = useTranslation();
  const [selectedService, setSelectedService] = useState({});
  const [selectedCorpRegiment, setCorpRegiment] = useState({});
  const [selectedLastRank, setSelectedLastRank] = useState({});
  //const [armyFormErrors, setArmyFormErrors] = useState(false);
  const [formErrors, setFormErrors] = useState("");
  const [errorKey, setErrorKey] = useState([]);
  const [loading, setLoading] = useState(false);

  const [highQualification, setHighQualification] = useState([]);
  const [selectedHighqualification, setSelectedHighqualification] = useState(
    []
  );
  const [nMcountry, setNMcountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [instiCountryDisable, setInstiCountryDisable] = useState(true);
  const [institutionList, setinstitutionList] = useState([]);
  const [selectedInstitution, setSelectedInstitution] = useState([]);
  const [options1, setOptions1] = useState({});

  const [englishScore, setEnglishScore] = useState();
  const [min, setMin] = useState();
  const [max, setMax] = useState();
  const [thisIELTSMin, setThisIELTSMin] = useState(0.1);
  const [thisIELTSMax, setThisIELTSMax] = useState(10.0);

  const [scope_of_studies, setScope_of_studies] = useState([]);
  const [selectedScopeOfStudies, setSelectedScopeOfStudies] = useState([]);
  const [pensionStatus, setPensionStatus] = useState(""); // Add this line

  const [perhebatTraining, setPerhebatTraining] = useState([]);
  const [selectedPerhebatTraining, setSelectedPerhebatTraining] = useState([]);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    resetField,
    setError,
    setFocus,
    clearErrors,
    formState: { errors, values },
  } = useForm({
    defaultValues: { ...formValues },
    resolver:
      deviceStore.platform === "ios"
        ? yupResolver(armySchemaIos)
        : yupResolver(armySchema),
    //context: { statesDisable, citiesDisable },
  });

  const formVal = watch();

  const onNavigate = (path) => {
    navigate(path);
  };

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a;
    }, null);

    if (firstError) {
      setFocus("firstError");
    }
  }, [errors, setFocus]);

  const setEnglishValue = (val) => {
    setValue("english_score", val);
    setValue("englishscore", val);
  };

  const fetchScopeOfStudies = async () => {
    const { data, status } = await getScopeOfStudies();
    if (status === 200) {
      const Scopes = data.data;
      const Scope_Of_Studies = Scopes.map((x) => {
        return { ...x, label: x.Name, value: x.Id };
      });
      setScope_of_studies(Scope_Of_Studies);
      if (formVal.scope) {
        let scopeOfStudies = Scope_Of_Studies.filter(
          (x) => x.value == formVal.scope
        );
        if (scopeOfStudies.length > 0) {
          scopeOfStudies = scopeOfStudies[0];
        }
        setSelectedScopeOfStudies(scopeOfStudies);
      }
    }
  };
  const fetchHighQualification = async () => {
    const { data, status } = await getAcademicQualifications();
    if (status === 200) {
      const qualifications = data.data;
      const highestqualification = qualifications.map((x) => {
        return { ...x, label: x.Name, value: x.Id };
      });
      setHighQualification(highestqualification);

      // Edit mode
      if (formVal.high_qualification) {
        let selHighqualification = highestqualification.filter(
          (x) => x.value == formVal.high_qualification
        );
        if (selHighqualification.length > 0) {
          selHighqualification = selHighqualification[0];
          setValue("high_qualification", selHighqualification.value);
        }
        setSelectedHighqualification(selHighqualification);
      }
    }
  };

  const perhebatTrainingOptions = [
    { value: 1, label: t("Pre - Transition Training") },
    { value: 2, label: t("Transition Training") },
    { value: 3, label: t("Post Transition Training") },
    { value: 4, label: t("None") },
  ];

  const fetchArmyDetails = async () => {
    const { data } = await getArmyDetails();
    if (data.status === 1) {
      if (data?.army_service) {
        const armyService = data?.army_service.map((x) => {
          return { ...x, label: t(x.name), value: x.id };
        });
        setArmyService(armyService);
        if (formVal.army_service) {
          let army_Service = armyService.filter(
            (x) => x.value == formVal.army_service
          );
          if (army_Service.length > 0) {
            army_Service = army_Service[0];
            setValue("army_service", army_Service.value);
          }
          setSelectedService(army_Service);
        }
      }
      if (data?.army_corp_regiment) {
        const armyCorpReg = data?.army_corp_regiment.map((x) => {
          return { ...x, label: t(x.name), value: x.id };
        });
        setArmyCorpRegiment(armyCorpReg);

        if (formVal.army_corp_regiment) {
          let army_CorpReg = armyCorpReg.filter(
            (x) => x.value == formVal.army_corp_regiment
          );
          if (army_CorpReg.length > 0) {
            army_CorpReg = army_CorpReg[0];
            setValue("army_corp_regiment", army_CorpReg.value);
          }
          setCorpRegiment(army_CorpReg);
        }
      }
      if (data?.army_last_rank) {
        const armyLast = data?.army_last_rank.map((x) => {
          return { ...x, label: t(x.name), value: x.id };
        });
        setArmyLastRank(armyLast);

        if (formVal.army_last_rank) {
          let army_LastRank = armyLast.filter(
            (x) => x.value == formVal.army_last_rank
          );
          if (army_LastRank.length > 0) {
            army_LastRank = army_LastRank[0];
            setValue("army_last_rank", army_LastRank.value);
          }
          setSelectedLastRank(army_LastRank);
        }
      }

      // console.log(perhebatTrainingOptions,'perhebatTrainingOptions')
      if (perhebatTrainingOptions) {
        const trainingStatus = perhebatTrainingOptions.map((x) => {
          return { ...x, label: t(x.label), value: x.value };
        });
        setPerhebatTraining(trainingStatus);
        if (formVal.perhebat_training_status) {
          let army_trainingStatus = trainingStatus.filter(
            (x) => x.value == formVal.perhebat_training_status
          );
          if (army_trainingStatus.length > 0) {
            army_trainingStatus = army_trainingStatus[0];
            setValue("perhebat_training_status", army_trainingStatus.value);
          }
          setSelectedPerhebatTraining(army_trainingStatus);
        }
      }
    }
  };

  const getNonMalaysian = async () => {
    // const { data, status } = await getCurrentCountries(
    const { data, status } = await getAllCountries(
      {
        search_key: "",
      },
      { key_term: "country", hint: "nm", loc: "loc" }
    );
    if (status === 200) {
      setNonMalaysian(data);
    }
  };

  const setNonMalaysian = (data) => {
    if (data.status === 1) {
      const location = data.data;
      const country = location.map((x) => {
        return { ...x, label: x.Name, value: x.Id };
      });
      setNMcountry(country);

      // Edit mode
      if (formVal.insti_country) {
        let selCountry = country.filter(
          (x) => x.value == formVal.insti_country
        );
        if (selCountry.length > 0) {
          selCountry = selCountry[0];
          setValue("insti_country", selCountry.value);
        }
        setSelectedCountry(selCountry);
      }
    }
  };

  const getInstitution = async () => {
    const { data, status } = await getUniversity();
    if (status === 200) {
      setInstitutionDatas(data);
    }
  };

  const setInstitutionDatas = (data) => {
    if (data.status === 1) {
      const institutionData = data.data;
      const institution = institutionData.map((x) => {
        return { ...x, label: x.Name, value: x.Id };
      });
      setinstitutionList(institution);

      // Edit mode
      if (formVal.insti_name) {
        let selInstitution = institution.filter(
          (x) => x.label == formVal.insti_name
        );
        if (selInstitution.length > 0) {
          selInstitution = selInstitution[0];
          setValue("insti_name", selInstitution.value);
          // setInstiCountryDisable(true);
        }
        setSelectedInstitution(selInstitution);
      }
    }
  };

  const getOptions = async (name) => {
    let key_term = name;
    const { data, status } = await getOption({ search_key: "" }, key_term);
    if (data.data.length !== 0) {
      let newData = data.data.map((x) => {
        return {
          label: x,
          value: x,
        };
      });
      let obj = { ...options1 };
      obj[name] = newData;
      setOptions1(obj);
    } else {
      return null;
    }
  };
  const [thisCgpaMin, setThisCgpaMin] = useState(0.1);
  const [thisCgpaMax, setThisCgpaMax] = useState(10.0);
  const [selectedGrade, setSelectedGrade] = useState([]);
  const [grades, setGrade] = useState([]);
  const [gradeInput, setGradeInput] = useState(false);
  const fetchGrade = async () => {
    const { data, status } = await getGrades();
    if (status === 200) {
      const gradesList = data.data;
      const grades = gradesList.map((x) => {
        return { ...x, label: x.Title, value: x.Id };
      });
      setGrade(grades);

      // Edit mode
      if (formVal.grade) {
        let selGrade = grades.filter((x) => x.value == formVal.grade);
        if (selGrade.length > 0) {
          selGrade = selGrade[0];
          setValue("grade", selGrade.value);
          setValue("grade_abbr", selGrade.label);
        }
        setSelectedGrade(selGrade);
      }
    }
  };

  const setGradeValue = (val) => {
    setValue("grade", val);
    setValue("grade_abbr", val);
  };

  useEffect(() => {
    fetchArmyDetails();
    fetchHighQualification();
    fetchScopeOfStudies();
    getNonMalaysian();
    getInstitution();
    fetchGrade();
  }, []);

  const radioChange = (value, name) => {
    setValue(name, value);
    setErrorKey((prevErrors) => prevErrors?.filter((item) => item !== name));
    switch (name) {
      case "insti_country_status":
        setValue("insti_country", "");
        setValue("insti_name", "");
        setValue("insti_name", "");
        setValue("insti_country_abbr", "");
        setValue("university", "");
        setValue("insti_name_abbr", "");
        setSelectedCountry("");
        setSelectedInstitution("");
        if (value == 1) {
          setInstiCountryDisable(true);
        } else {
          setInstiCountryDisable(false);
        }

        break;
      case "english_tests":
        setValue("english_score", "");
        setValue("englishscore", "");
        setEnglishScore("");
        if (value === "TOEFL") {
          setMin(0);
          setMax(30);
        } else if (value === "IELTS") {
          setMin(0);
          setMax(9);
        } else {
          setMin(null);
          setMax(null);
        }
        if (value === "I have not taken any tests") {
          setValue("englishscore", "I have not taken any tests");
          setValue("english_score", "I have not taken any tests");
        }
        break;
      case "pension_status": // Add this case
        setPensionStatus(value); // Set the pension status state
        setValue(name, value);
        break;
      case "grade_status":
        setValue("grade_abbr", "");
        setValue("grade", "");
        if (value === "No Current Grade") {
          setValue("grade_abbr", "No Current Grade");
          setValue("grade", "No Current Grade")
        }
        break;
      case "is_army_active":

      default:
        break;
    }
  };

  const dropdownChange = (name, val) => {
    setValue(name, val.value);
    switch (name) {
      case "army_service":
        setSelectedService(val);
        setValue("army_service", val.value);
        dispatch(
          updateValues({
            ...formValues,
            ...formVal,
            army_service: "",
          })
        );
        break;
      case "high_qualification":
        setSelectedHighqualification(val);
        setValue("high_qualification_abbr", val.label);
        dispatch(
          updateValues({
            ...formValues,
            ...formVal,
            [name]: val.value,
          })
        );
        if (val.label === "SRP/PMR" || val.label === "SPM" || val.label === "STPM") {
          setValue("grade_status", "No Current Grade");
          setValue("grade", "No Current Grade");
        }
        break;
      case "scope":
        setSelectedScopeOfStudies(val);
        setValue("scope_abbr", val.label);
        dispatch(
          updateValues({
            ...formValues,
            ...formVal,
            [name]: val.value,
          })
        );
        break;
      case "army_corp_regiment":
        setCorpRegiment(val);
        setValue("army_corp_regiment", val.value);
        break;
      case "army_last_rank":
        setSelectedLastRank(val);
        setValue("army_last_rank", val.value);
        break;

      case "insti_name":
        setSelectedInstitution(val);
        setValue("insti_name", val.label);
        setValue("insti_country", "132");
        dispatch(
          updateValues({
            ...formValues,
            ...formVal,
            [name]: val.value,
          })
        );
        break;
      case "insti_country":
        setSelectedCountry(val);
        setValue("insti_country", val.value);
        dispatch(
          updateValues({
            ...formValues,
            ...formVal,
            [name]: val.value,
          })
        );
        break;
      case "perhebat_training_status":
        setSelectedPerhebatTraining(val);
        setValue("perhebat_training_status", val.value);
        break;
      case "grade_status":
        setValue("grade", "");
        if (value === "Grades") {
          setGradeInput(true);
        } else {
          setGradeInput(false);
        }
        if (value === "No Current Grade") {
          setValue("grade_abbr", "No Current Grade");
          setValue("grade", "No Current Grade")
        }
        break;
        case "grade":
          setSelectedGrade(val);
          setValue("grade", val.value);
          setValue("grade_abbr", val.label);
          break;

      default:
        break;
    }
  };

  const onErrorSubmit = () => {
    //setArmyFormErrors(true);
  };

  const onSubmit = async () => {
    setLoading(true);
    const { data } = await onBoardStepTwoForm({
      ...formVal,
      is_army: 1,
      talent_status: formValues.talent_status,
    });
    if (data && data.status === 1) {
      dispatch(updateValues({ ...formValues, ...formVal }));
      setLoading(false);
      setFormErrors("");
      //setArmyFormErrors(false);
      window.allow_user_continue = true;
      navigate("/uploadpic");
    } else {
      setLoading(false);
      setFormErrors(data && data.message);
      setErrorKey(data?.key);
    }
  };


  const limitMaxMonth = (val) => {
    if (Number(val) > 12) {
      setValue("dur_month", "");
      setError("dur_month", {
        type: "manual",
        message: "Month does not exceed 12",
      });
    } else {
      setValue("dur_month", val);
      setError("dur_month", {
        type: "manual",
        message: "",
      });
    }
  };

  const onSave = async () => {
    setLoading(true);
    const req = removeKeys({ ...formVal });
    const { data } = await onBoardFormSubmission({
      ...req,
      step: formValues.profile_picture ? 4 : 3,
      // step: 3
    });
    if (data && data.status === 1) {
      //---------
      if (formVal.insti_location_status === 0) {
        //study abroad
        if (formVal.insti_name) {
          formVal.insti_name = "";
          formVal.insti_name_abbr = formVal.university;
        }
      } else if (formVal.insti_location_status === 1) {
        //study Locally
        if (formVal.university) {
          formVal.university = "";
        }
      }

      dispatch(updateValues({ ...formValues, ...formVal }));
      // dispatch(updateValues({ ...formVal, ...formValues }));

      //----------
      navigate("/dashboard");
      setLoading(false);
      setFormErrors("");
    } else {
      setLoading(false);
      setFormErrors(data && data.message);
    }
  };
  const dateChange = (name, val) => {
    setValue(name, formatDate(val));
  };

  const clearDate = (e, name) => {
    e.preventDefault();
    setValue(name, "");
  };
  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur()
    e.stopPropagation()
    setTimeout(() => {
      e.target.focus()
    }, 0)
  };
  return (
    <UserCreationLayout
      sidebarImg={value == "unemployed" ? unEmployeeCover : employecover}
    >
      <IntroSection
        name={t(armyStaticContent.title)}
        description={t(armyStaticContent.description)}
      />
      <PaginationDivider step={2} />

      <FormWrapper>
        <Row>
          <FormGroup>
            <Controller
              name="is_army"
              control={control}
              render={({ field }) => (
                <Input
                  type="hidden"
                  val={1}
                />
              )}
            />
          </FormGroup>

          <FormGroup>
            <LabelText>{t("Army Id*")}</LabelText>
            <Controller
              name="army_id_number"
              control={control}
              render={({ field }) => (
                <Input
                  placeholder={t("Please enter your army id")}
                  onChange={() => setFormErrors("")}
                  {...field}
                />
              )}
            />
            {errors && errors.army_id_number && (
              <ErrorField err={t(errors.army_id_number.message)} />
            )}
          </FormGroup>

          <FormGroup>
            <LabelText>{t(armyStaticContent.service_type)}*</LabelText>
            <Controller
              name="army_service"
              control={control}
              render={({ field }) => (
                <Dropdown
                  placeholder={t("Select Service Type")}
                  options={armyService}
                  label={selectedService?.label}
                  val={selectedService?.id}
                  change={(val) => dropdownChange("army_service", val)}
                />
              )}
            />
            {errors && errors.army_service && (
              <ErrorField err={t(errors.army_service.message)} />
            )}
          </FormGroup>

          <FormGroup>
            <LabelText>{t(armyStaticContent.corps_regiment)}*</LabelText>
            <Controller
              name="army_corp_regiment"
              control={control}
              render={({ field }) => (
                <Dropdown
                  placeholder={t("Select Corps/Regiment")}
                  options={armyCorpRegiment}
                  label={selectedCorpRegiment?.label}
                  val={selectedCorpRegiment?.id}
                  change={(val) => dropdownChange("army_corp_regiment", val)}
                />
              )}
            />
            {errors && errors.army_corp_regiment && (
              <ErrorField err={t(errors.army_corp_regiment.message)} />
            )}
          </FormGroup>

          <FormGroup>
            <LabelText>{t(armyStaticContent.last_rank)}*</LabelText>
            <Controller
              name="army_last_rank"
              control={control}
              render={({ field }) => (
                <Dropdown
                  placeholder={t("Select Last Rank")}
                  options={armyLastRank}
                  label={selectedLastRank?.label}
                  val={selectedLastRank?.id}
                  change={(val) => dropdownChange("army_last_rank", val)}
                />
              )}
            />
            {errors && errors.army_last_rank && (
              <ErrorField err={t(errors.army_last_rank.message)} />
            )}
          </FormGroup>

          <FormGroup>
            <LabelText>{t(armyStaticContent.army_active_label)}*</LabelText>

            <Row>
              <Col>
                <ScholarshipWrapper>
                  <Controller
                    name="army_is_active"
                    control={control}
                    render={({ field }) =>
                      armyStaticContent.army_is_active.map((options, index) => (
                        <RadioInput
                          key={options.label + index}
                          label={t(options.label)}
                          checked={options.value === formVal.army_is_active}
                          onChange={() =>
                            radioChange(options.value, "army_is_active")
                          }
                        />
                      ))
                    }
                  />
                </ScholarshipWrapper>
              </Col>
              {errors && errors.army_is_active && (
                <ErrorField err={t(errors.army_is_active.message)} />
              )}
            </Row>
          </FormGroup>
        </Row>
        <Row>
          <LabelText>{t(armyStaticContent.workDuration)}</LabelText>
          <Col xs="12" lg="6" md="6" xl="4">
            <FormGroup>
              <Controller
                name="dur_year"
                control={control}
                render={({ field }) => (
                  <Input
                    type="number"
                    placeholder={t("Years")}
                    {...field}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                      if (event.target.value.length > 2) {
                        event.preventDefault();
                      }
                    }}
                    min={0}
                    max={100}
                    onWheel={numberInputOnWheelPreventChange}
                  />
                )}
              />
              {errors && errors.dur_year && (
                <ErrorField err={t(errors.dur_year.message)} />
              )}
            </FormGroup>
          </Col>
          <Col xs="12" lg="6" md="6" xl="4">
            <FormGroup>
              <Controller
                name="dur_month"
                control={control}
                render={({ field }) => (
                  <Input
                    type="number"
                    placeholder={t("Months")}
                    {...field}
                    onChange={(e) => limitMaxMonth(e.target.value)}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                      if (event.target.value.length > 1) {
                        event.preventDefault();
                      }
                    }}
                    min={1}
                    max={12}
                    onWheel={numberInputOnWheelPreventChange}
                  />
                )}
              />
              {errors && errors.dur_month && (
                <ErrorField err={t(errors.dur_month.message)} />
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <LabelText>{t(armyStaticContent.job)}</LabelText>
              <Controller
                name="position"
                control={control}
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder={t("Position/Job title")}
                    {...field}
                    maxLength="100"
                  />
                )}
              />
              {errors && errors.position && (
                <ErrorField err={t(errors.position.message)} />
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <LabelText>{t(armyStaticContent.allowance)}</LabelText>
              <Controller
                name="salary"
                control={control}
                render={({ field }) => (
                  <Input
                    type="number"
                    placeholder={t("Salary (RM per month before tax)")}
                    {...field}
                    onWheel={numberInputOnWheelPreventChange}
                  />
                )}
              />
              {errors && errors.salary && <ErrorField err={t(errors.salary.message)} />}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <LabelText>{t(armyStaticContent.academicTitle)}</LabelText>
              <Controller
                name="high_qualification"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    search={true}
                    placeholder={t("Select")}
                    options={highQualification}
                    change={(val) => dropdownChange("high_qualification", val)}
                    val={selectedHighqualification.value}
                    label={selectedHighqualification.label}
                    // disable={formVal.high_qualification ? false : true}
                    {...field}
                    refCallback={field?.ref}
                    ref={null}
                  />
                )}
              />
              {errors && errors.high_qualification && (
                <ErrorField err={t(errors.high_qualification.message)} />
              )}
            </FormGroup>
          </Col>
        </Row>
        {(formVal.high_qualification !== 10 && formVal.high_qualification !== 11 && formVal.high_qualification !== 12) && (
          <>
            {" "}
            <FormGroup>
              <Row>
                <Col>
                  <LabelText>{t(armyStaticContent.highestQualification)}</LabelText>
                  <ScholarshipWrapper>
                    <Controller
                      name="insti_country_status"
                      control={control}
                      render={({ field }) =>
                        armyStaticContent.countries.map((identity, index) => (
                          <RadioInput
                            key={identity.label + index}
                            {...field}
                            checked={
                              identity.value === formVal.insti_country_status
                            }
                            onChange={() =>
                              radioChange(identity.value, "insti_country_status")
                            }
                            label={t(identity.label)}
                            name="insti_country_status"
                            margin="20px"
                          />
                        ))
                      }
                    />
                  </ScholarshipWrapper>
                </Col>
              </Row>
            </FormGroup>
          </>
        )}
        {(formVal.insti_country_status === 0 && formVal.high_qualification !== 10 && formVal.high_qualification !== 11 && formVal.high_qualification !== 12) && (
          <>
            {" "}
            {/* study abroad: show insti_country and university input */}
            <Row>
              <Col>
                <FormGroup>
                  <Controller
                    name="insti_country"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        search={true}
                        options={nMcountry}
                        placeholder={t("If Other Countries, select country")}
                        val={selectedCountry.value}
                        label={selectedCountry.label}
                        disable={instiCountryDisable}
                        change={(val) => dropdownChange("insti_country", val)}
                        {...field}
                        refCallback={field?.ref}
                        ref={null}
                      />
                    )}
                  />

                  {errors && errors.insti_country && (
                    <ErrorField err={t(errors.insti_country.message)} />
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Controller
                    name="insti_name"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder={t("University")}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  {errors && errors.insti_name && (
                    <ErrorField err={t(errors.insti_name.message)} />
                  )}
                </FormGroup>
              </Col>
            </Row>
          </>
        )}

        {(formVal.insti_country_status === 1 && formVal.high_qualification !== 10 && formVal.high_qualification !== 11 && formVal.high_qualification !== 12) && (
          <>
            {" "}
            {/* study locally: show insti_name dropdown */}
            <Row>
              <Col>
                <FormGroup>
                  <Controller
                    name="insti_name"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        search={true}
                        options={institutionList}
                        placeholder={t("Select Name of Institution")}
                        val={selectedInstitution.value}
                        label={selectedInstitution.label}
                        change={(val) => dropdownChange("insti_name", val)}
                        {...field}
                        refCallback={field?.ref}
                        ref={null}
                      />
                    )}
                  />
                  {errors && errors.insti_name && (
                    <ErrorField err={t(errors.insti_name.message)} />
                  )}
                </FormGroup>
              </Col>
            </Row>
          </>
        )}
        <FormGroup>
          <Row>
            <Col>
              <FormGroup>
                <LabelText>{t(armyStaticContent.englishTestTitle)}</LabelText>
                <Controller
                  name="english_tests"
                  control={control}
                  render={({ field }) =>
                    armyStaticContent.tests.map((test, index) => (
                      <EnglishTestWrapper>
                        <RadioInput
                          key={test + index}
                          {...field}
                          checked={test === formVal.english_tests}
                          onChange={() => radioChange(test, "english_tests")}
                          label={t(test)}
                        />
                      </EnglishTestWrapper>
                    ))
                  }
                />
                {errors && errors.english_tests && (
                  <ErrorField err={t(errors.english_tests.message)} />
                )}
              </FormGroup>
              <FormGroup>
                <Controller
                  name="english_score"
                  control={control}
                  render={({ field }) => (
                    <>
                      {formVal.english_tests === "CEFR" ||
                        formVal.english_tests === "MUET" ? (
                        <Dropdown
                          options={
                            formVal.english_tests === "CEFR"
                              ? options1
                                ? options1.CEFR
                                : null
                              : options1
                                ? options1.MUET
                                : null
                          }
                          placeholder={t("Select")}
                          onFocus={() => {
                            getOptions(formVal.english_tests);
                          }}
                          val={
                            formVal.english_score
                              ? formVal.english_score
                              : englishScore
                          }
                          label={
                            formVal.english_score
                              ? formVal.english_score
                              : englishScore
                          }
                          change={(val) => dropdownChange("english_score", val)}
                          {...field}
                          refCallback={field?.ref}
                          ref={null}
                        />
                      ) : formVal.english_tests === "IELTS" ? (
                        <Input
                          placeholder={t("Please enter IELTS score")}
                          type="number"
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                            if (event.target.value.length > 3) {
                              event.preventDefault();
                            }
                          }}
                          min={thisIELTSMin}
                          max={thisIELTSMax}
                          step={thisIELTSMin}
                          value={formVal.english_score}
                          onChange={(e) => setEnglishValue(e.target.value)}
                          onWheel={numberInputOnWheelPreventChange}
                        />
                      ) : formVal.english_tests === "Others" ? (
                        <Input
                          placeholder={t("Please specify")}
                          type="text"
                          value={formVal.english_score}
                          onChange={(e) => setEnglishValue(e.target.value)}
                        />
                      ) : formVal.english_tests ===
                        "I have not taken any tests" ? (
                        <Input
                          placeholder="I have not taken any tests"
                          type="hidden"
                          value="I have not taken any tests"
                          onChange={(e) => setEnglishValue(e.target.value)}
                        />
                      ) : (
                        <Input
                          placeholder={t("Please enter TOEFL score")}
                          {...field}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          min={min}
                          max={max}
                          type="number"
                          onWheel={numberInputOnWheelPreventChange}
                        />
                      )}
                    </>
                  )}
                />

                {errors && errors.english_score && (
                  <ErrorField err={t(errors.english_score.message)} />
                )}
              </FormGroup>
            </Col>
          </Row>
        </FormGroup>
        {(formVal.high_qualification !== 10 && formVal.high_qualification !== 11 && formVal.high_qualification !== 12) && (
          <>
            {" "}
            <Row>
              <Col>
                <FormGroup>
                  <LabelText>{t(armyStaticContent.scope)}</LabelText>
                  <Controller
                    name="scope"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        search={true}
                        options={scope_of_studies}
                        placeholder={t("Please select")}
                        change={(val) => dropdownChange("scope", val)}
                        val={selectedScopeOfStudies.value}
                        label={selectedScopeOfStudies.label}
                        disabled={formVal.scope ? false : true}
                        {...field}
                        refCallback={field?.ref}
                        ref={null}
                      />
                    )}
                  />
                  {errors.scope && <ErrorField err={t(errors.scope.message)} />}
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <DividerLine />
              <Row>
                <Col>
                  <FormGroup>
                    <LabelText>{t(armyStaticContent.gradeTitle)}</LabelText>
                    <GradeWrapper>
                      <Controller
                        name="grade_status"
                        control={control}
                        render={({ field }) =>
                          armyStaticContent.grades.map((grade, index) => (
                            <RadioInput
                              key={grade + index}
                              {...field}
                              checked={grade === formVal.grade_status}
                              onChange={() => radioChange(grade, "grade_status")}
                              label={t(grade)}
                              margin="20px"
                            />
                          ))
                        }
                      />
                    </GradeWrapper>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <Controller
                      name="grade"
                      control={control}
                      render={({ field }) => (
                        <>
                          {gradeInput || formVal.grade_status == "Grades" ? (
                            <Dropdown
                              search={true}
                              options={grades}
                              placeholder={t("Please select a grade")}
                              change={(val) => dropdownChange("grade", val)}
                              val={selectedGrade?.value}
                              label={selectedGrade?.label}
                              disabled={formVal.grade ? false : true}
                              {...field}
                              refCallback={field?.ref}
                              ref={null}
                            />
                          ) : formVal.grade_status === "CGPA" ? (
                            <Input
                              placeholder={t("Please enter CGPA")}
                              type="number"
                              onKeyPress={(event) => {
                                if (!/[0-9.]/.test(event.key)) {
                                  event.preventDefault();
                                }
                                if (event.target.value.length > 3) {
                                  event.preventDefault();
                                }
                              }}
                              min={thisCgpaMin}
                              max={thisCgpaMax}
                              step={thisCgpaMin}
                              value={formVal.grade}
                              onChange={(e) => setGradeValue(e.target.value)}
                              onWheel={numberInputOnWheelPreventChange}
                            />
                          ) : formVal.grade_status === "Others" ? (
                            <Input
                              placeholder={t("Please specify")}
                              type="text"
                              value={formVal.grade}
                              onChange={(e) => setGradeValue(e.target.value)}
                            />
                          ) : formVal.grade_status === "No Current Grade" ? (
                            <Input
                              placeholder="No current grade"
                              type="hidden"
                              value="No current grade"
                              onChange={(e) => setGradeValue(e.target.value)}
                            />
                          ) : (
                            <Input
                              placeholder={t("Please enter")}
                              type="number"
                              onKeyPress={(event) => {
                                if (!/[0-9.]/.test(event.key)) {
                                  event.preventDefault();
                                }
                                if (event.target.value.length > 3) {
                                  event.preventDefault();
                                }
                              }}
                              min={thisCgpaMin}
                              max={thisCgpaMax}
                              step={thisCgpaMin}
                              value={formVal.grade}
                              onChange={(e) => setGradeValue(e.target.value)}
                              onWheel={numberInputOnWheelPreventChange}
                            />
                          )}
                        </>
                      )}
                    />
                    {errors && errors.grade && <ErrorField err={t(errors.grade.message)} />}
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>
          </>
        )}
        <FormGroup>
          <LabelText>{t("Pension Status")}*</LabelText>
          <ScholarshipWrapper>
            <Controller
              name="pension_status"
              control={control}
              render={({ field }) =>
                armyStaticContent.pension_status_options.map(
                  (options, index) => (
                    <RadioInput
                      key={options.label + index}
                      label={t(options.label)}
                      checked={options.value === formVal.pension_status}
                      onChange={() =>
                        radioChange(options.value, "pension_status")
                      }
                    />
                  )
                )
              }
            />
          </ScholarshipWrapper>
          {errors && errors.pension_status && <ErrorField err={t(errors.pension_status.message)} />}
        </FormGroup>

        <FormGroup>
          <LabelText>{t("Training Status in PERHEBAT")}*</LabelText>
          <Controller
            name="perhebat_training_status"
            control={control}
            render={({ field }) => (
              <Dropdown
                placeholder={t("Select Training Status")}
                options={perhebatTrainingOptions}
                label={selectedPerhebatTraining?.label}
                val={selectedPerhebatTraining?.value}
                change={(val) =>
                  dropdownChange("perhebat_training_status", val)
                }
              />
            )}
          />
          {errors && errors.perhebat_training_status && <ErrorField err={t(errors.perhebat_training_status.message)} />}
        </FormGroup>
        <FormGroup>
          <LabelText>{t("Retirement Year")}*</LabelText>
          <DatesWrapper>
            <Controller
              name="retirement_year"
              control={control}
              render={({ field }) => (
                <>
                  <label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={materialTheme}>
                        <DatePicker
                          autoOk
                          variant="inline"
                          format="dd/MM/yyyy" // Set format to display only the year
                          placeholder={t("dd/mm/yyyy")} // Placeholder text can be set to year format
                          views={["year", "month", "date"]} // Show only the year view
                          value={
                            formVal.retirement_year
                              ? formVal.retirement_year
                              : null
                          }
                          onChange={(val) => dateChange("retirement_year", val)}
                          fullWidth
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                    <CalenderIconWrapper>
                      <CalenderIcon />
                      {formVal.retirement_year && (
                        <IconButton onClick={(e) => clearDate(e, "retirement_year")}>
                          <img src={CancelIcon} alt="" />
                        </IconButton>
                      )}
                    </CalenderIconWrapper>
                  </label>
                </>
              )}
            />
          </DatesWrapper>
          {errors && errors.retirement_year && <ErrorField err={t(errors.retirement_year.message)} />}
        </FormGroup>

        <ButtonWrapper>
          <Button
            width="198px"
            onClick={handleSubmit(onSubmit, onErrorSubmit)}
            name={t("Continue")}
          />
          <Button
            width="198px"
            onClick={() => onNavigate("/aboutme")}
            secondary={true}
            name={t("Back")}
          />
        </ButtonWrapper>
        {/* {armyFormErrors && (
          <ErrorField
            err={t("Please make sure all the mandatory fields have been inserted")}
          />
        )} */}
      </FormWrapper>
    </UserCreationLayout>
  );
}

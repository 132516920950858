import styled from "styled-components";

// styling starts
const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 15px 0;
  line-height: 24px;
  padding: 6px 0px;
`;
// styling ends

export default function Loader() {
  return (
    <LoaderWrapper>
      <div className="spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    </LoaderWrapper>
  );
}

import { useEffect } from "react";

import styled from "styled-components";

import Sidebar from "../../components/sidebar/sidebar";

const PageContentSection = styled.div`
  overflow-y: auto;
  flex-grow: 2;
  padding: 4rem 3rem;
  &::-webkit-scrollbar {
    width: 2px;
  }
  @media (max-width: 1090px) {
    padding: 1rem 1rem;
  }
`;
const LayoutWrapper = styled.section`
  display: none;

  @media (max-width: 1024px) {
    display: flex;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 2;
  }
`;
const NavbarSection = styled.div`
  width: 346px;
  height: 100vh;
  background: #ffffff;
  border-radius: 0px 10px 10px 0px;
`;
export default function Menus({ children }) {
  useEffect(() => {
    document.body.classList.add("bg-body-cover");
  }, []);
  return (
    <LayoutWrapper>
      <NavbarSection>
        <Sidebar />
      </NavbarSection>
      <PageContentSection>{children}</PageContentSection>
    </LayoutWrapper>
  );
}

import { Modal } from "react-bootstrap";

function AlertCloseModal(props) {
  return (
    <div>
      <Modal show={props.show} onHide={props.close} centered size="md" contentClassName="modal-height">
        <Modal.Header
          closeButton
          style={{
            position: "absolute",
            width: "476px",
            height: "100px",
            left: "11px",
            top: "12px",
            background: "rgba(83, 80, 141, 0.1)",
            borderRadius: "24px 24px 0px 0px",
          }}
        >
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        
        <div style={{ padding: "40px 15px 10px", minHeight: "220px" }}>
          <div style={{ textAlign: "center" }}>{props.content}</div>
        </div>
        
      </Modal>
    </div>
  );
}

export default AlertCloseModal;

import React from 'react';
import styled from 'styled-components';

const PageWrapper = styled.div`
  margin-top: 40px;
  min-height: 80vh;
  padding: 0rem 25rem;
  @media (max-width: 767px) {
    padding: 0rem 0.8rem;
  }
`;

const HeaderText = styled.h2`
  font-weight: 600;
  font-size: 35px;
  line-height: 38px;
  color: #2d3843; /* Using your grey color */
  margin: 0 0 1.5rem 0;
`;

const StyledCardText = styled.div`
  font-size: 20px;
  color: #d04c8d; /* Using your primary color */
  font-family: "General Sans";
  font-weight: 500;
`;

const SubHeaderText = styled.h3`
  font-weight: 500;
  font-size: 24px;
  margin-top: 50px;

  line-height: 32px;
  display: flex;
  color: #d04c8d; /* Using your primary color */
  span {
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    padding-left: 0.5rem;
    color: #6f7e8c; /* Using your secondary color */
  }
`;

const SubHeaderText1 = styled.h3`
  font-weight: 500;
  font-size: 21px;
  line-height: 32px;
  display: flex;
  color: #2d3843; /* Using your grey color */
  span {
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    padding-left: 0.5rem;
    color: #6f7e8c; /* Using your secondary color */
  }
`;

const Cards = styled.div`
  background-color: white;
  width: 100%;
  margin-top: 20px;
  margin-bottom:20px;
  border-radius: 10px;
  padding: 25px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 3px 3px #a39c9c; /* Adding shadow */
  &:hover {
    box-shadow: 0px 5px 5px #a39c9c; /* Shadow on hover */
  }
`;

const AccountDeletionPage = () => {
  return (
    <PageWrapper>
      <HeaderText>Account Deletion Process for mynext Talent</HeaderText>
      <Cards>
        <StyledCardText>
          <strong>1. Navigate to Settings:</strong>
          <ul>
            <li>Open the mynext Talent app on your device.</li>
            <li>Tap on the "Settings" option in the menu.</li>
          </ul>
        </StyledCardText>
      </Cards>
      <Cards>
        <StyledCardText>
          <strong>2. Request Account Deletion:</strong>
          <ul>
            <li>Within the Settings menu, locate and select "Account Settings."</li>
            <li>Scroll down to find the "Delete Account" option.</li>
            <li>Tap on "Delete Account" to initiate the process.</li>
          </ul>
        </StyledCardText>
      </Cards>
      <Cards>
        <StyledCardText>
          <strong>3. Data Retention Policy:</strong>
          <ul>
            <li>We retain certain data for a period of 12 months after account deletion.</li>
            <li>The data retained includes assessment results.</li>
            <li>After 12 months, all retained data will be permanently deleted from our servers.</li>
          </ul>
        </StyledCardText>
      </Cards>

      <SubHeaderText>
        Please note that this process is irreversible. Once your account is deleted, all associated data will be
        removed according to our retention policy.
      </SubHeaderText>

      <SubHeaderText1>
        For any further assistance or inquiries, please contact our support team at{' '}
        <span>support@mynexttalent.com</span>.
      </SubHeaderText1>

      <SubHeaderText1>Thank you for using mynext Talent!</SubHeaderText1>
    </PageWrapper>
  );
};

export default AccountDeletionPage;

import { useState, useEffect } from "react";
import styled from "styled-components";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import { Colors } from "../../utilities/colors";

import CustomButton from "../../components/button/button";
import { makeStyles } from "@material-ui/core/styles";
import DialogModel from "./dialogBox";
import { useLocation, useParams } from "react-router-dom";
import { ReactComponent as ArrowBack } from "../../assets/svg/arrowBack.svg";
import InternIntroSection from "./internIntoSection/internIntroSection";
import InterShipCard from "./interShipCard/interShipCard";
import { ongettingInternInfo, internshipApply } from "../../services/apiCalls";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../components/loader/loader";
import ViewDetails from "./viewDetail/viewDetail";
import Menus from "../../components/hamburger/menus";
import HamburgerLay from "../../components/hamburger/hamburger";

import { useTranslation } from "react-i18next";

import { fetchPercentage } from "../../Redux/Reducers/ProfileReducer/ProfileReducer";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));
const HamburgerLayout = styled.div`
  @media (min-width: 1024px) {
    display: none;
  }
`;
const DashboardWrapper = styled.div`
  @media (max-width: 1024px) {
    display: flex;
  }
`;
const PageWrapper = styled.div`
  min-height: 80vh;
  padding: 0rem 3rem;
  @media (max-width: 767px) {
    padding: 0rem 0.8rem;
  }
`;

const SubheaderDiv = styled.div`
  display: flex;
  align-items: center;
  h3 {
    padding: 0px 3px;
    margin: 2px 0px;
  }
  .view {
    margin: 0px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-decoration-line: underline;
    color: rgb(208, 76, 141);
  }
`;

const SubHeaderText = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  cursor: pointer;
  color: ${Colors.primaryText};
`;

const RecommendedJobsCard = styled.div`
  background-color: white;
  margin-bottom: 30px;
  border-radius: 10px;
  padding: 25px;

  ul {
    font-weight: 500;
    font-size: 16px;
    font-family: General Sans;
  }
  ul li {
    margin: 0px 0px 10px;
  }
`;

const PdfText = styled.div`
  color: ${Colors.primaryText};
  text-decoration: underline;
  font-family: "General Sans";
  display: flex;
  p {
    padding-left: 0.5rem;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ButtonWrapper = styled.div`
  margin-right: 2rem;
`;

const TitleHeading = styled.div`
  font-size: 18px;
  font-family: "General Sans";
  font-weight: 500;
  margin-bottom: 10px;
`;

const ParagraphTag = styled.div`
  font-size: 16px;
  font-family: "General Sans";
  font-weight: 600;
  margin-bottom: 5px;
`;

const SpanTag = styled.div`
  display: inline-flex;
  font-size: 16px;
  font-family: "General Sans";
  font-weight: 400;
`;
const SaveText = styled.div`
  color: rgba(80, 79, 140, 1);
  font-weight: 600;
  font-size: 16px;
  font-family: General Sans;
  text-decoration: underline;
`;
const HeaderSection = styled.div`
  padding-bottom: 1rem;
  border-bottom: 1px solid ${Colors.labelText};
`;

export default function IntershipDetails({ getDatas, getInternshipStatus }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [internData, setInternData] = useState("");
  const [open, setOpen] = useState(false);
  const [isOpenham, setOpenHam] = useState(false);

  const search = useLocation().search;
  const { id } = useParams();

  // redirect to dashboard if has not completed assessment
  const completion = useSelector((state) => {
    return state.profile.profilePercentage;
  });
  const minCondition = 100; //minimum profile completion required to enable job/internship application
  const InternshipsMessage =
  "Please complete About Me, Personality & Motivation, Work Interests, and English Proficiency Assessments to use this feature";
  // if ((completion != null) & (completion < 100)) {
  //   navigate("/dashboard");
  // }

  useEffect(() => {
    dispatch(fetchPercentage());
  }, []);

  useEffect(() => {
    if (id) {
      getInternData();
    }
  }, [id]);

  const getInternData = async () => {
    setLoading(true);
    const { data, status } = await ongettingInternInfo({ id: id });
    if (data && data.status === 1) {
      setInternData(data.data);
      setLoading(false);
      await internshipApply({ viewed: true }, id);
    } else {
      setLoading(false);
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const { t } = useTranslation();

  return (
    <MainLayout>
      <HamburgerLayout>
        <HamburgerLay onToggle={() => setOpenHam(!isOpenham)} />
      </HamburgerLayout>
      {isOpenham && <Menus />}
      <PageWrapper>
        <SubheaderDiv>
          <div style={{ padding: " 0 4px" }}>
            <ArrowBack />
          </div>
          <SubHeaderText onClick={() => goBack()}>{t("Back")}</SubHeaderText>
        </SubheaderDiv>
        {loading ? (
          <Loader />
        ) : (
          <RecommendedJobsCard>
            <HeaderSection>
              <InternIntroSection
                internIntro={internData}
                getInternData={getInternData}
              />
              <InterShipCard data={internData} />
              {internData &&
              internData.status === "accepted" &&
              !["applied", "offered", "rejected"].includes(
                internData.talent_intern_status
              ) ? (
                <FlexDiv>
                  <ButtonWrapper>
                    <CustomButton
                      name={t("Apply Now")}
                      light
                      onClick={() => completion >= minCondition ? setOpen(!open) : toast.error(t(InternshipsMessage), {position: toast.POSITION.TOP_RIGHT,})}
                    />
                  </ButtonWrapper>
                </FlexDiv>
              ) : (
                <FlexDiv>
                  <ButtonWrapper>
                    <CustomButton
                      name={t("Applied")}
                      light
                    />
                  </ButtonWrapper>
                </FlexDiv>
              )}
            </HeaderSection>
            <ViewDetails data={internData} />
          </RecommendedJobsCard>
        )}

        <DialogModel
          open={open}
          onClose={() => {setOpen(false); getInternData()}}
          id={id}
          data={internData}
          getDatas={getDatas}
          getInternshipStatus={getInternshipStatus}
          orgNameFromList={"N/A"}
        />
      </PageWrapper>
    </MainLayout>
  );
}

import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import { Row } from "react-bootstrap";
import moment from "moment";
import { useTranslation } from "react-i18next";

import "./style.css";
const PreviewTimeline = ({ data }) => {
  const monthDiff = (d1, d2) => {
    d2 = new Date(d2);
    d1 = new Date(d1);
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  };

  const getDuration = (d1, d2) => {
    if(d2 < d1){
      return ""
    }
    d2 = new Date(d2);
    d1 = new Date(d1);
    
    const date1 = moment(d1).format("DD/MM/YYYY");
    const date2 = moment(d2).format("DD/MM/YYYY");
    
    var startDate = new Date(
      date1.split("/")[2],
      date1.split("/")[1] - 1,
      date1.split("/")[0]
    );
    var endDate = new Date(
      date2.split("/")[2],
      date2.split("/")[1] - 1,
      date2.split("/")[0]
    );

    var diff = new Date(endDate - startDate);
    var diffResult = (diff.getFullYear() - 1970) * 12 + diff.getMonth();
    if (diffResult === 0) {
      return t("1 Month");
    } else {
      return `${diffResult}` + t(" Months");
    }
  };
  
  const { t } = useTranslation();

  return (
    <div>
      <Row>
        <Timeline>
          {data &&
            data.length > 0 &&
            data.map((m, i) => {
              return (
                <TimelineItem key={i} className='remove-timeline-before-padding'>
                  <TimelineSeparator>
                    <TimelineDot style={{ background: i === 0 ? "#D04C8D" : "" }} />
                    {i < data.length - 1 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent
                    className='pt-0 text-capitalize '
                    style={{ display: "flex", width: "100%", justifyContent: "space-between" }}
                  >
                    <div>
                      <div className=' font-family-general-sans-medium font-color-dark-grey fw-500 pb-1'>
                        {m.role}
                      </div>
                      <div className='font-family-general-sans-medium font-color-pink fw-500 pb-1'>
                        {m.organization}
                      </div>
                      <div className='font-family-general-sans-medium font-color-light-grey fw-500'>
                        {m.location}
                      </div>
                    </div>
                    <p className='font-family-general-sans-regular font-color-light-grey'>
                      {/* {monthDiff(m.start_date, m.end_date)}&nbsp;&nbsp;
                      {monthDiff(m.start_date, m.end_date) > 1 ? "Months" : "Month"} */}
                      {getDuration(m.start_date, m.end_date)}

                    </p>
                  </TimelineContent>
                </TimelineItem>
              );
            })}
        </Timeline>
      </Row>
    </div>
  );
};

export default PreviewTimeline;

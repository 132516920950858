import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.bg ? props.bg : "#f3f3f3")};
  height: 186px;
  margin-right: 10%;
  border-radius: 15px;
  cursor: pointer;
  width: 100%;
  @media (max-width: 900px) {
    max-width: 320px;
    margin-right: 0%;
  }
`;
const CardText = styled.p`
  display: grid;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: center;
  color: #504f8c;
  span {
    font-size: 14px;
  }
`;
const AboutContentWrapper = styled.div`
  position: relative;

  align-itmes: center;
  @media (max-width: 900px) {
    top: -10px;
  }
`;
const ImgContainer = styled.div`
  width: 175px;
  position: relative;
`;

const AboutImage = styled.img`
  position: absolute;
  height: 200px;
  top: -5rem;
  left: 0;
  width: 100%;
  z-index: 0;
  @media (max-width: 900px) {
    height: 100px;
    left: 5px;
    width:auto;
  }
`;

export default function AboutmeCards(props) {
  const { t } = useTranslation();
  return (
    <CardWrapper bg={props.bg} onClick={props.onSelect}>
      <AboutContentWrapper>
        <Row>
          <Col lg="4">
            <ImgContainer>
              <AboutImage src={props.cardImage} alt="Cover image" />
            </ImgContainer>
          </Col>
          <Col lg="8">
            <CardText>
              {t(props.cardName)}
              <span>{t(props.descriptions)}</span>
            </CardText>
          </Col>
        </Row>
      </AboutContentWrapper>
    </CardWrapper>
  );
}

import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import { useTranslation } from "react-i18next";

const FlexWrapper = styled.div`
  display: flex;
`;
const SectionWrapper = styled.div`
  background: #fdfdfd;
  border: 1px solid rgba(228, 228, 228, 0.26);
  box-sizing: border-box;
  border-radius: 33px;
  padding: 25px;
  margin: 30px 0;
`;
const HeaderSection = styled(FlexWrapper)`
  align-items: center;
  border-bottom: 0.3px solid ${Colors.labelText};
  padding-bottom: 10px;
  > h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${Colors.primaryText};
    margin: 0;
  }
`;

const LocationSection = styled(FlexWrapper)`
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
`;
const SingleSection = styled.div`
  > h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #696969;
    margin: 0;
    margin-bottom: 10px;
    text-transform: capitalize;
  }
  > p {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin: 0;
    color: #202020;
    text-transform: capitalize;
  }
`;

export default function Location(props) {
  const {t} = useTranslation();
  const company = props.companyDetails
  const company_status = company.status

  return (
    <SectionWrapper style={{background:props?.primaryColor}}>
      <HeaderSection>
      <h3 style={{color:props?.secondaryColor}}>{t("Location")}</h3>
        
        </HeaderSection>
        {company && company.locations ? (
          company.locations.map((location, index) => (
            <>
              {location.status.toLowerCase() === "primary" ?
                <LocationSection key={location + index}>
                  <SingleSection>
                    <h3>{t(location.status)}</h3>
                    <p>
                      {t(location.rgtr_address)}, {t(location.city_abbr)}
                    </p>
                    <p>
                      {t(location.state_abbr)}, {t(location.country_abbr)}
                    </p>
                    <p>
                      {t(location.country_code)}
                    </p>
                    
                  </SingleSection>
                </LocationSection>
                :
                ""
              }
            </>
          ))
        ) : (
          <p><p class="noDataFound">{t('No data found')}</p></p>
        )}
        {company && company.locations ? (
          company.locations.map((location, index) => (
            <>
              {location.status.toLowerCase() === "secondary" ?
                <LocationSection key={location + index}>
                  <SingleSection>
                    <h3>{t(location.status)}</h3>
                    <p>
                      {t(location.rgtr_address)}, {t(location.city_abbr)}
                    </p>
                    <p>
                      {t(location.state_abbr)}, {t(location.country_abbr)}
                    </p>
                    <p>
                      {t(location.country_code)}
                    </p>
                  </SingleSection>
                </LocationSection>
                :
                ""
              }
            </>
          ))
        ) : (
          <p><p class="noDataFound">{t('No data found')}</p></p>
        )}
      </SectionWrapper>
  );
}

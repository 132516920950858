import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import AlertModal from "../../components/alertModal/alertModal";
import CustomButton from "../../components/button/button";
import { useTranslation } from "react-i18next";
import JobAlertModal from "../../components/jobAlertModal/jobAlertModal";
import Input from "../../components/input/input";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  onJobDocUpload,
  onJobDocUpdate,
  onJobDocDelete,
  getAppliedJobDocuments,
} from "../../services/apiCalls";
import { Colors } from "../../utilities/colors";
import { GrClose } from "react-icons/gr";
import "./jobapply.css";

const Message = styled.div`
  font-size: 22px;
  padding-bottom: 5px;
  margin-bottom: 20px;
`;
const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
  font-family: "General Sans";
  color: "black";
  padding-bottom: 5px;
  margin-bottom: 20px;
`;
const Heading = styled.p`
  padding-bottom: 5px;
  margin-bottom: 20px;
  color: #d04c8d;
`;
const Paragraph = styled.p`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #202020;
`;
const TitleHead = styled.p`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;

  color: #202020;
`;
const ChooseFile = styled.div`
  color: #d04c8d;
  text-decoration: underline;
  // cursor: pointer;
  position: relative;
  // padding: 0px 4rem;
  text-align: center;
  .fileInput {
    position: absolute;
    z-index: 1;
    opacity: 0;
    width: 60%;
    top: 0.5rem;
    left: 70px;
  }
  span {
    margin-left: 1rem;
  }
  .GrClose {
    position: absolute;
    right: 1rem;
    top: 0.5rem;
  }
  .GrClose path {
    stroke: rgba(172, 172, 172, 1);
  }
`;
export const Label = styled.div`
  font-family: General Sans;
  font-weight: 700;
  font-size: 16px;
  color: black;
  margin-bottom: 0.625rem;
  text-transform: capitalize;
`;

const Uploader = styled.div`
  position: relative;
  width: "100%";
  > input {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  > LinkText {
    cursor: pointer !important;
  }
`;
const LinkText = styled.label`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  display: block;
  color: #d04c8d;
  text-transform: capitalize;
  cursor: pointer !important;
`;
const JobConfirmationModal = ({
  id,
  show,
  onAccept,
  closeModal,
  message,
  title,
  heading,
  para,
  head,
  job,
  jobDocs,
}) => {
  const { t } = useTranslation();
  const [formDataForUpload, setFormDataForUpload] = useState(undefined);
  const [docDetails, setDocDetails] = useState([]);
  const [details, setJobDetails] = useState("");
  const [readyToUpload, setReadyToUpload] = useState(false);
  const [retrivedJobDocs, setRetrivedJobDocs] = useState([]);
  const [mandates, setMandates] = useState([]);

  function isEmpty(obj) {
    if (obj === undefined) return true;
    return Object.keys(obj).length === 0;
  }

  const uploadFile = async (event, jobData, file) => {
    const formdata = new FormData();
    const filesFormats = [
      "application/pdf",
      ".doc",
      ".docx",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ".pdf",
    ];
    let maxFileSize = 1024 * 1024 * 5; //5mb
    const isRightFormat = filesFormats.includes(event?.target?.files[0]?.type);
    if (!isRightFormat) {
      toast.error(t("Invalid File Format."), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (event?.target?.files[0]?.size > maxFileSize) {
      toast.error(t("File size exceeds maximum limit."), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      formdata.append("file_path", event?.target?.files[0]);
      formdata.append("job_id", jobData?.job);
      formdata.append("job_document_id", jobData?.id);
      formdata.append("file_name", event?.target?.files[0]?.name);

      setFormDataForUpload(formdata);
      setReadyToUpload(true);
    }
  };

  const deleteFile = async (event, jobData) => {
    const { data, status } = await onJobDocDelete({
      id: jobData.id,
    });
    if (data && data.status === 1) {
      toast.success(t(`Document Deleted successfully.`), {
        position: toast.POSITION.TOP_RIGHT,
      });
      getDocsIfAlreadyUploaded(true);
    } else {
      toast.error(t(`Document Not Found.`), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  function createComponents(retrived_docs) {
    let ret_job_doc_id_arr = retrived_docs.map((i) => {
      return i.job_document_id;
    });
    if (jobDocs && jobDocs.length > 0) {
      let docList = [];
      for (let i = 0; i < jobDocs.length; i++) {
        if (true) {
          //(ret_job_doc_id_arr.includes(jobDocs[i].id)) {
          let ret_idx = ret_job_doc_id_arr.indexOf(jobDocs[i].id);
          docList.push(
            <div style={{ paddingBottom: "40px" }}>
              <Row>
                <Col lg="6" sm="6">
                  <Label>
                    {t(jobDocs[i].document_type) +
                      t(jobDocs[i].mandatory === "Y" ? "*" : "")}
                  </Label>
                </Col>
                <Col lg="6" sm="6">
                  <ChooseFile>
                    <input
                      onChange={(e) => uploadFile(e, jobDocs[i])}
                      // accept=".doc,.docx,application/pdf,application/msword"
                      // accept=".doc,.docx,.pdf"
                      accept="application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      type="file"
                      className="fileInput"
                    />
                    <div
                      style={{
                        width: "100%",
                        overflow: "hidden",
                        cursor: "pointer",
                      }}
                      key={ret_idx}
                    >
                      {retrived_docs[ret_idx]?.file_name
                        ? retrived_docs[ret_idx]?.file_name
                        : t("Choose file")}
                    </div>
                    {retrived_docs[ret_idx]?.file_name && (
                      <GrClose
                        className="GrClose"
                        onClick={(e) => deleteFile(e, jobDocs[i])}
                      />
                    )}
                  </ChooseFile>
                </Col>
              </Row>
            </div>
          );
        }
      }
      setDocDetails(docList);
      // setJobDetails(docList);
    }
  }

  useEffect(() => {
    getDocsIfAlreadyUploaded();
    updateMandatoryJobDocs();
  }, [jobDocs]);

  useEffect(() => {
    if (readyToUpload) {
      uploadDocs();
    }
  }, [readyToUpload]);

  /*useEffect(() => {
    if (retrivedJobDocs.length == 0){
      getDocsIfAlreadyUploaded()
    }
  });*/

  function updateMandatoryJobDocs() {
    if (jobDocs && jobDocs.length > 0) {
      let arr = jobDocs.map((i) => {
        return i.mandatory == "Y" ? i.id : null;
      });
      arr = arr.filter(function (e) {
        return e;
      });
      setMandates(arr);
    }
  }

  function onSubmit() {
    onAccept(job.id);
  }

  const uploadDocs = async () => {
    let ret_job_doc_id_arr = retrivedJobDocs.map((i) => {
      return i.job_document_id;
    });
    if (
      !ret_job_doc_id_arr.includes(formDataForUpload.get("job_document_id"))
    ) {
      const { data, status } = await onJobDocUpload({
        data: formDataForUpload,
      });
      if (data && data.status === 1) {
        toast.success(t(`Document uploaded successfully.`), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      let idx = ret_job_doc_id_arr.indexOf(
        formDataForUpload.get("job_document_id")
      );
      id = retrivedJobDocs[idx].id;
      const { data, status } = await onJobDocUpdate({
        id: id,
        job_document_id: formDataForUpload.get("job_document_id"),
        data: formDataForUpload,
      });
      if (data && data.status === 1) {
        toast.success(t(`Document updated successfully.`), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
    getDocsIfAlreadyUploaded(true);
    setReadyToUpload(false);
    // uploadFile(false)
  };

  const getDocsIfAlreadyUploaded = async (byPassChecks = false) => {
    if ((!isEmpty(job) && retrivedJobDocs.length == 0) || byPassChecks) {
      const { data, status } = await getAppliedJobDocuments({
        job_id: job.id,
      });

      if (data && data.status === 1) {
        setRetrivedJobDocs(data.data);
        createComponents(data.data);
      }
    }
  };

  function handleClose() {
    setRetrivedJobDocs([]);
    closeModal();
  }

  function isDisabled() {
    if (
      mandates &&
      mandates.length > 0 &&
      retrivedJobDocs &&
      retrivedJobDocs.length == 0
    )
      return true;
    if (
      mandates &&
      mandates.length > 0 &&
      retrivedJobDocs &&
      retrivedJobDocs.length > 0
    ) {
      console.log(retrivedJobDocs);
      let ret_job_doc_id_arr = retrivedJobDocs.map((i) => {
        return i.job_document_id;
      });
      for (let i = 0; i < mandates.length; i++) {
        if (!ret_job_doc_id_arr.includes(mandates[i])) {
          return true;
        }
      }
    }
    return false;
  }

  let content = (
    <>
      <Title>{title}</Title>
      <Heading>{heading}</Heading>
      <Paragraph>{para}</Paragraph>
      <TitleHead>{head}</TitleHead>
      <Message>{message}</Message>

      <div>{docDetails && docDetails.length > 0 ? docDetails : <></>}</div>
      <div>
        <Col md="6" />
        <Col
          md="12"
          className="d-flex justify-content-center align-items-center"
          style={{ position: "relative", top: " 74px" }}
        >
          <CustomButton
            width="192px"
            name={t("Submit")}
            color="#FFFFFF"
            margin="20px 40px 30px 0px"
            onClick={onSubmit}
            disabled={isDisabled()}
            //onClick={() => onAccept(job.id)}
          />
          <CustomButton
            secondary
            width="192px"
            margin="20px 0px 30px 60px"
            name={t("Cancel")}
            onClick={handleClose}
          />
        </Col>
      </div>
    </>
  );
  return <JobAlertModal show={show} close={closeModal} content={content} />;
};

export default JobConfirmationModal;

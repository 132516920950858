import React from "react";
import { StudentAd2Card } from "./ad2Card";
import { Utils } from "../../utilities/utils";
import { Col } from "react-bootstrap";
import { FutureOfWorkSpeedometer } from "./futureOfWorkSpeedometer";
import { useTranslation } from "react-i18next";

export function Ad2FutureOfWork({data,onViewMoreClick}) {
    const chartData = data;
    const status = chartData && chartData.status;
    const total = chartData && chartData.totalScore;
    const cognitive = chartData && chartData?.domains?.Cognitive;
    const digital = chartData && chartData?.domains?.Digital;
    const selfLeadership = chartData && chartData?.domains?.['Self Leadership'];
    const interpersonal = chartData && chartData?.domains?.Interpersonal;
    const getLabels = (arr) => {
        return Utils.getLabels(arr).map(value => {
            return value.charAt(0).toUpperCase() + value.slice(1);
        })
    }
    const labelsSet1 = [...new Set([...getLabels(cognitive), ...getLabels(digital)])];
    const labelsSet2 = [...new Set([...getLabels(selfLeadership), ...getLabels(interpersonal)])];
    const { t, i18n } = useTranslation();
    const cardsData = [
        {
            title: t("Cognitive"),
            value: chartData && chartData?.domains?.["Cognitive"] && chartData?.domains?.["Cognitive"]?.points,
        },
        {
            title: t("Digital"),
            value: chartData && chartData?.domains?.["Digital"] && chartData?.domains?.["Digital"]?.points,
        },
        {
            title: t("Self Leadership"),
            value: chartData && chartData?.domains?.["Self Leadership"] && chartData?.domains?.["Self Leadership"]?.points,
        },
        {
            title: t("Interpersonal"),
            value: chartData && chartData?.domains?.["Interpersonal"] && chartData?.domains?.["Interpersonal"]?.points,
        },
    ];

    return <>
        <StudentAd2Card title={t("The Future Of Work")} status={status} onViewMoreClick={onViewMoreClick}>
            {
                cardsData.map((item, index) =>
                    <Col md="6" key={index}>
                        <FutureOfWorkSpeedometer {...item} />
                    </Col>)
            }
        </StudentAd2Card>
    </>;
}

import React from "react";
import styled from "styled-components";
import AlertCloseModal from "../../components/alertCloseModal/alertCloseModal";
import CustomButton from "../../components/button/button";
import { useTranslation } from "react-i18next";
import { Col } from "react-bootstrap";
const Title = styled.p`
  position: absolute;
  width: 142px;
  height: 22px;
  top: 25px;
  color: #d04c8d;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
`;
const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50px;
  height:150px
`;

const Messages = styled.div`
  position: absolute;
  height: 22px;
  top: 54px;
  font-weight:600;
  font-family: "General Sans";
  left: 22px;
`;

const GraduateConfirmationModal = ({
  id,
  show,
  onAccept,
  closeModal,
  message,
  title,
  text,
}) => {
  const { t } = useTranslation();
  let content = (
    <>
      <Title>{title}</Title>
      <Messages>{message}</Messages>
      <Message>{text}</Message>

      <div style={{ position: "relative", top: "70px" }}>
        <Col md="6" />
        <Col
          md="12"
          className="d-flex justify-content-center align-items-center" style={{position:"relative",
        top:"-55px"}}
        >
          <CustomButton
            width="192px"
            margin="20px 0px 30px 0px"
            name={t("Make Myself Visisble")}
            onClick={() => onAccept(id)}
          />
         
        </Col>
      </div>
    </>
  );
  return <AlertCloseModal show={show} close={closeModal} content={content} />;
};

export default GraduateConfirmationModal;

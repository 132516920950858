import { useState } from "react";

import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { useTranslation } from "react-i18next";

import UserCreationLayout from "../../layouts/userCreation/userCreationLayout";
import IntroSection from "../../components/introSection/introSection";
import Divider from "../../components/divider/divider";
import Input from "../../components/input/input";
import Button from "../../components/button/button";
import { Colors } from "../../utilities/colors";
import ErrorField from "../../components/errorField/errorField";
import { forgotPasswordEmail } from "../../services/apiCalls";
import Loader from "../../components/loader/loader";

// styling starts
const LoginPageWrapper = styled.section``;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (max-width: 650px) {
    display: block;
  }
`;
const LabelText = styled.label`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;

const SignUpBtn = styled(LabelText)`
  color: ${Colors.primary};
  text-decoration: underline;
  margin-left: 10px;
  cursor: pointer;
`;

const FormWrapper = styled.div`
  margin: 10px 0px;
  max-width: 440px;
  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;
const Label = styled.label`
  font-weight: 500;
  display: block;
`;
const FormLabel = styled(Label)`
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.labelText};
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const GroupWrapper = styled.div`
  margin-bottom: 20px;
`;
const UserHelpText = styled(Label)`
  color: ${Colors.inputHighlightText};
  font-size: 12px;
  line-height: 16px;
  margin-top: 10px;
  cursor: pointer;
  text-align: right;
  text-decoration: none;
`;
// styling ends

const schema = yup
  .object({
    email_address: yup
      .string()
      .email("The e-mail address entered is incorrect")
      .required("E-mail address is required."),
  })
  .required();

export default function Forget() {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email_address: "",
      en_type: "dGFsZW50",
    },
    resolver: yupResolver(schema),
  });

  const [formErrors, setFormErros] = useState("");
  const [loading, setLoader] = useState(false);

  const userDetail = watch();
  const navigate = useNavigate();

  const onNavigate = (path) => {
    navigate(path);
  };

  const onSubmit = async () => {
    setLoader(true);
    userDetail.email_address = userDetail.email_address.toLowerCase();
    const { data, status } = await forgotPasswordEmail(userDetail);
    if (data && data.status === 1) {
      setFormErros(data.message);
      navigate("/reset", { state: { name: userDetail } });
    } else {
      setFormErros(data && data.message);
    }
    setLoader(false);
  };

  const { t } = useTranslation();

  return (
    <UserCreationLayout>
      <HeaderWrapper onClick={() => onNavigate("/")}>
        <IoIosArrowBack color={Colors.primary} size={20} />
        <SignUpBtn>{t("Back")}</SignUpBtn>
      </HeaderWrapper>
      <IntroSection
        name={t("Reset Password")}
        description={t("Please provide the following details to reset your password.")}
      />
      <Divider />
      <FormWrapper>
        <GroupWrapper>
          <FormLabel>{t("Email address*")}</FormLabel>
          <Controller
            name="email_address"
            control={control}
            render={({ field }) => (
              <Input
                placeholder={t("Enter email address")}
                type="email"
                {...field}
              />
            )}
          />
          {errors.email_address && (
            <ErrorField err={t(errors.email_address.message)} />
          )}
        </GroupWrapper>
        {formErrors && <ErrorField err={t(formErrors)} />}
        {loading ? (
          <Loader />
        ) : (
          <Button
            className="w-100"
            onClick={handleSubmit(onSubmit)}
            name={t("Send Verification Code")}
          />
        )}
      </FormWrapper>
    </UserCreationLayout>
  );
}

import styled from "styled-components";
import InternLogoDefault from "../../../assets/icons/svg/favicon.svg";
import { toast } from "react-toastify";
import { Colors } from "../../../utilities/colors";
import { Row, Col } from "react-bootstrap";
import { ReactComponent as FavouriteIcon } from "../../../assets/icons/svg/startPink.svg";
import { ReactComponent as UnfavouriteIcon } from "../../../assets/icons/svg/starGray.svg";
import { internshipApply } from "../../../services/apiCalls";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FlexWrapper = styled.div`
  margin-bottom: 1.825rem;
`;
const Line = styled.label`
  font-family: General Sans;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: right;
  text-decoration: underline;
  color: #d04c8d;
  cursor: pointer;
`;

const CompanyName = styled.div`
  font-family: General Sans;
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 7px;
  color: ${Colors.primaryText};
`;

const CompanyAddress = styled.div`
  font-family: General Sans;
  font-weight: 500;
  font-size: 18px;
  color: #525252;
`;
const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ImgContainer = styled.div`
  @media (max-width: 1200px) {
    width: 150px;
  }
  border-radius: 50%;
`;
const JobTitle = styled.div`
  display: flex;
  font-family: General Sans;
  font-weight: 500;
  font-size: 22px;
`;
const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

const IconWrapper = styled.div`
  margin-left: 1rem;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &.pointer {
    cursor: pointer;
  }
`;

const TextContainer = styled.div`
  margin-top: 1rem;
`;
export default function InternIntroSection({ internIntro, getInternData }) {
  const org = internIntro.org_details;
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const { t } = useTranslation();
  const addFavourite = async () => {
    if (
      ["applied", "offered", "rejected"].includes(
        internIntro.talent_intern_status
      )
    ) {
    } else {
      const { data } = await internshipApply(
        {
          favouriteStatus:
            internIntro.talent_favourite_status === "favourite"
              ? "unfavourite"
              : "favourite",
        },
        id
      );
      if (data && data.status === "1") {
        if (internIntro.talent_favourite_status === "favourite") {
          toast.success(t("Internship is removed from favourite!"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (internIntro.talent_favourite_status === "unfavourite") {
          toast.success(t("Internship is added to favourite!"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
        }
        getInternData();
      }
    }
  };

  return (
    <FlexWrapper>
      <Row>
        <Col xl="2" lg="12">
          <ImgContainer>
            <StyledImage
              src={
                org?.logo_path
                  ? org?.logo_path
                  : InternLogoDefault
              }
            />
          </ImgContainer>
        </Col>
        <Col xl="10">
          <TextContainer>
            <FlexDiv>
              <JobTitle>
                {internIntro.title}
                <IconWrapper
                  onClick={addFavourite}
                  className={
                    ["applied", "offered", "rejected"].includes(
                      internIntro.talent_intern_status
                    )
                      ? ""
                      : "pointer"
                  }
                >
                  {internIntro.talent_favourite_status === "favourite" ? (
                    <FavouriteIcon />
                  ) : (
                    <UnfavouriteIcon />
                  )}
                </IconWrapper>
              </JobTitle>
              {/* <Line>Edit</Line> */}
            </FlexDiv>
            <CompanyName>{org && org.name}</CompanyName>
            <CompanyAddress>
              📍{(org?.city, org?.state, org?.country_abbr)}
            </CompanyAddress>
          </TextContainer>
        </Col>
      </Row>
    </FlexWrapper>
  );
}


import styled from "styled-components";
import { Colors } from "../../utilities/colors";

const FlexWrapper = styled.div`
  display: flex;
`;
const PageWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 10px;
  padding: 2rem;
  margin: 2rem 0;

  @media (max-width: 500px) {
    padding: 0.5rem;
    margin: 0.5rem 0;
  }
`;

const SectionWrappper = styled(FlexWrapper)`
  display: flex;
  @media (max-width: 725px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
const Image = styled.img`
  width: 210px;
  height: 210px;
  border-radius: 50%;
  margin-bottom: 20px;
`;
const ContentWrapper = styled.div`
  margin-left: 10px;
  width: 70%;
  > h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: ${Colors.labelText};
    margin: 0;
    margin-bottom: 5px;
    text-transform: capitalize;
  }
  > h {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;

    color: #525252;
  }
  > p {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${Colors.labelText};
  }
  > p {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${Colors.labelText};
  }
  @media (max-width: 500px) {
    margin-left: 0px;
    width: 100%;
  }
`;
const DividerLine = styled.hr`
  margin: 10px 0;
  border: 0.4px solid ${Colors.labelText};
`;


const LinkText = styled.label`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  display: block;
  color: #d04c8d;
  text-transform: capitalize;
`;
const ImageWrapper = styled.div`
  text-align: center;
  width: 30%;
  min-width: 210px;
`;

const HeaderSection = styled.div`
  display: grid;
  margin: 0;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  > p {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${Colors.labelText};
    margin: 0;
  }
`;


export default function MyCompanyCard(props) {
  const company = props?.companyDetails
  const rgtr_address =
    company.locations && company.locations.length > 0
      ? company.locations[0].status === "primary"
        ? company.locations[0].rgtr_address
        : company.locations[1].status === "primary"
          ? company.locations[1].rgtr_address
          : ""
      : "";
  const state =
    company.locations && company.locations.length > 0
      ? company.locations[0].status === "primary"
        ? company.locations[0].state_abbr
        : company.locations[1].status === "primary"
          ? company.locations[1].state_abbr
          : ""
      : "";

  const city =
    company.locations && company.locations.length > 0
      ? company.locations[0].status === "primary"
        ? company.locations[0].city_abbr
        : company.locations[1].status === "primary"
          ? company.locations[1].city_abbr
          : ""
      : "";

  const country =
    company.locations && company.locations.length > 0
      ? company.locations[0].status === "primary"
        ? company.locations[0].country_abbr
        : company.locations[1].status === "primary"
          ? company.locations[1].country_abbr
          : ""
      : "";

  return (
    <div>

      <PageWrapper>
        <SectionWrappper>
          <ImageWrapper className="col-md-4">
            <Image
              src={
                company?.logo_path
              }
              alt="company logo"
            />

          </ImageWrapper>
          <ContentWrapper >
            <h3>{company?.name}</h3>
            <p>
              SSM: {company.ssm_number}
            </p>
            <p>
              {company && rgtr_address} , {city}, {state}
            </p>
            <HeaderSection>
              {company && <p> {country}</p>}
              
            </HeaderSection>

            <DividerLine />
            <p>{company && company.moto}</p>
            <br />

            <h>Type Of Organization</h>
            <br />
            <LinkText>{company && company.type_of_org_abbr === "N/A"
              ? company.type_of_org
              : company.type_of_org_abbr}</LinkText>
            <br />
            <h>Sector</h>
            <LinkText>
              {company && company.sector_abbr === "N/A"
                ? company.sector
                : company.sector_abbr}
            </LinkText>
            <br />
            
          </ContentWrapper>
          
        </SectionWrappper>
      </PageWrapper>
    </div>
  );
}

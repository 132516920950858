import React from "react";
import { Col, Row } from "react-bootstrap";
import { Student, StudentCoachingProfileBoxTitle } from "../styles/Ad2Styles";
import ReactSpeedometer from "react-d3-speedometer";
import { useTranslation } from "react-i18next";

export function FutureOfWorkSpeedometer({ value, title }) {
    const { t, i18n } = useTranslation();
    return <>
        <Row style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <Col xs="12" style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '30px 0px 10px 0px'
            }}>
                <ReactSpeedometer
                    currentValueText=""
                    height={170}
                    width={280}
                    minValue={0}
                    maxValue={1}
                    ringWidth={50} //thickness
                    value={parseInt(value) / 100} 
                    needleColor="#542F41"
                    segmentColors={["#FF75B9","#D24C8E","#9B3668"]}
                    valueFormat=".0%"
                    needleHeightRatio={0.7}
                    customSegmentStops={[0, 0.28, 0.73, 1.0]}
                    customSegmentLabels={[
                        {
                            text: t("Low"),
                            position: "OUTSIDE",
                            color: "#555",
                        },
                        {
                            text: t("Moderate"),
                            position: "OUTSIDE",
                            color: "#555",
                        },
                        {
                            text: t("High"),
                            position: "OUTSIDE",
                            color: "#555",
                        }
                    ]}
                />
            </Col>
            <Col xs="12">
                <StudentCoachingProfileBoxTitle>
                    <span>{t(title)}</span>
                </StudentCoachingProfileBoxTitle>
            </Col>
        </Row>
    </>;
}
import { useEffect, useState } from "react";

import { Bar } from "react-chartjs-2";
import ReactSpeedometer from "react-d3-speedometer";
import PropTypes from "prop-types";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { HrLine, StudentModalHeader } from "../commonStyles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function FutureWorkFactorModal(props) {
  const { t } = useTranslation();

  const [usableData, setUsableData] = useState([]);
  const [, setUsableDataLabels] = useState([]);
  const [meterOne, setMeterOne] = useState([]);
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // const color = [
  //   ["#504F8C", "#D04C8D"],
  //   ["#0656f8", "#00ffb4"],
  //   ["#ff0000", "#ffa500"],
  //   ["#56bb0f", "#ebff00"],
  //   ["#F9E105", "#DDBC11"],
  //   ["#ff004d", "#0200fe"],
  // ];
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "",
      },
    },
    scales: {
      y: {
        max: 100,
      },
    },
  };

  const data = {
    labels: [t("future of work")],
    datasets: usableData,
  };

  const fethUpdateData = () => {
    var datasLabel = [];
    var datasMeters = [];
    var mainData = [];
    Object.keys(props?.data).forEach((v, ist) => {
      datasMeters.push(parseInt(props?.data[v]["points"]));
      var x = Math.floor(Math.random() * 128 + 64);
      var y = Math.floor(Math.random() * 128 + 64);
      var z = Math.floor(Math.random() * 128 + 64);
      var bar_color = "rgba(" + x + "," + y + "," + z + ",0.5)";

      Object.keys(props?.data[v]["values"]).forEach((e, i) => {
        mainData.push({
          label: t(e),
          data: [props?.data[v]["values"][e]["points"]],
          backgroundColor: bar_color,
        });
        datasLabel.push(e);
      });
    });
    setUsableData(mainData);
    setMeterOne(datasMeters);
    setUsableDataLabels(datasLabel);
  };
  useEffect(() => {
    fethUpdateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <StudentModalHeader>{t("Future of Work")}</StudentModalHeader>
      <HrLine />
      <br />
      <br />
      <div className="row">
        <div className="col-md-6">
          {/* <HalfGaugeChart
              range={[0, 50]}
              gradient={color[0] ?? []}
              currentValue={40}
            />{" "} */}
          <div className="" style={{ textAlign: "center" }}>
            <ReactSpeedometer
              currentValueText={t("Cognitive")}
              width={270}
              height={220}
              minValue={0}
              maxValue={1}
              value={meterOne[0] / 100 ?? 0}
              startColor="#FFB1C1"
              endColor="#9AD0F5"
              valueFormat=".0%"
              customSegmentStops={[0, 0.33, 0.67, 1.0]}
              customSegmentLabels={[
                {
                  text: t("low"),
                  position: "OUTSIDE",
                  color: "#555",
                },
                {
                  text: t("moderate"),
                  position: "OUTSIDE",
                  color: "#555",
                },
                {
                  text: t("high"),
                  position: "OUTSIDE",
                  color: "#555",
                },
              ]}
            />
          </div>
        </div>
        <div className="col-md-6">
          {/* <HalfGaugeChart
              range={[0, 50]}
              gradient={color[1] ?? []}
              currentValue={20}
            />{" "} */}
          <div className="mx-2" style={{ textAlign: "center" }}>
            <ReactSpeedometer
              currentValueText={t("Digital")}
              width={270}
              height={220}
              minValue={0}
              maxValue={1}
              value={meterOne[1] / 100 ?? 0}
              startColor="#FFB1C1"
              endColor="#9AD0F5"
              valueFormat=".0%"
              customSegmentStops={[0, 0.33, 0.67, 1.0]}
              customSegmentLabels={[
                {
                  text: t("low"),
                  position: "OUTSIDE",
                  color: "#555",
                },
                {
                  text: t("moderate"),
                  position: "OUTSIDE",
                  color: "#555",
                },
                {
                  text: t("high"),
                  position: "OUTSIDE",
                  color: "#555",
                },
              ]}
            />
          </div>
        </div>
        <div className="col-md-6">
          {/* <HalfGaugeChart
              range={[0, 50]}
              gradient={color[2] ?? []}
              currentValue={11}
            /> */}
          <div className="mx-2" style={{ textAlign: "center" }}>
            <ReactSpeedometer
              currentValueText={t("Interpersonal")}
              width={270}
              height={220}
              minValue={0}
              maxValue={1}
              value={meterOne[2] / 100 ?? 0}
              startColor="#FFB1C1"
              endColor="#9AD0F5"
              valueFormat=".0%"
              customSegmentStops={[0, 0.33, 0.67, 1.0]}
              customSegmentLabels={[
                {
                  text: t("low"),
                  position: "OUTSIDE",
                  color: "#555",
                },
                {
                  text: t("moderate"),
                  position: "OUTSIDE",
                  color: "#555",
                },
                {
                  text: t("high"),
                  position: "OUTSIDE",
                  color: "#555",
                },
              ]}
            />
          </div>
        </div>
        <div className="col-md-6">
          {/* <HalfGaugeChart
              range={[0, 50]}
              gradient={color[2] ?? []}
              currentValue={11}
            /> */}
          <div className="mx-2" style={{ textAlign: "center" }}>
            <ReactSpeedometer
              currentValueText={t("Self Leadership")}
              width={270}
              height={220}
              minValue={0}
              maxValue={1}
              value={meterOne[3] / 100 ?? 0}
              startColor="#FFB1C1"
              endColor="#9AD0F5"
              valueFormat=".0%"
              customSegmentStops={[0, 0.33, 0.67, 1.0]}
              customSegmentLabels={[
                {
                  text: t("low"),
                  position: "OUTSIDE",
                  color: "#555",
                },
                {
                  text: t("moderate"),
                  position: "OUTSIDE",
                  color: "#555",
                },
                {
                  text: t("high"),
                  position: "OUTSIDE",
                  color: "#555",
                },
              ]}
            />
          </div>
        </div>
      </div>

      <Bar options={options} data={data} />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            aria-label="visible arrows tabs example"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                "&.Mui-disabled": { opacity: 0.3 },
              },
            }}
            centered
          >
            {Object.keys(props.data).map((v, ist) => {
              return <Tab label={t(v)} {...a11yProps(ist)} />;
            })}
          </Tabs>
        </Box>

        {props.data["Cognitive"] && props.data["Cognitive"] !== undefined ? (
          <TabPanel value={value} index={0}>
            <h3>{t("Cognitive")}</h3>

            <h5>{t("Domains")}</h5>

            <h6 style={{ color: "rgb(208, 76, 141)" }}>
              {t("Communication")} -{" "}
              {t(props.data["Cognitive"]["values"]["Communication"]["score"])}{" "}
            </h6>
            {/* High */}
            {props.data["Cognitive"]["values"]["Communication"]["score"] &&
            props.data["Cognitive"]["values"]["Communication"]["score"] ===
              "High" ? (
              <li>
                {t(
                  "This skill positions the talent well to access exciting opportunities in the world of work for the future. Being able to understand key messages and communicate them to others in ways that they can easily understand, listen well and understand the views of others are all important skills in the workplace. Asking the right questions so that the talent has all the information he/she needs when starting a new piece of work is also important. Being able to speak in public could be an important skill to acquire."
                )}
              </li>
            ) : (
              <></>
            )}

            {/* Moderate */}
            {props.data["Cognitive"]["values"]["Communication"]["score"] ===
            "Moderate" ? (
              <li>
                {t(
                  "Developing this skill will position the talent well to access opportunities in the world of work for the future. Being able to understand key messages and communicate them to others in ways that they can easily understand, listen well and understand the views of others are all important skills in the workplace. Asking the right questions so that the talent has all the information he/she needs when starting a new piece of work is also important. Being able to speak in public could be an important skill to acquire."
                )}
              </li>
            ) : (
              <></>
            )}

            {/* Low */}
            {props.data["Cognitive"]["values"]["Communication"]["score"] ===
            "Low" ? (
              <li>
                {t(
                  "This is an area that requires some attention for the talent. Being able to understand key messages and communicate them to others in ways that they can easily understand, listen well and understand the views of others are all important skills in the workplace. Asking the right questions so that the talent has all the information he/she needs when starting a new piece of work is also important. Being able to speak in public could be an important skill to acquire. The talent must take every opportunity to develop these skills to enhance his/her prospects for the world of work in the future."
                )}
              </li>
            ) : (
              <></>
            )}

            <br />

            <h6 style={{ color: "rgb(208, 76, 141)" }}>
              {t("Critical Thinking")} -{" "}
              {t(
                props.data["Cognitive"]["values"]["Critical Thinking"]["score"]
              )}
            </h6>
            {/* High */}
            {props.data["Cognitive"]["values"]["Critical Thinking"]["score"] ===
            "High" ? (
              <li>
                {t(
                  "This skill positions talents well to access exciting opportunities in the world of work for the future. Being able tosolve problems using logical thinking, obtaining relevant information and having an awareness of the impact of cognitive biases is important in the modern workplace. Weighing up the advantages and disadvantages of a decision or course of action is also an important skill."
                )}
              </li>
            ) : (
              <></>
            )}

            {/* Moderate */}
            {props.data["Cognitive"]["values"]["Critical Thinking"]["score"] ===
            "Moderate" ? (
              <li>
                {t(
                  "Developing this skill will position the talent well to access opportunities in the world of work for the future. Being able to solve problems using logical thinking, obtaining relevant information and having an awareness of the impact of cognitive biases is important in the modern workplace. Weighing up the advantages and disadvantages of a decision or course of action is also an important skill."
                )}
              </li>
            ) : (
              <></>
            )}

            {/* Low */}
            {props.data["Cognitive"]["values"]["Critical Thinking"]["score"] ===
            "Low" ? (
              <li>
                {t(
                  "This is an area that requires some attention for the talent. Being able to solve problems using logical thinking, obtaining relevant information and having an awareness of the impact of cognitive biases is important in the modern workplace. Weighing up the advantages and disadvantages of a decision or course of action is also an important skill. The talent must take every opportunity to develop these skills to enhance his/her prospects for the world of work in the future."
                )}
              </li>
            ) : (
              <></>
            )}

            <br />

            <h6 style={{ color: "rgb(208, 76, 141)" }}>
              {t("Mental Flexibility")} -{" "}
              {t(
                props.data["Cognitive"]["values"]["Mental Flexibility"]["score"]
              )}{" "}
            </h6>

            {/* High */}
            {props.data["Cognitive"]["values"]["Mental Flexibility"][
              "score"
            ] === "High" ? (
              <li>
                {t(
                  "This skill positions the talent well to access exciting opportunities in the world of work for the future. Being able to use creativity and imagination to help solve problems or come up with new ideas, to use learning from one area and apply it to another area for positive results, to see a situation from many perspectives and to be open to new learning are all important skills in the workplace. To use these effectively, one should easily adapt to change."
                )}
              </li>
            ) : (
              <></>
            )}

            {/* Moderate */}
            {props.data["Cognitive"]["values"]["Mental Flexibility"][
              "score"
            ] === "Moderate" ? (
              <li>
                {t(
                  "Developing this skill will position the talent well to access opportunities in the world of work for the future. Being able to use creativity and imagination to help solve problems or come up with new ideas, to use learning from one area and apply it to another area for positive results, to see a situation from many perspectives and to be open to new learning are all important skills in the workplace. To use these effectively, one should easily adapt to change."
                )}
              </li>
            ) : (
              <></>
            )}

            {/* Low */}
            {props.data["Cognitive"]["values"]["Mental Flexibility"][
              "score"
            ] === "Low" ? (
              <li>
                {t(
                  "This is an area that requires some attention for the talent. Being able to use creativity and imagination to help solve problems or come up with new ideas, to use learning from one area and apply it to another area for positive results, to see a situation from many perspectives and to be open to new learning are all important skills in the workplace. To use these effectively, one should easily adapt to change. The talent must take every opportunity to develop these skills to enhance his/her prospects for the world of work in the future."
                )}
              </li>
            ) : (
              <></>
            )}

            <br />

            <h6 style={{ color: "rgb(208, 76, 141)" }}>
              {t("Planning and Ways of Working")} -
              {t(
                props.data["Cognitive"]["values"][
                  "Planning and Ways of Working"
                ]["score"]
              )}
            </h6>
            {/* High */}
            {props.data["Cognitive"]["values"]["Planning and Ways of Working"][
              "score"
            ] === "High" ? (
              <li>
                {t(
                  "This skill positions the talent well to access exciting opportunities in the world of work for the future. Being able to plan and prioritise one’s work efficiently to ensure its completion is important in the modern workplace. When starting a project, creating a plan to ensure that one stays on track, yet being flexible to switch tasks if required is also an important skill."
                )}
              </li>
            ) : (
              <></>
            )}

            {/* Moderate */}
            {props.data["Cognitive"]["values"]["Planning and Ways of Working"][
              "score"
            ] === "Moderate" ? (
              <li>
                {t(
                  "Developing this skill will position the talent well to access opportunities in the world of work for the future. Being able to plan and prioritise one’s work efficiently to ensure its completion is important in the modern workplace. When starting a project, creating a plan to ensure that one stays on track, yet being flexible to switch tasks if required is also an important skill."
                )}
              </li>
            ) : (
              <></>
            )}

            {/* Low */}
            {props.data["Cognitive"]["values"]["Planning and Ways of Working"][
              "score"
            ] === "Low" ? (
              <li>
                {t(
                  "This is an area that requires some attention for the talent. Being able to plan and prioritise your work efficiently to ensure its completion is important in the modern workplace. When starting a project, creating a plan to ensure that you stay on track, yet being flexible to switch tasks if required is also an important skill. The talent must take every opportunity to develop these skills to enhance his/her prospects for the world of work in the future."
                )}
              </li>
            ) : (
              <></>
            )}

            <br />
          </TabPanel>
        ) : (
          <></>
        )}
      </Box>
      {props.data["Interpersonal"] &&
      props.data["Interpersonal"] !== undefined ? (
        <TabPanel value={value} index={1}>
          <h3>{t("Interpersonal")}</h3>

          <h6 style={{ color: "rgb(208, 76, 141)" }}>
            {t("Coaching Others")} -{" "}
            {t(
              props.data["Interpersonal"]["values"]["Coaching Others"]["score"]
            )}{" "}
          </h6>
          {/* High */}
          {props.data["Interpersonal"]["values"]["Coaching Others"]["score"] ===
          "High" ? (
            <li>
              {t(
                "This skill positions the talent well to access exciting opportunities in the world of work for the future. Being able to help others think about what they want to achieve, to empower them by helping them focus on their strengths, to help set goals for themselves and to facilitate the development of their skills and knowledge are all important coaching skills needed in the workplace today."
              )}
            </li>
          ) : (
            <></>
          )}

          {/* Moderate */}
          {props.data["Interpersonal"]["values"]["Coaching Others"]["score"] ===
          "Moderate" ? (
            <li>
              {t(
                "Developing this skill will position the talent well to access opportunities in the world of work for the future. Being able to help others think about what they want to achieve, to empower them by helping them focus on their strengths, to help set goals for themselves and to facilitate the development of their skills and knowledge are all important coaching skills needed in the workplace today."
              )}
            </li>
          ) : (
            <></>
          )}

          {/* Low */}
          {props.data["Interpersonal"]["values"]["Coaching Others"]["score"] ===
          "Low" ? (
            <li>
              {t(
                "This is an area that requires some attention for the talent. Being able to help others think about what they want to achieve, to empower them by helping them focus on their strengths, to help set goals for themselves and to facilitate the development of their skills and knowledge are all important coaching skills needed in the workplace today. The talent must take every opportunity to develop these skills to enhance his/her prospects for the world of work in the future."
              )}
            </li>
          ) : (
            <></>
          )}

          <br />

          <h6 style={{ color: "rgb(208, 76, 141)" }}>
            {t("Developing Relationships")} -
            {t(
              props.data["Interpersonal"]["values"]["Developing Relationships"][
                "score"
              ]
            )}
          </h6>
          {/* High */}
          {props.data["Interpersonal"]["values"]["Developing Relationships"][
            "score"
          ] === "High" ? (
            <li>
              {t(
                "This skill positions the talent well to access exciting opportunities in the world of work for the future. Being able to have empathy for others by putting oneself in their position to understand their point of view, build trusting relationship with others by being open, honest and supportive and demonstrate humility in one’s interactions with others are important skills in the workplace. Being sociable opens the way to building relationships with work colleagues or clients."
              )}
            </li>
          ) : (
            <></>
          )}

          {/* Moderate */}
          {props.data["Interpersonal"]["values"]["Developing Relationships"][
            "score"
          ] === "Moderate" ? (
            <li>
              {t(
                "Developing this skill will position the talent well to access opportunities in the world of work for the future. Being able to have empathy for others by putting oneself in their position to understand their point of view, build trusting relationship with others by being open, honest and supportive and demonstrate humility in one’s interactions with others are important skills in the workplace. Being sociable opens the way to building relationships with work colleagues or clients."
              )}
            </li>
          ) : (
            <></>
          )}

          {/* Low */}
          {props.data["Interpersonal"]["values"]["Developing Relationships"][
            "score"
          ] === "Low" ? (
            <li>
              {t(
                "This is an area that requires some attention for the talent. Being able to have empathy for others by putting oneself in their position to understand their point of view, build trusting relationship with others by being open, honest and supportive and demonstrate humility in one’s interactions with others are important skills in the workplace. Being sociable opens the way to building relationships with work colleagues or clients. The talent must take every opportunity to develop these skills to enhance his/her prospects for the world of work in the future."
              )}
            </li>
          ) : (
            <></>
          )}

          <br />

          <h6 style={{ color: "rgb(208, 76, 141)" }}>
            {t("Effective Teamwork")} -{" "}
            {t(
              props.data["Interpersonal"]["values"]["Effective Teamwork"][
                "score"
              ]
            )}{" "}
          </h6>
          {/* High */}
          {props.data["Interpersonal"]["values"]["Effective Teamwork"][
            "score"
          ] === "High" ? (
            <li>
              {t(
                "This skill positions the talent well to access exciting opportunities in the world of work for the future. Being able to work with a diverse group of people towards a common goal and ensure that they feel included and their views are heard is important in the workplace today. Getting to know people as individuals, understanding what motivates them and supporting them to resolve conflicts are significant skills for the workplace."
              )}
            </li>
          ) : (
            <></>
          )}

          {/* Moderate */}
          {props.data["Interpersonal"]["values"]["Effective Teamwork"][
            "score"
          ] === "Moderate" ? (
            <li>
              {t(
                "Developing this skill will position the talent well to access opportunities in the world of work for the future. Being able to work with a diverse group of people towards a common goal and ensure that they feel included and their views are heard is important in the workplace today. Getting to know people as individuals, understanding what motivates them and supporting them to resolve conflicts are significant skills for the workplace."
              )}
            </li>
          ) : (
            <></>
          )}

          {/* Low */}
          {props.data["Interpersonal"]["values"]["Effective Teamwork"][
            "score"
          ] === "Low" ? (
            <li>
              {t(
                "This is an area that requires some attention for the talent. Being able to work with a diverse group of people towards a common goal and ensure that they feel included and their views are heard is important in the workplace today. Getting to know people as individuals, understanding what motivates them and supporting them to resolve conflicts are significant skills for the workplace. The talent must take every opportunity to develop these skills to enhance his/her prospects for the world of work in the future."
              )}
            </li>
          ) : (
            <></>
          )}

          <br />

          <h6 style={{ color: "rgb(208, 76, 141)" }}>
            {t("Mobilising Systems")} -{" "}
            {t(
              props.data["Interpersonal"]["values"]["Mobilising Systems"][
                "score"
              ]
            )}
          </h6>
          {/* High */}
          {props.data["Interpersonal"]["values"]["Mobilising Systems"][
            "score"
          ] === "High" ? (
            <li>
              {t(
                "This skill positions the talent well to access exciting opportunities in the world of work for the future. Being able to be a role model of positive behaviour, negotiating with others so that everyone feels that they have gained a positive result and creating an inspiring vision around a project or idea to build motivation and engagement in others are all important skills in the modern workplace. Gaining the information required to understand how organisations work and what is required of the talent is also significant."
              )}
            </li>
          ) : (
            <></>
          )}

          {/* Moderate */}
          {props.data["Interpersonal"]["values"]["Mobilising Systems"][
            "score"
          ] === "Moderate" ? (
            <li>
              {t(
                "Developing this skill will position the talent well to access opportunities in the world of work for the future. Being able to be a role model of positive behaviour, negotiating with others so that everyone feels that they have gained a positive result and creating an inspiring vision around a project or idea to build motivation and engagement in others are all important skills in the modern workplace. Gaining the information required to understand how organisations work and what is required of the talent is also significant."
              )}
            </li>
          ) : (
            <></>
          )}

          {/* Low */}
          {props.data["Interpersonal"]["values"]["Mobilising Systems"][
            "score"
          ] === "Low" ? (
            <li>
              {t(
                "This is an area that requires some attention for the talent. Being able to be a role model of positive behaviour, negotiating with others so that everyone feels that they have gained a positive result and creating an inspiring vision around a project or idea to build motivation and engagement in others are all important skills in the modern workplace. Gaining the information required to understand how organisations work and what is required of the talent is also significant. The talent must take every opportunity to develop these skills to enhance his/her prospects for the world of work in the future."
              )}
            </li>
          ) : (
            <></>
          )}
        </TabPanel>
      ) : (
        <></>
      )}
      {props.data["Self Leadership"] &&
      props.data["Self Leadership"] !== undefined ? (
        <TabPanel value={value} index={2}>
          <h3>{t("Self Leadership")}</h3>

          <h6 style={{ color: "rgb(208, 76, 141)" }}>
            {t("Entrepreneurship")} -{" "}
            {t(
              props.data["Self Leadership"]["values"]["Entrepreneurship"][
                "score"
              ]
            )}
          </h6>

          {/* High */}
          {props.data["Self Leadership"]["values"]["Entrepreneurship"][
            "score"
          ] === "High" ? (
            <li>
              {t(
                "This skill positions the talent well to access exciting opportunities in the world of work for the future. Being able to take appropriate risks, innovate, adapt to change and apply positive energy towards one’s work is important for one’s ability to secure employment, progress in his/her career and stay ahead of the competition."
              )}
            </li>
          ) : (
            <></>
          )}

          {/* Moderate */}
          {props.data["Self Leadership"]["values"]["Entrepreneurship"][
            "score"
          ] === "Moderate" ? (
            <li>
              {t(
                "Developing this skill will position the talent well to access opportunities in the world of work for the future. Being able to take appropriate risks, innovate, adapt to change and apply positive energy towards one’s work is important for one’s ability to secure employment, progress in his/her career and stay ahead of the competition."
              )}
            </li>
          ) : (
            <></>
          )}

          {/* Low */}
          {props.data["Self Leadership"]["values"]["Entrepreneurship"][
            "score"
          ] === "Low" ? (
            <li>
              {t(
                "This is an area that requires some attention for the talent. Being able to take appropriate risks, innovate, adapt to change and apply positive energy towards one’s work is important for one’s ability to secure employment, progress in his/her career and stay ahead of the competition. The talent must take every opportunity to develop these skills to enhance his/her prospects for the world of work in the future."
              )}
            </li>
          ) : (
            <></>
          )}

          <br />

          <h6 style={{ color: "rgb(208, 76, 141)" }}>
            {t("Goal Achievement")} -{" "}
            {t(
              props.data["Self Leadership"]["values"]["Goal Achievement"][
                "score"
              ]
            )}
          </h6>
          {/* High */}
          {props.data["Self Leadership"]["values"]["Goal Achievement"][
            "score"
          ] === "High" ? (
            <li>
              {t(
                "This skill positions the talent well to access exciting opportunities in the world of work for the future. Being able to take responsibility for setting and working towards one’s goals, persevering, especially during periods of uncertainty and committing to one’s professional development is important for his/her ability to secure employment and progress in his/her career."
              )}
            </li>
          ) : (
            <></>
          )}

          {/* Moderate */}
          {props.data["Self Leadership"]["values"]["Goal Achievement"][
            "score"
          ] === "Moderate" ? (
            <li>
              {t(
                "Developing this skill will position the talent well to access opportunities in the world of work for the future. Being able to take responsibility for setting and working towards one’s goals, persevering, especially during periods of uncertainty and committing to one’s professional development is important for his/her ability to secure employment and progress in his/her career."
              )}
            </li>
          ) : (
            <></>
          )}

          {/* Low */}
          {props.data["Self Leadership"]["values"]["Goal Achievement"][
            "score"
          ] === "Low" ? (
            <li>
              {t(
                "This is an area that requires some attention for the talent. Being able to take responsibility for setting and working towards one’s goals, persevering, especially during periods of uncertainty and committing to one’s professional development is important for his/her ability to secure employment and progress in his/her career. The talent must take every opportunity to develop these skills to enhance his/her prospects for the world of work in the future."
              )}
            </li>
          ) : (
            <></>
          )}

          <br />

          <h6 style={{ color: "rgb(208, 76, 141)" }}>
            {t("Self-Awareness")} -{" "}
            {t(
              props.data["Self Leadership"]["values"]["Self-Awareness"]["score"]
            )}
          </h6>
          {/* High */}
          {props.data["Self Leadership"]["values"]["Self-Awareness"][
            "score"
          ] === "High" ? (
            <li>
              {t(
                "This skill positions the talent well to access exciting opportunities in the world of work for the future. Being able to understand one’s emotions, how they impact one and knowing how to calm oneself down when one is feeling stressed is important for his/her health, wellbeing, and his/her performance at work."
              )}
            </li>
          ) : (
            <></>
          )}

          {/* Moderate */}
          {props.data["Self Leadership"]["values"]["Self-Awareness"][
            "score"
          ] === "Moderate" ? (
            <li>
              {t(
                "Developing this skill will position the talent well to access opportunities in the world of work for the future. Being able to understand one’s emotions, how they impact one and knowing how to calm oneself down when one is feeling stressed is important for his/her health, wellbeing, and his/her performance at work."
              )}
            </li>
          ) : (
            <></>
          )}

          {/* Low */}
          {props.data["Self Leadership"]["values"]["Self-Awareness"][
            "score"
          ] === "Low" ? (
            <li>
              {t(
                "This is an area that requires some attention for the talent. Being able to understand one’s emotions, how they impact one and knowing how to calm oneself down when one is feeling stressed is important for his/her health, wellbeing, and his/her performance at work. The talent must take every opportunity to develop these skills to enhance his/her prospects for the world of work in the future."
              )}
            </li>
          ) : (
            <></>
          )}

          <br />

          <h6 style={{ color: "rgb(208, 76, 141)" }}>
            {t("Self-Management")} -{" "}
            {t(
              props.data["Self Leadership"]["values"]["Self-Management"][
                "score"
              ]
            )}
          </h6>
          {/* High */}
          {props.data["Self Leadership"]["values"]["Self-Management"][
            "score"
          ] === "High" ? (
            <li>
              {t(
                "This skill positions the talent well to access exciting opportunities in the world of work for the future. Being able to look after one’s wellbeing, motivate oneself and make progress towards one’s goals is important for his/her ability to secure employment and progress in his/her career."
              )}
            </li>
          ) : (
            <></>
          )}

          {/* Moderate */}
          {props.data["Self Leadership"]["values"]["Self-Management"][
            "score"
          ] === "Moderate" ? (
            <li>
              {t(
                "Developing this skill will position the talent well to access opportunities in the world of work for the future. Being able to look after one’s wellbeing, motivate oneself and make progress towards one’s goals is important for his/her ability to secure employment and progress in his/her career."
              )}
            </li>
          ) : (
            <></>
          )}

          {/* Low */}
          {props.data["Self Leadership"]["values"]["Self-Management"][
            "score"
          ] === "Low" ? (
            <li>
              {t(
                "This is an area that requires some attention for the talent. Being able to look after one’s wellbeing, motivate oneself and make progress towards one’s goals is important for his/her ability to secure employment and progress in his/her career. The talent must take every opportunity to develop these skills to enhance his/her prospects for the world of work in the future."
              )}
            </li>
          ) : (
            <></>
          )}
        </TabPanel>
      ) : (
        ""
      )}

      {props.data["Digital"] && props.data["Digital"] !== undefined ? (
        <TabPanel value={value} index={3}>
          <h3>{t("Digital")} </h3>

          <h5>{t("Domains")}</h5>

          <h6 style={{ color: "rgb(208, 76, 141)" }}>
            {t("Digital Fluency")} -{" "}
            {t(props.data["Digital"]["values"]["Digital Fluency"]["score"])}{" "}
          </h6>
          {/* High */}
          {props.data["Digital"]["values"]["Digital Fluency"]["score"] &&
          props.data["Digital"]["values"]["Digital Fluency"]["score"] ===
            "High" ? (
            <li>
              {t(
                "This skill positions the talent well to access exciting opportunities in the world of work for the future. Being able to use technology and interact professionally in the digital world are important skills to some extent, in all careers in the future of work."
              )}
            </li>
          ) : (
            <></>
          )}

          {/* Moderate */}
          {props.data["Digital"]["values"]["Digital Fluency"]["score"] ===
          "Moderate" ? (
            <li>
              {t(
                "Developing this skill will position the talent well to access opportunities in the world of work for the future. Being able to use technology and interact professionally in the digital world are important skills to some extent, in all careers in the future of work."
              )}
            </li>
          ) : (
            <></>
          )}

          {/* Low */}
          {props.data["Digital"]["values"]["Digital Fluency"]["score"] ===
          "Low" ? (
            <li>
              {t(
                "This is an area that requires some attention for the talent. Being able to use technology and interact professionally in the digital world are important skills to some extent, in all careers in the future of work. The talent must take every opportunity to develop these skills to enhance his/her prospects for the world of work in the future."
              )}
            </li>
          ) : (
            <></>
          )}

          <br />

          <h6 style={{ color: "rgb(208, 76, 141)" }}>
            {t("Software Development")} -{" "}
            {t(
              props.data["Digital"]["values"]["Software Development"]["score"]
            )}
          </h6>
          {/* High */}
          {props.data["Digital"]["values"]["Software Development"]["score"] ===
          "High" ? (
            <li>
              {t(
                "This skill positions the talent well to access exciting opportunities in the world of work for the future. Being able to develop digital systems, understand and develop computer code and create digital programmes to solve problems are valuable assets if the talent sees digital skills as a core element of his/her chosen career. If you think this could be significant for your career."
              )}
            </li>
          ) : (
            <></>
          )}

          {/* Moderate */}
          {props.data["Digital"]["values"]["Software Development"]["score"] ===
          "Moderate" ? (
            <li>
              {t(
                "Developing this skill will position the talent well to access opportunities in the world of work for the future. Being able to develop digital systems, understand and develop computer code and create digital programmes to solve problems are valuable assets if the talent sees digital skills as a core element of his/her chosen career. If you think this could be significant for your career."
              )}
            </li>
          ) : (
            <></>
          )}

          {/* Low */}
          {props.data["Digital"]["values"]["Software Development"]["score"] ===
          "Low" ? (
            <li>
              {t(
                "This is an area that requires some attention for the talent. Being able to develop digital systems, understand and develop computer code and create digital programmes to solve problems are valuable assets if the talent sees digital skills as a core element of his/her chosen career. If the talent thinks this could be significant for his/her career, he/she must take every opportunity to develop these skills to enhance his/her prospects for the world of work in the future."
              )}
            </li>
          ) : (
            <></>
          )}

          <br />

          <h6 style={{ color: "rgb(208, 76, 141)" }}>
            {t("Software Use")} -{" "}
            {t(props.data["Digital"]["values"]["Software Use"]["score"])}
          </h6>

          {/* High */}
          {props.data["Digital"]["values"]["Software Use"]["score"] ===
          "High" ? (
            <li>
              {t(
                "This skill positions the talent well to access exciting opportunities in the world of work for the future. Being able to learn about, adapt to and integrate new software into one’s work, are important skills to some extent, in all careers in the future of work. If you think this could be significant for your career."
              )}
            </li>
          ) : (
            <></>
          )}

          {/* Moderate */}
          {props.data["Digital"]["values"]["Software Use"]["score"] ===
          "Moderate" ? (
            <li>
              {t(
                "Developing this skill will position the talent well to access opportunities in the world of work for the future. Being able to learn about, adapt to and integrate new software into one’s work, are important skills to some extent, in all careers in the future of work. If you think this could be significant for your career."
              )}
            </li>
          ) : (
            <></>
          )}

          {/* Low */}
          {props.data["Digital"]["values"]["Software Use"]["score"] ===
          "Low" ? (
            <li>
              {t(
                "This is an area that requires some attention for the talent. Being able to learn about, adapt to and integrate new software into one’s work, are important skills to some extent, in all careers in the future of work. If the talent thinks this could be significant for his/her career, he/she must take every opportunity to develop these skills to enhance his/her prospects for the world of work in the future."
              )}
            </li>
          ) : (
            <></>
          )}

          <br />

          <h6 style={{ color: "rgb(208, 76, 141)" }}>
            {t("Understanding Digital Systems")} -{" "}
            {t(
              props.data["Digital"]["values"]["Understanding Digital Systems"][
                "score"
              ]
            )}
          </h6>
          {/* High */}
          {props.data["Digital"]["values"]["Understanding Digital Systems"][
            "score"
          ] === "High" ? (
            <li>
              {t(
                "This skill positions the talent well to access exciting opportunities in the world of work for the future. Being able to use, understand and develop digital systems, and use data to gain insights are valuable assets if the talent sees digital skills as a core element of his/her chosen career. If you think this could be significant for your career."
              )}
            </li>
          ) : (
            <></>
          )}

          {/* Moderate */}
          {props.data["Digital"]["values"]["Understanding Digital Systems"][
            "score"
          ] === "Moderate" ? (
            <li>
              {t(
                "Developing this skill will position the talent well to access opportunities in the world of work for the future. Being able to use, understand and develop digital systems, and use data to gain insights are valuable assets if the talent sees digital skills as a core element of his/her chosen career. If you think this could be significant for your career."
              )}
            </li>
          ) : (
            <></>
          )}

          {/* Low */}
          {props.data["Digital"]["values"]["Understanding Digital Systems"][
            "score"
          ] === "Low" ? (
            <li>
              {t(
                "This is an area that requires some attention for the talent. Being able to use, understand and develop digital systems, and use data to gain insights are valuable assets if the talent sees digital skills as a core element of his/her chosen career. If the talent thinks this could be significant for his/her career, he/she must take every opportunity to develop these skills to enhance his/her prospects for the world of work in the future."
              )}
            </li>
          ) : (
            <></>
          )}
        </TabPanel>
      ) : (
        <></>
      )}
    </>
  );
}

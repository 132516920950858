export const messagesData = {
    "talent": {
        "messages": [{
            key:"reset password",
            value: "To reset your password, please visit the login page and click on the ‘Forgot password?’ link. Enter your email and you’ll receive a verification code which you can use to reset your password securely.",
        },{
            key:"lupa kata laluan",
            value: "Untuk menetapkan semula kata laluan anda, sila lawati halaman log masuk dan klik pada pautan ‘Lupa kata laluan?’. Masukkan e-mel anda dan anda akan menerima kod pengesahan yang boleh anda gunakan untuk menetapkan semula kata laluan anda dengan selamat.",
        }, {
            key: "update profile",
            value: "To update your profile, please login and click on the ‘About Me’ card on your dashboard. You will be able to update your personal details there.",
        }, {
            key: "kemaskini profil",
            value: "Untuk mengemas kini profil anda, sila log masuk dan klik pada kad 'Perihal Saya' pada papan pemuka anda. Anda akan dapat mengemas kini butiran peribadi anda di sana.",
        }, {
            key: "hello hi hey",
            value: "Hello. Nice to meet you.",
            
        }, {
            key: "what mynext talent",
            value: "A platform for undergraduate & postgraduates which specialises in providing access to profiling tools that helps them discover their work value and interests, most employable traits, and connect them directly to ideal internship opportunities.",
        }, {
            key: "apa mynext talent",
            value: "Satu platform untuk sarjana muda & pasca siswazah yang mengkhusus dalam menyediakan akses kepada alat pemprofilan yang membantu mereka menemui nilai kerja dan minat mereka, ciri-ciri kebolehpasaran, dan menghubungkan mereka terus kepada peluang latihan industri yang ideal.",
        }, {
            key: "how register",
            value: "You may refer to our mynext Talent User Guide for the step-by-step process on how to register for mynext here: https://www.mynext.my/assets/pdf/mynext-Talent-User-Guide.pdf",
        }, {
            key: "cara daftar",
            value: "Anda boleh merujuk kepada Panduan Pengguna Bakat mynext kami untuk proses langkah demi langkah tentang cara mendaftar mynext di sini: https://www.mynext.my/assets/pdf/mynext-Talent-User-Guide.pdf",
        }, {
            key: "what psychometric test",
            value: "There are 6 psychometric assessments offered only on mynext Talent: Work Values, Work Interests, Personality & Motivation, Employability, Career Explorer, and The Future of Work. Additionally, mynext Talent also offers a free English Proficiency test.",
        }, {
            key: "ujian psikometrik apa",
            value: "Terdapat 6 penilaian psikometrik ditawarkan hanya pada mynext Talent: Nilai Kerja, Minat Kerja, Personaliti & Motivasi, Kebiolehpasaran, Penjelajah Kerjaya dan Masa Depan Kerja. Selain itu, mynext Talent juga menawarkan ujian Kemahiran Bahasa Inggeris percuma.",
        }, {
            key: "psychometric graded test",
            value: "No, the psychometric assessments are designed to profile your personal traits, strengths, weaknesses, work motivations and employability factors",
        }, {
            key: "ujian psikometrik digred",
            value: "Tidak, penilaian psikometrik direka untuk memprofilkan sifat peribadi anda, kekuatan, kelemahan, motivasi kerja dan faktor kebolehkerjaan",
        }, {
            key: "who view psychometric result",
            value: "When you apply for an internship posting, the company can view your personal information and psychometric assessment results before offering you the position to pick the most suitable candidate. Additionally, your institution’s coaching staff are also able to view your results as part of mynext University",
        }, {
            key: "siapa boleh lihat keputusan",
            value: "Apabila anda memohon latihan industri di mynext, syarikat itu boleh melihat maklumat peribadi anda dan keputusan penilaian psikometrik sebelum menawarkan penempatan latihan industri bagi memilih calon yang paling sesuai. Selain itu, kakitangan kejurulatihan institusi pengajian tinggi anda juga boleh melihat keputusan anda daripada mynext University.",
        }, {
            key: "how apply internship",
            value: "You may begin applying for available internship opportunities offered by companies across Malaysia after filling in your personal information and completing the Personality & Motivation, Work Interests, and English Proficiency assessments on your Dashboard.",
        }, {
            key: "cara memohon latihan industri",
            value: "Anda boleh mula memohon untuk latihan industri di mynext yang ditawarkan oleh syarikat di seluruh Malaysia selepas mengisi maklumat peribadi anda dan melengkapkan penilaian Personaliti & Motivasi, Kepentingan Kerja dan Kemahiran Bahasa Inggeris pada Papan Pemuka anda.",
        }, {
            key: "not listed",
            value: "As mynext Talent is new and growing with constant updates to the system, certain institutions may not be present. In this case, you may contact us at this email address to request for your institution details to be added: hello@mynext.my",
        }, {
            key: "tiada dalam sistem",
            value: "Memandangkan mynext Talent adalah baharu dan berkembang dengan kemas kini berterusan kepada sistem, institusi tertentu mungkin tidak hadir. Dalam kes ini, anda boleh menghubungi kami di alamat e-mel ini untuk meminta butiran institusi anda ditambahkan: hello@mynext.my",
        }, {
            key: "mynext talent or mynext university",
            value: "mynext University is for university staff. For university students, you may begin your internship journey by registering for mynext Talent.",
        }, {
            key: "mynext talent atau mynext universiti",
            value: "mynext University adalah untuk kakitangan universiti. Bagi pelajar universiti, anda boleh memulakan perjalanan latihan anda dengan mendaftar untuk mynext Talent.",
        }, {
            key: "which email",
            value: "It is highly recommended to register yourself on mynext Talent using a personal email address as you may want to use the platform again after graduation or completing your internship period.",
        }, {
            key: "emel peribadi atau emel kerja",
            value: "Adalah sangat disyorkan untuk mendaftarkan diri anda di mynext Talent menggunakan alamat e-mel peribadi kerana anda mungkin mahu menggunakan platform semula selepas tamat pengajian atau menamatkan tempoh latihan anda.",
        }, {
            key: "delete my account",
            value: "To delete your mynext Talent account, you may submit a request to this email address accompanied by your reasoning to remove the account: hello@mynext.my",
        }, {
            key: "padam akaun",
            value: "Untuk memadamkan akaun mynext Talent anda, anda boleh menghantar permohonan ke alamat e-mel ini disertai alasan anda untuk memadamkan akaun: hello@mynext.my",
        }, {
            key: "issue enquiry enquiries",
            value: "You may contact the mynext Team by dropping us a message at www.mynext.my/contact and we will gladly assist you with any issues or inquiries. You may also call our hotline at 03-7839 7111.",
        }, {
            key: "isu pertanyaan",
            value: "Anda boleh menghubungi mynext Team dengan menghantar mesej kepada kami di www.mynext.my/contact dan kami akan membantu anda dengan sebarang isu atau pertanyaan. Anda juga boleh menghubungi talian hotline kami di 03-7839 7111.",
        },
    ]
    },
    "company": {
        "messages": []
    },
    "university": {
        "messages": []
    }

}
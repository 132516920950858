import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import UpdateConfirmation from "./updateConfirmation";
const UpdatePage = () => {
  const { t } = useTranslation();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const showConfirm = () => {
    setShowConfirmation(true);
  };
  return (
    <div>
      <button onClick={() => showConfirm()}>update profile</button>
      <UpdateConfirmation
        show={showConfirmation}
        title={t("PROFILE UPDATE")}
        message={t("Update Your Profile")}
        text={t("Would you like to update Profile or Cv?")}
        closeModal={() => setShowConfirmation(false)}
      />
    </div>
  );
};

export default UpdatePage;

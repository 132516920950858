import { configureStore } from "@reduxjs/toolkit";

import selfIntroStepOneFormReducer from "./pages/selfIntro/reducer";
import modalReducer from "./components/modal/modalReducer";
import talentDataReducer from "./pages/login/reducer";
import percentageReducer from "./pages/dashboard/reducer";
import profileReducer from "./Redux/Reducers/ProfileReducer/ProfileReducer";
import mySettings from "./pages/settings/settingsProfile/reducer";
import internshipFilter from "./pages/internship/reducer";
import jobReducer from './reducers/jobReducer'
import detailModalReducer from "./reducers/detailModalReducer";
export const store = configureStore({
  reducer: {
    selfIntroOne: selfIntroStepOneFormReducer,
    modal: modalReducer,
    talent: talentDataReducer,
    percentage: percentageReducer,
    profile: profileReducer,
    mySettings: mySettings,
    internshipFilter: internshipFilter,
    job_reducer:jobReducer,
    detailModal: detailModalReducer,
  },
});

import { Row, Col } from "react-bootstrap";
import { OceanDescription, OceanLabel, OceanSlider } from "./commonStyles";
import { StudentAd2Card } from "./ad2Card";
import { useState } from "react";
import { useTranslation } from "react-i18next";
const workCompetencyConstants = [
    {
        key: 0,
        title: "Embracing & Managing",
        description: 
        "Adjusts well to uncertainties. Skillfully handles pressure and difficulties.",
    },
    {
        key: 1,
        title: "Connecting & Portraying",
        description: 
        "Communicates and networks effectively. Successfully persuades and influences others. Relates to others in a confident, relaxed manner.",
    },
    {
        key: 2,
        title: "Supervising & Determining",
        description: 
        "Takes charge and assumes authority. Takes the initiative, provides guidance, and accepts accountability.",
    },
    {
        key: 3,
        title: "Ambitious & Result-Oriented",
        description: 
        "Committed to setting personal goals and highly motivated to achieving results. Stimulated by accomplishments and the recognition of effort. Demonstrates knowledge of commerce, business and economic affairs. Solicits possibilities for professional and personal growth.",
    },
    {
        key: 4,
        title: "Innovating & Envisioning",
        description: 
        "Works well in circumstances where being receptive to diverse perspectives is necessary. Pursues self improvement by seeking new knowledge. Uses imagination and ingenuity to overcome difficulties and issues. Regards situations comprehensively and systematically. Encourages positive organizational transformation.",
    },
    {
        key: 5,
        title: "Evaluating & Resolving",
        description: 
        "Demonstrates evidence of analytical clarity. Competent at reaching the core of difficult challenges. Effectively uses own expertise. Adopts new technology quickly. Writes clearly and effectively.",
    },
    {
        key: 6,
        title: "Facilitating & Collaborating",
        description: 
        "Supports others and demonstrates respect and favorable regard for them. Focuses on the needs of others and works well with various parties. Possesses reliable characteristics that are compatible with those of the organization.",
    },
    {
        key: 7,
        title: "Coordinating & Implementing",
        description: 
        "Prepares for work in advance and organizes work systematically. Executes work by adhering to instructions. Emphasizes the fulfillment of client needs and provides service or product according to established guidelines.",
    },
]
export default function WorkCompetencyFactorChart(data) {
    const { t, i18n } = useTranslation();
    const [visible0, setVisible0] = useState(false);
    const [visible1, setVisible1] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [visible3, setVisible3] = useState(false);
    const [visible4, setVisible4] = useState(false);
    const [visible5, setVisible5] = useState(false);
    const [visible6, setVisible6] = useState(false);
    const [visible7, setVisible7] = useState(false);
    const handleVisible0 = () => setVisible0(!visible0);
    const handleVisible1 = () => setVisible1(!visible1);
    const handleVisible2 = () => setVisible2(!visible2);
    const handleVisible3 = () => setVisible3(!visible3);
    const handleVisible4 = () => setVisible4(!visible4);
    const handleVisible5 = () => setVisible5(!visible5);
    const handleVisible6 = () => setVisible6(!visible6);
    const handleVisible7 = () => setVisible7(!visible7);
    const getLevel = (val) => {
        if (val < 25 ) { return "low" }
        else if (val < 75) { return "moderate" } 
        else { return "high" };
    };
    const finalData = data['data'];
    return <>
        <StudentAd2Card title={t("Work Competency Factor")} sm="12" lg="12">
            <Row>
                <Col>
                    <OceanLabel onClick={handleVisible0}>{t(workCompetencyConstants[0].title)}</OceanLabel>
                </Col>
                <Col> {/* value passed in "level" should be same as "value" */}
                    <OceanSlider level={getLevel(finalData["Embracing & Managing"])} value={finalData["Embracing & Managing" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
                </Col>
            </Row>
            {visible0 &&
                <Row>
                    <Col>
                        <OceanDescription>{t(workCompetencyConstants[0].description)}</OceanDescription>
                    </Col>
                </Row>
            }
            <Row>
                <Col>
                    <OceanLabel onClick={handleVisible1}>{t(workCompetencyConstants[1].title)}</OceanLabel>
                </Col>
                <Col>
                    <OceanSlider level={getLevel(finalData["Connecting & Portraying"])} value={finalData["Connecting & Portraying" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
                </Col>
            </Row>
            {visible1 &&
                <Row>
                    <Col>
                        <OceanDescription>{t(workCompetencyConstants[1].description)}</OceanDescription>
                    </Col>
                </Row>
            }
            <Row>
                <Col>
                    <OceanLabel onClick={handleVisible2}>{t(workCompetencyConstants[2].title)}</OceanLabel>
                </Col>
                <Col>
                    <OceanSlider level={getLevel(finalData["Supervising & Determining"])} value={finalData["Supervising & Determining" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
                </Col>
            </Row>
            {visible2 &&
                <Row>
                    <Col>
                        <OceanDescription>{t(workCompetencyConstants[2].description)}</OceanDescription>
                    </Col>
                </Row>
            }
            <Row>
                <Col>
                    <OceanLabel onClick={handleVisible3}>{t(workCompetencyConstants[3].title)}</OceanLabel>
                </Col>
                <Col>
                    <OceanSlider level={getLevel(finalData["Ambitious & Result-Oriented"])} value={finalData["Ambitious & Result-Oriented" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
                </Col>
            </Row>
            {visible3 &&
                <Row>
                    <Col>
                        <OceanDescription>{t(workCompetencyConstants[3].description)}</OceanDescription>
                    </Col>
                </Row>
            }
            <Row>
                <Col>
                    <OceanLabel onClick={handleVisible4}>{t(workCompetencyConstants[4].title)}</OceanLabel>
                </Col>
                <Col>
                    <OceanSlider level={getLevel(finalData["Innovating & Envisioning"])} value={finalData["Innovating & Envisioning" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
                </Col>
            </Row>
            {visible4 &&
                <Row>
                    <Col>
                        <OceanDescription>{t(workCompetencyConstants[4].description)}</OceanDescription>
                    </Col>
                </Row>
            }

            <Row>
                <Col>
                    <OceanLabel onClick={handleVisible5}>{t(workCompetencyConstants[5].title)}</OceanLabel>
                </Col>
                <Col>
                    <OceanSlider level={getLevel(finalData["Evaluating & Resolving"])} value={finalData["Evaluating & Resolving" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
                </Col>
            </Row>
            {visible5 &&
                <Row>
                    <Col>
                        <OceanDescription>{t(workCompetencyConstants[5].description)}</OceanDescription>
                    </Col>
                </Row>
            }
            <Row>
                <Col>
                    <OceanLabel onClick={handleVisible6}>{t(workCompetencyConstants[6].title)}</OceanLabel>
                </Col>
                <Col>
                    <OceanSlider level={getLevel(finalData["Facilitating & Collaborating"])} value={finalData["Facilitating & Collaborating" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
                </Col>
            </Row>
            {visible6 &&
                <Row>
                    <Col>
                        <OceanDescription>{t(workCompetencyConstants[6].description)}</OceanDescription>
                    </Col>
                </Row>
            }
            <Row>
                <Col>
                    <OceanLabel onClick={handleVisible7}>{t(workCompetencyConstants[7].title)}</OceanLabel>
                </Col>
                <Col>
                    <OceanSlider level={getLevel(finalData["Coordinating & Implementing"])} value={finalData["Coordinating & Implementing" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
                </Col>
            </Row>
            {visible7 &&
                <Row>
                    <Col>
                        <OceanDescription>{t(workCompetencyConstants[7].description)}</OceanDescription>
                    </Col>
                </Row>
            }
        </StudentAd2Card>
    </>;
}
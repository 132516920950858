import { useEffect, useState } from "react";
import styled from "styled-components";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import Menus from "../../components/hamburger/menus";
import HamburgerLay from "../../components/hamburger/hamburger";
import { Colors } from "../../utilities/colors";
import * as Mui from "@material-ui/core";
import CvCard from "../../components/cvcards/cvcard";
import { Card, Col, Dropdown, Row } from "react-bootstrap";
import Input from "../../components/input/input";
import Phone from "../../assets/icons/phone.png";
import Mail from "../../assets/icons/mail.png";
import EditIcon from "../../assets/svg/pencil.svg";
import "./style.css";
import CvLocation from "./location/cv-location";
import Compensation from "./compensation/compensation";
import Employement from "./employement/employement";
import Internship from "./internship/internship";
import Events from "./events/events";
import InternshipPreference from "./internship-preference/internship-preference";
import AboutMe from "./about-me/about-me";
import Skills from "./skills/skills";
import Education from "./education/education";
import {
  getProfileDetails,
  getTalentprofileview,
  getmycvuserdatails,
  updategetmycvuserdatails,
} from "../../services/apiCalls";
import { onBoardFormGetData } from "../../services/apiCalls";
import PhoneInput from "react-phone-number-input";
import CustomButton from "../../components/button/button";
import { toast } from "react-toastify";
import { ImageWrapper } from "../analyticalDashboardPage/commonStyles";
import { useNavigate } from "react-router-dom";
import MyCvPreview from "./my-cv-preview/my-cv-preview";
import { isValidObject } from "../../utilities/validators";
import { getResumeData } from "../../services/apiCalls";
import { useDispatch, useSelector } from "react-redux";
// import { setResumeData } from "../../../Redux/Reducers/ProfileReducer/ProfileReducer"
import { setResumeData } from "../../Redux/Reducers/ProfileReducer/ProfileReducer";
import { userDetailsUpdate } from "../../services/apiCalls";
import detailimage from "../../assets/avatar.png";
import { useTranslation } from "react-i18next";
import { resetFilter } from "../internship/reducer"; //INIVOS
import MyCvAssesment from "./mycvassesment";
import Toggle from "./Toggle";
import Button from "../../components/button/button";
import VisibilityCVPopup from "../../components/visibilityPopup/visibilityPopup";
import { ModalHeaderTextThree } from "../../components/alertModal/indexComponents";
import Loader from "../../components/loader/loader";

const PageWrapper = styled.div`
  min-height: 80vh;
  padding: 0rem 3rem;
  @media (max-width: 767px) {
    padding: 0rem 0.8rem;
  }
`;
const PropertyText = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 180%;
  color: #d04c8d;
  width: 200px;
  display: table-cell;
`;
const ValueText = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 180%;
  color: #6b6b6b;
  display: table-cell;
`;
const RecommendedJobsCard = styled.div`
  background-color: white;
  margin-bottom: 30px;
  border-radius: 10px;
  padding: 3%;
  cursor: pointer;
`;

const ResumeName = styled.div`
  font-size: 20px;
  font-family: "General Sans";
  font-weight: 600;
  position: relative;
`;

const CardContainer = styled.div`
  margin-top: 4%;
`;

const FormGroup = styled.div`
  margin-bottom: 25px;
  @media (max-width: 599px) {
    margin-bottom: 10px;
    margin-top: 1.5rem;
  }
`;

const Icon = styled.img`
  height: 18px;
  width: 18px;
`;

const ResumeOnlyme = styled.div`
  display: -webkit-box;
  float: right;
`;

const EllipsisWrapper = styled.div`
  display: flex;
  justify-content: right;
  padding-top: 20px;
  padding-right: 20px;
  cursor: pointer;
`;

const Elipsis = styled.div`
  height: 6px;
  width: 6px;
  background-color: #aaaaaa;
  border-radius: 50%;
  display: inline-block;
`;
const DropdownList = styled.span`
  padding-left: 10px;
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #202020;
`;

const EmailContainer = styled.div`
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #525252;
  padding-top: 10px;
`;

const EducationDegree = styled.div`
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #d04c8d;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const EducationCYear = styled.div`
  font-family: General Sans;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  color: #525252;
`;

const StrengthWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const DashboardWrapper = styled.div`
  @media (max-width: 1024px) {
    display: flex;
  }
`;

const HamburgerLayout = styled.div`
  @media (min-width: 1024px) {
    display: none;
  }
`;
const LabelText = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202020;
  position: relative;
  top: 30px;
  left: 200px;
  margin: 10px 0 30px 0;
  @media (min-width: 320px) and (max-width: 480px) {
    position: relative;
    top: 30px;
    left: 0px;
    margin: 10px 9px 30px 20px;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    position: relative;
    top: 30px;
    left: 82px;
    margin: 10px 0 30px 0;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #202020;
    position: relative;
    top: 30px;
    left: 100px;
    margin: 10px 0 30px 0;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    font-size: 18px;
    line-height: 24px;
    color: #202020;
    position: relative;
    top: 30px;
    left: 118px;
    margin: 10px 0 30px 0;
  }
`;
const CardWrapper = styled.div`
  background: #ffffff;
  border-radius: 34px;
  height: 173px;
  margin: 20px 50px;
  @media (min-width: 320px) and (max-width: 480px) {
    margin: 10px 12px;
    height: 270px;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    height: 250px;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    height: 250px;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    height: 250px;
  }
`;
const StatusText = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 115.5%;
  // text-decoration-line: underline;
  color: #504f8c;
  position: relative;
  left: 400px;
  top: 10px;
  @media (min-width: 320px) and (max-width: 480px) {
    position: relative;
    display: flex;
    justify-content: center;
    left: 10px;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    position: relative;
    top: 10px;
    display: flex;
    left: auto;
    justify-content: center;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    position: relative;
    top: 10px;
    display: flex;
    justify-content: center;
    left: auto;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    position: relative;
    display: flex;
    justify-content: center;
    left: auto;
  }
`;
const MainDiv = styled.div`
  position: relative;
  left: 220px;
  top: 30px;
  width: 300px;
  @media (min-width: 320px) and (max-width: 480px) {
    width: auto;
    left: auto;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    position: relative;
    left: 157px;
    top: 30px;
    width: 300px;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    position: relative;
    left: 168px;
    top: 30px;
    width: 300px;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    position: relative;
    left: 180px;
    top: 30px;
    width: 300px;
  }
`;
const MainToggle = styled.div`
  position: relative;
  left: 470px;
  bottom: 15px;
  @media (min-width: 320px) and (max-width: 480px) {
    position: relative;
    left: 200px;
    bottom: 15px;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    position: relative;
    left: 400px;
    bottom: 15px;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    position: relative;
    left: 419px;
    bottom: 15px;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    position: relative;
    left: 415px;
    bottom: 15px;
  }
`;
const SaveDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  gap: 20px;
  top: -112px;
  left: 100px;
  @media (min-width: 320px) and (max-width: 480px) {
    position: relative;
    top: -23%;
    left: 0;
    button {
      width: 121px;
    }
  }
  @media (min-width: 481px) and (max-width: 768px) {
    position: relative;
    display: flex;
    justify-content: center;
    top: -23%;
    left: 0;
    button {
      width: 121px;
    }
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    position: relative;
    display: flex;
    justify-content: center;
    top: -23%;
    left: 0;
    button {
      width: 121px;
    }
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    position: relative;
    display: flex;
    justify-content: center;
    top: -23%;
    left: 0;
    button {
      width: 121px;
    }
  }
`;
const useStyles = Mui.makeStyles((theme) => ({
  avatarStyle: {
    width: theme.spacing(20),
    height: theme.spacing(20),
    borderRadius: 100,
  },
}));

const initialDisplay = {
  internshipPref: false,
  employment: false,
  location: false,
  compensation: false,
  aboutMe: false,
  internship: false,
  events: false,
  education: false,
  strengths: false,
  motivated: false,
  workValues: false,
  tests: false,
  customSkills: false,
  skills: false,
};

export default function MyCV() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [mblNumber, setMblNumber] = useState("");
  const [disableContact, setDisableContact] = useState(true);
  const [profileDetails, setProfileDetails] = useState(null);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [display, setDisplay] = useState(initialDisplay);
  const [isMyCvDownLoad, setIsMyCvDownLoad] = useState(false);
  const [downloadingComplete, setDownloadingComplete] = useState(false);
  const [handleCvPrint, setHandleCvPrint] = useState(false);
  const talentData = useSelector((state) => state?.talent?.userData);
  const [onBoardFormDetails, setOnBoardFormDetails] = useState(null);
  const [openVisibilityModal,setOpenVisibilityModal] = useState(false);
  const [isOpenham, setOpenHam] = useState(false);
  const [salerylist, setSalerylist] = useState([
    { label: "2000 RM", value: "2000" },
    { label: "2500 RM", value: "2500" },
  ]);
  const [salery, setSalery] = useState("");
  const [visiable, setVisiable] = useState("Y");
  const [saleryOne, setSaleryOne] = useState("");
  const [visiableOne, setVisiableOne] = useState("Y");
  const [viewcount, setViewcount] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    //INIVOS
    dispatch(resetFilter());

    fetchProfileDetails();
    fetchOnBoardFormDetails();
    setDisplayFlags();
    return () => {
      setDisplay({ ...initialDisplay });
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const setDisplayFlags = () => {
    let flag = {
      internshipPref: true,
      employment: true,
      location: true,
      compensation: true,
      aboutMe: true,
      internship: true,
      events: true,
      education: true,
      strengths: true,
      motivated: true,
      workValues: true,
      tests: true,
      customSkills: true,
      skills: true,
    };
    setDisplay(flag);
  };

  const notification = (type, message) => {
    toast[type](message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const fetchProfileDetails = async () => {
    const { data, status } = await getProfileDetails();
    if (status === 200 && data.status === 1) {
      setProfileDetails(data.data);
    }
  };

  const talentprofileview = async () => {
    const { data, status } = await getTalentprofileview();
    console.log(data, status, "++++");
    if (status === 200 && data.status === 1) {
      if (data.data && data.data.profile_views) {
        setViewcount(data.data.profile_views);
      } else {
        setViewcount(0);
      }
    }
  };
  const fetchOnBoardFormDetails = async () => {
    const { data, status } = await onBoardFormGetData();
    if (status === 200 && data.status === 1) {
      if (data.data.insti_location_status === 0) {
        data.data.insti_name_abbr = data.data.university;
      } else {
        data.data.university = "";
      }
      setOnBoardFormDetails(data.data);
    }
  };
  const onNavigate = (route) => {
    navigate(route);
  };

  const [mobile_number, setMobileNumber] = useState("");
  const formValues = useSelector((state) => state.selfIntroOne.value);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState("");

  const onSubmit = (data) => {
    getUpdatedUserDetails(data);
    setDisableContact(true);
    setLoading(true);
  };

  const getUpdatedUserDetails = async ({ name, phone }) => {
    const { data, status } = await userDetailsUpdate({
      name: profileDetails?.display_name,
      phone_number:
        mobile_number === "" ? profileDetails?.mob_number : mobile_number,
    });
  };

  const cancelOverview = () => {
    setDisableContact(true);
  };

  const downloadComplete = (data) => {
    debugger;
    // setIsMyCvDownLoad(false)
    setDownloadingComplete(true);
    setHandleCvPrint(false);
  };

  if (downloadingComplete) {
    setTimeout(() => setIsMyCvDownLoad(false), 4000);
  }

  useEffect(async () => {
    await talentprofileview();
    async function fetchResumeData() {
      const response = await getResumeData();
      if (
        isValidObject(response) &&
        response.hasOwnProperty("data") &&
        response.data !== undefined &&
        response.hasOwnProperty("status")
      ) {
        //dispatch to store the response in redux
        dispatch(setResumeData(response.data.data));
      }
    }
    fetchResumeData();
    getmycydetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangePhone = (value) => {
    if (value) {
      setMobileNumber(value);
    }
    setProfileDetails({ ...profileDetails, mob_number: value });
  };

  const getUniName = (
    talent_status,
    insti_country_status,
    insti_location_status,
    insti_name,
    university
  ) => {
    //returns true if study locally, false if study abroad
    if (talent_status === "studying" || talent_status === "internship") {
      if (insti_location_status === 1) {
        //study locally
        return true;
      } else {
        //study abroad
        return false;
      }
    } else if (talent_status === "employed" || talent_status === "unemployed") {
      if (insti_country_status === 1) {
        //study locally
        return true;
      } else {
        //study abroad
        return false;
      }
    }
  };

  const getmycydetails = async () => {
    let data = await getmycvuserdatails();
    if (data.status == 200) {
      setSalery(data.data.data.salery);
      setSaleryOne(data.data.data.salery);

      setVisiable(data.data.data.visible);
      setVisiableOne(data.data.data.visible);
    }
  };

  const changesalery = async (v) => {
    // setSalery(v)
    setSaleryOne(v);
  };
  const funChange = async () => {
    await updategetmycvuserdatails("salery", { salery: saleryOne });
    await updategetmycvuserdatails("visible", { visible: visiableOne });

    await getmycydetails();
  };
  const funccan = (async) => {
    setSaleryOne(salery);
    setVisiableOne(visiable);
  };
  const changevisiable = async () => {
    let a = "";
    if (visiableOne == "Y") {
      a = "N";
      setVisiableOne("N");
      setOpenVisibilityModal(true);
    } else {
      a = "Y";
      setVisiableOne("Y");
      setOpenVisibilityModal(true);

    }
  };
  function onCloseVisibilityPopup(){
    setOpenVisibilityModal(false);
  }
  const { t } = useTranslation();
  // redirect to dashboard if has not completed assessment
  const completion = useSelector((state) => {
    return state.profile.profilePercentage;
  });
  const content = () => {
    return (
      <>
        {/* <Form
          onSubmit={handleSubmitBulk(onBulkUpload)}
          encType="multipart/form-data" */}
        {/* > */}
          <ModalHeaderTextThree>
             {visiableOne === "N" ? "Your CV is not visible to Company" : "Your CV is now visible to Company"}
          </ModalHeaderTextThree>
      
          <Row>
            {loading ? (
              <Loader />
            ) : (
              <Col
                md="12"
                className="d-flex justify-content-center align-items-center"
              >
                <Button
                  width="100px"
                  margin="40px 0px 30px 0px"
                  onClick={()=>{
                    funChange();
                    onCloseVisibilityPopup(false);

            }}
                  name={t("Save")}
                  // type="submit"
                />
                <Button
                  secondary
                  color="#FFFFFF"
                  name={t("Cancel")}
                  style={{
                    margin: "40px 5px 30px 10px",
                  }}
                  variant="outlined"
                  onClick={()=>{
                    funccan();
                    onCloseVisibilityPopup(false);
                  }}
                ></Button>
              </Col>
            )}
          </Row>
        {/* </Form> */}
      </>
    );
  };
  // if ((completion != null) & (completion < 100)) {
  //   navigate("/dashboard");
  // }

  return (
    <>
      <MainLayout>
        <h1 className="header" style={{ marginLeft: "60px" }}>
          My CV
        </h1>
        <CardWrapper>
          {/* <StatusText>{`You have ${viewcount} profile view`}</StatusText> */}
          <div style={{ display: "flex" }}>
            <LabelText>My expected salary</LabelText>
            <MainDiv>
              <Input
                placeholder={t("Enter salary")}
                name="salary"
                type="number"
                value={saleryOne}
                onChange={(val) => changesalery(val.target.value)}
              />
            </MainDiv>
          </div>
          <LabelText>Make me invisible</LabelText>
          <MainToggle>
            <Toggle data={visiableOne} onClick={changevisiable} />
          </MainToggle>
          {/* <SaveDiv>
            {salery !== saleryOne || visiable !== visiableOne ? (
              <>
                <Button name="Save" onClick={funChange} />
                <Button name="Cancel" onClick={funccan} />
              </>
            ) : (
              ""
            )}
          </SaveDiv> */}
        </CardWrapper>
        <PageWrapper>
          <DashboardWrapper>
            <HamburgerLayout>
              <HamburgerLay onToggle={() => setOpenHam(!isOpenham)} />
            </HamburgerLayout>
            {isOpenham && <Menus />}
          </DashboardWrapper>
          <RecommendedJobsCard>
            <div id="cv-loader">
              <div className="loader-container">
                <div className="common-loader">
                  <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
                </div>
              </div>
            </div>
            <EllipsisWrapper>
              <Dropdown className="dropdown__toggle">
                <Dropdown.Toggle variant="">
                  <Elipsis />
                  &nbsp;
                  <Elipsis />
                  &nbsp;
                  <Elipsis />
                </Dropdown.Toggle>
                <Dropdown.Menu className=" font-family-general-sans-medium">
                  {/* <Dropdown.Item onClick={() => setHandleCvPrint(true)}>
                    Print
                  </Dropdown.Item> */}
                  <Dropdown.Item
                    onClick={() => {
                      setIsMyCvDownLoad(true);
                    }}
                  >
                    {t("Download")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </EllipsisWrapper>

            <Row>
              <Col lg={2}>
                <ImageWrapper>
                  <Mui.Avatar
                    src={
                      talentData?.profile_picture
                        ? talentData?.profile_picture
                        : detailimage
                    }
                    alt="Profile pic"
                    className={classes.avatarStyle}
                  />
                </ImageWrapper>
              </Col>
              <Col lg={10}>
                <>
                  <ResumeName>{profileDetails?.full_name}</ResumeName>
                </>
                <div style={{ display: "table" }}>
                  <div style={{ display: "table-row" }}>
                    <PropertyText>Scope of Study : </PropertyText>
                    <ValueText>{profileDetails?.scope_abbr} </ValueText>
                  </div>
                </div>
                <div style={{ display: "table" }}>
                  <div style={{ display: "table-row" }}>
                    <PropertyText>Education Institution : </PropertyText>
                    <ValueText>
                      {getUniName(
                        onBoardFormDetails?.talent_status,
                        onBoardFormDetails?.insti_country_status,
                        onBoardFormDetails?.insti_location_status,
                        onBoardFormDetails?.insti_name_abbr,
                        onBoardFormDetails?.university
                      )
                        ? onBoardFormDetails?.insti_name_abbr
                        : onBoardFormDetails?.university}
                    </ValueText>
                  </div>
                </div>
                <div style={{ display: "table" }}>
                  <div style={{ display: "table-row" }}>
                    <PropertyText>Address : </PropertyText>
                    <div style={{ display: "flex" }}>
                      <ValueText>
                        <div style={{ display: "flex" }}>
                          <div style={{ marginRight: "5px" }}>
                            {profileDetails?.city_abbr},
                          </div>
                          <div style={{ marginRight: "5px" }}>
                            {profileDetails?.state_abbr},
                          </div>
                          <div style={{ marginRight: "5px" }}>
                            {profileDetails?.curr_country_abbr}
                          </div>
                          <div>{profileDetails?.postalcode}</div>
                        </div>
                      </ValueText>
                    </div>
                  </div>
                </div>
                <div style={{ display: "table" }}>
                  <div style={{ display: "table-row" }}>
                    <PropertyText>Primary e-mail id :</PropertyText>
                    <ValueText>{profileDetails?.email} </ValueText>
                  </div>
                </div>
                <div style={{ display: "table" }}>
                  <div style={{ display: "table-row" }}>
                    <PropertyText>Primary mobile no:</PropertyText>
                    <ValueText>
                      {mobile_number === ""
                        ? profileDetails?.mob_number
                        : mobile_number}
                    </ValueText>
                  </div>
                </div>
                <br />{" "}
              </Col>
            </Row>

            {/* <Row className="contact-details ">
              <Col md="1"></Col>
              <Col
                md="10 mmL-0"
                style={{
                  paddingLeft: innerWidth <= 800 ? "0px" : "100px",
                  marginLeft: "10%",
                }}
              >
                <Row>
                  <Col md="5">
                    <FormGroup>
                      {disableContact && (
                        <div className="input-icon">
                          <Icon src={Phone} />
                          <Input disabled={true} left="40px" value="" />
                        </div>
                      )}
                      {!disableContact && (
                        // <PhoneInput
                        //   defaultCountry="MY"
                        //   withCountryCallingCode
                        //   placeholder="Enter Mobile Number"
                        //   value={profileDetails?.mob_number}
                        //   onChange={onChangePhone}
                        //   maxLength={12}
                        // />
                        <PhoneInput
                          international
                          withCountryCallingCode={true}
                          countryCallingCodeEditable={false}
                          defaultCountry="MY"
                          value=""
                          onChange={onChangePhone}
                          maxLength={16}
                        />
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <EmailContainer>
                      <Icon src={Mail} />
                      &nbsp;&nbsp;&nbsp;
                    </EmailContainer>
                  </Col>
                </Row>
              </Col>

              {!disableContact && (
                <Row style={{ justifyContent: "flex-end" }} className="mt-3">
                  <ButtonWrapper>
                    <CustomButton onClick={onSubmit} name={t("Save")} />{" "}
                    &nbsp;&nbsp;
                    <CustomButton
                      onClick={cancelOverview}
                      secondary
                      name={t("Cancel")}
                    />
                  </ButtonWrapper>
                </Row>
              )}
            </Row> */}

            {/* <CardContainer>
              <Card className="intern-pref-card">
                {display.internshipPref && (
                  <InternshipPreference notification={notification} />
                )}
              </Card>
            </CardContainer> */}

            <CardContainer>
              <Row>
                <CvCard
                  title={t("Employment Details")}
                  titleColor={Colors.primaryText}
                  showLock={true}
                >
                  {display.employment && (
                    <Employement notification={notification} />
                  )}
                </CvCard>
              </Row>
            </CardContainer>
            <CardContainer>
              <Row style={{ padding: "0px" }}>
                <Col md="6" style={{ padding: "0px" }}>
                  <CvCard
                    title={t("Location")}
                    titleColor={Colors.primaryText}
                    showLock={true}
                  >
                    {display.location && (
                      <CvLocation notification={notification} />
                    )}
                  </CvCard>
                </Col>
                <Col md="6">
                  <CvCard
                    title={t("Compensation")}
                    titleColor={Colors.primaryText}
                    showLock={true}
                  >
                    {display.compensation && (
                      <Compensation notification={notification} />
                    )}
                  </CvCard>
                </Col>
              </Row>
            </CardContainer>

            <CardContainer>
              <Row>
                {display.aboutMe && <AboutMe notification={notification} />}
              </Row>
            </CardContainer>

            <CardContainer>
              <Row>
                <CvCard
                  title={t("Internship")}
                  titleColor={Colors.primaryText}
                  showLock={true}
                >
                  {display.internship && (
                    <Internship notification={notification} />
                  )}
                </CvCard>
              </Row>
            </CardContainer>

            <CardContainer>
              <Row>
                <CvCard
                  title={t("Event")}
                  titleColor={Colors.primaryText}
                  showLock={true}
                >
                  {display.events && <Events notification={notification} />}
                </CvCard>
              </Row>
            </CardContainer>

            <CardContainer>
              <Row>
                <CvCard
                  title={t("Education")}
                  titleColor={Colors.primaryText}
                  showLock={true}
                >
                  {display.education && (
                    <Education notification={notification} />
                  )}
                </CvCard>
              </Row>
            </CardContainer>

            {/* <CardContainer>
            <Row>
              <Col md="6">
                <CvCard title="Strengths" titleColor={Colors.primaryText} showLock={true}>
                  {display.strengths && <TextInput>User has not attempted the test.</TextInput>}
                </CvCard>
              </Col>
              <Col md="6">
                <CvCard title="Motivated By" titleColor={Colors.primaryText} showLock={true}>
                  {display.motivated && <TextInput>User has not attempted the test.</TextInput>}
                </CvCard>
              </Col>
            </Row>
          </CardContainer>
          <CardContainer>
            <Row>
              <Col md="6">
                <CvCard title="Work Values" titleColor={Colors.primaryText} showLock={true}>
                  {display.workValues && <TextInput>You have not added any work value.</TextInput>}
                </CvCard>
              </Col>
            </Row>
          </CardContainer>

          <CardContainer>
            <Row>
              <CvCard title="Test and Assessments" titleColor={Colors.primaryText} showLock={true}>
                {display.tests && <TestAssessments />}
              </CvCard>
            </Row>

          </CardContainer>
          */}
            <CardContainer>
              <Row>
                <Col md="12">
                  <CvCard
                    title={t("Custom Skills")}
                    titleColor={Colors.primaryText}
                    showLock={true}
                  >
                    {display.customSkills && <Skills />}
                  </CvCard>
                </Col>
                {/*   <Col md="6">
                <CvCard title="Skills" titleColor={Colors.primaryText} showLock={true}>
                  {display.skills && <TextInput>To declare career please <Anchor>click here</Anchor></TextInput>}
                </CvCard>
              </Col> */}
              </Row>
              <MyCvAssesment />
            </CardContainer>
          </RecommendedJobsCard>
        </PageWrapper>
      </MainLayout>
      <VisibilityCVPopup
         title={"My CV"}
         subTitle={"Update Visibility Status"}
         show={openVisibilityModal}
         close={onCloseVisibilityPopup}
         content={content()}
      >
        
      </VisibilityCVPopup>
      {isMyCvDownLoad && (
        <div style={{ height: "0", display: "hidden" }}>
          <MyCvPreview
            isMyCvDownLoad={isMyCvDownLoad}
            downloadComplete={downloadComplete}
            handleCvPrint={false}
          />
        </div>
      )}
      {handleCvPrint && (
        <div style={{ height: "0", display: "hidden" }}>
          <MyCvPreview
            isMyCvDownLoad={false}
            downloadComplete={downloadComplete}
            handleCvPrint={handleCvPrint}
          />
        </div>
      )}
    </>
  );
}

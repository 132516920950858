import styled from "styled-components";

import { Colors } from "../../utilities/colors";

// styling starts
const InputField = styled.input`
  background-color: ${Colors.inputBg};
  border-radius: 128px;
  border: 0px;
  padding: 12px 20px;
  padding-left: ${(props) => (props.left ? props.left : "")};
  outline: none;
  width: -webkit-fill-available;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.inputTextDark};
  &:hover,
  :focus,
  :active {
    box-shadow: ${(props) => (props.disabled ? "" : "0 0 0 1px #ff60b1")};
    border-color: ${(props) => (props.disabled ? "" : "#ff60b1")};
  }
  &:after {
    content: "";
    display: none;
  }
`;
// styling ends

export default function Input(props) {
  return (
    <>
      {/* <div title={props.messageTip ? props.messageTip : null}>
        <InputField
          {...props}
         
        />
      </div> */}

      <InputField {...props} />
    </>
  );
}

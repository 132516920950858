import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import InterviewModal from "./InterviewModal";
import "./myCalendar.css";
import styled from "styled-components";
import enLocale from '@fullcalendar/core/locales/en-gb';
import msLocale from '@fullcalendar/core/locales/ms';
import { useSearchParams } from "react-router-dom";

let todayStr = new Date().toISOString().replace(/T.*$/, "");
const renderEventContent = (eventInfo) => {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  );
};
const Calendar = styled.div`
  padding: 2rem 3rem;
  @media (max-width:500px){
    padding:1rem;
  }
`;

export default function MyCalendarView(props,handleClickOpen,handleClose,opens,deletefu) {
  const tasks = props.tasks;
  let INITIAL_EVENTS = [];
  const calendarRef = React.createRef();
  const [show, setShow] = useState(false);
  const [id, setId] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const lang = searchParams.get("lang");

  useEffect(() => {
    let d = calendarRef?.current?.getApi();
    if (d) {
      d.removeAllEvents();
      for (let i = 0; i < tasks.length; i++) {
        d.addEvent({
          id: tasks[i]["id"],
          title: tasks[i]["interview_title"],
          start: `${tasks[i]["interview_startdate"]}T${tasks[i]["interview_starttime"]}:00`,
          end: `${tasks[i]["interview_enddate"]}T${tasks[i]["interview_endtime"]}:00`,
        });
      }
    }
  }, [tasks, calendarRef]);

  const state = {
    weekendsVisible: true,
    currentEvents: [],
  };
  const selectevent = (e) => {
    setId(e.event._def.publicId);
    setShow(true);
  };

  
  return (
    <>
      <Calendar id="calender" style={{flexWrap:"wrap"}}>
        <FullCalendar
          ref={calendarRef}
          eventClick={selectevent}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          initialView="dayGridMonth"
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          weekends={true}
          initialEvents={INITIAL_EVENTS} 
          locales={lang == "my" ? msLocale : enLocale} //translates calendar. options are msLocale(malay) and enLocale(english)
          locale={lang == "my" ? 'ms' : 'en'} //translates calendar. options are ms(malay) and en(englishS)
        />
      </Calendar>
      <InterviewModal open={show} onClose={() => setShow(false)} id={id} />
     
    </>
  );
}

import styled from "styled-components";

import DeleteIcon from "../../../assets/icons/activeDelete.png";
import EditIcon from "../../../assets/svg/pencil.svg";
import { Colors } from "../../../utilities/colors";
import { ConvertDate } from "../utils/date-converter";
import { useTranslation } from "react-i18next";

const SectionDivisionWrapper = styled.div`
  padding: 1rem 1.5rem 1rem 1.5rem;
  background: #fdfdfd;
  border: 1px solid rgba(228, 228, 228, 0.26);
  box-sizing: border-box;
  border-radius: 5px;
  margin: 30px 0;
  overflow: auto;
  @media (max-width: 767px) {
    padding: 0.5rem;
  }
`;
const TableTop = styled.table`
  overflow-x: scroll;
  width: 100%;
`;
const TableHead = styled.th`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.labelText};
  padding: 15px 10px;
`;
const TableData = styled.td`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  padding: 15px 10px;
  color: #525252;
  text-transform: capitalize;
  border-top: 1px solid #ddd;
`;

const Icon = styled.img`
  margin: 0px 5px;
`;
const EditIconW = styled.img`
  color: rgb(208, 76, 141);
  margin: 0px 5px;
`;

export default function EventsTable(props) {
  const { deleteEvent, editEvent } = props;
  const { t } = useTranslation();
  return (
    <SectionDivisionWrapper>
      <TableTop>
        <thead>
          <tr>
            <TableHead>{t("Event Title")}</TableHead>
            <TableHead>{t("Duration Start Date")}</TableHead>
            <TableHead>{t("Duration End Date")}</TableHead>
            <TableHead style={{ width: "400px" }}>{t("Description")}</TableHead>
            <TableHead></TableHead>
            <TableHead></TableHead>
          </tr>
        </thead>
        <tbody>
          {props.data.map((x, index) => {
            const toShow = x.description.substring(0, 40) + "...";
            return (
              <tr key={index}>
                <TableData>{x.event_title}</TableData>
                <TableData>{ConvertDate(x.start_date, "dd/mm/yyyy")}</TableData>
                <TableData>{ConvertDate(x.end_date, "dd/mm/yyyy")}</TableData>
                <TableData>{toShow}</TableData>
                <TableData>
                  <EditIconW onClick={() => editEvent(x.id)} src={EditIcon} />
                </TableData>
                <TableData>
                  <Icon onClick={() => deleteEvent(x.id)} src={DeleteIcon} />
                </TableData>
              </tr>
            );
          })}
        </tbody>
      </TableTop>
    </SectionDivisionWrapper>
  );
}

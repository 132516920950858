import styled from "styled-components";
import { HrLine, StudentModalChartWrapper, StudentModalHeader } from "../commonStyles";
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { PolarArea } from "react-chartjs-2";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next"; //translation

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

const InterestProfileText = styled.div`
  font-family: General Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;

  color: #000000;

  span.introvert {
    color: #d04c8d;
    font-weight: 500;
  }
  span.feeling {
    color: #205195;
    font-weight: 500;
  }
  span.intuitive {
    color: #c25c0a;
    font-weight: 500;
  }
  span.perceiving {
    color: #0f9308;
    font-weight: 500;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const data2 = {
  labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        "rgba(255, 99, 132, 0.5)",
        "rgba(54, 162, 235, 0.5)",
        "rgba(255, 206, 86, 0.5)",
        "rgba(75, 192, 192, 0.5)",
        "rgba(153, 102, 255, 0.5)",
        "rgba(255, 159, 64, 0.5)",
      ],
      borderWidth: 1,
    },
  ],
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function PreferencesModal(props) {
  const [value, setValue] = useState(0);
  const [usableData, setUsableData] = useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fethUpdateData = () => {
    setUsableData(props.labels);
  };
  useEffect(() => {
    fethUpdateData();
  }, []);
  const data91 = {
    labels: props.labels,
    datasets: [
      {
        label: "# of Votes",
        data: props.data,
        backgroundColor: props.colors,
        borderWidth: 1,
      },
    ],
  };
  //translation
  const { t, i18n } = useTranslation();
  const changeLanguageHandler = (lang) => {
    const languageValue = lang;
    i18n.changeLanguage(languageValue);
  };
  // end translation
  return (
    <>
      <StudentModalHeader>{t("Personality & Motivation")}</StudentModalHeader>
      <HrLine />
      <br />
      <FlexWrapper>
        <StudentModalChartWrapper>
          <PolarArea
            data={data91}
            width="400px"
            height="400px"
            options={{
              responsive: false,
              maintainAspectRatio: false,
            }}
          />
          {/* <InterestProfileChart /> */}
        </StudentModalChartWrapper>
      </FlexWrapper>

      {/* <StudentModalChartWrapper>
        <PreferencesChart />
      </StudentModalChartWrapper> */}

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            aria-label="visible arrows tabs example"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                "&.Mui-disabled": { opacity: 0.3 },
              },
            }}
          >
            {usableData.map((e, i) => {
              return <Tab label={t(e)} {...a11yProps(i)} />;
            })}

            {/* <Tab label="Item Two" {...a11yProps(1)} />
            <Tab label="Item Three" {...a11yProps(2)} /> */}
          </Tabs>
        </Box>
        {typeof props.result === "object" &&
        !Array.isArray(props.result) &&
        props.result !== null ? (
          <>
            {" "}
            <TabPanel value={value} index={0}>
              {/* high */}

              {props?.result["Emotional Stability"]["score"] == "High" ? (
                <>
                  <h4>
                    {" "}
                    <span style={{ color: "#d04c8d" }}> {t("High")} </span>
                  </h4>
                  <p>
                    {t(
                      "Emotional Control relates to the talents’ ability to keep their emotions steady and under control in response to life experiences. Some of these words and phrases that describe High Emotional Control could be true for them. Mainly positive emotions about past, present and future - Rarely unhappy or stressed by life - Positive self-esteem - Self-efficacy - Overall satisfaction with life - High level of stress resilience - Accepting of setbacks and disappointments - Tolerant of risk - Resilient - Calm - Confident"
                    )}
                  </p>
                  <ul>
                    <li>
                      {t("Strengths")}
                      <ul>
                        <li>{t("work that is unpredictable")}</li>
                        <li>{t("coping with uncertainty")}</li>
                        <li>{t("activities with some risk")}</li>
                        <li>{t("challenge")}</li>
                        <li>{t("problem solving")}</li>
                      </ul>{" "}
                    </li>
                    <li>
                      {t("Vulnerabilities")}
                      <ul>
                        <li>
                          {t(
                            "to be unaware of when demands on them are becoming detrimental to their well-being."
                          )}
                        </li>
                        <li>
                          {t(
                            "to be too late in placing limits to what they take on"
                          )}
                        </li>
                        <li>{t("to underestimate risk.")}</li>
                        <li>
                          {t(
                            "to fail to empathise with or understand others who have low emotional control."
                          )}{" "}
                        </li>
                        <li>{t("problem solving")}</li>
                      </ul>{" "}
                    </li>
                    <li>
                      {t("Advice for future work")}
                      <ul>
                        <li>
                          {t("Must be willing to take on leadership roles.")}
                        </li>
                        <li>
                          {t(
                            "Must support others to develop emotional regulation skills."
                          )}{" "}
                        </li>
                        <li>
                          {t(
                            "Must look for opportunities for their skills and knowledge to be expanded."
                          )}{" "}
                        </li>
                        <li>
                          {t(
                            "Be aware of the limitations of others with lower emotional control than them."
                          )}
                        </li>
                        <li>
                          {t("Careers")}{" "}
                          <p>
                            {t(
                              "High Emotional Control is valuable in most careers, but especially where risk is greater such as the military, management, aviation, surgery, police and emergency services and self-employment. People with high emotional control more readily consider and implement career changes, work promotions and other transformations."
                            )}{" "}
                          </p>
                        </li>
                      </ul>{" "}
                    </li>
                  </ul>
                </>
              ) : (
                <></>
              )}

              {/* moderate */}

              {props?.result["Emotional Stability"]["score"] == "Moderate" ? (
                <>
                  <h4>
                    {" "}
                    <span style={{ color: "#d04c8d" }}>
                      {" "}
                      {t("Moderate")}{" "}
                    </span>
                  </h4>
                  <p>
                    {t(
                      "Moderate Emotional Control relates for talents to a moderate ability to keep their emotions steady and under control in response to life experiences. Some of these phrases that describe Moderate Emotional Control could be true for them."
                    )}
                    <ul>
                      <li>
                        {t(
                          "Mainly positive emotions about past, present and future."
                        )}{" "}
                      </li>
                      <li>{t("Overall satisfaction with life.")} </li>
                      <li>{t("Moderate levels of stress resilience.")} </li>
                      <li>
                        {t(
                          "Some ability to overcome setbacks and disappointments."
                        )}{" "}
                      </li>
                      <li>{t("Psychological resilience")} </li>
                    </ul>{" "}
                  </p>
                  <ul>
                    <li>
                      {t("Strengths")}
                      <ul>
                        <li>{t("activities with protected risk")}</li>
                        <li>{t("moderate challenge.")}</li>
                        <li>{t("problem solving.")} </li>
                        <li>
                          {t("supporting others with lower emotional control.")}{" "}
                        </li>
                      </ul>{" "}
                    </li>
                    <li>
                      {t("Vulnerabilities")}
                      <ul>
                        <li>{t("to be anxious in high risk situations.")}</li>
                        <li>
                          {t(
                            "to draw back from challenges that would stretch them, but they are capable of doing."
                          )}{" "}
                        </li>
                        <li>
                          {t(
                            "to be held back by others around them with higher levels of anxiety and lower resilience."
                          )}{" "}
                        </li>
                        <li>
                          {t(
                            "to underestimate their resilience under pressure."
                          )}
                        </li>
                      </ul>{" "}
                    </li>
                    <li>
                      {t("Advice for future work")}
                      <ul>
                        <li>
                          {t(
                            "Must not let negative emotions hold them back from  attempting new opportunities, such as self-employment."
                          )}{" "}
                        </li>
                        <li>
                          {t(
                            "Must be aware of the role they have in interfacing between colleagues with high and low emotional control to enable collaboration."
                          )}
                        </li>
                        <li>
                          {t(
                            "They could have a supportive role to colleagues who have lower level of emotional control."
                          )}
                        </li>
                        <li>
                          {t(
                            "Must be aware of their level of stress tolerance and choose environments where this will not be compromised."
                          )}
                        </li>
                        <li>{t("Value their ability to pick up on risk.")}</li>
                      </ul>{" "}
                    </li>
                    <li>
                      {t("Careers")}{" "}
                      <ul>
                        <li>
                          {t(
                            "Moderate Emotional Control is valuable in many careers where there are moderate levels of risk and uncertainty. This may include work where they can play a supportive role to senior leadership and management. Self-employment is also a potential career option."
                          )}
                        </li>
                      </ul>{" "}
                    </li>
                  </ul>
                </>
              ) : (
                <></>
              )}

              {/* low enotional control */}

              {props?.result["Emotional Stability"]["score"] == "Low" ? (
                <>
                  <h4>
                    {" "}
                    <span style={{ color: "#d04c8d" }}>
                      {" "}
                      {t("Low Emotional Control")}{" "}
                    </span>
                  </h4>
                  <p>
                    {t(
                      "Low Emotional Control means that talents experience negative emotions in response to life experiences more frequently than most people. Some of these words and phrases that describe Low Emotional Control could be true for them. Sadness - Low mood – Anxiety – Distress – Anger – Fear - Self-consciousness - Panic"
                    )}
                  </p>
                  <ul>
                    <li>
                      {t("Strengths")}
                      <ul>
                        <li>
                          {t(
                            "making others aware of unforeseen risk and danger."
                          )}{" "}
                        </li>
                        <li>
                          {t(
                            "seeing the problems in pursuing a project or course of action."
                          )}{" "}
                        </li>
                        <li>
                          {t(
                            "identifying with others who struggle with their emotional reactions."
                          )}{" "}
                        </li>
                      </ul>{" "}
                    </li>
                    <li>
                      {t("Vulnerabilities")}
                      <ul>
                        <li>
                          {t(
                            "problems with their mental and emotional wellbeing."
                          )}{" "}
                        </li>
                        <li>
                          {t(
                            "diminished ability to think clearly and make rational decisions."
                          )}
                        </li>
                        <li>{t("fail to cope effectively with stress.")}</li>
                        <li>
                          {t(
                            "to interpret ordinary situations as threatening."
                          )}{" "}
                        </li>
                        <li>
                          {t(
                            "to view minor frustrations as hopelessly difficult."
                          )}{" "}
                        </li>
                        <li>{t("to be in a consistently bad mood.")} </li>
                      </ul>{" "}
                    </li>
                    <li>
                      {t("Advice for future work")}
                      <ul>
                        <li>
                          {t(
                            "Must become aware of and acknowledge their feelings."
                          )}
                        </li>
                        <li>
                          {t(
                            "Must be confident that emotional control can be developed."
                          )}
                        </li>
                        <li>{t("Must ask for support.")}</li>
                        <li>{t("Must recognise their achievements.")}</li>
                        <li>
                          {t(
                            "Must practice relaxation and mindfulness to control their moods."
                          )}{" "}
                        </li>
                        <li>
                          {t(
                            "Must seek practical support to build stress resilience skills."
                          )}
                        </li>
                        <li>
                          {t("Careers")}{" "}
                          <p>
                            {t(
                              "People with Low Emotional Control prefer work they can do at their own pace and where they can control the pressure involved, such as work that is predictable and routine. They prefer work that is within their skills set. They can also succeed in creative work in the Arts and Music."
                            )}
                          </p>
                        </li>
                      </ul>{" "}
                    </li>
                  </ul>
                </>
              ) : (
                <></>
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {/* high */}

              {props?.result["Extraversion"]["score"] == "High" ? (
                <>
                  <h4>
                    {" "}
                    <span style={{ color: "#d04c8d" }}> {t("High")} </span>
                  </h4>
                  <p>
                    {t(
                      "As Extraverts talents relate more strongly to their external world than their internal world. Some of these words and phrases that describe extraverts could be true for them. Creative - Adventurous - Imaginative - Think of new ideas - Friendliness – Gregarious - Assertiveness - Energetic - Excitement-seeking - Sociable – Outgoing - Take the lead - Enjoy large gatherings - Enjoy attention"
                    )}
                  </p>
                  <ul>
                    <li>
                      {t("Strengths")}
                      <ul>
                        <li>
                          {t(
                            "social situations where they can express their ideas."
                          )}{" "}
                        </li>
                        <li>
                          {t(
                            "engaging in projects to which they can contribute creatively through fresh ideas and approaches."
                          )}{" "}
                        </li>
                        <li>
                          {t(
                            "investing effort and energy in projects that motivate them."
                          )}
                        </li>
                      </ul>{" "}
                    </li>
                    <li>
                      {t("Vulnerabilities")}
                      <ul>
                        <li>
                          {t(
                            "to be dominant in social contexts and less aware of what Introverts could contribute if given opportunity to do so."
                          )}
                        </li>
                        <li>
                          {t(
                            "to neglect the routine and mundane aspects of bringing ideas to reality."
                          )}
                        </li>
                      </ul>{" "}
                    </li>
                    <li>
                      {t("Advice for future work")}
                      <ul>
                        <li>
                          {t(
                            "Must seek to be part of a balanced team where others complement their Extraversion."
                          )}
                        </li>
                        <li>
                          {t(
                            "Must give others opportunities to participate and share ideas and experience."
                          )}
                        </li>
                        <li>{t("Must practice active listening.")}</li>
                        <li>
                          {t("Careers")}{" "}
                          <p>
                            {t(
                              "There is an important role for Extraverts in most careers that involve social interactions with management teams, staff or the public and where creative thinking and leadership are valued: for example sales, entrepreneurial activity, senior management, entertainment and politics."
                            )}
                          </p>
                        </li>
                      </ul>{" "}
                    </li>
                  </ul>
                </>
              ) : (
                <></>
              )}

              {/* moderate */}

              {props?.result["Extraversion"]["score"] == "Moderate" ? (
                <>
                  <h4>
                    {" "}
                    <span style={{ color: "#d04c8d" }}>
                      {" "}
                      {t("Moderate")}{" "}
                    </span>
                  </h4>
                  <p>
                    {t(
                      "They relate closely to both their external and internal worlds and have characteristics of Extraversion and Introversion. Some of these words and phrases that describe Extraverts could be true for them. Creative - Adventurous - Imaginative - Think of new ideas - Friendliness - Energetic - Excitement-seeking - Sociable – Outgoing - Take the lead - Content in their own company - A listener - Emotional regulation - Self-reflection"
                    )}
                  </p>
                  <ul>
                    <li>
                      {t("Strengths")}
                      <ul>
                        <li>{t("a wide range of work environments.")} </li>
                        <li>{t("a balance between social and solo time.")} </li>
                      </ul>{" "}
                    </li>
                    <li>
                      {t("Vulnerabilities")}
                      <ul>
                        <li>
                          {t(
                            "to need a balance between time on their own and time with other people."
                          )}
                        </li>
                        <li>
                          {t(
                            "to lose motivation, energy and concentration if the balance swings too far in one direction."
                          )}
                        </li>
                      </ul>{" "}
                    </li>
                    <li>
                      {t("Advice for future work")}
                      <ul>
                        <li>
                          {t(
                            "Must be aware of when they need to spend time with others or time on their own."
                          )}
                        </li>
                        <li>
                          {t(
                            "Must have a strategy for what to do when they realise they need more interaction with either their external world or internal world to function at their best."
                          )}
                        </li>
                        <li>
                          {t("Careers")}{" "}
                          <p>
                            {t(
                              "Moderate Extraverts have an important role in most careers because they can balance social contexts as well as independent work. They have a special role in interfacing between extravert and introvert colleagues to bring collaboration and unity of purpose."
                            )}
                          </p>
                        </li>
                      </ul>{" "}
                    </li>
                  </ul>
                </>
              ) : (
                <></>
              )}

              {/* low enotional control */}

              {props?.result["Extraversion"]["score"] == "Low" ? (
                <>
                  <h4>
                    {" "}
                    <span style={{ color: "#d04c8d" }}>
                      {" "}
                      {t("Low Extravert – High Introvert")}{" "}
                    </span>
                  </h4>
                  <p>
                    {t(
                      "As an Introvert they relate more strongly to their internal world than their external world. Some of these words and phrases that describe Introverts could be true for them. content in their own company – thinker - not assertive or talkative - a listener - like predictability - relaxed pace of life - self-control - regular habits and schedules - enjoy privacy - self-reflection"
                    )}
                  </p>
                  <ul>
                    <li>
                      {t("Strengths")}
                      <ul>
                        <li>{t("working independently.")}</li>
                        <li>{t("doing work to their own satisfaction.")}</li>
                        <li>
                          {t(
                            "knowing exactly what is expected of them in a team or project."
                          )}
                        </li>
                      </ul>{" "}
                    </li>
                    <li>
                      {t("Vulnerabilities")}
                      <ul>
                        <li>
                          {t(
                            "to be overwhelmed by too much social interaction and large groups."
                          )}{" "}
                        </li>
                        <li>
                          {t(
                            "to miss out being recognised or promoted because they are more retiring."
                          )}
                        </li>
                      </ul>{" "}
                    </li>
                    <li>
                      {t("Advice for future work")}
                      <ul>
                        <li>
                          {t(
                            "Must look for work that allows them to work independently and at their own pace."
                          )}
                        </li>
                        <li>
                          {t(
                            "Must not rule themselves out of promotion or leadership roles because there are more Extravert candidates."
                          )}
                        </li>
                        <li>
                          {t(
                            "Must learn to say ‘no’ to people and places that drain them, but seek out people who are supportive."
                          )}
                        </li>
                        <li>
                          {t(
                            "Must take steps to increase their confidence for speaking in social and work situations."
                          )}
                        </li>

                        <li>
                          {t("Careers")}{" "}
                          <p>
                            {t(
                              "There is an important role for Introverts in most careers. Introverts find fulfilment in roles such as writing, creative and artistic work, performance entertainment, software development, investigative research and self-employment."
                            )}
                          </p>
                        </li>
                      </ul>{" "}
                    </li>
                  </ul>
                </>
              ) : (
                <></>
              )}
            </TabPanel>
            <TabPanel value={value} index={2}>
              {/* high */}

              {props?.result["Openness to Experience"]["score"] == "High" ? (
                <>
                  <h4>
                    {" "}
                    <span style={{ color: "#d04c8d" }}> {t("High")} </span>
                  </h4>
                  <p>
                    {t(
                      "They are high in Openness which means they are open to new ideas and new experiences. Some of these words and phrases that describe Openness could be true for them. Imaginative – Creative - Individualistic and non-conforming - Intellectually curious -Artistic interests – Adventurous - Abstract, symbolic thinking - Broad interests - Express feeling readily - Challenge"
                    )}
                  </p>
                  <ul>
                    <li>
                      {t("Strengths")}
                      <ul>
                        <li>
                          {t(
                            "being open-minded and exploring new ideas and experiences."
                          )}{" "}
                        </li>
                        <li>{t("learning new skills or knowledge.")} </li>
                        <li>
                          {t(
                            "working where there is ambiguity and uncertainty."
                          )}{" "}
                        </li>
                        <li>
                          {t("seeing things from different perspectives.")}{" "}
                        </li>
                      </ul>{" "}
                    </li>
                    <li>
                      {t("Vulnerabilities")}
                      <ul>
                        <li>
                          {t("to have difficulty in completing routine tasks.")}
                        </li>
                        <li>
                          {t(
                            "to be unhappy in a role that does not provide opportunities to be creative and explore new ideas and experiences."
                          )}
                        </li>
                      </ul>{" "}
                    </li>
                    <li>
                      {t("Advice for future work")}
                      <ul>
                        <li>
                          {t(
                            "Must look for a career that involves variety and creativity."
                          )}
                        </li>
                        <li>
                          {t(
                            "Must make sure they bring their ideas to reality."
                          )}
                        </li>
                        <li>
                          {t(
                            "Must spend time with people from other fields of work to expand their ideas and experience."
                          )}
                        </li>
                        <li>
                          {t(
                            "Must be careful to value others with whom they work, who are good completing routine tasks."
                          )}
                        </li>
                        <li>
                          {t("Careers")}{" "}
                          <p>
                            {t(
                              "There is an important role for people who are high in Openness in many careers that involve openness to discovering new information, approaches or experiences such as research and experimentation, creative and artistic work, design, writing, entrepreneurial activity, sales, marketing and performance entertainment."
                            )}
                          </p>
                        </li>
                      </ul>{" "}
                    </li>
                  </ul>
                </>
              ) : (
                <></>
              )}

              {/* moderate */}

              {props?.result["Openness to Experience"]["score"] ==
              "Moderate" ? (
                <>
                  <h4>
                    {" "}
                    <span style={{ color: "#d04c8d" }}> {t("Moderate")}</span>
                  </h4>
                  <p>
                    {t(
                      "Moderate Openness indicates their ability to adapt to routines and their willingness to explore new ideas and experiences. Some of these words and phrases that describe people with Moderate Openness could be true for them. Imaginative – Creative - Individualistic and non-conforming - Intellectually curious -Artistic interests – Adventurous - Broad interests - Express feeling readily - Down to earth – Conventional - Balance between familiarity and novelty - Conforming."
                    )}
                  </p>
                  <ul>
                    <li>
                      {t("Strengths")}
                      <ul>
                        <li>
                          {t("being open-minded to new ideas and experiences.")}{" "}
                        </li>
                        <li>{t("learning new skills or knowledge.")} </li>
                        <li>
                          {t("seeing things from different perspectives.")}{" "}
                        </li>
                        <li>
                          {t("completing tasks and following procedures.")}{" "}
                        </li>
                      </ul>{" "}
                    </li>
                    <li>
                      {t("Vulnerabilities")}
                      <ul>
                        <li>
                          {t(
                            "to be content to follow routines and established procedures and miss opportunities to gain new experiences and knowledge or contribute creative ideas in work."
                          )}
                        </li>
                        <li>
                          {t(
                            "to remain in a role that does not provide opportunities to be creative and explore new ideas and experiences."
                          )}
                        </li>
                      </ul>{" "}
                    </li>
                    <li>
                      {t("Advice for future work")}
                      <ul>
                        <li>
                          {t(
                            "If they are mainly engaged in routine work, must find opportunities for creative activities as well."
                          )}
                        </li>
                        <li>
                          {t(
                            "Must take advantage of opportunities to enhance their present skills, knowledge and experience."
                          )}
                        </li>
                        <li>
                          {t(
                            "Must be willing to volunteer to take the lead in projects."
                          )}
                        </li>
                        <li>
                          {t(
                            "Must congratulate oneself when they step out of their comfort zone as it will build their confidence."
                          )}
                        </li>
                        <li>
                          {t("Careers")}{" "}
                          <p>
                            {t(
                              "There is an important role for people who are moderate in Openness in most careers. The ability to pivot from creative thinking and new experiences to routine procedures and tasks gives a flexibility to thrive in most working environments from administration and management to creative design and software coding."
                            )}
                          </p>
                        </li>
                      </ul>{" "}
                    </li>
                  </ul>
                </>
              ) : (
                <></>
              )}

              {/* low enotional control */}

              {props?.result["Openness to Experience"]["score"] == "Low" ? (
                <>
                  <h4>
                    {" "}
                    <span style={{ color: "#d04c8d" }}> {t("Low")} </span>
                  </h4>
                  <p>
                    {t(
                      "Low Openness indicates their preference for routine, predictability, rituals, and regular habits. Predictability gives a sense of security and control over their environment, schedule and life. Some of these words and phrases that describe Low Openness could be true for them. Down to earth – Conventional – Concrete thinking - Focused interests rather than broad range of interests - Prefer familiarity over novelty - Emotionally reserved - Conforming"
                    )}
                  </p>
                  <ul>
                    <li>
                      {t("Strengths")}
                      <ul>
                        <li>{t("organising.")}</li>
                        <li>{t("following procedures.")}</li>
                        <li>{t("completing tasks.")}</li>
                      </ul>{" "}
                    </li>
                    <li>
                      {t("Vulnerabilities")}
                      <ul>
                        <li>
                          {t(
                            "to find it hard to adapt to change or the unexpected."
                          )}
                        </li>
                        <li>
                          {t(
                            "to have difficulty working with ambiguity and uncertainty."
                          )}
                        </li>
                        <li>
                          {t(
                            "to feel stress when working with people who do not keep to procedures and rules."
                          )}
                        </li>
                      </ul>{" "}
                    </li>
                    <li>
                      {t("Advice for future work")}
                      <ul>
                        <li>
                          {t(
                            "Must take the lead in setting up processes for projects."
                          )}
                        </li>
                        <li>
                          {t(
                            "Must let others know of their preference for predictability and their need to know of changes in advance."
                          )}
                        </li>
                        <li>
                          {t(
                            "Must offer to organise 'traditions' in their family or work."
                          )}
                        </li>
                        <li>
                          {t(
                            "When facing change, must find out as much information as possible in advance."
                          )}
                        </li>
                        <li>
                          {t(
                            "Must plan ahead for unexpected events, for example ‘if this happens, I will…’"
                          )}
                        </li>
                        <li>
                          {t(
                            "Must reflect on how they have successfully adapted to change in the past."
                          )}
                        </li>
                        <li>
                          {t("Careers")}{" "}
                          <p>
                            {t(
                              "People who are low in Openness have important roles in most careers, especially where following processes and established procedures are involved, such as administration, management, supervision of people or things, completing tasks to a high standard."
                            )}
                          </p>
                        </li>
                      </ul>{" "}
                    </li>
                  </ul>
                </>
              ) : (
                <></>
              )}
            </TabPanel>
            <TabPanel value={value} index={3}>
              {/* high */}

              {props?.result["Agreeableness"]["score"] == "High" ? (
                <>
                  <h4>
                    {" "}
                    <span style={{ color: "#d04c8d" }}> {t("High")} </span>
                  </h4>
                  <p>
                    {t(
                      "High Agreeableness means talents are motivated to promote cooperation and harmony with others. Some of these words and phrases that describe agreeableness could be true for them: Compliant - Kind - Trusting – Conciliatory – Considerate – Friendly - Generous - Helpful - Willingness to compromise to others’ interests - Optimistic view of people"
                    )}
                  </p>
                  <ul>
                    <li>
                      {t("Strengths")}
                      <ul>
                        <li>
                          {t("working with and getting on with other people.")}{" "}
                        </li>
                        <li>{t("being popular.")} </li>
                        <li>{t("understanding other’s point of view.")} </li>
                        <li>{t("actively listening to others.")} </li>
                        <li>{t("collaborating with colleagues.")} </li>
                      </ul>{" "}
                    </li>
                    <li>
                      {t("Vulnerabilities")}
                      <ul>
                        <li>{t("to fail to express them views.")}</li>
                        <li>
                          {t("to fail to make objective tough decisions.")}{" "}
                        </li>
                        <li>
                          {t("to avoid confrontation when it is appropriate.")}{" "}
                        </li>
                        <li>{t("to hide what they really think.")}</li>
                        <li>
                          {t("to avoid conflict when it is appropriate.")}
                        </li>
                        <li>
                          {t(
                            "to be weak in bargaining and negotiating for their own interests."
                          )}{" "}
                        </li>
                      </ul>{" "}
                    </li>
                    <li>
                      {t("Advice for future work")}
                      <ul>
                        <li>
                          {t(
                            "Consider careers that are associated with people."
                          )}{" "}
                        </li>
                        <li>
                          {t(
                            "Must not their agreeableness to make them compliant when they need to disagree."
                          )}
                        </li>
                        <li>
                          {t(
                            "Must beware of taking on responsibilities too readily to help others."
                          )}
                        </li>
                        <li>
                          {t(
                            "Observe less agreeable people to learn how to be more assertive."
                          )}
                        </li>
                        <li>
                          {t("Careers")}{" "}
                          <p>
                            {t(
                              "High Agreeableness is highly valued, especially in careers involving people, such as  teaching and caring professions. It is a valuable trait in being part of a team, but less so in leading a team, where difficult decisions about people may need to be made."
                            )}
                          </p>
                        </li>
                      </ul>{" "}
                    </li>
                  </ul>
                </>
              ) : (
                <></>
              )}

              {/* moderate */}

              {props?.result["Agreeableness"]["score"] == "Moderate" ? (
                <>
                  <h4>
                    {" "}
                    <span style={{ color: "#d04c8d" }}> {t("Moderate")} </span>
                  </h4>
                  <p>
                    {t(
                      "Combines a balance of being agreeable and disagreeable, which means the talent is able to get on with others well but also to be more objective in relating to others when it is appropriate. Some of these words and phrases that describe Moderate Agreeableness could be true for them. Compliant - Kind - Trusting – Conciliatory – Considerate – Friendly - Generous - Helpful - Willingness to compromise to others’ interests - Optimistic view of people - Assertive – Sceptical – Confident - Honest"
                    )}
                  </p>
                  <ul>
                    <li>
                      {t("Strengths")}
                      <ul>
                        <li>
                          {t("working with and getting on with other people.")}{" "}
                        </li>
                        <li>{t("understanding other’s point of view.")} </li>
                        <li>{t("actively listening to others.")} </li>
                        <li>{t("collaborating with colleagues.")} </li>
                      </ul>{" "}
                    </li>
                    <li>
                      {t("Vulnerabilities")}
                      <ul>
                        <li>{t("to fail to them express their views.")}</li>
                        <li>
                          {t("to fail to make objective tough decisions.")}
                        </li>
                        <li>{t("to hide what they really think.")}</li>
                        <li>
                          {t("to avoid conflict when it is appropriate.")}
                        </li>
                        <li>
                          {t(
                            "to be weak in bargaining and negotiating for their own interests."
                          )}
                        </li>
                      </ul>{" "}
                    </li>
                    <li>
                      {t("Advice for future work")}
                      <ul>
                        <li>
                          {t(
                            "Must consider careers that are associated with people."
                          )}
                        </li>
                        <li>
                          {t(
                            "Be prepared to express your views when not in agreement."
                          )}
                        </li>
                        <li>
                          {t(
                            "Value your ability to represent others who are high in agreeableness and have difficulty expressing themselves."
                          )}
                        </li>
                        <li>
                          {t("Careers")}{" "}
                          <p>
                            {t(
                              "Agreeableness is highly valued, especially in careers involving people, such as teaching and caring professions. It is a valuable trait in being part of a team and in leadership. Develop your weaker traits to widen your career choices."
                            )}
                          </p>
                        </li>
                      </ul>{" "}
                    </li>
                  </ul>
                </>
              ) : (
                <></>
              )}

              {/* low enotional control */}

              {props?.result["Agreeableness"]["score"] == "Low" ? (
                <>
                  <h4>
                    {" "}
                    <span style={{ color: "#d04c8d" }}>
                      {" "}
                      {t("Low Emotional Control")}{" "}
                    </span>
                  </h4>
                  <p>
                    {t(
                      "Low Agreeableness is associated with the ability to place self-interest above getting along with others and being less concerned about other’s wellbeing. Some of these words that describe Low Agreeableness could be true for the talent. Suspicious - Self-confident - Sceptical - Competitive – Uncooperative – Forthright- Opinionated – Confrontational - Honest"
                    )}
                  </p>
                  <ul>
                    <li>
                      {t("Strengths")}
                      <ul>
                        <li>{t("expressing their point of view.")}</li>
                        <li>
                          {t("getting others to agree with them or comply.")}
                        </li>
                        <li>{t("prioritising their own interests.")}</li>
                        <li>{t("negotiation and bargaining.")}</li>
                      </ul>{" "}
                    </li>
                    <li>
                      {t("Vulnerabilities")}
                      <ul>
                        <li>{t("to override the views of others.")}</li>
                        <li>{t("to fail to actively listen.")}</li>
                        <li>
                          {t(
                            "to be unaware of what others are really thinking."
                          )}{" "}
                        </li>
                        <li>{t("to be unaware of offending others.")} </li>
                      </ul>{" "}
                    </li>
                    <li>
                      {t("Advice for future work")}
                      <ul>
                        <li>
                          {t(
                            "Must work on listening more to the views of others."
                          )}{" "}
                        </li>
                        <li>
                          {t(
                            "Must be prepared to compromise when there are strong reasons for doing so."
                          )}
                        </li>
                        <li>
                          {t(
                            "Must observe agreeable people to learn how to develop your social skills."
                          )}{" "}
                        </li>
                        <li>
                          {t("Careers")}{" "}
                          <p>
                            {t(
                              "People with Low Agreeableness are commonly engaged in careers involving machines and technology such as engineering and the trades. It is a common trait in entrepreneurs."
                            )}
                          </p>
                        </li>
                      </ul>{" "}
                    </li>
                  </ul>
                </>
              ) : (
                <></>
              )}
            </TabPanel>
            <TabPanel value={value} index={4}>
              {/* high */}

              {props?.result["Conscientiousness"]["score"] == "High" ? (
                <>
                  <h4>
                    {" "}
                    <span style={{ color: "#d04c8d" }}> {t("High")} </span>
                  </h4>
                  <p>
                    {t(
                      "Talent is strong in controlling and regulating his/her life and work.  Some of these words and phrases that describe Conscientiousness could be true for the talent. Dependability - Hard working - Self-efficacy - Orderliness - Dutifulness – Diligent Ambition- Striving for achievement - Sacrificing gratification for a goal- Self-discipline - Persistence"
                    )}
                  </p>
                  <ul>
                    <li>
                      {t("Strengths")}
                      <ul>
                        <li>
                          {t("setting goals and striving to achieve them.")}
                        </li>
                        <li>
                          {t(
                            "organising themselves and others to achieve an objective."
                          )}{" "}
                        </li>
                        <li>{t("hard and challenging work.")} </li>
                        <li>{t("being considered reliable.")} </li>
                        <li>{t("a fast-paced environment.")} </li>
                      </ul>{" "}
                    </li>
                    <li>
                      {t("Vulnerabilities")}
                      <ul>
                        <li>
                          {t(
                            "to be prone to stress and driven to achieve unreachable goals."
                          )}
                        </li>
                        <li>
                          {t(
                            "to place unrealistic demands on others around them, especially if they do not match your level of Conscientiousness."
                          )}
                        </li>
                      </ul>{" "}
                    </li>
                    <li>
                      {t("Advice for future work")}
                      <ul>
                        <li>
                          {t(
                            "Must look for work that is challenging and allows them to aim for and achieve goals."
                          )}{" "}
                        </li>
                        <li>
                          {t(
                            "Be conscientious about taking breaks from work to do things they enjoy for their own sake."
                          )}
                        </li>

                        <li>
                          {t("Careers")}{" "}
                          <p>
                            {t(
                              "High Conscientiousness is valued in most careers and by most employers and is a valuable trait for recruitment and promotion. It is a characteristic that is valued in senior management in organisations, as well as a key trait in entrepreneurs."
                            )}
                          </p>
                        </li>
                      </ul>{" "}
                    </li>
                  </ul>
                </>
              ) : (
                <></>
              )}

              {/* moderate */}

              {props?.result["Conscientiousness"]["score"] == "Moderate" ? (
                <>
                  <h4>
                    {" "}
                    <span style={{ color: "#d04c8d" }}> {t("Moderate")}</span>
                  </h4>
                  <p>
                    {t(
                      "They can adapt to being disciplined and achievement-orientated when this is required or when they are personally motivated towards a goal. They can also be more relaxed and work at a slower pace when it is appropriate. Some of these words and phrases describing high and low Conscientiousness could be true for them. Dependability - Hard working - Self-efficacy - Diligent- Sacrificing gratification for a goal - Self-discipline - Persistence - Focused - Relaxed - Interesting to be around"
                    )}
                  </p>
                  <ul>
                    <li>
                      {t("Strengths")}
                      <ul>
                        <li>
                          {t(
                            "a wide range of work environments, provided they are able to balance high intensity work towards goals with less intense and goal-orientated work."
                          )}
                        </li>
                        <li>
                          {t(
                            "a fast-paced environment if it is not sustained for long periods."
                          )}
                        </li>
                        <li>
                          {t(
                            "interfacing between colleagues and staff who are high or low in Conscientiousness."
                          )}
                        </li>
                      </ul>{" "}
                    </li>
                    <li>
                      {t("Vulnerabilities")}
                      <ul>
                        <li>
                          {t(
                            "to lose motivation if they experience prolonged intensity in goal-orientated work."
                          )}
                        </li>
                        <li>
                          {t(
                            "to vary in their focus depending on how much they are motivated by their work."
                          )}
                        </li>
                        <li>
                          {t(
                            "to limit to how much they are willing to sacrifice to achieve objectives."
                          )}
                        </li>
                      </ul>{" "}
                    </li>
                    <li>
                      {t("Advice for future work")}
                      <ul>
                        <li>
                          {t(
                            "If possible, must schedule more demanding tasks for the time of day when they do your best work."
                          )}
                        </li>
                        <li>
                          {t(
                            "Must avoid long periods of intense work without breaks for relaxation or less intense activity."
                          )}
                        </li>
                        <li>
                          {t("Careers")}{" "}
                          <p>
                            {t(
                              "People who are moderate in Conscientiousness are adaptable to many different work contexts and careers. Their level of Conscientiousness makes them employable and also improves their prospects for promotion."
                            )}
                          </p>
                        </li>
                      </ul>{" "}
                    </li>
                  </ul>
                </>
              ) : (
                <></>
              )}

              {/* low enotional control */}

              {props?.result["Conscientiousness"]["score"] == "Low" ? (
                <>
                  <h4>
                    {" "}
                    <span style={{ color: "#d04c8d" }}> {t("Low")}</span>
                  </h4>
                  <p>
                    {t(
                      "They are more relaxed about your life and less interested in controlling and regulating their life and work. It does not mean they are not reliable or hardworking, but that they are less goal-orientated and are more motivated by enjoying their life in the present than reaching for future rewards. Some of these phrases describing Low Conscientiousness could be true for them. Not driven to achieve - Lack of focus - Less observant of rules and boundaries - Interesting to be around – Relaxed - Unmotivated at times"
                    )}
                  </p>
                  <ul>
                    <li>
                      {t("Strengths")}
                      <ul>
                        <li>
                          {t("living life in the present and avoiding stress.")}
                        </li>
                        <li>
                          {t(
                            "being spontaneous and engaging in what interests you personally."
                          )}
                        </li>
                      </ul>{" "}
                    </li>
                    <li>
                      {t("Vulnerabilities")}
                      <ul>
                        <li>
                          {t(
                            "to miss out on opportunities for employment and promotion."
                          )}
                        </li>
                        <li>
                          {t(
                            "to cause you to fail to organise their life to achieve a desired goal, even though they have the skills and knowledge."
                          )}
                        </li>
                      </ul>{" "}
                    </li>
                    <li>
                      {t("Advice for future work")}
                      <ul>
                        <li>
                          {t(
                            "Must watch other people around them, who are more conscientious, for habits or behaviours that they could introduce into their life. This will help them improve their prospects for recruitment and promotion."
                          )}
                        </li>
                        <li>
                          {t(
                            "Low Conscientiousness can be improved by taking a role that involves hard work and responsibility."
                          )}
                        </li>

                        <li>
                          {t("Careers")}{" "}
                          <p>
                            {t(
                              "People who are low in Conscientiousness can find careers where they can work at their own pace and to their own standards to be most satisfying and successful."
                            )}
                          </p>
                        </li>
                      </ul>{" "}
                    </li>
                  </ul>
                </>
              ) : (
                <></>
              )}
            </TabPanel>
          </>
        ) : (
          <div>{t("Assessment Not Completed")}</div>
        )}
      </Box>
    </>
  );
}


import styled from "styled-components";
import Input from "../input/input";
import { Form } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import "./interview.css";
import { useState } from "react";
import { useSnapshot } from "valtio";
import { mycalendarProxy } from "../../pages/myCalendar/mycalendarproxy";
import { useEffect } from "react";
import { changApplications } from "../../services/apiCalls";
import { toast } from "react-toastify";

const CardsWrapper = styled.div`
  position: absolute;
  width: 867px;
  height: 706px;
  top: 20%;
  background: #ffffff;
  border-radius: 10px;
  margin-top: -70px;

  @media (max-width: 500px) {
    width: 386px;
    position: relative;
    padding-top: 10px;
    height: 108vh;
  }
`;
const CardWrapper = styled.div`
  background: rgba(83, 80, 141, 0.1);
  border-radius: 24px 24px 0px 0px;
  height: 86px;
  margin: 10px;
  display: flex;
`;
const Icon = styled.div`
  position: relative;
  top: 5px;
  left: 590px;
  @media (max-width: 500px) {
    right: 120px;
  }
`;
const Title = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #d04c8d;
  padding: 20px 50px;
`;
const Content = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  padding: 50px 0px;
  margin: -10px 0 0 -135px;
`;
const FormLabel = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;

  margin: 20px 0 0 10px;
`;
const Wrapper = styled.div`
  position: relative;
  width: 85%;
  height: 128px;
  left: 77px;
  //bottom: 20px;
  Input {
    height: 40px;
  }
  @media (max-width: 500px) {
    left: auto;
    margin-left: 10px;
    Input {
      height: 40px;
      width: 70%;
    }
  }
`;

const Wrapper2 = styled.div`
  position: relative;
  width: 501px;
  height: 128px;
  left: 77px;
  top: 170px;
  magin: 10px 0 0 0;
  @media (max-width: 500px) {
    position: relative;
    /* width: 501px; */
    height: 128px;
    left: 19px;
    top: 23%;
  }
`;

const Span = styled.span`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #202020;
`;

const InterviewModal = ({ open, onClose, id }) => {
  useSnapshot(mycalendarProxy);
  const [ch, setCh] = useState(0);
  const [title, setTitle] = useState("");
  const [addemailsvalue, setAddemailsvalue] = useState("");
  const [addemails, setAddemails] = useState("");
  const [stdate, setSttdate] = useState(null);
  const [sttime, setSttime] = useState(null);
  const [endate, setEntdate] = useState(null);
  const [entime, setEntime] = useState(null);
  const [change, setChange] = useState(0);
  const [doc, setDoc] = useState("");
  const [dis, setDis] = useState(true);
  const [errormsg, setErrormsg] = useState("");

  const [docvalue, setDocvalue] = useState("");
  const [doclist, setDoclist] = useState([
    "Resume",
    "Cover Letter",
    "Transcripts",
    "Writing Sample",
  ]);

  useEffect(async () => {
    checkdis();
  }, [title, addemails, stdate, sttime, endate, entime, doc]);

  useEffect(async () => {
    for (let i = 0; i < mycalendarProxy.applist.length; i++) {
      console.log(mycalendarProxy.applist[i]["id"], id);
      if (mycalendarProxy.applist[i]["id"] == id) {
        let l = mycalendarProxy.applist[i];
        if (ch == 0 && title != l["interview_title"]) {
          setTitle(l["interview_title"]);
        }
        if (ch == 0 && addemails != l["attendees_email"]) {
          setAddemails(l["attendees_email"]);
        }
        if (ch == 0 && doc != l["attendees_documents"]) {
          setDoc(l["attendees_documents"]);
          setDoclist(l["attendees_documents"].split(","));
        }
        if (ch == 0 && stdate != l["interview_startdate"]) {
          setSttdate(l["interview_startdate"]);
        }
        if (ch == 0 && endate != l["interview_enddate"]) {
          setEntdate(l["interview_enddate"]);
        }
        if (ch == 0 && sttime != l["interview_starttime"]) {
          setSttime(l["interview_starttime"]);
        }
        if (ch == 0 && endate != l["interview_endtime"]) {
          setEntime(l["interview_endtime"]);
        }

        setCh(1);

      }
    }
  }, [id]);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const submit = async () => {
    let l = {
      interview_startdatetime:
        stdate.replaceAll("-", "") + sttime.replaceAll(":", ""),
      interview_enddatetime:
        endate.replaceAll("-", "") + entime.replaceAll(":", ""),
      interview_title: title,
      interview_startdate: stdate,
      interview_enddate: endate,
      interview_starttime: sttime,
      interview_endtime: entime,
      attendees_email: addemails,
      attendees_documents: doc,
    };

    const { data } = await changApplications("interview", id, l);
    if (data && data["status"] == 0) {
      toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      close();
    }
  };
  const checkdis = () => {
    if (title && addemails && stdate && sttime && endate && entime && doc) {
      if (
        title.length > 0 &&
        addemails.length > 0 &&
        stdate.length > 0 &&
        sttime.length > 0 &&
        endate.length > 0 &&
        entime.length > 0 &&
        doc.length > 0 &&
        errormsg.length == 0
      ) {
        setDis(false);
      } else {
        setDis(true);
      }
    }
  };
  const [opens, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(async () => {
    if (stdate && sttime && endate && entime && addemails.length > 0) {
      let interview_startdatetime =
        stdate.replaceAll("-", "") + sttime.replaceAll(":", "");
      let interview_enddatetime =
        endate.replaceAll("-", "") + entime.replaceAll(":", "");

      let em = addemails.split(",");

      if (
        interview_startdatetime.length == 12 &&
        interview_enddatetime.length == 12
      ) {
        console.clear();
        for (let j = 0; j < em.length; j++) {
          for (let i = 0; i < mycalendarProxy.applist.length; i++) {
            if (
              mycalendarProxy.applist[i]["interview_startdatetime"] &&
              mycalendarProxy.applist[i]["interview_enddatetime"] &&
              mycalendarProxy.applist[i]["attendees_email"]
            ) {
              let a = Number(
                mycalendarProxy.applist[i]["interview_startdatetime"]
              );
              let b = Number(
                mycalendarProxy.applist[i]["interview_enddatetime"]
              );
              let c = Number(interview_startdatetime);
              let d = Number(interview_enddatetime);
              if (
                ((a <= c && b >= c) || (a <= d && b >= d)) &&
                mycalendarProxy.applist[i]["attendees_email"].indexOf(em[j]) !=
                -1 &&
                mycalendarProxy.applist[i]["id"] != id
              ) {
                setErrormsg(
                  em[j] +
                  ` alredy schedule interview ${mycalendarProxy.applist[i]["interview_startdate"]} ${mycalendarProxy.applist[i]["interview_starttime"]} - ${mycalendarProxy.applist[i]["interview_enddate"]} ${mycalendarProxy.applist[i]["interview_enddatetime"]}`
                );
                i = mycalendarProxy.applist.length + 1;
                j = em.length + 1;
              } else {
                setErrormsg("");
              }
            }
          }
        }
      }
    }
  }, [addemails, stdate, sttime, entime]);

  const close = () => {
    if (mycalendarProxy.setcheck == 0) {
      mycalendarProxy.setcheck = 1;
    } else {
      mycalendarProxy.setcheck = 0;
    }
    onClose();
  };
  const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const deletefu = async () => {
    const { data } = await changApplications("shortlist", id, {});
    close();
  };

  const adddoc = (d) => {
    console.log(d);
    if (doc.indexOf(d) == -1) {
      if (doc.length == 0) {
        setDoc(d);
      } else {
        setDoc(doc + "," + d);
      }
    } else {
      if (doc.indexOf(d + ",") != -1) {
        setDoc(doc.replace(d + ",", ""));
      } else {
        if (doc.indexOf("," + d) != -1) {
          setDoc(doc.replace("," + d, ""));
        } else {
          if (doc.indexOf(d) != -1) {
            setDoc(doc.replace(d, ""));
          }
        }
      }
    }
    if (change == 0) {
      setChange(1);
    } else {
      setChange(0);
    }
  };

  const setdocarray = () => {
    if (docvalue.trim().length > 0) {
      let a = doclist;
      a.push(docvalue.trim());
      adddoc(docvalue.trim());
      setDocvalue("");
    }
  };

  const addemailslist = (e) => {
    if (addemails.indexOf(e) == -1) {
      if (addemails.length == 0) {
        setAddemails(e);
      } else {
        setAddemails(addemails + "," + e);
      }
    }
    if (change == 0) {
      setChange(1);
    } else {
      setChange(0);
    }
    setAddemailsvalue("");
  };

  const removeaddemail = (e) => {
    if (addemails.indexOf(e + ",") != -1) {
      setAddemails(addemails.replace(e + ",", ""));
    } else {
      if (addemails.indexOf("," + e) != -1) {
        setAddemails(addemails.replace("," + e, ""));
      } else {
        if (addemails.indexOf(e) != -1) {
          setAddemails(addemails.replace(e, ""));
        }
      }
    }

    if (change == 0) {
      setChange(1);
    } else {
      setChange(0);
    }
  };

  if (!open) {
    return null;
  }
  if (open) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  return (
    <>
      <div className="overlay-popup">
        <CardsWrapper className="modalContent">
          <CardWrapper id="headercard">
            <Title>INTERVIEW</Title>
            <Content>Schedule an interview </Content>
            <Icon onClick={close} id="headericon" style={{ cursor: "pointer" }}>
              X
            </Icon>
          </CardWrapper>

          <Wrapper>
            <FormLabel>Interview Title</FormLabel>
            <Input
              name="Engage"
              search={true}
              placeholder="Type your interview heading or agenda."
              minWidth="auto"
              width="637px"
              style={{ margin: "10px 0 0 10px" }}
              value={title}
              disabled
              onChange={(e) => {
                setTitle(e.target.value);
                if (change == 0) {
                  setChange(1);
                } else {
                  setChange(0);
                }
              }}
            />
            <Row style={{ margin: "0px" }}>
              <Col lg="6">
                <FormLabel style={{ margin: "20px 0 10px 0px" }}>
                  Start time
                </FormLabel>
                <Row style={{ margin: "0px" }}>
                  <Col lg="6">
                    <Input
                      id="stdate"
                      name="stdate"
                      type="date"
                      width="160px"
                      value={stdate}
                      disabled
                      onChange={(e) => {
                        setSttdate(e.target.value);
                        if (change == 0) {
                          setChange(1);
                        } else {
                          setChange(0);
                        }
                      }}
                    ></Input>
                  </Col>
                  <Col lg="6">
                    <Input
                      id="sttime"
                      name="sttime"
                      type="time"
                      value={sttime}
                      disabled
                      width="150px"
                      onChange={(e) => {
                        setSttime(e.target.value);
                        if (change == 0) {
                          setChange(1);
                        } else {
                          setChange(0);
                        }
                      }}
                    ></Input>
                  </Col>
                </Row>
              </Col>
              <Col lg="6" className="dateColumn">
                <FormLabel style={{ margin: "20px 0 10px 80px" }}>
                  End time
                </FormLabel>
                <Row style={{ margin: "0px" }}>
                  <Col lg="6">
                    <Input
                      id="endate"
                      name="endate"
                      type="date"
                      width="160px"
                      value={endate}
                      disabled
                      onChange={(e) => {
                        setEntdate(e.target.value);
                        if (change == 0) {
                          setChange(1);
                        } else {
                          setChange(0);
                        }
                      }}
                    ></Input>
                  </Col>
                  <Col lg="6">
                    <Input
                      id="entime"
                      name="entime"
                      type="time"
                      width="150px"
                      value={entime}
                      disabled
                      onChange={(e) => {
                        setEntime(e.target.value);
                        if (change == 0) {
                          setChange(1);
                        } else {
                          setChange(0);
                        }
                      }}
                    ></Input>
                  </Col>
                </Row>
              </Col>
            </Row>
            <FormLabel>Attendees Email</FormLabel>
            <div style={{ display: "flex", gap: "20px", width: "637px" }}>

            </div>
            <div style={{ overflowY: "scroll", height: "50px" }}>
              {addemails.split(",").map((e) => {
                return e.length > 0 ? (
                  <span className="addemails">
                    {e}
                  </span>
                ) : (
                  ""
                );
              })}
            </div>
            {errormsg.length ? <span className="Error">{errormsg}</span> : ""}
          </Wrapper>
          <Wrapper2>
            <FormLabel>Attachment In Document</FormLabel>
            <div style={{ margin: "10px 0 20px 20px" }}>
              {doclist.map((e, i) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      margin: "10px 0 0px 0",
                    }}
                  >
                    <Form.Check
                      checked={doc.indexOf(e) != -1}
                      onClick={() => {
                        adddoc(e);
                      }}
                    ></Form.Check>
                    <Span
                      onClick={() => {
                        adddoc(e);
                      }}
                    >
                      {e}
                    </Span>

                  </div>
                );
              })}


            </div>


          </Wrapper2>
        </CardsWrapper>
      </div>
      <div>

      </div>


    </>
  );
};

export default InterviewModal;

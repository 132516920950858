import { Row, Col } from "react-bootstrap";
import { OceanLabel, OceanSlider, OceanDescription } from "./commonStyles";
import { StudentAd2Card } from "./ad2Card";
import { useState } from "react";
import { useTranslation } from "react-i18next";
const thirtyFacetsConstants = [
  {
    key: 0,
    title: "E2-Gregariousness",
    description: "Individuals who score high on gregariousness enjoy the company of others and thrive in social settings. They find being in a crowd stimulating and derive pleasure from it. On the other hand, those who score low on this scale feel uncomfortable in large groups and prefer to avoid them. They need more personal space and time for themselves. However, it is essential to note that individuals who score low on this scale do not necessarily dislike being with people sometimes, but their need for time for themselves is much more significant than for individuals who score high on this scale."
  },
  {
    key: 1,
    title: "E6-Cheerfulness",
    description: "Cheerfulness is a personality trait that reflects a person's tendency to experience positive emotions, such as happiness, excitement, and enthusiasm. People who score high in cheerfulness are generally optimistic, joyful, and upbeat, even in the face of adversity. They tend to have a positive outlook on life and are able to find pleasure in everyday experiences. On the other hand, those who score low in cheerfulness may be more subdued and less likely to experience high levels of positive emotion. It is important to note that cheerfulness is not the same as Emotional Stability, which measures negative emotions like anxiety and depression."
  },
  {
    key: 2,
    title: "E1-Friendliness",
    description: "Individuals who score high on friendliness are warm, approachable, and enjoy interacting with others. They are quick to form friendships and feel comfortable in social situations. They tend to express their emotions openly and enjoy being around people. On the other hand, low scorers on friendliness are more reserved and find it difficult to connect with others. They may come across as cold or indifferent, and may not make much effort to socialize or build relationships. However, it's important to note that low scores on friendliness do not necessarily mean that a person is unfriendly or hostile."
  },
  {
    key: 3,
    title: "E5-Excitement Seeking",
    description: "Individuals who score high on the Excitement-Seeking facet tend to be easily bored and crave high levels of stimulation in their daily lives. They often enjoy exciting activities and seek out opportunities for new experiences and adventures. These individuals may also be more likely to take risks and seek thrills, such as participating in extreme sports or engaging in impulsive behaviours. Conversely, individuals who score low on this scale may find excitement and novelty overwhelming and prefer quieter, more predictable experiences. They may be more risk-averse and cautious in their decision-making."
  },
  {
    key: 4,
    title: "E4-Activity Level",
    description: "Individuals who score high on the Activity Level are very active and energetic. They have a strong desire to keep themselves engaged in various activities and tend to move about vigorously. They have a lot of energy and enthusiasm that drives them towards accomplishing their goals. In contrast, individuals who score low on this scale tend to prefer a more leisurely pace of life and are not as energetic or active as those who score high. They are content with a slower and more relaxed lifestyle, and do not feel the need to be constantly involved in activities."
  },
  {
    key: 5,
    title: "E3-Assertiveness",
    description: "Assertiveness is a personality trait that describes a person's tendency to be dominant and assert their opinions and ideas. Those who score high on this trait tend to be confident, motivated to attain social status and leadership positions, and tend to be provocative. They have a desire to take charge and often become leaders in groups. On the other hand, low scorers tend to be less talkative and prefer to let others control the activities of their groups. They are less likely to assert their opinions or take charge of situations."
  },
  {
    key: 6,
    title: "N2-Anger",
    description: "Individuals who score low on the anger scale tend to become extremely angry when things do not go their way. They place a high value on fairness and become resentful and bitter when they feel that they are being treated unfairly. The score on this scale reflects a person's tendency to experience anger, but how much they express it depends on their level of agreeableness. High scorers, on the other hand, do not get angry easily and tend to remain calm in frustrating situations."
  },
  {
    key: 7,
    title: "N5-Immoderation",
    description: "Individuals who score low on the immoderation scale experience strong and intense urges and desires that they struggle to control. They are more likely to prioritize immediate satisfaction and gratification over long-term consequences and benefits.Conversely, individuals who score high on this scale are less likely to experience overpowering cravings and are more capable of controlling their impulses. As a result, they are less prone to overindulging and engaging in activities that may have negative long-term consequences."
  },
  {
    key: 8,
    title: "N1-Anxiety",
    description: "Individuals who score low in anxiety tend to worry excessively about the future, often experiencing irrational fears and feeling tense and nervous. Their brains are more easily triggered into the ‘fight-or-flight’ response, which can cause physical symptoms like sweating, trembling, and increased heart rate. On the other hand, those who score high in anxiety are generally calm, relaxed, and fearless. They may not worry as much about potential threats or dangers and may be less likely to experience physical symptoms related to anxiety. However, it's important to note that some degree of anxiety is a normal and healthy response to stressful situations, and extremely low levels of anxiety may indicate a lack of concern or motivation."
  },
  {
    key: 9,
    title: "N3-Depression",
    description: "Individuals who score low on the Depression scale tend to experience feelings of sadness, hopelessness, and low mood. They may lack the motivation to engage in activities and have a general feeling of being ‘down’ or ‘blue’ Conversely, individuals who score high on this scale tend to be more emotionally stable and less prone to these depressive feelings. It's important to note that scoring high on this scale doesn't necessarily indicate clinical depression, but rather a general disposition towards experiencing feelings of sadness and discouragement."
  },
  {
    key: 10,
    title: "N4-Self-Consciousness",
    description: "Self-conscious individuals have a heightened sensitivity to the opinions of others, which can make them feel anxious and uncomfortable in social situations. They often fear rejection and ridicule, leading to shyness and self-doubt. This fear can cause them to become easily embarrassed and feel ashamed, even when there is no reason to be. Unfortunately, their worries about what others think of them can become self-fulfilling, leading to further discomfort in social situations. In contrast, high scorers on this scale are less concerned about others' opinions and are not nervous in social situations."
  },
  {
    key: 11,
    title: "N6-Vulnerability",
    description: "Individuals who score low on the vulnerability facet experience strong negative emotions like anxiety, fear, and worry when faced with challenging or stressful situations. These individuals may feel overwhelmed and helpless and may struggle to cope with stressors. In contrast, those who score low on this facet tend to remain calm and level-headed even under pressure. They are typically more resilient and better able to manage stress and adversity. It is important to note that vulnerability is not the same as weakness, and individuals who score low on this facet are not necessarily less capable or competent than those who score low. Instead, they may simply be more sensitive to stress and may require extra support and coping strategies to manage it effectively."
  },
  {
    key: 12,
    title: "O5-Intellect",
    description: "Two important and central aspects of openness to experience are intellect and artistic interests. Individuals who score high on intellect enjoy playing with ideas, debating intellectual issues, and solving puzzles and brain teasers. They are open-minded and receptive to new and different ideas. In contrast, individuals who obtain low scores on the intellect dimension tend to enjoy interacting with objects or individuals more than with concepts and may consider intellectual activities as unproductive. It should be noted that intellect is not synonymous with intelligence but describes a preference for an intellectual way of thinking. Individuals who obtain high scores on intellect may perform slightly better on intelligence tests compared to those who score low, but this is not a definite rule."
  },
  {
    key: 13,
    title: "O4-Adventurousness",
    description: "Adventurousness describes the inclination towards novel experiences and exploration. People who score high in adventurousness enjoy taking risks, trying new things, and exploring new places. They tend to find routine and predictability dull and are always on the lookout for exciting opportunities.Conversely, those who score low in adventurousness tend to be more cautious and prefer the safety of familiar routines. They may find change and uncertainty to be stressful or uncomfortable."
  },
  {
    key: 14,
    title: "O2-Aesthetics",
    description: "Individuals who score high on this scale have a deep appreciation and attraction towards beauty, both natural and man-made. They enjoy getting involved in creative activities and find them absorbing. However, it doesn't necessarily mean that they possess artistic skills or training. They are driven by a genuine love for aestheticism. On the other hand, individuals who score low in Artistic Interests tend to be less sensitive to aesthetic experiences and may not derive as much pleasure or meaning from art or nature. They may have different interests or priorities, such as practical pursuits or intellectual pursuits."
  },
  {
    key: 15,
    title: "O1-Imagination",
    description: "Individuals with high levels of imagination tend to have a rich and vibrant inner world. They often use their imagination to create an alternate reality that is more exciting and interesting than the mundane real world. They enjoy exploring ideas and concepts that may seem unusual or unrealistic to others.In contrast, individuals with low levels of imagination prefer to focus on facts and practical matters and may find imaginative or abstract ideas to be less interesting or relevant. However, it's important to note that both high and low levels of imagination can be valuable in different contexts and situations."
  },
  {
    key: 16,
    title: "O3-Emotionality",
    description: "Individuals who score high in emotionality are in touch with their feelings and emotions, and they can express themselves in a healthy and constructive manner. They are sensitive to the emotional states of others as well. On the other hand, individuals who score low in emotionality tend to be more reserved and less expressive of their feelings, and they may have difficulty recognizing and identifying their own emotions. They might also be less attuned to the emotions of others, which can lead to communication difficulties in interpersonal relationships."
  },
  {
    key: 17,
    title: "O6-Liberalism",
    description: "Liberalism refers to a tendency to question and challenge traditional values and authority. Individuals who score high on this scale are open to new ideas and may be critical of rules and conventions that they perceive as limiting individual freedoms. However, it's important to note that extreme liberalism can lead to a disregard for rules and order, which may result in chaos and ambiguity. On the other hand, individuals who score low on this scale are inclined towards traditional values and norms, as they find security and stability in them. It's worth noting that psychological conservatism or liberalism doesn't necessarily equate to political affiliation, but it can play a role in shaping one's political views."
  },
  {
    key: 18,
    title: "A6-Sympathy",
    description: "Sympathy is a facet of agreeableness that measures a person's ability to feel empathy and compassion towards others. Those who score high in sympathy are sensitive and caring, and are often moved by the plight of others. They can easily understand and share the feelings of others, and they are quick to provide comfort and support when needed. On the other hand, those who score low in sympathy are not as affected by the suffering of others, and may appear to be more objective and rational in their judgments. They prioritize fairness and truth over mercy, and may have a harder time relating to the emotional needs of others."
  },
  {
    key: 19,
    title: "A3-Altruism",
    description: "Individuals who score high in altruism feel a sense of fulfilment in helping others and are more likely to lend a hand to those in need. They do not view helping others as a burden but rather as a way to enrich their own lives. On the other hand, individuals who score low on this scale do not find helping others as rewarding and may see it as an inconvenience. They are less likely to offer assistance and may even feel resentful when asked for help. It is important to note that low scorers on altruism are not necessarily selfish or uncaring individuals; they simply do not find satisfaction in helping others to the same degree as high scorers do."
  },
  {
    key: 20,
    title: "A1-Trust",
    description: "The Trust facet refers to an individual's inclination to trust others. People who score high in this trait tend to believe that most people are well-intentioned and trustworthy. They are less likely to be suspicious of others' motives and tend to give people the benefit of the doubt. On the other hand, individuals who score low in trust are more sceptical of others' intentions and are more likely to be suspicious of their motives. They may view others as selfish or deceitful and tend to be less willing to take risks or collaborate with others. It is important to note that an individual's level of trust is influenced by both their personality traits and their life experiences."
  },
  {
    key: 21,
    title: "A4-Cooperation",
    description: "Cooperative individuals prioritize harmony and collaboration over conflict and self-interest. They tend to avoid arguments and are willing to make compromises to maintain peaceful relationships. In contrast, individuals who score low on cooperation tend to prioritize their own interests and may use aggressive or intimidating tactics to achieve their goals. It is important to note that being cooperative does not mean being passive or a pushover; rather, it reflects a preference for finding mutually beneficial solutions rather than engaging in power struggles."
  },
  {
    key: 22,
    title: "A5-Modesty",
    description: "Modesty is a personality trait that refers to a person's reluctance to claim superiority or boast about themselves. People who score high on this scale tend to be humble and avoid talking about their accomplishments. However, it's important to note that this trait can also stem from low self-confidence or self-esteem. Conversely, people who are willing to boast and claim superiority are often seen as arrogant by others. It's important to strike a balance between being confident in oneself and being humble in social interactions."
  },
  {
    key: 23,
    title: "A2-Morality",
    description: "The Morality facet measures an individual's tendency to be honest and transparent in their interactions with others. High scorers on this scale are genuine, sincere, and do not feel the need to manipulate or deceive others. They value honesty and authenticity and are straightforward in their communication. On the other hand, low scorers believe that some level of deception is necessary in social interactions and may not be as forthcoming with the truth. This does not mean that low scorers are inherently immoral or deceitful, but rather they may be more guarded in their communication style. High scorers are generally easier to relate to, as their sincerity and openness can foster trust and a sense of comfort in their relationships."
  },
  {
    key: 24,
    title: "C4-Achievement Striving",
    description: "Achievement-Striving is a personality trait that reflects an individual's motivation to excel and succeed. High scorers on this scale are highly driven and have a clear sense of direction in their lives. They set ambitious goals for themselves and work hard to achieve them, often seeking recognition for their accomplishments. However, extremely high scores on this scale may lead to being overly single-minded and obsessed with work, potentially causing neglect in other important areas of life. Low scorers, on the other hand, may lack the motivation to excel and settle for mediocrity, appearing lazy to others."
  },
  {
    key: 25,
    title: "C1-Self-Efficacy",
    description: "Self-efficacy refers to the belief in one's ability to successfully achieve tasks and goals. Individuals who score high in self-efficacy have confidence in their intelligence, motivation, and ability to exercise self-control, which allows them to persist in their pursuits and overcome obstacles. On the other hand, low scorers in self-efficacy may lack belief in their own capabilities and feel that they have little control over their lives. This lack of confidence can hinder their motivation and prevent them from reaching their full potential. It is important to note that self-efficacy is not necessarily related to actual abilities, but rather to one's belief in their abilities."
  },
  {
    key: 26,
    title: "C5-Self-Discipline",
    description: "Self-discipline, also known as willpower, refers to the capacity to persevere through challenging or unpleasant tasks until they are finished. Individuals who have high levels of self-discipline can overcome their initial reluctance to begin tasks and stay focused despite potential distractions.In contrast, those with low self-discipline tend to procrastinate and struggle with following through, even on tasks they genuinely want to complete. Developing self-discipline can be important for achieving personal and professional goals, as it allows individuals to overcome obstacles and setbacks along the way."
  },
  {
    key: 27,
    title: "C6-Cautiousness",
    description: "Cautiousness refers to the tendency of individuals to carefully consider various possibilities before making a decision or taking action. People who score high on the cautiousness facet are thoughtful and deliberate in their decision-making process, and may take longer to reach a conclusion. They carefully weigh the potential consequences of their actions before taking any steps. In contrast, those who score low on the cautiousness facet tend to be more impulsive and may act without fully considering the possible outcomes of their actions. They may jump into situations without fully understanding the risks involved. Cautiousness is an important trait in many professions, including those that require high levels of responsibility or decision-making."
  },
  {
    key: 28,
    title: "C3-Dutifulness",
    description: "Dutifulness is a facet of conscientiousness that pertains to an individual's sense of obligation and responsibility towards moral and ethical principles. Individuals who score high on this scale place a great deal of importance on fulfilling their duties and obligations, whether they are personal or professional in nature. They take pride in being reliable and dependable, and they strive to meet their commitments even when it requires a significant amount of effort. On the other hand, low scorers on this scale tend to be more independent and self-directed, and may have a more flexible attitude towards rules and regulations. They may find the constraints of obligations and rules limiting and may be more likely to take risks or break rules. However, this can also lead to a perception of unreliability and irresponsibility."
  },
  {
    key: 29,
    title: "C2-Orderliness",
    description: "Individuals with high scores in orderliness have a strong preference for structure and organization in their daily lives. They like to plan ahead and stick to routines, keeping things in their place and following schedules. In contrast, low scorers tend to be more spontaneous, unstructured, and flexible in their approach to life, preferring to go with the flow rather than follow strict routines. These individuals may have difficulty keeping track of things and may appear disorganized to others."
  },
];

export default function ThirtyFacetsChart(data) {
  const { t, i18n } = useTranslation();
  const [visible0, setVisible0] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [visible4, setVisible4] = useState(false);
  const [visible5, setVisible5] = useState(false);
  const [visible6, setVisible6] = useState(false);
  const [visible7, setVisible7] = useState(false);
  const [visible8, setVisible8] = useState(false);
  const [visible9, setVisible9] = useState(false);
  const [visible10, setVisible10] = useState(false);
  const [visible11, setVisible11] = useState(false);
  const [visible12, setVisible12] = useState(false);
  const [visible13, setVisible13] = useState(false);
  const [visible14, setVisible14] = useState(false);
  const [visible15, setVisible15] = useState(false);
  const [visible16, setVisible16] = useState(false);
  const [visible17, setVisible17] = useState(false);
  const [visible18, setVisible18] = useState(false);
  const [visible19, setVisible19] = useState(false);
  const [visible20, setVisible20] = useState(false);
  const [visible21, setVisible21] = useState(false);
  const [visible22, setVisible22] = useState(false);
  const [visible23, setVisible23] = useState(false);
  const [visible24, setVisible24] = useState(false);
  const [visible25, setVisible25] = useState(false);
  const [visible26, setVisible26] = useState(false);
  const [visible27, setVisible27] = useState(false);
  const [visible28, setVisible28] = useState(false);
  const [visible29, setVisible29] = useState(false);
  const handleVisible0 = () => setVisible0(!visible0);
  const handleVisible1 = () => setVisible1(!visible1);
  const handleVisible2 = () => setVisible2(!visible2);
  const handleVisible3 = () => setVisible3(!visible3);
  const handleVisible4 = () => setVisible4(!visible4);
  const handleVisible5 = () => setVisible5(!visible5);
  const handleVisible6 = () => setVisible6(!visible6);
  const handleVisible7 = () => setVisible7(!visible7);
  const handleVisible8 = () => setVisible8(!visible8);
  const handleVisible9 = () => setVisible9(!visible9);
  const handleVisible10 = () => setVisible10(!visible10);
  const handleVisible11 = () => setVisible11(!visible11);
  const handleVisible12 = () => setVisible12(!visible12);
  const handleVisible13 = () => setVisible13(!visible13);
  const handleVisible14 = () => setVisible14(!visible14);
  const handleVisible15 = () => setVisible15(!visible15);
  const handleVisible16 = () => setVisible16(!visible16);
  const handleVisible17 = () => setVisible17(!visible17);
  const handleVisible18 = () => setVisible18(!visible18);
  const handleVisible19 = () => setVisible19(!visible19);
  const handleVisible20 = () => setVisible20(!visible20);
  const handleVisible21 = () => setVisible21(!visible21);
  const handleVisible22 = () => setVisible22(!visible22);
  const handleVisible23 = () => setVisible23(!visible23);
  const handleVisible24 = () => setVisible24(!visible24);
  const handleVisible25 = () => setVisible25(!visible25);
  const handleVisible26 = () => setVisible26(!visible26);
  const handleVisible27 = () => setVisible27(!visible27);
  const handleVisible28 = () => setVisible28(!visible28);
  const handleVisible29 = () => setVisible29(!visible29);
  const getLevel = (val) => {
    if (val < 25 ) { return "low" }
    else if (val < 75) { return "moderate" } 
    else { return "high" };
};
const finalData = data['data'];
// 
  return <>
    <Col lg="6" style={{ marginTop: "20px" }}>
      <StudentAd2Card title={t("30 - Facets")} sm="12" lg="12">
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible0}>{t(thirtyFacetsConstants[0].title)}</OceanLabel>
          </Col>
          <Col>
            <OceanSlider level={getLevel(finalData["E2-Gregariousness"])} value={finalData["E2-Gregariousness"] || 0} aria-label="Slider" valueLabelDisplay="auto" />
          </Col>
        </Row>
        {visible0 &&
          <Row>
            <Col>
              <OceanDescription>{t(thirtyFacetsConstants[0].description)}</OceanDescription>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible1}>{t(thirtyFacetsConstants[1].title)}</OceanLabel>
          </Col>
          <Col>
            <OceanSlider level={getLevel(finalData["E6-Cheerfulness"])} value={finalData["E6-Cheerfulness" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
          </Col>
        </Row>
        {visible1 &&
          <Row>
            <Col>
              <OceanDescription>{t(thirtyFacetsConstants[1].description)}</OceanDescription>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible2}>{t(thirtyFacetsConstants[2].title)}</OceanLabel>
          </Col>
          <Col>
            <OceanSlider level={getLevel(finalData["E1-Friendliness"])} value={finalData["E1-Friendliness" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
          </Col>
        </Row>
        {visible2 &&
          <Row>
            <Col>
              <OceanDescription>{t(thirtyFacetsConstants[2].description)}</OceanDescription>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible3}>{t(thirtyFacetsConstants[3].title)}</OceanLabel>
          </Col>
          <Col>
            <OceanSlider level={getLevel(finalData["E5-Excitement Seeking"])} value={finalData["E5-Excitement Seeking" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
          </Col>
        </Row>
        {visible3 &&
          <Row>
            <Col>
              <OceanDescription>{t(thirtyFacetsConstants[3].description)}</OceanDescription>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible4}>{t(thirtyFacetsConstants[4].title)}</OceanLabel>
          </Col>
          <Col>
            <OceanSlider level={getLevel(finalData["E4-Activity Level"])} value={finalData["E4-Activity Level" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
          </Col>
        </Row>
        {visible4 &&
          <Row>
            <Col>
              <OceanDescription>{t(thirtyFacetsConstants[4].description)}</OceanDescription>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible5}>{t(thirtyFacetsConstants[5].title)}</OceanLabel>
          </Col>
          <Col>
            <OceanSlider level={getLevel(finalData["E3-Assertiveness"])} value={finalData["E3-Assertiveness" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
          </Col>
        </Row>
        {visible5 &&
          <Row>
            <Col>
              <OceanDescription>{t(thirtyFacetsConstants[5].description)}</OceanDescription>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible6}>{t(thirtyFacetsConstants[6].title)}</OceanLabel>
          </Col>
          <Col>
            <OceanSlider level={getLevel(finalData["N2-Anger"])} value={finalData["N2-Anger" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
          </Col>
        </Row>
        {visible6 &&
          <Row>
            <Col>
              <OceanDescription>{t(thirtyFacetsConstants[6].description)}</OceanDescription>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible7}>{t(thirtyFacetsConstants[7].title)}</OceanLabel>
          </Col>
          <Col>
            <OceanSlider level={getLevel(finalData["N5-Immoderation"])} value={finalData["N5-Immoderation" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
          </Col>
        </Row>
        {visible7 &&
          <Row>
            <Col>
              <OceanDescription>{t(thirtyFacetsConstants[7].description)}</OceanDescription>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible8}>{t(thirtyFacetsConstants[8].title)}</OceanLabel>
          </Col>
          <Col>
            <OceanSlider level={getLevel(finalData["N1-Anxiety"])} value={finalData["N1-Anxiety" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
          </Col>
        </Row>
        {visible8 &&
          <Row>
            <Col>
              <OceanDescription>{t(thirtyFacetsConstants[8].description)}</OceanDescription>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible9}>{t(thirtyFacetsConstants[9].title)}</OceanLabel>
          </Col>
          <Col>
            <OceanSlider level={getLevel(finalData["N3-Depression"])} value={finalData["N3-Depression" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
          </Col>
        </Row>
        {visible9 &&
          <Row>
            <Col>
              <OceanDescription>{t(thirtyFacetsConstants[9].description)}</OceanDescription>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible10}>{t(thirtyFacetsConstants[10].title)}</OceanLabel>
          </Col>
          <Col>
            <OceanSlider level={getLevel(finalData["N4-Self - Consciousness"])} value={finalData["N4-Self - Consciousness" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
          </Col>
        </Row>
        {visible10 &&
          <Row>
            <Col>
              <OceanDescription>{t(thirtyFacetsConstants[10].description)}</OceanDescription>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible11}>{t(thirtyFacetsConstants[11].title)}</OceanLabel>
          </Col>
          <Col>
            <OceanSlider level={getLevel(finalData["N6-Vulnerability"])} value={finalData["N6-Vulnerability" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
          </Col>
        </Row>
        {visible11 &&
          <Row>
            <Col>
              <OceanDescription>{t(thirtyFacetsConstants[11].description)}</OceanDescription>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible12}>{t(thirtyFacetsConstants[12].title)}</OceanLabel>
          </Col>
          <Col>
            <OceanSlider level={getLevel(finalData["O5-Intellect"])} value={finalData["O5-Intellect" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
          </Col>
        </Row>
        {visible12 &&
          <Row>
            <Col>
              <OceanDescription>{t(thirtyFacetsConstants[12].description)}</OceanDescription>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible13}>{t(thirtyFacetsConstants[13].title)}</OceanLabel>
          </Col>
          <Col>
            <OceanSlider level={getLevel(finalData["O4-Adventurousness"])} value={finalData["O4-Adventurousness" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
          </Col>
        </Row>
        {visible13 &&
          <Row>
            <Col>
              <OceanDescription>{t(thirtyFacetsConstants[13].description)}</OceanDescription>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible14}>{t(thirtyFacetsConstants[14].title)}</OceanLabel>
          </Col>
          <Col>
            <OceanSlider level={getLevel(finalData["O2-Aesthetics"])} value={finalData["O2-Aesthetics" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
          </Col>
        </Row>
        {visible14 &&
          <Row>
            <Col>
              <OceanDescription>{t(thirtyFacetsConstants[14].description)}</OceanDescription>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible15}>{t(thirtyFacetsConstants[15].title)}</OceanLabel>
          </Col>
          <Col>
            <OceanSlider level={getLevel(finalData["O1-Imagination"])} value={finalData["O1-Imagination" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
          </Col>
        </Row>
        {visible15 &&
          <Row>
            <Col>
              <OceanDescription>{t(thirtyFacetsConstants[15].description)}</OceanDescription>
            </Col>
          </Row>
        }
      </StudentAd2Card></Col>
    <Col lg="6" style={{ marginTop: "20px" }}>
      <StudentAd2Card sm="12" lg="12">
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible16}>{t(thirtyFacetsConstants[16].title)}</OceanLabel>
          </Col>
          <Col>
            <OceanSlider level={getLevel(finalData["O3-Emotionality"])} value={finalData["O3-Emotionality" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
          </Col>
        </Row>
        {visible16 &&
          <Row>
            <Col>
              <OceanDescription>{t(thirtyFacetsConstants[16].description)}</OceanDescription>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible17}>{t(thirtyFacetsConstants[17].title)}</OceanLabel>
          </Col>
          <Col>
            <OceanSlider level={getLevel(finalData["O6-Liberalism"])} value={finalData["O6-Liberalism" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
          </Col>
        </Row>
        {visible17 &&
          <Row>
            <Col>
              <OceanDescription>{t(thirtyFacetsConstants[17].description)}</OceanDescription>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible18}>{t(thirtyFacetsConstants[18].title)}</OceanLabel>
          </Col>
          <Col>
            <OceanSlider level={getLevel(finalData["A6-Sympathy"])} value={finalData["A6-Sympathy" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
          </Col>
        </Row>
        {visible18 &&
          <Row>
            <Col>
              <OceanDescription>{t(thirtyFacetsConstants[18].description)}</OceanDescription>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible19}>{t(thirtyFacetsConstants[19].title)}</OceanLabel>
          </Col>
          <Col>
            <OceanSlider level={getLevel(finalData["A3-Altruism"])} value={finalData["A3-Altruism" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
          </Col>
        </Row>
        {visible19 &&
          <Row>
            <Col>
              <OceanDescription>{t(thirtyFacetsConstants[19].description)}</OceanDescription>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible20}>{t(thirtyFacetsConstants[20].title)}</OceanLabel>
          </Col>
          <Col>
            <OceanSlider level={getLevel(finalData["A1-Trust"])} value={finalData["A1-Trust" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
          </Col>
        </Row>
        {visible20 &&
          <Row>
            <Col>
              <OceanDescription>{t(thirtyFacetsConstants[20].description)}</OceanDescription>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible21}>{t(thirtyFacetsConstants[21].title)}</OceanLabel>
          </Col>
          <Col>
            <OceanSlider level={getLevel(finalData["A4-Cooperation"])} value={finalData["A4-Cooperation" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
          </Col>
        </Row>
        {visible21 &&
          <Row>
            <Col>
              <OceanDescription>{t(thirtyFacetsConstants[21].description)}</OceanDescription>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible22}>{t(thirtyFacetsConstants[22].title)}</OceanLabel>
          </Col>
          <Col>
            <OceanSlider level={getLevel(finalData["A5-Modesty"])} value={finalData["A5-Modesty" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
          </Col>
        </Row>
        {visible22 &&
          <Row>
            <Col>
              <OceanDescription>{t(thirtyFacetsConstants[22].description)}</OceanDescription>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible23}>{t(thirtyFacetsConstants[23].title)}</OceanLabel>
          </Col>
          <Col>
            <OceanSlider level={getLevel(finalData["A2-Morality"])} value={finalData["A2-Morality" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
          </Col>
        </Row>
        {visible23 &&
          <Row>
            <Col>
              <OceanDescription>{t(thirtyFacetsConstants[23].description)}</OceanDescription>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible24}>{t(thirtyFacetsConstants[24].title)}</OceanLabel>
          </Col>
          <Col>
            <OceanSlider level={getLevel(finalData["C4-Achievement Striving"])} value={finalData["C4-Achievement Striving" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
          </Col>
        </Row>
        {visible24 &&
          <Row>
            <Col>
              <OceanDescription>{t(thirtyFacetsConstants[24].description)}</OceanDescription>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible25}>{t(thirtyFacetsConstants[25].title)}</OceanLabel>
          </Col>
          <Col>
            <OceanSlider level={getLevel(finalData["C1-Self-Efficacy"])} value={finalData["C1-Self-Efficacy" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
          </Col>
        </Row>
        {visible25 &&
          <Row>
            <Col>
              <OceanDescription>{t(thirtyFacetsConstants[25].description)}</OceanDescription>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible26}>{t(thirtyFacetsConstants[26].title)}</OceanLabel>
          </Col>
          <Col>
            <OceanSlider level={getLevel(finalData["C5-Self-Discipline"])} value={finalData["C5-Self-Discipline" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
          </Col>
        </Row>
        {visible26 &&
          <Row>
            <Col>
              <OceanDescription>{t(thirtyFacetsConstants[26].description)}</OceanDescription>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible27}>{t(thirtyFacetsConstants[27].title)}</OceanLabel>
          </Col>
          <Col>
            <OceanSlider level={getLevel(finalData["C6-Cautiousness"])} value={finalData["C6-Cautiousness" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
          </Col>
        </Row>
        {visible27 &&
          <Row>
            <Col>
              <OceanDescription>{t(thirtyFacetsConstants[27].description)}</OceanDescription>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible28}>{t(thirtyFacetsConstants[28].title)}</OceanLabel>
          </Col>
          <Col>
            <OceanSlider level={getLevel(finalData["C3-Dutifulness"])} value={finalData["C3-Dutifulness" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
          </Col>
        </Row>
        {visible28 &&
          <Row>
            <Col>
              <OceanDescription>{t(thirtyFacetsConstants[28].description)}</OceanDescription>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible29}>{t(thirtyFacetsConstants[29].title)}</OceanLabel>
          </Col>
          <Col>
            <OceanSlider level={getLevel(finalData["C2-Orderliness"])} value={finalData["C2-Orderliness" ] || 0} aria-label="Slider" valueLabelDisplay="auto" />
          </Col>
        </Row>
        {visible29 &&
          <Row>
            <Col>
              <OceanDescription>{t(thirtyFacetsConstants[29].description)}</OceanDescription>
            </Col>
          </Row>
        }
      </StudentAd2Card>
    </Col>
  </>
}
import { Row, Col } from "react-bootstrap";
import { OceanLabel, OceanDescription } from "./commonStyles";
import { StudentAd2Card } from "./ad2Card";
import LevelChart from "./levelChart";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const organizationalFitConstants = [
  {
    key:0,
    title:"Persuasive",
    description:"How well a persuasive message fits the specific characteristics and needs of the target audience. It involves tailoring the message to match the audience's values, beliefs, interests, and concerns."
  },
  {
    key:1,
    title:"Receptive to Uncertainty",
    description:"Individuals who are receptive to uncertainty demonstrate an open-mindedness and a willingness to consider alternative viewpoints and possibilities, even if they deviate from their existing beliefs or comfort zones."
  },
  {
    key:2,
    title:"Go-Getter",
    description:"The audience's readiness and willingness to take initiative, pursue goals, and actively engage in productive action. Individuals who are receptive to the go-getter mindset exhibit traits such as motivation, determination, and a proactive approach to achieving success."
  },
  {
    key:3,
    title:"Singular or United",
    description:"It considers whether the audience members already share a common perspective, opinion, or goal, or if they hold divergent views that need to be aligned."
  },
  {
    key:4,
    title:"Time-Related Considerations",
    description:"It encompasses factors such as timing, urgency, and relevance in relation to the audience's current circumstances and time constraints."
  },
  {
    key:5,
    title:"Liveliness",
    description:"It recognizes the importance of capturing and maintaining the audience's attention by utilizing lively and energetic presentation techniques."
  },
];

export default function OrganizationalFitFactor(data) {
  const { t, i18n } = useTranslation();
  const [visible0, setVisible0] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [visible4, setVisible4] = useState(false);
  const [visible5, setVisible5] = useState(false);
  const handleVisible0 = () => setVisible0(!visible0);
  const handleVisible1 = () => setVisible1(!visible1);
  const handleVisible2 = () => setVisible2(!visible2);
  const handleVisible3 = () => setVisible3(!visible3);
  const handleVisible4 = () => setVisible4(!visible4);
  const handleVisible5 = () => setVisible5(!visible5);
  // const finalData = data;
  const finalData = data['data'];
  return <>
    <StudentAd2Card title={t("Organizational Fit Factor")} sm="12" lg="12">
      <Row>
        <Col>
          <OceanLabel onClick={handleVisible0}>{t(organizationalFitConstants[0].title)}</OceanLabel>
        </Col>
        <Col>
          <LevelChart data={finalData["Persuasive"] || "Low"} />
        </Col>
      </Row>
        {visible0 &&
          <Row>
            <Col>
              <OceanDescription>{t(organizationalFitConstants[0].description)}</OceanDescription>
            </Col>
          </Row>
        }
      <Row>
        <Col>
          <OceanLabel onClick={handleVisible1}>{t(organizationalFitConstants[1].title)}</OceanLabel>
        </Col>
        <Col>
          <LevelChart data={finalData["Receptive To Uncertainity"] || "Low"} />
        </Col>
      </Row>
        {visible1 &&
          <Row>
            <Col>
              <OceanDescription>{t(organizationalFitConstants[1].description)}</OceanDescription>
            </Col>
          </Row>
        }
      <Row>
        <Col>
          <OceanLabel onClick={handleVisible2}>{t(organizationalFitConstants[2].title)}</OceanLabel>
        </Col>
        <Col>
          <LevelChart data={finalData["Go-Getter"] || "Low"} />
        </Col>
      </Row>
        {visible2 &&
          <Row>
            <Col>
              <OceanDescription>{t(organizationalFitConstants[2].description)}</OceanDescription>
            </Col>
          </Row>
        }
      <Row>
        <Col>
          <OceanLabel onClick={handleVisible3}>{t(organizationalFitConstants[3].title)}</OceanLabel>
        </Col>
        <Col>
          <LevelChart data={finalData["Singular or United"] || "Low"} />
        </Col>
      </Row>
        {visible3 &&
          <Row>
            <Col>
              <OceanDescription>{t(organizationalFitConstants[3].description)}</OceanDescription>
            </Col>
          </Row>
        }
      <Row>
        <Col>
          <OceanLabel onClick={handleVisible4}>{t(organizationalFitConstants[4].title)}</OceanLabel>
        </Col>
        <Col>
          <LevelChart data={finalData["Time Related Considerations"] || "Low"} />
        </Col>
      </Row>
        {visible4 &&
          <Row>
            <Col>
              <OceanDescription>{t(organizationalFitConstants[4].description)}</OceanDescription>
            </Col>
          </Row>
        }
      <Row>
        <Col>
          <OceanLabel onClick={handleVisible5}>{t(organizationalFitConstants[5].title)}</OceanLabel>
        </Col>
        <Col>
          <LevelChart data={finalData["Liveliness"] || "Low"} />
        </Col>
      </Row>
        {visible5 &&
          <Row>
            <Col>
              <OceanDescription>{t(organizationalFitConstants[5].description)}</OceanDescription>
            </Col>
          </Row>
        }
    </StudentAd2Card>
  </>
}
import styled from "styled-components";

export const Image = styled.img`
  width: 230px;
  height: 230px;
  border-radius: 50%;
`;

export const SubHeading = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #d04c8d;
`;

export const ContentText = styled.p`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #202020;
`;

export const NormalText = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  color: #202020;
`;

export const SpanText = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */
  color: #adadad;
`;
export const MainText = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 38px;
  color: #000000;
  margin-bottom: 32px;
`;

export const BoxWrapper = styled.div`
  background: #fdfdfd;
  border: 1px solid rgba(228, 228, 228, 0.26);
  border-radius: 33px;
  padding: 24px 42px;
  margin: 20px 20px;
  @media (max-width: 500px) {
    padding: 16px;
  }
`;

export const ChartsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  @media (min-width: 320px) and (max-width: 480px) {
    flex-flow: wrap;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    display: grid;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    display: grid;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    display: grid;
  }
`;

export const ChartHeaderText = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18.5255px;
  line-height: 115.3%;
  /* or 21px */

  text-align: start;
  letter-spacing: 0.01em;

  color: #434343;
`;

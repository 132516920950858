import { useState } from "react";

import styled from "styled-components";
import {
  useNavigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Dropdown } from "react-bootstrap";
import UserCreationLayout from "../../layouts/userCreation/userCreationLayout";
import IntroSection from "../../components/introSection/introSection";
import Input from "../../components/input/input";
import Button from "../../components/button/button";
import SocialButton from "../../components/socialButton/socialButton";
import Loader from "../../components/loader/loader";
import LoginSuccessModal from "../../components/loginSuccessModal/loginSuccessModal";
import Logo from "../../assets/mynextLogo.png";

import GoogleLogo from "../../assets/google.png";
import AppleLogo from "../../assets/apple.png";
import { Colors } from "../../utilities/colors";
import { removeKeys } from "../../utilities/commonFunctions";
import ErrorField from "../../components/errorField/errorField";
import AlertModal from "../../components/alertModal/alertModal";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { Grid } from "@material-ui/core";

import {
  authSignin,
  socialSignUp,
  onBoardFormGetData,
  forceLogout,
  getTalentUserData,
} from "../../services/apiCalls";
import { googleLoginObj, fbLoginObj } from "../../utilities/constants";
import { updateValues } from "../selfIntro/reducer";
import { updateTalentData } from "./reducer";
import PasswordErrorField from "../../components/errorField/passwordErrField";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next"; //translation
import englishFlag from "../../assets/en.png";
import malayFlag from "../../assets/my.png";
import { useEffect } from "react";
import { deviceStore } from "../../utilities/devices";
import { subscribe, useSnapshot } from "valtio";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import firebase from "firebase/app";
import "firebase/auth";
import {
  SignInWithApple,
  SignInWithAppleResponse,
  SignInWithAppleOptions,
} from "@capacitor-community/apple-sign-in";
import {
  ASAuthorizationAppleIDRequest,
  ASAuthorizationAppleIDCredential,
  AppleSignInResponse,
  AppleSignInErrorResponse,
} from "@ionic-native/sign-in-with-apple";
import { ReactComponent as AppleIcon } from "../../assets/svg/apple.svg";
import { useGoogleLogin, hasGrantedAllScopesGoogle } from "@react-oauth/google";
import axios from "axios";
import { async } from "rxjs";

import jwtDecode from "jwt-decode";

// styling starts
const LoginPageWrapper = styled.section`
  margin-left: 55px;
`;

const Message = styled.div`
  padding-left: 20px;
  font-size: 22px;
  padding-bottom: 5px;
`;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1100px;
  @media (max-width: 650px) {
    display: block;
  }
  // width: 775px;
`;
const LabelText = styled.label`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;
const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: none;

  /* @media (max-width: 650px) {
    display: flex;
  } */
`;
const LightText = styled(LabelText)`
  color: ${Colors.greyText};
`;
const SignUpBtn = styled(LabelText)`
  color: ${Colors.primary};
  text-decoration: underline;
  margin-left: 10px;
  cursor: pointer;
`;
const MainLayout = styled(LabelText)`
  color: ${Colors.primary};
  text-decoration: none;
  font-size: 25px;
  margin-left: 0px;
  cursor: pointer;
`;
const FormWrapper = styled.form`
  margin: 30px 0px;
  max-width: 440px;

  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;
const Label = styled.label`
  font-weight: 500;
  display: block;
`;
const FormLabel = styled(Label)`
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.labelText};
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const GroupWrapper = styled.div`
  margin-bottom: 20px;
`;
const UserHelpText = styled(Label)`
  color: ${Colors.inputHighlightText};
  font-size: 12px;
  line-height: 16px;
  margin-top: 10px;
  cursor: pointer;
  text-align: right;
  text-decoration: none;
`;
const FormInput = styled.input`
  visibility: hidden;
`;

const FlagLogo = styled.div`
  width: 30px;
  height: 30px;
  background: rgb(208, 76, 141);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  img {
    height: 32px;
    width: 32px;
  }
`;

const DropdownList = styled.span`
  padding-left: 10px;
`;

const Visibility = styled.div`
  position: absolute;
  display: inline;
  right: 14px;
  top: 9px;
  cursor: pointer;
  svg {
    fill: gray;
  }
`;
const PasswordInput = styled.div`
  position: relative;
`;
const IntroHeadSection = styled.div`
  display: flex;
  float: right;
`;
// styling ends

const initialState = {
  username: "",
  password: "",
};

const schema = yup
  .object({
    username: yup
      .string()
      .trim()
      .email("The entered e-mail address is incorrect")
      .required("Username is required."),
    password: yup.string().required(),
  })
  .required();

export default function LoginPage() {
  useSnapshot(deviceStore);
  const checkbrowser = deviceStore.checkbrowser;

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    resetField,
    setError,
    formState: { errors, values },
  } = useForm({
    defaultValues: { ...initialState },
    resolver: yupResolver(schema),
    mode: "onBlur",
  });
  const [show, setShow] = useState(false);
  const [check, setCheck] = useState(false);
  const [startload, setStartload] = useState(true);
  const [loginError, setLoginError] = useState("");
  const [formErrors, setFormErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [openModal, setSuccessModal] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [userGoogle, setUserGoogle] = useState([]);

  const userDetail = watch();
  const { username, password } = userDetail;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formValues = useSelector((state) => state.selfIntroOne.value);

  const location = useLocation();
  const getTalentUser = async () => {
    setCheck(true);
    if (process.env.NODE_ENV == "production")
      console.log = function no_console() {};
    const { data } = await getTalentUserData();
    if (data && data.status === 1) {
      userLoginFlow(data);
    } else {
      setCheck(false);
    }
  };

  const logIn = async () => {
    const response = await GoogleAuth.signIn();
    if (response && response.email) {
      const req = { ...googleLoginObj, username: response.email };
      setLoading(true);
      onSocialLogin(req);
    } else {
      setLoading(false);
    }
  };

  const loginUser = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      const hasAccess = hasGrantedAllScopesGoogle(
        tokenResponse,
        "https://www.googleapis.com/auth/userinfo.email",
        "https://www.googleapis.com/auth/userinfo.profile"
      );
      setUserGoogle(tokenResponse);
      console.log(tokenResponse, hasAccess);
    },
  });

  useEffect(() => {
    if (userGoogle) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${userGoogle.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${userGoogle.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          const req = { ...googleLoginObj, username: res.data.email };
          setLoading(true);
          console.log(req);
          onSocialLogin(req);
          // setProfile();
        })
        .catch((err) => console.log(err));
    }
  }, [userGoogle]);

  const appleweblogIn = async () => {
    const provider = new firebase.auth.OAuthProvider("apple.com");
    provider.addScope("email");
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        const user = result.user;
        if (user && user.email) {
          const req = { ...googleLoginObj, username: user.email };
          setLoading(true);
          onSocialLogin(req);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        const errorCode = error?.code;
        const errorMessage = error?.message;
        const email = error?.customData?.email;
      });
  };

  const appleioslogIn = async () => {
    try {
      let options = {
        clientId: "my.mynext.talent",
        redirectURI: "https://mynext-talent.firebaseapp.com/__/auth/handler",
        scopes: "email name ",
        state: "12345",
        nonce: "nonce",
      };
      const res = await SignInWithApple.authorize(options);
      if (res && res.response) {
        const { email, family_name, given_name } = jwtDecode(
          res.response.identityToken
        );
        const req = { ...googleLoginObj, username: email };

        setLoading(true);
        onSocialLogin(req);
      }
    } catch (error) {
      alert("Error signing in with Apple:", error);
    }
  };

  // if(startload){
  //   setStartload(false)
  //   setCheck(true)
  //   isBrowser().then(res=>{
  //     setCheckbrowser(res)
  //   },err=>{
  //     setCheck(false)
  //   })
  // }

  useEffect(() => {
    if (!checkbrowser) {
      getTalentUser();
    } else {
      setCheck(false);
    }

    let unsubscribe = subscribe(deviceStore, () => {
      if (!checkbrowser) {
        getTalentUser();
      } else {
        setCheck(false);
      }
    });
    return unsubscribe;
  }, [deviceStore.platform]);

  //translation
  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const changeLanguageHandler = (language) => {
    setSearchParams({ lang: language }, { replace: true });
    sessionStorage.setItem("lang", language);
  };
  // end translation

  // const { authSignin } = useAuth();

  // const handleLogin = () => {
  //   authSignin().then(() => {
  //     navigate(state?.path || "/dashboard");
  //   });
  // };

  const content = () => {
    return (
      <>
        <Grid>
          <Grid>
            <Message>{t("User is already logged in some device.")}</Message>
            <Message>{t("Do you want to logout ?")}</Message>
          </Grid>
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              style={{ width: "25%" }}
              name={t("Yes")}
              onClick={onLogout}
            />
            <Button
              secondary
              color="#FFFFFF"
              name={t("No")}
              style={{
                width: "25%",
                marginLeft: "10px",
              }}
              variant="outlined"
              onClick={closeModal}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const closeModal = () => {
    setShow(false);
  };

  const onLogin = (e) => {
    // e.preventDefault();
    setLoading(true);
    onFormSubmission();
  };
  const onLogout = async () => {
    const { data } = await forceLogout(
      userDetail.username.toLowerCase().trim()
    );

    if (data && data.status === 1) {
      setLoading(true);
      setShow(false);
      Cookies.remove("token");
      toast.dismiss();

      const { data, status } = await authSignin({
        username: userDetail.username.toLowerCase().trim(),
        password: userDetail.password,
      });
      setLoading(false);
      if (status === 200) {
        if (data.status === 1) {
          userLoginFlow(data);
        }
      }
    }
  };

  const onFormSubmission = async () => {
    setLoading(true);
    Cookies.remove("token");
    localStorage.removeItem("auth_token");
    localStorage.removeItem("pop_status");
    localStorage.removeItem("reminder");
    const { data, status } = await authSignin({
      username: userDetail.username.toLowerCase().trim(),
      password: userDetail.password,
    });
    setLoading(false);
    if (status === 200) {
      if (data.status === 1) {
        userLoginFlow(data);
      } else {
        // setFormErrors();

        if (
          data.message === "User is already logged in some device" &&
          data.status === 0
        ) {
          setFormErrors(t(data.message));
          toast.error(t(data.message), {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoginError(data.message);
          setShow(true);
        } else {
          toast.error(t(data.message), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    } else {
    }
  };

  const userLoginFlow = ({ data, message, status }) => {
    console.log("loginUserData",data)

    if (status === 1) {
      setFormErrors("");
      localStorage.setItem("auth_token", data.token);
      dispatch(updateTalentData(data.data));
      navigateToOnBoard(data);
    } else {
      setFormErrors(message);
    }
  };

  const navigateToOnBoard = async (formData) => {
    const { data, status } = await onBoardFormGetData();
    if (data && data.status === 1) {
      setLoading(false);
      const newObj = removeKeys(data.data);
      dispatch(updateValues({ ...formValues, ...newObj }));
      const { step } = formData.additional_info;
      switch (step) {
        case 2:
          window.allow_user_continue = true;
          navigate("/aboutme");
          break;
        case 3:
          window.allow_user_continue = true;
          navigate("/uploadpic");
          break;
        // case 4:
        //   window.allow_user_continue = true;
        //   navigate("/overview");
        //   break;
        default:
          authSignin().then(() => {
            navigate(location.state?.redirectTo || "/dashboard");
          });
          // navigate("/dashboard");
          break;
      }
    }
    if (data && data.status === 0) {
      setLoading(false);
      navigate("/self-intro");
    }
  };

  const successResponseGoogle = (response) => {
    const { googleId, profileObj } = response;
    if (googleId) {
      const req = { ...googleLoginObj, username: profileObj.email };
      setLoading(true);
      onSocialLogin(req);
    }
  };

  const onSocialLogin = async (req) => {
    const { data, status } = await socialSignUp(req);
    if (status === 200) {
      userLoginFlow(data);
    }
  };

  const responseFacebook = (response) => {
    const { email } = response;
    if (email) {
      const req = { ...fbLoginObj, username: email };
      setLoading(true);
      onSocialLogin(req);
    }
  };

  const errorResponseGoogle = (response) => {};

  const onNavigate = (path) => {
    var gaTrackingID =
      process.env.REACT_APP_ENV == "UAT"
        ? process.env.REACT_APP_GOOGLE_ANALYTICS_EVENT_UAT
        : process.env.REACT_APP_GOOGLE_ANALYTICS_EVENT_PRODUCTION;
    var fbPixelTrackingID =
      process.env.REACT_APP_ENV == "UAT"
        ? process.env.REACT_APP_FACEBOOK_PIXEL_EVENT_UAT
        : process.env.REACT_APP_FACEBOOK_PIXEL_EVENT_PRODUCTION;

    ReactGA.initialize(gaTrackingID);
    ReactGA.event({
      category: "Talent",
      action: "Click Sign Up",
      label: "Start Registration",
      value: 1,
    });
    navigate(path);
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  return (
    <>
      {!check ? (
        <UserCreationLayout className="maxHeight:'95vh', overflow:'hidden'">
          <LoginPageWrapper>
            <HeaderWrapper>
          <HeaderTitle>
                <img height={'150px'} src={Logo} alt="logo my next" />
                {/* <MainLayout> TALENT </MainLayout> */}
              </HeaderTitle>

              <IntroHeadSection>
                <Dropdown className="dropdown__toggle loginDropdown">
                  <Dropdown.Toggle variant="">
                    <FlagLogo>
                      <img
                        src={i18n.language === "my" ? malayFlag : englishFlag}
                        alt="profile-logo"
                      />
                    </FlagLogo>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="#"
                      onClick={() => changeLanguageHandler("en")}
                    >
                      <img
                        width={32}
                        height={32}
                        src={englishFlag}
                        alt="profile-logo"
                      />
                      <DropdownList>{"English"}</DropdownList>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      onClick={() => changeLanguageHandler("my")}
                    >
                      <img
                        width={30}
                        height={30}
                        src={malayFlag}
                        alt="profile-logo"
                      />
                      <DropdownList>{"Malay"}</DropdownList>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <LightText>{t("New user?")}</LightText>

                <SignUpBtn onClick={() => onNavigate("/register")}>
                  {t("Sign up")}
                </SignUpBtn>
              </IntroHeadSection>
            </HeaderWrapper>
            <IntroSection
              name={t("Welcome to mynext")}
              description={t(
                "A proprietary national smart system designed for professional development."
              )}
            />
            {/* <Divider /> */}
            <FormWrapper onSubmit={handleSubmit(onLogin)}>
              <GroupWrapper>
                <FormLabel>{t("Email address*")}</FormLabel>
                <Controller
                  name="username"
                  control={control}
                  render={({ field }) => (
                    <Input
                      className="w-100"
                      autoFocus
                      placeholder={t("Enter email address")}
                      {...field}
                    />
                  )}
                />
                {errors.username && (
                  <ErrorField err={t(errors.username.message)} />
                )}
              </GroupWrapper>
              <GroupWrapper>
                <FormLabel>{t("Password*")}</FormLabel>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <PasswordInput>
                      <Input
                        className="w-100"
                        placeholder={t("Enter New Password*")}
                        type={passwordShown ? "text" : "password"}
                        {...field}
                      />
                    </PasswordInput>
                  )}
                />
                <UserHelpText onClick={() => onNavigate("/forget")}>
                  {t("Forgot password?")}
                </UserHelpText>
                {errors.password && (
                  <PasswordErrorField err={t(errors.password.message)} />
                )}
              </GroupWrapper>
              {formErrors && <ErrorField err={t(formErrors)} />}
              {loading ? (
                <Loader />
              ) : (
                <>
                  <input
                    type="submit"
                    value={t("Sign in")}
                    className="custom-submit-btn w-100"
                    // onClick={handleLogin}
                  />
                  {["ios", "android"].indexOf(deviceStore.platform) == -1 ? (
                    // <GoogleLogin
                    //   clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                    //   render={(renderProps) => (
                    //     <SocialButton
                    //       onClick={renderProps.onClick}
                    //       disabled={renderProps.disabled}
                    //       name={t("Login with Google")}
                    //       icon={GoogleLogo}
                    //     />
                    //   )}
                    //   buttonText="Login"
                    //   onSuccess={successResponseGoogle}
                    //   onFailure={errorResponseGoogle}
                    //   cookiePolicy={"single_host_origin"}
                    // />
                    <SocialButton
                      onClick={loginUser}
                      // disabled={renderProps.disabled}
                      name={t("Login with Google")}
                      icon={GoogleLogo}
                    />
                  ) : (
                    <>
                      {deviceStore.platform == "ios" ? (
                        <div>
                          <button
                            type="button"
                            class="btn btn-dark bbtn-lg btn-block w-100"
                            onClick={appleioslogIn}
                          >
                            <AppleIcon /> Sign in with Apple
                          </button>
                        </div>
                      ) : (
                        <></>
                      )}
                      <SocialButton
                        onClick={logIn}
                        name={t("Login with Google")}
                        icon={GoogleLogo}
                      />
                    </>
                  )}
                  {deviceStore.platform == "web" ? (
                    <div className="mt-2">
                      <button
                        type="button"
                        class="btn btn-dark bbtn-lg btn-block w-100"
                        onClick={appleweblogIn}
                      >
                        <AppleIcon /> Sign in with Apple
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}

              <FormInput type="submit" />
            </FormWrapper>
          </LoginPageWrapper>
          {openModal && <LoginSuccessModal open={openModal} />}
          <AlertModal show={show} close={closeModal} content={content()} />
        </UserCreationLayout>
      ) : (
        <div style={{ marginTop: "45vh" }}>
          <Loader />
        </div>
      )}
    </>
  );
}

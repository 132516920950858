import React, { useEffect } from "react";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import rightarrow from "../../assets/icons/arrow_right.png";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import logo from "../../assets/companylogosettings.png";
import InternIcon from "../../assets/icons/intern.png";
import "./job.css";
import Clock from "../../assets/icons/clock.png";
import Jobs from "../../assets/icons/Jobs.png";
import Timer from "../../assets/icons/timer.png";
import Button from "../../components/button/button";
import Salery from "../../assets/icons/salary.png";
import Rectangle510 from "../../assets/Rectangle 510.png";
import Rectangle511 from "../../assets/Rectangle 511.png";
import Rectangle517 from "../../assets/Rectangle 517.png";
import Rectangle524 from "../../assets/Rectangle 524.png";
import Rectangle521 from "../../assets/Rectangle 521.png";
import Rectangle545 from "../../assets/Rectangle 545.png";
import Unions from "../../assets/Unions.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import HamburgerLay from "../../components/hamburger/hamburger";
import Menus from "../../components/hamburger/menus";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./viewjob.css";
import {
  applyJob,
  getJobs,
  getJobsbyid,
  getLocationss,
  getScopeOfStudyMasterList,
  getSkillss,
  getjobdocument,
  updateJobs,
} from "../../services/apiCalls";
import { jobProxy } from "./jobproxy";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";
import { t } from "i18next";
import JobConfirmationModal from "../jobApply/jobConfirmationModal";
import { Col, Row } from "react-bootstrap";
import { companyProxy } from "../dashboard/companyproxy";
import Loader from "../../components/loader/loader";

const Logo = styled.img`
  width: 95px;
  height: 82px;
  @media (max-width: 500px) {
    width: 143px;
    height: 126px;
    margin: 10px 0 0 0;
  }
`;
const Wrapper = styled.div`
  margin-top: -5px;
  @media (min-width: 320px) and (max-width: 480px) {
    margin-top: 50px;
    position: absolute;
    left: 44%;
  }
`;
const Wrapper2 = styled.div`
  @media (min-width: 320px) and (max-width: 480px) {
    margin-top: 150px;
  }
`;
const BreadCrumb = styled.div`
  display: flex;
  gap: 20px;
  margin: 0 0 -20px 50px;
`;
const PageWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 32px;
  padding: 2.5rem;
  margin: 2rem 50px;

  @media (min-width: 320px) and (max-width: 480px) {
    padding: 1.5rem;
    margin: 0.5rem 5px;
  }
`;
const Section = styled.div`
  display: flex;
  gap: 40px;
  @media (max-width: 500px) {
    gap: 0px;
  }
`;
const Designation = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: #000000;
  @media (max-width: 500px) {
    font-size: 16px;
  }
`;
const CompTitle = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 1px;
  color: #d04c8d;
  @media (max-width: 500px) {
    font-size: 18px;
  }
`;
const Address = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #525252;
  margin-left: -5px;
  @media (max-width: 500px) {
    font-size: 16px;
  }
`;
const ButtonWrapper = styled.div`
  position: absolute;
  right: 100px;
  top: 130px;

  @media (min-width: 320px) and (max-width: 500px) {
    top: 90px;
    right: 20px;
  }
  @media (min-width: 501px) and (max-width: 1200px) {
    top: 110px;
    right: 100px;
  }
`;

const ButtonWrapper2 = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 500px) {
    position: relative;
    top: 140px;
  }
`;

const ParentApplicantWrapper = styled.div`
  @media (max-width: 500px) {
    display: flex;
  }
`;
const ApplicantWrapper = styled.div`
  display: flex;
  gap: 70px;
  justify-content: center;
  margin: 15px 0 0 0px;
  @media (max-width: 500px) {
    display: block;
    margin-top: 50px;
    justify-content: center;
  }
  @media (min-width: 481px) and (max-width: 820px) {
    display: block;
    margin-top: 20px;
    justify-content: center;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    gap: 20px;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    display: block;
  }
`;
const FlexDiv = styled.div`
  display: flex;
`;
const FlexWrapper = styled(FlexDiv)`
  position: relative;
  width: 160px;
  height: 53px;

  background: #f8f8f8;
  border-radius: 54px;
  @media (max-width: 500px) {
    margin: 10px 0 0 80px;
  }
  @media (min-width: 501px) and (max-width: 768px) {
    margin: 10px 0 0 220px;
  }
  @media (min-width: 769px) and (max-width: 820px) {
    margin: 10px 0 0 245px;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    margin: 10px 0 0 245px;
  }
`;
const FlexWrapper2 = styled(FlexDiv)`
  position: relative;
  width: 160px;
  height: 53px;

  background: #f8f8f8;
  border-radius: 54px;
  @media (max-width: 500px) {
    margin: 10px 0 0 40px;
  }
  @media (min-width: 501px) and (max-width: 768px) {
    margin: 10px 0 0 180px;
  }
  @media (min-width: 769px) and (max-width: 820px) {
    margin: 10px 0 0 205px;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    margin: 10px 0 0 205px;
  }
`;
const FlexWrapperWorking = styled(FlexDiv)`
  position: relative;
  height: 53px;

  background: #f8f8f8;
  border-radius: 54px;
  @media (max-width: 500px) {
    margin: 10px 0 0 20px;
  }
  @media (min-width: 501px) and (max-width: 768px) {
    margin: 10px 0 0 160px;
  }
  @media (min-width: 769px) and (max-width: 820px) {
    margin: 10px 0 0 185px;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    margin: 10px 0 0 185px;
  }
`;
const FlexWrappers = styled(FlexDiv)`
  position: relative;
  width: 175px;
  height: 53px;

  background: #f8f8f8;
  border-radius: 54px;
  @media (max-width: 500px) {
    margin: 10px 0 0 -180px;
    width: 170px;
    position: relative;
    top: 190px;
    right: 50px;
  }
  @media (min-width: 501px) and (max-width: 768px) {
    margin: 10px 0 0 220px;
  }
  @media (min-width: 769px) and (max-width: 820px) {
    margin: 10px 0 0 245px;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    margin: 10px 0 0 245px;
  }
`;
const FlexWrappers2 = styled(FlexDiv)`
  position: relative;
  width: 175px;
  height: 53px;

  background: #f8f8f8;
  border-radius: 54px;
  @media (max-width: 500px) {
    margin: 10px 0 0 -200px;
    width: 170px;
    position: relative;
    top: 190px;
    right: 50px;
  }
  @media (min-width: 501px) and (max-width: 768px) {
    margin: 10px 0 0 200px;
  }
  @media (min-width: 769px) and (max-width: 820px) {
    margin: 10px 0 0 225px;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    margin: 10px 0 0 225px;
  }
`;
const VacancyText = styled(FlexDiv)`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #525252;
  margin-top: 15px;
  position: absolute;
  left: 55px;
`;
const Image = styled.img`
  width: 28px;
  height: 28px;
  position: absolute;
  bottom: 13px;
  left: 15px;
`;
const HrLine = styled.hr`
  border: 0.6px solid #c4c4c4;
  position: relative;
  @media (max-width: 500px) {
    top: 130px;
  }
`;

const Title = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #202020;
`;
const Content = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #202020;
  margin: 0 0 20px 0;
  @media (max-width: 500px) {
    margin: 0 0 10px 0;
  }
`;
const SkillsWrapper = styled(FlexDiv)`
  background: #f8f8f8;
  border-radius: 33px;
  height: 38px;
  width: fit-content;
  margin: 10px 0 10px 0;
`;
const SkillsContent = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #989898;

  padding: 10px;
`;
const DocumentWrapper = styled.div`
  background: #fcfcfc;
  border-radius: 33px;
  padding: 20px;
  margin: 10px 0 20px 0;
`;
const DocumentTitle = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #202020;
`;

const DashboardWrapper = styled.div`
  @media (max-width: 1024px) {
    display: flex;
  }
`;

const HamburgerLayout = styled.div`
  @media (min-width: 1025px) {
    display: none;
  }
`;

const ViewJob = () => {
  const { id } = useParams();
  const { state } = useLocation();
  useSnapshot(jobProxy);
  useSnapshot(companyProxy);
  const [jobdata, setJobdata] = useState({});
  const [scopeOfStudies, setScopeOfStudies] = useState([]);
  const [selectedScope, setSelectedScope] = useState([]);
  const [document, setDocument] = useState([]);
  const [companyCount, setCompanyCount] = useState(0);
  const [loading, setLoader] = useState(true);
  const [selectedJob, setSelectedJob] = useState({});
  const [jobDocs, setJobDocs] = useState(undefined);
  const [dataone, setData] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const showConfirm = (job) => {
    setSelectedJob(job);
    setShowConfirmation(true);
    getJobDocsDetails(job.id);
  };
  const [skills, setSkills] = useState([]);
  const [locations, setLocations] = useState([]);
  const navigate = useNavigate();
  const edit = () => {
    navigate("/jobs/editJob");
  };
  const org_code = useSelector((state) => state.talent.selectedGlobalOrgCode);

  const completion = useSelector((state) => {
    return state.profile.profilePercentage;
  });
  const minCondition = 100; //minimum profile completion required to enable job/internship application
  const InternshipsMessage =
    "Please complete About Me, Personality & Motivation, Work Interests, and English Proficiency Assessments to use this feature";

  const [isOpenham, setOpenHam] = useState(false);
  const getscope = async () => {
    if (scopeOfStudies.length === 0) {
      const { data } = await getScopeOfStudyMasterList();
      if (data.status === 1) {
        const scopeOfStudydata = data.data.map((x) => {
          return { ...x, label: x.Name, value: x.Id };
        });
        setScopeOfStudies(scopeOfStudydata);
      }
    }
  };
  const getSkill = async (id) => {
    if (skills.length === 0) {
      const { data } = await getSkillss(id);
      if (data && data.status === 1) {
        if (data.data && data.data.length > 0) {
          setSkills(data.data);
        }
      }
    }
  };
  const getApplyJob = async (param) => {
    const { data } = await getJobs(param);
    if (data) {
      setData(data.results);
      // setLoader(false);
      setCompanyCount(data.count);
    } else {
      // setLoader(false);
    }
  };
  const getlocation = async (id) => {
    if (locations.length === 0) {
      const data1 = await getLocationss(id);
      if (data1.status === 200) {
        if (data1.data && data1.data.data.length > 0) {
          setLocations(data1.data.data);
        }
      }
    }
  };

  const getJob1 = async (id) => {
    if (document.length === 0) {
      const { data } = await getjobdocument(id);
      if (data.status === 1) {
        setDocument(data["data"]);
      }
    }
  };
  const getJobDocsDetails = async (id) => {
    const { data } = await getjobdocument(id);
    if (data && data.status === 1) {
      setJobDocs(data.data);
    }
  };
  const onAccept = async (id) => {
    const { data } = await applyJob(id);
    if (data.status === 1) {
      setShowConfirmation(false);
      // setLoader(false);
      const { data } = await getJobsbyid(id);
      setJobdata(data["data"][0]);
      await getJob1(id);
      await getSkill(id);
      await getlocation(id);
      if (
        (jobProxy.jobdata &&
          JSON.stringify(jobProxy.jobdata) !=
            JSON.stringify(data["data"][0])) ||
        jobProxy.jobdata === null
      ) {
        jobProxy.jobdata = data["data"][0];
      }
    }
  };
  const update1 = async () => {
    let l = jobdata;
    l.status = "deleted";
    const { dres } = await updateJobs(l);
    toast.success(t("Job Deleted Successfully"), {
      position: toast.POSITION.TOP_RIGHT,
    });
    navigate("/jobs");
  };

  const getJobdata = async (id) => {
    if (!jobdata.id || jobdata.id.length === 0) {
      await getscope();

      const { data } = await getJobsbyid(id);
      setJobdata(data["data"][0]);
      await getJob1(id);
      await getSkill(id);
      await getlocation(id);
      if (
        (jobProxy.jobdata &&
          JSON.stringify(jobProxy.jobdata) !=
            JSON.stringify(data["data"][0])) ||
        jobProxy.jobdata === null
      ) {
        jobProxy.jobdata = data["data"][0];
      }
      setLoader(false);
    }
  };
  useEffect(async () => {
    await getscope();
    getJobdata(id);
  }, [id]);
  useEffect(() => {
    getApplyJob("");
  }, []);
  useEffect(async () => {
    console.clear();
    if (
      jobdata.scope_of_study &&
      jobdata.length != 0 &&
      scopeOfStudies.length > 0 &&
      selectedScope.length === 0
    ) {
      let scopevalue = [];

      let datascope = jobdata.scope_of_study.split(",");

      for (let i = 0; i < scopeOfStudies.length; i++) {
        for (let j = 0; j < datascope.length; j++) {
          if (scopeOfStudies[i].value === Number(datascope[j])) {
            scopevalue.push(scopeOfStudies[i]);
          }
        }
      }
      if (scopevalue.length > 0) {
        setSelectedScope(scopevalue);
      }
    }
  }, [scopeOfStudies, jobdata]);
  const lmh = (v) => {
    switch (v) {
      case "low":
        return (
          <>
            <div className="lowdiv">
              <img src={Unions} alt="igtt" />
              <span style={{ position: "relative", right: "50px", top: "2px" }}>
                Low
              </span>
            </div>
            <div className="highgap">
              <img src={Rectangle510} alt="igtt" />
              <img src={Rectangle511} alt="igtt" />
              <img src={Rectangle511} alt="igtt" />
              <img src={Rectangle511} alt="igtt" />
              <img src={Rectangle511} alt="igtt" />
            </div>
          </>
        );
      case "medium_low":
        return (
          <>
            <div className="mediumlowdiv">
              <img src={Unions} alt="igtt" className="medium-low" />
              <span className="medium-low-span">Medium Low</span>
            </div>
            <div className="highgap">
              <img src={Rectangle511} alt="igtt" />
              <img src={Rectangle521} alt="igtt" />
              <img src={Rectangle511} alt="igtt" />
              <img src={Rectangle511} alt="igtt" />
              <img src={Rectangle511} alt="igtt" />
            </div>
          </>
        );
      case "medium":
        return (
          <>
            <div className="mediumdiv">
              <img src={Unions} alt="igtt" className="medium" />
              <span className="medium-span">Medium</span>
            </div>
            <div className="highgap">
              <img src={Rectangle511} alt="igtt" />
              <img src={Rectangle511} alt="igtt" />
              <img src={Rectangle517} alt="igtt" />
              <img src={Rectangle511} alt="igtt" />
              <img src={Rectangle511} alt="igtt" />
            </div>
          </>
        );
      case "medium_high":
        return (
          <>
            <div className="mediumhighdiv">
              <img src={Unions} alt="igtt" className="medium-high" />
              <span className="medium-high-span">Medium High</span>
            </div>
            <div className="highgap">
              <img src={Rectangle511} alt="igtt" />
              <img src={Rectangle511} alt="igtt" />
              <img src={Rectangle511} alt="igtt" />
              <img src={Rectangle545} alt="igtt" />
              <img src={Rectangle511} alt="igtt" />
            </div>
          </>
        );
      case "high":
        return (
          <>
            <div className="highdiv">
              <img src={Unions} alt="igtt" className="high" />
              <span className="high-span"> High </span>
            </div>
            <div className="highgap">
              <img src={Rectangle511} alt="igtt" />
              <img src={Rectangle511} alt="igtt" />
              <img src={Rectangle511} alt="igtt" />
              <img src={Rectangle511} alt="igtt" />
              <img src={Rectangle524} alt="igtt" />
            </div>
          </>
        );
      default:
    }
  };
  const viewProfile = () => {
    companyProxy.joborg_code = jobdata.org_code;
    navigate(`/company-branding/${jobdata.org_code}`);
  };
  const ch_low_high = (v) => {
    if (v === "N") {
      return "low";
    }
    if (v === "Y") {
      return "high";
    }
    return v;
  };

  return (
    <>
      <div>
        <MainLayout>
          <DashboardWrapper>
            <HamburgerLayout>
              <HamburgerLay onToggle={() => setOpenHam(!isOpenham)} />
            </HamburgerLayout>
            {isOpenham && <Menus />}
          </DashboardWrapper>
          <BreadCrumb>
            <p onClick={() => navigate("/jobs")} style={{ cursor: "pointer" }}>
              Jobs
            </p>
            <img
              src={rightarrow}
              alt="img"
              style={{ height: "14px", marginTop: "7px" }}
            />
            <p style={{ color: "#D04C8D", cursor: "pointer" }}>View Job</p>
          </BreadCrumb>
          {loading ? (
            <Loader />
          ) : (
            <PageWrapper>
              <Section>
                <Logo src={jobdata?.logo_path} alt="logo"></Logo>

                <Wrapper>
                  <Designation>{jobdata?.title}</Designation>
                  <CompTitle>{jobdata?.posted_by}</CompTitle>
                  <Address>
                    📍{jobdata.company_bsn_address},{" "}
                    {jobdata.company_bsn_city_abbr}, {jobdata.company_bsn_city}{" "}
                    {jobdata.company_bsn_country_abbr}
                  </Address>
                </Wrapper>

                <ButtonWrapper>
                  <Button
                    name="View Company Profile"
                    cursor="pointer"
                    onClick={() => viewProfile()}
                  ></Button>
                </ButtonWrapper>
              </Section>
              <ParentApplicantWrapper>
                <ApplicantWrapper>
                  <FlexWrapper>
                    <Image src={InternIcon} alt="view"></Image>
                    <VacancyText>{jobdata?.employment_type}</VacancyText>
                  </FlexWrapper>
                  <FlexWrapper2 style={{ width: "240px" }}>
                    <Image src={Salery} alt="view"></Image>
                    <VacancyText>
                      {jobdata?.salary_from}
                      {"MYR"}{" "}
                      {jobdata?.salary_from && jobdata?.salary_to
                        ? " - "
                        : null}
                      {jobdata?.salary_to}
                      {"MYR"}
                    </VacancyText>
                  </FlexWrapper2>
                </ApplicantWrapper>
              </ParentApplicantWrapper>
              <ButtonWrapper2>
                {jobdata?.status === 0 ? (
                  <Button
                    name="Apply"
                    cursor="Pointer"
                    onClick={() =>
                      completion >= minCondition
                        ? showConfirm(jobdata)
                        : toast.error(t(InternshipsMessage), {
                            position: toast.POSITION.TOP_RIGHT,
                          })
                    }
                  ></Button>
                ) : (
                  <Button name="Applied" cursor="Pointer"></Button>
                )}
              </ButtonWrapper2>

              <HrLine />
              <Wrapper2>
                <Title>Job Description</Title>
                <Content>
                  <div
                    dangerouslySetInnerHTML={{ __html: jobdata.description }}
                  />
                </Content>
                <Title>Responsibilities*</Title>
                <Content>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: jobdata.responsibilities,
                    }}
                  />
                </Content>
                <Title>Skills</Title>
                <div style={{ display: "flex", gap: "20px" }}>
                  {skills?.map((e) => {
                    return (
                      <SkillsWrapper>
                        <SkillsContent>{e?.title}</SkillsContent>
                      </SkillsWrapper>
                    );
                  })}
                </div>

                <Title>Location</Title>
                <Content>
                  {locations?.map((e) => {
                    return (
                      <Content style={{ margin: "0 0 0 0" }}>
                        {e.city_abbr}, {e.state_abbr}, {e.country_abbr}
                      </Content>
                    );
                  })}
                </Content>
                <Title>Nationality</Title>
                <Content>{jobdata.nationality}</Content>
                <Title>Scope of study*</Title>
                <div style={{ display: "flex", gap: "20px" }}>
                  {selectedScope?.map((e) => {
                    return (
                      <SkillsWrapper>
                        <SkillsContent>{e?.Name}</SkillsContent>
                      </SkillsWrapper>
                    );
                  })}
                </div>

                <Title>Probation Period</Title>
                {jobdata.probation_period === "Not Applicable" ? (
                  <Content>Not Applicable</Content>
                ) : (
                  <Content>{jobdata.probation_period} Months</Content>
                )}

                <Title>Working Arrangement*</Title>
                <Content>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: jobdata.working_arrangement,
                    }}
                  />
                </Content>
                <Title>Benefits*</Title>
                <Content>
                  <div dangerouslySetInnerHTML={{ __html: jobdata.benefits }} />
                </Content>

                <Title>Required Documents</Title>
                <DocumentWrapper>
                  <DocumentTitle>
                    <table
                      style={{ background: "#FCFCFC", borderRadius: "33px" }}
                    >
                      <thead>
                        <tr>
                          <th>Document</th>
                          <th>Mandatory</th>
                        </tr>
                      </thead>
                    </table>
                    {document.map((list, index) => (
                      <div key={index} style={{ fontWeight: "500" }}>
                        <hr />
                        <tr>
                          <td>{list.document_type}</td>
                          {list.mandatory === "Y" ? (
                            <td
                              style={{ position: "absolute", right: "36.5%" }}
                            >
                              Yes
                            </td>
                          ) : (
                            <td
                              style={{ position: "absolute", right: "36.5%" }}
                            >
                              No
                            </td>
                          )}
                        </tr>
                      </div>
                    ))}
                  </DocumentTitle>
                </DocumentWrapper>

                {/* <Title>Work Competency Factors</Title>
                {jobdata.research_evaluate == 0.6 &&

                  <SkillsWrapper>
                    <SkillsContent>{t("Evaluating & Resolving")}</SkillsContent>
                  </SkillsWrapper>
                }
                {jobdata.inception_formation == 0.6 &&

                  <SkillsWrapper>
                    <SkillsContent>{t("Innovating & Envisioning")}</SkillsContent>
                  </SkillsWrapper>
                }
                {jobdata.communication_presentation == 0.6 &&
                  <SkillsWrapper>
                    <SkillsContent>{t("Connecting & Portraying")}</SkillsContent>
                  </SkillsWrapper>
                }
                {jobdata.leadership == 0.6 &&
                  <SkillsWrapper>
                    <SkillsContent>{t("Supervising & Determining")}</SkillsContent>
                  </SkillsWrapper>
                }
                {jobdata.teamwork == 0.6 &&
                  <SkillsWrapper>
                    <SkillsContent>{t("Facilitating & Collaborating")}</SkillsContent>
                  </SkillsWrapper>
                }
                {jobdata.collectedness == 0.6 &&
                  <SkillsWrapper>
                    <SkillsContent>{t("Embracing & Managing")}</SkillsContent>
                  </SkillsWrapper>
                }
                {jobdata.plan_execte == 0.6 &&
                  <SkillsWrapper>
                    <SkillsContent>{t("Coordinating & Implementing")}</SkillsContent>
                  </SkillsWrapper>
                }
                {jobdata.inventive_accomplishment == 0.6 &&
                  <SkillsWrapper>
                    <SkillsContent>{t("Ambitious & Result-Oriented")}</SkillsContent>
                  </SkillsWrapper>
                }
                {jobdata.research_evaluate == false &&
                  jobdata.inception_formation == false &&
                  jobdata.communication_presentation == false &&
                  jobdata.leadership == false &&
                  jobdata.teamwork == false &&
                  jobdata.collectedness == false &&
                  jobdata.plan_execte == false &&
                  jobdata.inventive_accomplishment == false &&
                  <Row>
                    <Col xlg={3} >
                      <Content style={{ marginTop: "15px" }}>
                        {t("None")}
                      </Content>
                    </Col>
                  </Row>
                } */}
              </Wrapper2>
            </PageWrapper>
          )}
        </MainLayout>
        <JobConfirmationModal
          job={selectedJob}
          show={showConfirmation}
          title={t("Review Your Application")}
          heading={t(
            `You’re about to apply as an ${selectedJob?.title} at ${selectedJob?.company_name} Company.`
          )}
          para={t(
            "Your CV will be shared to the company for the hiring process. Please make sure you have updated it before submitting your application."
          )}
          head={t("Upload the below documents together with your application")}
          closeModal={() => setShowConfirmation(false)}
          onAccept={onAccept}
          jobDocs={jobDocs}
        />
      </div>
    </>
  );
};
export default ViewJob;

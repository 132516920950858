import styled from "styled-components";
import { useTranslation } from "react-i18next";


const CreatingRealValueStyling = styled.div`
  font-family: General Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  .heading {
    font-family: General Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
    color: #74388d;
  }
  .main-description {
    color: #74388d;
  }
  .sub-heading {
    font-family: General Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

export default function CreatingRealValueModalContent() {
  const { t, } = useTranslation();

  return (
    <CreatingRealValueStyling>
      <span className="heading">{t('Creating real value')}</span>
      <br />
      <span className="main-description">
        {t("Values in this main area pertain to the sense of importance you place on being able to contribute effectively to the organization and society at large through your work. These values encompass those relating to your identification with your organization's mission, the innate sense of pride in your organization's output, the efficiency of the work processes, the ethical standards that the organization lives up to, as well as the subjective value of the organization and your role as a creator of value.")}
      </span>
      <br />
      <br />
      <span className="sub-heading">{t('Mission')}</span>
      <br />
      {t("Values in this area refer to your understanding and support of the organization's mission and overall direction. It includes your identification with the organization's leadership direction and alignment with organizational values.")}
      <br />
      <br />
      <span className="sub-heading">{t('Quality')}</span>
      <br />
      {t("Values in this area refer to the sense of organizational pride you have when it comes to the output (products and/or services) of your organization. It also includes your personal, subjective judgments of the quality of such output.")}
      <br />
      <br />
      <span className="sub-heading">{t('Efficiency')}</span>
      <br />
      {t("Values in this area refer to your perceptions of the efficient use of resources within your organization. It includes being able to operate at minimal wastage with little to no redundancy in operational processes throughout the organization.")}
      <br />
      <br />
      <span className="sub-heading">{t('Ethics')}</span>
      <br />
      {t("Values in this area refer to your subjective judgment of the ethical standard of your organization. It includes your perceptions of the degree to which your organization applies an ethical code and the extent of organizational compliance to those standards.")}
      <br />
      <br />
      <span className="sub-heading">{t('Creating real value')}</span>
      <br />
      {t("Values in this area refer to your perceptions of the subjective value created by your organization to society at large. It also includes your perceptions of your role as a value creator within your organization.")}
    </CreatingRealValueStyling>
  );
}

import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import { Row, Col } from "react-bootstrap";

export const ManualWrapper = styled.div`
  @media only screen and (max-width: 600px) {
    padding: 15px;
    margin-left: 4px;
    margin-right: 24px;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    padding: 15px;
    margin-left: 4px;
    margin-right: 24px;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    margin-left: -26px;
    margin-right: -71px;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    margin-left: -26px;
    margin-right: -71px;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    margin-left: -26px;
    margin-right: -71px;
  }
`;
export const ModalHeaderText = styled.div`
  position: absolute;
  // left: 51px;
  margin-left: 10px;
  top: 44px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #d04c8d;
`;

export const ModalHeaderTextTwo = styled.div`
  position: absolute;
  // left: 51px;
  margin-left: 10px;
  top: 72px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #000000;
`;

export const ModalHeaderTextThree = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 35px;
  text-align: center;

  color: #202020;
  margin-top: 29px;
`;

export const ModalHeader = styled.div`
  height: 136px;
  background: rgba(83, 80, 141, 0.1);
  border-radius: 24px 24px 0px 0px;
`;

export const HeaderWrapper = styled.div``;
export const HeaderText = styled.h2`
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: ${Colors.dark};
  margin-top: 0;
`;

export const HeaderDescription = styled.p`
  height: 21px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #878787;
`;

export const Label = styled.label`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => (props.color ? props.color : Colors.labelText)};
  text-transform: ${(props) => (props.color ? "unset" : "capitalize")};
`;

export const DownloadTemplate = styled(Label)`
  color: ${Colors.inputHighlightText};
  cursor: pointer;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-decoration-line: underline;
  margin-top: 48px;
  margin-left: 3px;
`;

export const Upload = styled(Label)`
  color: ${Colors.inputHighlightText};
  font-family: "General Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  border: 1px solid #d04c8d;
  border-radius: 128px;
  padding: 7px 30px;
  margin-top: 35px;
  text-align: center;
  // width: "149px";
  margin-right: 20px;
  @media (min-width: 481px) and (max-width: 768px) {
    margin-right: 0px;
    cursor:pointer;
    padding: 7px 17px;

  }
  @media (max-width: 500px) {
    position: relative;
    display: table;
    line-height: 27px;
    padding: 4px 10px;
    margin-top: 20px;

  }
`;
export const Upload1 = styled(Label)`
  color: ${Colors.inputHighlightText};
  font-family: "General Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  border: 1px solid #d04c8d;
  border-radius: 128px;
  padding: 7px 30px;
  margin-top: 35px;
  text-align: center;
  // width: "200px";
  margin-right: 20px;
  @media (min-width: 769px) and (max-width: 1024px) {
    margin-right: 20px;
    position: relative;
    cursor:pointer;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    margin-right: 20px;
    position: absolute;
    cursor:pointer;
  }
  @media (min-width: 320px) and (max-width:480px) {
    position: relative;
    right: 8%;
    line-height: 27px;
    padding: 0px 7px;
    margin-top: 20px;
  }
`;

export const UploadComponent = styled.div`
  display: flex;
  justify-content: start;
  margin-left: 8px;
  padding-bottom: 8px;
`;

export const ComponentFile = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const SearchColumn = styled(Col)`
  input {
    font-family: General Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    ::placeholder {
      color: #bcbcbc;
    }
  }
`;

export const ButtonColumn = styled(Col)`
  button {
    padding: 11px 20px;
  }
`;

export const FilterButton = styled(Col)`
  background-color: white;
  border-radius: 23px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
`;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const jobReducer = createSlice({
  name: "jobreducer",
  initialState,
  reducers: {
    setId: (state, action) => {
      return { id: action.payload };
    },
    clearId: () => {
      return { id: null };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setId, clearId } = jobReducer.actions;

export default jobReducer.reducer;

import { useEffect, useState } from 'react';
import { Col, Row } from "react-bootstrap";
import CustomButton from "../../../components/button/button";
import SkillCard from './skillCard';
import styled from 'styled-components';
import { Colors } from '../../../utilities/colors';
import { deleteSkills, getSkills, saveSkills, getAllSkills } from '../../../services/apiCalls';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Button = styled.button`
  background-color: transparent;
  border: 1px solid #acacac;
  padding: 5px;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  color: #acacac;
`;

const SkillsInfo = styled.span`
color: ${Colors.inputTextDark};
paddingTop: '10px';
`;
const CardContainer = styled.div``;

const SkillCardWrapper = styled.div`
padding-top: 10px;
padding-left:0px;
display: flex;
flex-wrap: wrap;
`;

const Skills = () => {
  const [addSkills, setAddSkills] = useState(false);
  const [existingSkillsList, setSkills] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState({})
  const [skill, setSkill] = useState('');
  const [isPatch, setIsPatch] = useState(false);
  const [isEditable, setIsEditable] = useState(true);

  useEffect(() => {
    fetchSkills();
  }, [])

  const dropdownChange = async (val) => {
    setSelectedSkill(val[0])
  }

  const handleSave = async () => {
    const payload = {
      data: [selectedSkill.label]
    }
    var temp_arr = [];
    existingSkillsList.map((list) => (
      temp_arr.push(list.label)
    ))
    if(temp_arr.includes(selectedSkill.label)){
      toast.error("skill already added", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }else{
      const { data, status } = await saveSkills(payload);
      if (status === 200 && data.status === 1) {
        fetchSkills();
      }
      setSelectedSkill('');
      setAddSkills(false);
    }
    
  }

  const deleteSkill = async (id) => {
    const { data, status } = await deleteSkills(id);
    if (status === 200 && data.status === 1) {
      fetchSkills();
      resetForm();
    }
  }

  const resetForm = () => {
    setAddSkills(false);
    setSkill('');
  }

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const fetchAllSkills = async (searchKey) => {
    const { data, status } = await getAllSkills(searchKey);
    if (status === 200 && data.status === 1) {
      const options = data.data.map(x => {
        return { ...x, label: x.Example, value: x.Id };
      });
      setOptions(options);
      setIsLoading(false);
    }
  }

  const fetchSkills = async (searchKey) => {
    const { data, status } = await getSkills();
    if (status === 200 && data.status === 1) {
      if (data.data.length < 30) {
        setIsEditable(true);
      }
      else if (data.data.length >= 30) {
        setIsEditable(false);
      }
      const existingSkills = data.data;
      const existingSkillsList = existingSkills.map(x => {
        return { ...x, label: x.title, value: x.id };
      });
      setSkills(existingSkillsList);
    }
  }

  const filterBy = () => true;
  const handleSearch = (query) => {
    setIsLoading(true);
    fetchAllSkills(query);
  }

  const { t } = useTranslation();

  return (
    <CardContainer>
      <Row>
        <Col md="12" className="p-0">
          <Button 
            onClick={() => setAddSkills(true)}
            disabled={!isEditable}>{t("Add Skills")}</Button>
          <SkillsInfo>&nbsp;&nbsp;{isEditable ? t('(Max 30 skills can be added)') : t('(Maximum number of skills has been added. Make space to add new skills)')}</SkillsInfo>
          {addSkills &&
            <Row>
              <Col md="12" className="pl-0 mt-3">
                <SkillCardWrapper>
                  <AsyncTypeahead
                    style={{ minWidth: '41.5%' }}
                    filterBy={filterBy}
                    id="async-example"
                    isLoading={isLoading}
                    labelKey="label"
                    minLength={3}
                    onSearch={handleSearch}
                    options={options}
                    placeholder={t("Search for a skill ...")}
                    onChange={(val) => dropdownChange(val)}
                  />
                </SkillCardWrapper>
              </Col>
              <Col md="12" className='mt-3 pl-0'>
                <CustomButton className="mw-50"
                  style={{ marginTop: '0px' }}
                  onClick={handleSave}
                  margin="15px 10px 15px 0px"
                  width="8em"
                  fontSize="14px"
                  lineHeight="18px"
                  minWidth="0"
                  name={t("Save")}
                />
                <CustomButton  className="mw-50"
                  onClick={resetForm}
                  style={{ marginTop: '0px' }}
                  secondary
                  name={t("Cancel")}
                  margin="15px 10px 15px 0px"
                  width="8em"
                  fontSize="14px"
                  lineHeight="18px"
                  minWidth="0"
                />
              </Col>
            </Row>}
          <Row>
            <SkillCardWrapper >
              {existingSkillsList.length > 0 && existingSkillsList.map((skill) => (
                <SkillCard
                  id={skill.value}
                  label={skill.label}
                  delete={(id) => deleteSkill(id)}
                  
                />
              ))}
            </SkillCardWrapper>
          </Row>
        </Col>
      </Row>
    </CardContainer>
  )
}
export default Skills;
export const mynextTalent = {
    defaultCountryId: 132,
    defaultCountry: 'Malaysia',
    defaultStateId: 1949,
    defaultCityId: 76497,
    defaultRaceId: 2,
    defaultNationalityId: 1,
    defaultUniversityId: 98,
    defaultCampusId: 1,
    defaultFacultyId: 116,
    defaultStudyProgramme: 1
};
import styled from "styled-components";

import Logo from "../../../assets/icons/skillCancel.png";

const SkillCardWrapper = styled.div`
  background: #D3D3D3;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 12px 20px;
  margin:0px 20px 10px 0;
  > img {
    cursor:pointer;
  }
`;
const SkillText = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #989898;
  margin:0;
  margin-right:30px;
`;
export default function SkillCard(props) {
  return (
    <SkillCardWrapper>
      <SkillText>{props.label}</SkillText>
      <img onClick={()=>props.delete(props.id)} src={Logo} alt='cancel skill' />
    </SkillCardWrapper>
  );
}

import React from "react"
import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import AlertModal from '../../components/alertModal/alertModal';
import CustomButton from "../../components/button/button";
import { useTranslation } from "react-i18next";
const Message = styled.div`
  font-size: 22px;
  padding-bottom: 5px;
  margin-bottom:20px
`;
const AddEmployeeUnlink = ({ id, show, onAccept, closeModal, message})=>{
    const { t } = useTranslation();
    let content = <>
        <Message>{message}</Message>
        <div>
            <Col md="6" />
            <Col
                md="12"
                className="d-flex justify-content-center align-items-center"
            >
               
                <CustomButton
                    secondary
                    width="192px"
                    name={t("No")}
                    color="#FFFFFF"
                    margin="20px 0px 30px 0px"
                    onClick={()=>closeModal("unlink")}
                />
                 <CustomButton
                    width="192px"
                    margin="20px 0px 30px 20px"
                    name={t("Yes")}
                    onClick={() => onAccept("unlink")}
                />
            </Col>
        </div>
    </>
    return (<AlertModal show={show} close={()=>closeModal("unlink")}  content={content} />)
}

export default AddEmployeeUnlink
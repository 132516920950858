import * as yup from "yup";
import { countPlaces } from "../../utilities/commonFunctions";

export const studyingStaticContent = {
  title: "Tell us more about yourself",
  description:
    "We will need some basic information about you before we get started",
  scholarshipTitle: "Type of Scholarship*",
  scholarshipTypes: [
    { label: "Scholarship/Loan", value: 0 },
    { label: "Self- Funded", value: 1 },
  ],
  curr_qualification: "Current Academic Qualification*",
  insti_location: "Type of Institution*",
  institutionTypes: [
    {
      label:
        "I'm studying in Malaysia or on exchange programme (If you are on an exchange programme, please indicate your Malaysian Education Institution)",
      value: 1,
    },
    {
      label: "I'm studying abroad",
      value: 0,
    },
  ],
  instiName: "Name of Institution*",
  university: "Name of University/Institution",
  campus: "Campus*",
  study: "Study Programme*",
  faculty: "Faculty*",
  country: "Select Country*",
  scope: "Scope of Study*",
  enrollmentDate: "University Enrollment Date*",
  graduationDate: "Expected Graduation Date*",
  yearOfStudy: "Current Year of Study*",
  gradeTitle: "Please indicate your current grade*",
  grades: ["CGPA", "Grades", "Others", "No Current Grade"],
  englishTestTitle: "English equivalency test*",
  tests: [
    "MUET",
    "CEFR",
    "TOEFL",
    "IELTS",
    "Others",
    "I have not taken any tests",
  ],
  internTitle: "Internship Period",
  job: "Position/Job Title*",
  allowance: "Allowance Per Month (RM)*",
};

export const studyingFields = {
  scholar_status: "",
  scholar_data: "",
  curr_qualification: "",
  insti_location_status: "",
  study_program: "",
  insti_name: "",
  campus: "",
  faculty: "",
  insti_country: "",
  univ_enroll_date: "",
  exp_graduate_date: "",
  curr_study_year: "",
  campusIndicator: false,
  scope: "",
  grade_status: "",
  grade: "",
  english_tests: "",
  english_score: "",
  intern_dur_year: "",
  intern_dur_month: "",
  intern_position: "",
  intern_allowance: "",
  step: 2,
};

export const studyingErrorMessages = {
  scholar_status: "Please choose scholarship type.",
  insti_location_status: "Please select one option.",
};

const tests = ["MUET", "CEFR", "TOEFL", "IELTS", "Others"];
const grades = ["CGPA", "Grades", "Others"];

export const studySchema = yup
  .object({
    scholar_status: yup.string().required(),
    //scholar_data: yup.string().required("Scholarship/Loan type is required."),
    scholar_data: yup.string().when("scholar_status", (scholar_status) => {
      if (scholar_status === 0) {
        return yup.string().required("Type of scholarship is required");
      }
    }),

    curr_qualification: yup
      .string()
      .required("Current academic qualification is required"),
    insti_location_status: yup
      .number()
      .typeError("You must choose one option")
      .required(""),

    insti_name: yup
      .string()
      .when("insti_location_status", (insti_location_status) => {
        if (insti_location_status === 1)
          return yup.string().required("Institution name is required");
      }),

    campus: yup
      .string()
      .when(
        // "insti_location_status",
        // (insti_location_status) => {
        //   if (insti_location_status === 1) {
        //     return yup.string().required("Campus is required");

        ["$campusDisable", "insti_location_status"],
        (campusDisable, insti_location_status) => {
          if (!campusDisable && insti_location_status === 1) {
            return yup.string().required("Campus is required");

          }
        }
      ),

    faculty: yup
      .string()
      .when(
        ["$campusDisable", '$facultyDisable', "insti_location_status"],
        (campusDisable, facultyDisable, insti_location_status) => {
          if (!campusDisable && !facultyDisable && insti_location_status === 1) {
            return yup.string().required("Faculty is required");
          }
        }
      ),
    study_program: yup
      .string()
      .when(
        ["$campusDisable", "$facultyDisable", "$programmeDisable", "insti_location_status"],
        (campusDisable, facultyDisable, programmeDisable, insti_location_status) => {
          if (!campusDisable && !facultyDisable && !programmeDisable && insti_location_status === 1) {
            return yup.string().required("Study program is required");
          }
        }
      ),
    insti_country: yup
      .string()
      .when("insti_location_status", (insti_location_status) => {
        if (insti_location_status === 0)
          return yup.string().required("Institution country is required");
      }),
    university: yup
      .string()
      .when("insti_location_status", (insti_location_status) => {
        if (insti_location_status === 0)
          return yup.string()
          .required("University name is required")
          .matches(/^[A-Za-z][A-Za-z0-9 .,\-'&]+$/, "Insert valid university name");
        // (val) => val == undefined || val == null || val == "";
      }),
    univ_enroll_date: yup.string().required("Enrolment date is required"),
    exp_graduate_date: yup
      .string()
      .required("Expected Graduation Date is required"),
    curr_study_year: yup
      .string()
      .required("Current academic qualification is required"),
    scope: yup.string().required("Scope is required"),
    grade_status: yup.string().required("Grade status is required"),
    grade: yup.string().when("grade_status", (grade_status) => {
      if (grade_status === "CGPA") {
        return yup
          .string()
          .required("CGPA is required")
          .test(
            "grade",
            "CGPA should be between 1 and 10 and precision up to 2 decimal places",
            (val) => val === undefined || val === null || (val <= 10 && val >= 1)
          );
      }
      else if (grade_status === "Grades") {
        return yup
          .string()
          .required("Grade is required");
      }
      else if (grade_status === "Others") {
        return yup
          .string()
          .required("Grade is required");
      }
      else {
        if (tests.indexOf(grade_status) !== -1)
          return yup.string().required("Grade is required");
      }
    }),
    english_tests: yup.string().required("Test selection is required"),
    english_score: yup.string().when("english_tests", (english_tests) => {
      if (english_tests === "IELTS") {
        return yup
          .string()
          .required("Test score is required")
          .test(
            "english_score",
            "Score should be between 0 and 9",
            (val) => val === undefined || val === null || (val <= 9 && val >= 0)
          );
      } else if (english_tests === "TOEFL") {
        return yup
          .string()
          .required("Test score is required")
          .test(
            "english_score",
            "Score should be between 0 and 30",
            (val) => val === undefined || val === null || (val <= 30 && val >= 0)
          );
      } else if (english_tests === "Others") {
        return yup
          .string()
          .required("Test score and type is required")
      } else {
        if (tests.indexOf(english_tests) !== -1)
          return yup.string().required("Test score is required");
      }
    }),
  })
  .required();

export const internSchema = yup
  .object({
    scholar_status: yup.string().required(),
    //scholar_data: yup.string().required("Scholarship/Loan type is required."),
    // scholar_data: yup.string().when("scholar_status", (scholar_status) => {
    //   if (scholar_status === 0)
    //     return yup.string().required("type of scholarship is a required field");
    // }),
    scholar_data: yup.string().when("scholar_status", {
      is: 0,
      then: yup
        .string()
        .required("Type of scholarship is required")
        .test(
          "scholar_data",
          "type of scholarship is a required ",
          (val) => val === undefined || val === null || val === ""
        ),
    }),
    curr_qualification: yup
      .string()
      .required("Current academic qualification is required."),
    insti_location_status: yup.number().required(),

    insti_name: yup
      .string()
      .when("insti_location_status", (insti_location_status) => {
        if (insti_location_status === 1)
          return yup.string().required("Institution name is required");
      }),
    campus: yup
      .string()
      .when(
        // "insti_location_status",
        // (insti_location_status) => {
        //   if (insti_location_status === 1) {
        //     return yup.string().required("Campus is required");
        //   }
        // }
        ["$campusDisable", "insti_location_status"],
        (campusDisable, insti_location_status) => {
          if (!campusDisable && insti_location_status === 1) {
            return yup.string().required("Campus is required");

          }
        }
      ),

    faculty: yup
      .string()
      .when(
        ["$facultyDisable", "insti_location_status"],
        (facultyDisable, insti_location_status) => {
          if (facultyDisable === false && insti_location_status === 1) {
            return yup.string().required("Faculty is required");
          }
        }
      ),
    // study_program: yup
    //   .string()
    //   .when(
    //     ["$programmeDisable", "insti_location_status"],
    //     (programmeDisable, insti_location_status) => {
    //       if (programmeDisable === false && insti_location_status === 1) {
    //         return yup.string().required("Study program is required");
    //       }
    //     }
    //   ),
    insti_country: yup
      .string()
      .when("insti_location_status", (insti_location_status) => {
        if (insti_location_status === 0)
          return yup.string().required("Institution country is required");
      }),
    university: yup
      .string().when("insti_location_status", (insti_location_status) => {
        if (insti_location_status === 0)
          return yup.string()
          .required("University name is required")
          .matches(/^[A-Za-z][A-Za-z0-9 .,\-'&]+$/, "Insert valid university name");
      }),
    univ_enroll_date: yup.string().required("Enrolment date is required"),
    exp_graduate_date: yup
      .string()
      .required("Expected Graduation Date is required"),
    curr_study_year: yup.string().required("Year of study is required"),
    scope: yup.string().required("Scope is required"),
    grade_status: yup.string().required("Grade status is required"),
    grade: yup.string().when("grade_status", (grade_status) => {
      if (grade_status === "CGPA") {
        return yup
          .string()
          .required("CGPA is required")
          .test(
            "grade",
            "CGPA should be between 1 and 10 and precision up to 2 decimal places",
            (val) => val === undefined || val === null || (val <= 10 && val >= 1)
          );
      }
      else if (grade_status === "Grades") {
        return yup
          .string()
          .required("Grade is required");
      }
      else if (grade_status === "Others") {
        return yup
          .string()
          .required("Grade is required");
      }
      else {
        if (tests.indexOf(grade_status) !== -1)
          return yup.string().required("Grade is required");
      }
    }),
    english_tests: yup.string().required("Test selection is required"),
    english_score: yup.string().when("english_tests", (english_tests) => {
      if (english_tests === "IELTS") {
        return yup
          .string()
          .required("Test score is required")
          .test(
            "english_score",
            "Score should be between 0 and 9",
            (val) => val === undefined || val === null || (val <= 9 && val >= 0)
          );
      } else if (english_tests === "TOEFL") {
        return yup
          .string()
          .required("Test score is required")
          .test(
            "english_score",
            "Score should be between 0 and 30",
            (val) => val === undefined || val === null || (val <= 30 && val >= 0)
          );
      } else if (english_tests === "Others") {
        return yup
          .string()
          .required("Test score and type is required")
      } else {
        if (tests.indexOf(english_tests) !== -1)
          return yup.string().required("Test score is required");
      }
    }),
    intern_dur_year: yup.string().required("Start date is required"),
    intern_dur_month: yup.string().required("End date is required"),
    intern_position: yup.string().required("Job title is required"),
    intern_allowance: yup.string().required("Allowance per month is required"),
  })
  .required();

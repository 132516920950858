import { Modal } from "react-bootstrap";
// import { ModalHeader, ModalHeaderText, ModalHeaderTextTwo } from "../../pages/studentManagement/indexComponents";
import { ModalHeader, ModalHeaderText, ModalHeaderTextTwo } from "../alertModal/indexComponents";




function VisibilityCVPopup(props) {
  return (
    <div>
      <Modal show={props.show} onHide={props.close} backdrop="static" centered>
        <div style={{ padding: '9px' }}>
          <ModalHeader></ModalHeader>
          <ModalHeaderText>{props.title}</ModalHeaderText>
          <ModalHeaderTextTwo>{props.subTitle}</ModalHeaderTextTwo>
          {props.content}
        </div>
      </Modal>
    </div>
  );
}

export default VisibilityCVPopup;

// import Cookies from 'js-cookie';  #unused variable#
import { Navigate, useLocation  } from "react-router-dom";

function RestrictedRoute({ children }) {
  console.log("this routing");
  const currentUser = localStorage.getItem("auth_token");
  const location = useLocation();
  
  return currentUser ?<Navigate to='/dashboard' />: children  ;

}

export default RestrictedRoute;
 

import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import {
  getDataWorkEmployability,
  getDataWorkEnglishTest,
  getDataWorkFutureWorks,
  getDataWorkInterest,
  getDataWorkPersonality,
  getDataWorkValues,
  getResumeData,
  getTalentUserData,
} from "../../services/apiCalls";
import { t } from "i18next";
import { getTalentOceanReport } from "../../services/apiCalls";
import { IconButton } from "@material-ui/core";
import { Col, Row, Container } from "react-bootstrap";
import WorkCompetencyFactorChart from "../studentOceanCharts/workCompetencyFactorChart";
import PersonalityAspectsChart from "../studentOceanCharts/personalityAspectsChart";
import ThirtyFacetsChart from "../studentOceanCharts/thirtyFacetsChart";
import OrganizationalFitFactor from "../studentOceanCharts/organizationalFitFactorChart";
import PredictiveReliabilityFactorChart from "../studentOceanCharts/predictiveReliabilityFactorChart";
import { Ad2StudentPersonalityProfile } from "../studentOceanCharts/studentPersonalityChart";
import { Ad2StudentWorkInterest } from "../studentOceanCharts/studentWorkInterestChart";
import { Ad2EmployabilityFactors } from "../studentOceanCharts/studentEmployabilityChart";
import { Ad2FutureOfWork } from "../studentOceanCharts/studentFutureOfWorkChart";
import { Ad2EnglishProficiency } from "../studentOceanCharts/studentEnglishChart";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import DetailModal from "../../components/detailModal/detailModal"
import { updateModal } from "../../reducers/detailModalReducer";
import InterestProfileModal from "./my-cv-preview/modals/interestProfileModal/interestProfileModal";
import PreferencesModal from "./my-cv-preview/modals/preferencesModal/preferencesModal";
import EmployabilityFactorModal from "./my-cv-preview/modals/employabilityFactorModal/employabilityFactorModal";
import ValueProfileModal from "./my-cv-preview/modals/valueProfileModal/valueProfileModal";
import FutureWorkFactorModal from "./my-cv-preview/modals/futureWorkFactorModal/futureWorkFactorModal";
import MotivationLevelModal from "./my-cv-preview/modals/motivationLevelModal/motivationLevelModal";
import EnglishProficiencyModal from "./my-cv-preview/modals/englishProficiencyModal/englishProficiencyModal";
import { Ad2StudentWorkValues } from "../studentOceanCharts/studentWorkValuesChart";
const UpIcon = styled(ArrowDropUpIcon)`
  position: relative;
  color: #c4c4c4;
`;
const DownIcon = styled(ArrowDropDownIcon)`
  position: relative;
  color: #c4c4c4;
`;

const MainWrapper = styled.div`
  background: #ffffff;
  border-radius: 55px;
  padding: 24px;
  margin: 40px;
`;

const SeparatorDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
const HrLine = styled.div`
  width: 100%;
  // position: relative;
  // margin: 15px;
  height: 10px;
  border-bottom: 0.6px solid #c4c4c4;
`;
const Separator = styled.div`
  padding: 0 10px;
  flex: 1 0 auto;
  text-align: center;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202020;
`;
const ModalContainer = styled(Container)`
  @media (min-width: 480px) {
    width: 80vw;
  }

  @media (min-width: 768px) {
    width: 100vw;
  }

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    width: 892px;
    // padding: 25px 70px;
  }

  /* Large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
    width: 892px;
    // padding: 25px 70px;
  }
`;

export default function MyCvAssesment() {
  const dispatch = useDispatch();
  const params = useParams();
  const id = params && params.id;
  // const uni_id = useSelector((state) => state.auth.user.university.id);
  const [modalMode, setModalMode] = useState("");
  const [dataUser, setUserdata] = useState({});
  const [workValues, setWorkValues] = useState([]);
  const [workValuesView, setWorkValuesView] = useState([]);
  const [workValuesLabels, setWorkValuesLabels] = useState([]);
  const [workValuesColors, setWorkValuesColors] = useState([]);
  const [workValuesData, setWorkValuesData] = useState([]);

  const [workInterest, setWorkInterest] = useState([]);
  const [workInterestView, setWorkInterestView] = useState([]);
  const [workInterestLabels, setWorkInterestLabels] = useState([]);
  const [workInterestColors, setWorkInterestColors] = useState([]);
  const [workInterestData, setWorkInterestData] = useState([]);

  const [workPersonality, setWorkPersonality] = useState([]);
  const [workPersonalityData, setWorkPersonalityData] = useState([]);
  const [workPersonalityView, setWorkPersonalityView] = useState([]);
  const [workPersonalityLabels, setWorkPersonalityLabels] = useState([]);
  const [workPersonalityColors, setWorkPersonalityColors] = useState([]);

  const [workEmployability, setWorkEmployability] = useState([]);
  const [workEmployabilityData, setWorkEmployabilityData] = useState([]);
  const [workEmployabilityView, setWorkEmployabilityView] = useState([]);
  const [workEmployabilityDetails, setWorkEmployabilityDetails] = useState([]);

  const [workFutureWorks, setWorkFutureWorks] = useState([]);
  const [workFutureWorksView, setWorkFutureWorksView] = useState([]);
  const [workFutureWorksData, setWorkFutureWorksData] = useState([]);

  const [workEnglishTest, setWorkEnglishTest] = useState([]);
  const [workEnglishTestView, setWorkEnglishTestView] = useState([]);

  const [userData, setUserData] = useState([]);
  const [talentData, setTalentData] = useState("");
  const [openAssessmentReport, setOpenAssessmentReport] = useState(true);
  const [openOceanReport, setOpenOceanReport] = useState(true);

  const [openStudentHistory, setOpenStudentHistory] = useState(true);
  const [studentHistoryData, setStudentHistoryData] = useState([]);
  const [facetData, setFacetData] = useState([]);
  const [orgFitData, setOrgFitData] = useState([]);
  const [personalityAspectData, setPersonalityAspectData] = useState([]);
  const [reliabilityData, setReliabilityData] = useState([]);
  const [competencyData, setCompetencyData] = useState([]);
  const [profileDetails, setProfileDetails] = useState(null);

  const getDataValue = async (id) => {
    const { data } = await getDataWorkValues(id);
    if (data?.status === 1) {
      var datasLabel = [];
      var datasValue = [];
      var datasColor = [];
      var datasValues = [];
      if(Array.isArray(data?.data) && data?.data.length === 0){
        setWorkValuesData({data:[],status:"noDataFound"});
      }else{
        Object.keys(data?.data).map((e, i) => {
          datasLabel.push(t(e));
          datasValue.push(data?.data[e]["points"]);
          datasValues.push(data?.data[e]["values"]);
  
          var x = Math.floor(Math.random() * 256);
          var y = 25 + Math.floor(Math.random() * 256);
          var z = 50 + Math.floor(Math.random() * 256);
          datasColor.push("rgb(" + x + "," + y + "," + z + ",0.5)");
        });
        setWorkValuesLabels(datasLabel);
        setWorkValues(datasValue);
        setWorkValuesColors(datasColor);
        setWorkValuesView(datasValues);
        setWorkValuesData(data?.data);
      }
    }
  };
  console.log(JSON.stringify(workEmployabilityData), "ahgfdsfygf");
  const getDataIntrest = async (id) => {
    const { data } = await getDataWorkInterest(id);
    if (data?.status === 1) {
      var datasLabel = [];
      var datasValue = [];
      var datasColor = [];
      var datasValues = [];

      Object.keys(data?.data).map((e, i) => {
        datasLabel.push(t(e));
        datasValue.push(data?.data[e]["points"]);
        var x = Math.floor(Math.random() * 256);
        var y = 25 + Math.floor(Math.random() * 256);
        var z = 50 + Math.floor(Math.random() * 256);
        datasColor.push("rgb(" + x + "," + y + "," + z + ",0.5)");
      });
      setWorkInterestLabels(datasLabel);
      setWorkInterest(datasValue);
      setWorkInterestColors(datasColor);
      setWorkInterestView(data?.data);
      setWorkInterestData(data?.data);
    }
  };
  const getDataPersonality = async (id) => {
    const { data } = await getDataWorkPersonality(id);
    if (data?.status === 1) {
      var datasLabel = [];
      var datasValue = [];
      var datasColor = [];

      Object.keys(data?.data).map((e, i) => {
        datasLabel.push(t(e));
        datasValue.push(data?.data[e]["points"]);
        var x = Math.floor(Math.random() * 256);
        var y = 25 + Math.floor(Math.random() * 256);
        var z = 50 + Math.floor(Math.random() * 256);
        datasColor.push("rgb(" + x + "," + y + "," + z + ",0.5)");
      });

      setWorkPersonalityData(data?.data);
      setWorkPersonalityLabels(datasLabel);
      setWorkPersonality(datasValue);
      setWorkPersonalityColors(datasColor);
      setWorkPersonalityView(data?.data);
    }
  };
  const getDataEmployability = async (id) => {
    const { data } = await getDataWorkEmployability(id);
    if (data?.status === 1) {
      console.clear();
      console.log(data.data, "ghadhagsfdfasfdfsfdrtQFDQETDS");
      setWorkEmployabilityDetails(data);
      setWorkEmployabilityView(data?.data?.domains);
      setWorkEmployability(data?.data?.totalScore?.points);
      setWorkEmployabilityData(data?.data);
    }
  };

  const getDataFutureWorks = async (id) => {
    const { data } = await getDataWorkFutureWorks(id);
    if (data?.status === 1 && data?.data?.domains) {
      setWorkFutureWorksView(data?.data.domains);
      setWorkFutureWorks(data?.data.totalScore?.points);
      setWorkFutureWorksData(data?.data);
    }
  };
  const getDataEnglishTest = async (id) => {
    const { data } = await getDataWorkEnglishTest(id);
    if (data?.status === 1) {
      setWorkEnglishTestView(data?.data);
      setWorkEnglishTest(
        data?.data?.General && data?.data?.General.points !== undefined
          ? data?.data?.General.points
          : "0"
      );
    }
  };
  const getOceanReport = async (id) => {
    const { data } = await getTalentOceanReport(id);
    if (data?.status === 1) {
      const OCEANData = data?.data?.data[0];
      setFacetData(OCEANData["30 - Facets"]);
      setOrgFitData(OCEANData["Organizational Fit Factor"]);
      setPersonalityAspectData(OCEANData["Personality Aspects"]);
      setReliabilityData(OCEANData["Predictive Reliability Factor"]);
      setCompetencyData(OCEANData["Work Competency Factor"]);
    }
  };
  const getMyCv = async (id) => {
    // const { data } = await getMyCyOverall(id);
    const { data } = await getResumeData();
    if (data?.status === 1) {
      setUserdata(data.data);
      console.log(JSON.stringify(data.data), "gfhgdsgfgsdfash");
    }
  };

  const getUser = async () => {
    const { data } = await getTalentUserData();

    if (data && data.status === 1) {
      let id = data.data.id;
      await getMyCv(id);
      await getDataValue(id);
      await getDataIntrest(id);
      await getDataPersonality(id);
      await getDataEmployability(id);
      await getDataFutureWorks(id);
      await getDataEnglishTest(id);
      await getOceanReport(id); //this is static id for refren university
    }
  };
  console.log(competencyData, "gasdgASFDRASDxtrfas");
  useEffect(() => {
    getUser();
  }, []);
  const handleOpenOceanReport = () => {
    setOpenOceanReport(!openOceanReport);
  };
  const handleOpenAssessmentReport = () => {
    setOpenAssessmentReport(!openAssessmentReport);
  };
  const getModalContent = () => {
    switch (modalMode) {
      case "interest":
        return (
          <InterestProfileModal
            data={workValuesView}
            labels={workValuesLabels}
          />
        );
      case "preferences":
        return (
          <PreferencesModal
            result={workPersonalityData}
            data={workPersonality}
            labels={workPersonalityLabels}
            colors={workPersonalityColors}
          />
        );
      case "employability":
        return <EmployabilityFactorModal data={workEmployabilityDetails} />;
      case "valueProfile":
        return <ValueProfileModal data={workEmployabilityView} />;
      case "futureLearning":
        return <FutureWorkFactorModal data={workFutureWorksView} />;
      case "motivationLevel":
        return <MotivationLevelModal data={workInterestView} />;
      case "englishProficiency":
        return <EnglishProficiencyModal data={workEnglishTestView} />;
      default:
        break;
    }
  };
  return (
    <>
      <MainWrapper>

        <SeparatorDiv onClick={handleOpenAssessmentReport}>
          <HrLine />
          <Separator>{t("Assessment Report")}</Separator>
          <HrLine />
          {openAssessmentReport ? (
            <IconButton>
              <UpIcon />
            </IconButton>
          ) : (
            <IconButton>
              <DownIcon />
            </IconButton>
          )}
        </SeparatorDiv>
        {openAssessmentReport ? (
          <>
            <Row>
              <Col lg="12" style={{ marginTop: "20px" }}>
                <Ad2StudentPersonalityProfile
                  onViewMoreClick={() => {
                    setModalMode("preferences");
                    dispatch(updateModal(true));
                    console.log("Clicked personality details");
                  }}
                  data={workPersonalityData} />
              </Col>
              <Col lg="6" style={{ marginTop: "20px" }}>
                <Ad2StudentWorkValues
                  onViewMoreClick={() => {
                    setModalMode("interest");
                    dispatch(updateModal(true));
                    console.log("Clicked workvalue");
                  }}
                  data={workValuesData}
                />
              </Col>
              <Col lg="6" style={{ marginTop: "20px" }}>
                <Ad2StudentWorkInterest 
                  onViewMoreClick={() => {
                    setModalMode("motivationLevel");
                    dispatch(updateModal(true));
                    console.log("Clicked motivation");
                  }}
                  data={workInterestData} />
              </Col>
            </Row>
            <Row>
              <Col lg="12" style={{ marginTop: "20px" }}>
                <Ad2EmployabilityFactors
                  data={workEmployabilityData}
                  onViewMoreClick={() => {
                    setModalMode("employability");
                    dispatch(updateModal(true));
                    console.log("Clicked employ");
                  }}
                ></Ad2EmployabilityFactors>
              </Col>
            </Row>
            <Row>
              <Col lg="12" style={{ marginTop: "20px" }}>
                <Ad2FutureOfWork 
                  onViewMoreClick={() => {
                    setModalMode("futureLearning");
                    dispatch(updateModal(true));
                    console.log("Clicked future");
                  }}
                  data={workFutureWorksData}></Ad2FutureOfWork>
              </Col>
            </Row>
            <Row>
              <Col lg="12" style={{ marginTop: "20px", marginBottom: "20px" }}>
                <Ad2EnglishProficiency
                  onViewMoreClick={() => {
                    setModalMode("englishProficiency");
                    dispatch(updateModal(true));
                    console.log("Clicked english");
                  }}
                  data={workEnglishTestView}
                ></Ad2EnglishProficiency>
              </Col>
            </Row>
          </>
        ) : (
          ""
        )}

        <br />
        <hr />
        <SeparatorDiv onClick={handleOpenOceanReport}>
          <HrLine />
          <Separator>{t("OCEAN Report")}</Separator>
          <HrLine />
          {openOceanReport ? (
            <IconButton>
              <UpIcon />
            </IconButton>
          ) : (
            <IconButton>
              <DownIcon />
            </IconButton>
          )}
        </SeparatorDiv>

        {openOceanReport ? (
          <>
            <Row lg="12">
              <Col style={{ marginTop: "20px" }}>
                <WorkCompetencyFactorChart data={competencyData}>
                  {" "}
                </WorkCompetencyFactorChart>
              </Col>
              <Col style={{ marginTop: "20px" }}>
                <PersonalityAspectsChart
                  data={personalityAspectData}
                ></PersonalityAspectsChart>
              </Col>
            </Row>
            <Row lg="12">
              
                <ThirtyFacetsChart data={facetData}> </ThirtyFacetsChart>
              
            </Row>
            <Row lg="12">
              {/* <Col style={{ marginTop: "20px" }}>
                <OrganizationalFitFactor
                  data={orgFitData}
                ></OrganizationalFitFactor>
              </Col> */}
              <Col style={{ marginTop: "20px" }}>
                <PredictiveReliabilityFactorChart
                  data={reliabilityData}
                ></PredictiveReliabilityFactorChart>
              </Col>
            </Row>
          </>
        ) : (
          ""
        )}
      </MainWrapper>
      <DetailModal>
        <ModalContainer>
          <Row>
            <Col>{getModalContent()}</Col>
          </Row>
        </ModalContainer>
      </DetailModal>
    </>
  );
}

import styled from "styled-components";

import { Colors } from "../../utilities/colors";

const RadioButtonGroup = styled.div`
  display: flex;
  margin-bottom: ${(props) => (props.margin ? props.margin : "0px")};
  cursor: pointer;
  margin-top: ${(props) =>
    props.name === "disability_status" ? props.margin : ""};

  @media (max-width: 599px) {
    display: ${(props) =>
    props.name === "id_type" ||
      props.name === "gender" ||
      props.name === "scholar_status" ||
      props.name === "grade_status" ||
      props.name === "insti_country_status" ||
      props.name === "curr_tc_program"
      ? "contents"
      : ""};
  }
`;
const Label = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.labelText};
  margin: 0 1rem;
`;

export default function RadioInput({
  name,
  label,
  margin,
  checked,
  onChange,
  disable,
  data_disabled,
}) {
  // const onChange=()=>{
  //   alert('hi')
  // }
  return (
    <RadioButtonGroup
      onClick={onChange}
      margin={margin}
      name={name}
      className="radio"
    >
      <input
        checked={checked}
        onChange={onChange}
        name={name}
        value={label}
        // disabled={disable}
        type="radio"
      />
      <div className="redio-button"></div>
      {data_disabled === true ?
        <Label style={{color:"gray"}}>{label}</Label>
        :
        <Label>{label}</Label>
      }
    </RadioButtonGroup>
  );
}

import styled from "styled-components";
import { useTranslation } from "react-i18next";

const FooterWrapper = styled.footer`
  padding: 0 3rem;
  margin: 2rem 0;
  @media (max-width: 600px) {
    padding: 0 1rem;
    text-align: center;
    margin-top: 3rem;
  }
`;
const FlexWrapper = styled.div`
  display: flex;
`;
const HeaderTextWrapper = styled(FlexWrapper)`
  justify-content: space-between;
  padding: 1rem 0;
  border-top: 2px solid #B5B5B6;
  margin-top: 100px;
`;
const LocationText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #9a9a9a;
  margin: 0;
  @media (max-width: 600px) {
    color: #000;
    margin:auto;
  }
`;

export default function Footer() {
  const { t } = useTranslation();
  
  return (
    <FooterWrapper className="container-fluid">
      <HeaderTextWrapper>
        <LocationText>
          {t("Locate us:")}
          <br />
          6th Floor, Surian Tower, 1, Jalan PJU 7/3, Mutiara Damansara,
          <br /> 47810 Petaling Jaya, Selangor
        </LocationText>
      </HeaderTextWrapper>
    </FooterWrapper>
  );
}

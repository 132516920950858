import styled from "styled-components";
import { useTranslation } from "react-i18next";

const QualityOfLifeStyling = styled.div`
  font-family: General Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  .heading {
    font-family: General Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
    color: #ca7109;
  }
  .main-description {
    color: #ca7109;
  }
  .sub-heading {
    font-family: General Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

export default function QualityOfLifeModalContent() {
  const { t, } = useTranslation();

  return (
    <QualityOfLifeStyling>
      <span className="heading">{t('Quality of life')}</span>
      <br />
      <span className="main-description">
        {t("Values in this main area pertain to the sense of importance you place on being able to experience the fullness of your life, to live a satisfying life, and to have your needs fulfilled. These values encompass your experience of life, satisfaction with personal relationships in your life, and the degree to which you feel your basic needs are met on an everyday basis.")}
      </span>
      <br />
      <br />
      <span className="sub-heading">{t('Life experience')}</span>
      <br />
      {t("Values in this area refer to the degree to which you need to feel that you are satisfied with your life. This includes your general feelings of happiness and positive well-being at work on a day-to-day basis.")}
      <br />
      <br />
      <span className="sub-heading">{t("Satisfaction")}</span>
      <br />
      {t("Values in this area refer to the degree to which you feel satisfied with your personal relationships outside of the work environment. This includes the degree of bonding and time spent with friends and/or family members who are close to your heart.")}
      <br />
      <br />
      <span className="sub-heading">{t("Needs")}</span>
      <br />
      {t("Values in this area refer to the degree to which you feel your basic needs are being met. This includes the extent to which you perceive your organization as being supportive of your life and able to meet your basic needs.")}
    </QualityOfLifeStyling>
  );
}

import LoginPage from "./pages/login/loginPage";
import SignUpPage from "./pages/signUp/signUp";
import Forget from "./pages/forgetpassword/forget";
import Reset from "./pages/resetverify/reset";
import Confirm from "./pages/confirmpass/confirmpass";
import TermsPage from "./pages/termsAndConditions/termsAndConditions";
import Dashboard from "./pages/dashboard/dashboard";
import AuthRoute from "./utilities/authRoute";
import Studing1 from "./pages/studing/studingPage";
import Aboutme from "./pages/aboutme/aboutme";
import Employeed from "./pages/employeed/employeed";
import Overview from "./pages/overview/overview";
import Uploadprofilepic from "./pages/uploadprofilepic/uploadprofilepic";
import AboutmeIntro from "./pages/selfIntro/aboutmeIntro";
import Internship from "./pages/internship/internship";
import InterShipDetailsPage from "./pages/internship-details/intershipDetails";
import Settings from "./pages/settings/settings";
import MyCVO from "./pages/myCV/myCV";
import MyCvPreview from "./pages/myCV/my-cv-preview/my-cv-preview";
import AssetsPage from "./pages/assets/assets";
import UpdatePage from "./pages/updateProfile/updatePage";
import InternModal from "./pages/internshipModal/internModal";
import EmployeePage from "./pages/employees/employeePage";
import GraduateModal from "./pages/graduateTalentSearch/graduatePageModal";
import JobPage from "./pages/jobApply/jobpage";
import JobLandingPage from "./pages/jobPages/JobLanding";
import ViewJobs from "./pages/jobPages/ViewJobs";
import CompanyBrand from "./pages/companyBranding/companyBranding";
import MyCompany from "./pages/myCompany/myCompany";
import MyCalendar from "./pages/myCalendar/myCalendar";
import ViewProfile from "./pages/jobPages/viewProfile";
import BlogView from "./pages/dashboard/blogView";
import RestrictedRoute from "./utilities/restrictedRoute";
import InternEvaluation from "./pages/mySIPDirectHiring/internEvaluation/internEvaluation";
// import Lms from "./pages/lms/lms";
import ArmyPersonnel from "./pages/armyPersonnel/armyPersonnel";
import AccountDeletionPage from "./pages/deletionGuide";


export const routes = [
  {
    path: "/",
    element: (
      <RestrictedRoute>
        <LoginPage />
      </RestrictedRoute>
    ),
  },
  {
    path: "/register",
    element: (
      <RestrictedRoute>
        {" "}
        <SignUpPage />
      </RestrictedRoute>
    ),
  },
  {
    path: "/forget",
    element: (
      <RestrictedRoute>
        <Forget />
      </RestrictedRoute>
    ),
  },
  {
    path: "/reset",
    element: (
      <RestrictedRoute>
        <Reset />
      </RestrictedRoute>
    ),
  },
  {
    path: "/confirmpassword",
    element: (
      <RestrictedRoute>
        <Confirm />
      </RestrictedRoute>
    ),
  },

  {
    path: "/updateemployee",
    element: (
      <RestrictedRoute>
        <UpdatePage />
      </RestrictedRoute>
    ),
  },
  {
    path: "/intern-modal",
    element: (
      <RestrictedRoute>
        <InternModal />
      </RestrictedRoute>
    ),
  },
  {
    path: "/intern-employeed",
    element: (
      <RestrictedRoute>
        <EmployeePage />
      </RestrictedRoute>
    ),
  },
  {
    path: "/intern-graduate",
    element: (
      <RestrictedRoute>
        <GraduateModal />
      </RestrictedRoute>
    ),
  },
  {
    path: "/job-modal",
    element: (
      <RestrictedRoute>
        <JobPage />
      </RestrictedRoute>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <AuthRoute>
        <Dashboard />
      </AuthRoute>
    ),
  },
  {
    path: "/terms",
    element: <TermsPage />,
  },
  {
    path: "/studying",
    element: (
      <AuthRoute>
        <Studing1 />
      </AuthRoute>
    ),
  },
  {
    path: "/aboutme",
    element: (
      <AuthRoute>
        <Aboutme />
      </AuthRoute>
    ),
  },
  {
    path: "/employed",
    element: (
      <AuthRoute>
        <Employeed />
      </AuthRoute>
    ),
  },
  {
    path: "/overview",
    element: (
      <AuthRoute>
        <Overview />
      </AuthRoute>
    ),
  },

  {
    path: "/uploadpic",
    element: (
      <AuthRoute>
        <Uploadprofilepic />
      </AuthRoute>
    ),
  },
  {
    path: "/self-intro",
    element: (
      <AuthRoute>
        <AboutmeIntro />
      </AuthRoute>
    ),
  },
  {
    path: "/army-personnel",
    element:(
      <AuthRoute>
        <ArmyPersonnel/>
      </AuthRoute>
    )
  },
  {
    path: "/internships",
    element: (
      <AuthRoute>
        {/* <AboutmeIntro /> */}
        <Internship />
      </AuthRoute>
    ),
  },
  {
    path: "/internships/details/:id",
    element: (
      <AuthRoute>
        <InterShipDetailsPage />
      </AuthRoute>
    ),
  },
  {
    path: "/settings",
    element: (
      <AuthRoute>
        <Settings />
      </AuthRoute>
    ),
  },
  {
    path: "/cv",
    element: (
      // <AuthRoute>
      <MyCVO />
      // </AuthRoute>
    ),
  },
  {
    path: "/previewcv",
    element: (
      <AuthRoute>
        <MyCvPreview />
      </AuthRoute>
    ),
  },
  {
    path: "/assets",
    element: (
      <AuthRoute>
        <AssetsPage />
      </AuthRoute>
    ),
    path: "/jobs",
    element: (
      <AuthRoute>
        <JobLandingPage />
      </AuthRoute>
    ),
  },
  {
    path: "/jobs/view-jobs/:id",
    element: (
      <AuthRoute>
        <ViewJobs />
      </AuthRoute>
    ),
  },
  {
    path: "/my-company",
    element: (
      <AuthRoute>
        <MyCompany />
      </AuthRoute>
    ),
  },
  {
    path: "/jobs/view-jobs/view-profile",
    element: (
      <AuthRoute>
        <ViewProfile />
      </AuthRoute>
    ),
  },
  {
    path: "/my-calendar",
    element: (
      <AuthRoute>
        <MyCalendar />
      </AuthRoute>
    ),
  },

  {
    path: "/company-branding/:id",
    element: (
      <AuthRoute>
        <CompanyBrand />
      </AuthRoute>
    ),
  },

  {
    path: "/view-blog/:slug",
    element: (
      <AuthRoute>
        <BlogView />
      </AuthRoute>
    ),
  },

  {
    path: "/intern-evaluation/:internshipId",
    element: (
      <AuthRoute>
        <InternEvaluation />
      </AuthRoute>
    ),
  },
  {
    path: "/account-deletion-guide",
    element: <AccountDeletionPage />,
  },

  // {
  //   path: "/lms",
  //   element: (
  //     <AuthRoute>
  //       <Lms />
  //     </AuthRoute>
  //   ),
  // },
];

import styled from 'styled-components'

import { Colors } from '../../utilities/colors';

// styling starts
const ButtonWrapper=styled.div`
position:relative;
background: ${Colors.light};
box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
border-radius: 128px;
font-weight: 500;
font-size: 16px;
line-height: 22px;
color:${Colors.inputText};
margin-top:15px;
display:flex;
align-items:center;
justify-content:center;
padding:12px 20px;
cursor:pointer;
`;
const Icon=styled.img`
position:absolute;
// left:25px;
left :15%;
`;
// styling ends

export default function SocialButton({name,icon,onClick}) {
    return (
        <ButtonWrapper onClick={onClick}>
            {name}
            <Icon src={icon}/>
        </ButtonWrapper>
    )
}

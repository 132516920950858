import { useState, useEffect } from "react";
import styled from "styled-components";

import { Colors } from "../../../utilities/colors";
import DialogModel from "../../internship-details/dialogBox";
import CustomButton from "../../../components/button/button";
import InternLogoDefault from "../../../assets/icons/svg/favicon.svg";
import { useNavigate } from "react-router";
import MyrIcon from "../../../assets/icons/MYR.svg";
import SIPIcon from "../../../assets/icons/SIP.svg";
import { internshipApply } from "../../../services/apiCalls";
import { Row, Col } from "react-bootstrap";
import Loader from "../../../components/loader/loader";
import { ReactComponent as FavouriteIcon } from "../../../assets/icons/svg/startPink.svg";
import { ReactComponent as UnfavouriteIcon } from "../../../assets/icons/svg/starGray.svg";
import {
  dateToDateMonthInWordsYear,
  dateDifference,
} from "../../../utilities/commonFunctions";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { deviceStore } from "../../../utilities/devices";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const StyledCardText = styled.div`
  color: #d04c8d;
  font-family: "General Sans";
  font-weight: 500;
  margin-top: 5px;
  font-size: 20px;
  @media (max-width: 700px) {
    font-size: 18px;
  }
`;

const JobsCard = styled.div`
  background-color: white;
  margin-bottom: 30px;
  border-radius: 10px;
  padding: 1.25rem 1.25rem 0rem;
  display: flex;
  cursor: pointer;
  @media (max-width: 700px) {
    display: block;
    padding: 6px;
  }
`;
const JobTitle = styled.div`
  display: flex;
  font-size: 20px;
  font-family: "General Sans";
  font-weight: 500;
  @media (max-width: 700px) {
    display: block;
  }
`;
const Title = styled.div`
  cursor: pointer;
`;
const ImgContainer = styled.div`
  width: 100px;
  height: 100px;
  cursor: pointer;
  @media (max-width: 599px) {
    width: initial;
    margin: 10px auto;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  @media (max-width: 599px) {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: contain;
  }
`;

const TextContainer = styled.div``;

const ColoredText = styled.div`
  color: ${Colors.primaryText};
  font-family: "General Sans";
  font-weight: 400;
  font-style: italic;
`;

const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GridCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 20fr;
  @media (max-width: 599px) {
    display: block;
  }
`;

const BottomWrapper = styled.div`
  padding: 0px 1rem 0.5rem;
`;

const CardWrapper = styled.div`
  border-bottom: 0.6px solid #bcb8b8;
  margin: 0.5rem 1rem 0.25rem;
  // cursor: pointer;
`;
const EmployeeDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 1rem 0 0rem;
  @media (max-width: 1200px) {
    padding: 0.5rem 0 0rem;
  }
`;
const SaveText = styled.div`
  color: rgba(80, 79, 140, 1);
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  font-family: General Sans;
  text-decoration: underline;
`;
const StyledCountry = styled.div`
  color: rgba(82, 82, 82, 1);
  font-weight: 500;
  font-size: 18px;
  margin-top: 5px;
  @media (max-width: 1200px) {
    font-size: 16px;
  }
`;
const Employment_img = styled.img`
  width: 30px;
  height: 25px;
`;
const EmployeementType = styled.p`
  align-self: center;
  font-size: 18px;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 15px;
  margin-bottom: 1rem;
  color: rgba(82, 82, 82, 1);
  @media (max-width: 1200px) {
    margin-bottom: unset;
  }
`;
const ApplyButton = styled.div`
  direction: rtl;
`;

const IconWrapper = styled.div`
  margin-left: 1rem;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &.pointer {
    cursor: pointer;
  }
`;
const DateText = styled.p`
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  margin: 0;
  @media (max-width: 500px) {
    font-size: 14px;
    font-style: normal;
    text-align: right;
  }
`;
const JobExpiredDate = styled(DateText)`
  color: #504f8c;
`;

export default function JobCard({
  datas,
  getDatas,
  getInternshipStatus,
  handleActionAddNotes,
}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statusFavourite, SetStatusFavourite] = useState();
  const completion = useSelector((state) => {
    return state.profile.profilePercentage;
  });
  const minCondition = 100; //minimum profile completion required to enable job/internship application
  const InternshipsMessage =
  "Please complete About Me, Personality & Motivation, Work Interests, and English Proficiency Assessments to use this feature";
  // if ((completion != null) & (completion < 100)) {
  //   navigate("/dashboard");
  // }

  const {
    title,
    min_compensation,
    max_compensation,
    intern_type,
    id,
    locations,
    talent_intern_status,
    organization_details,
    status,
    position_end_date,
    talent_favourite_status,
    position_start_date,
    internship_notes,
    payment_status,
  } = datas;
  useSnapshot(deviceStore);

  const [isFav, setFav] = useState(talent_favourite_status === "favourite");
  const navigate = useNavigate();
  const onNavigate = (id) => {
    navigate({ pathname: `/internships/details/${id}` });
  };

  const positionStartDuration = (positionStartDate) => {
    let dateDifferences = dateDifference(positionStartDate);
    if (dateDifferences === 1) {
      return dateDifferences + " " + t("day ago");
    } else if (dateDifferences > 1) {
      return dateDifferences + " " + t("days ago");
    } else if (dateDifferences === 0) {
      return "Today";
    } else {
    }
  };

  const getInternTypeName = (intern_type) =>{

    switch(intern_type){
      
      case 'Advertise':
      case 'advertise':
      case 'sip':
        return 'MySIP Advertised';
  
      case 'Direct Hiring':
        return 'MySIP Direct Hiring';
      
      case 'General Internship':
      case 'General':
      case 'general internship':
      case 'general':
        return 'General Internship'
    }
    
  }

  useEffect(() => {
    positionStartDuration();
  }, []);

  const saveForLater = async () => {
    // ARCHMAGE X-0159
    setFav(!isFav);
    const { data } = await internshipApply(
      {
        favouriteStatus: isFav ? "unfavourite" : "favourite",
      },
      id
    );

    if (data && data.status === "1") {
      getInternshipStatus();
    }
  };

  const { t } = useTranslation();

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <JobsCard onClick={() => onNavigate(id)}>
          <GridCard>
            <ImgContainer onClick={() => onNavigate(id)}>
              <StyledImage
                src={
                  organization_details.logo_path
                    ? organization_details.logo_path
                    : InternLogoDefault
                }
                alt="company-logo"
              />
            </ImgContainer>
            <TextContainer>
              <CardWrapper>
                <FlexDiv className="star_icon">
                  <JobTitle>
                    <Title onClick={() => onNavigate(id)}> {title}</Title>

                    <IconWrapper
                      onClick={saveForLater}
                      className={"pointer"} //ARCHMAGE
                    >
                      {isFav ? <FavouriteIcon /> : <UnfavouriteIcon />}
                    </IconWrapper>
                    <Title
                      style={{ marginLeft: "13px" }}
                      className="notes"
                      onClick={(e) => {
                        handleActionAddNotes(e, internship_notes);
                      }}
                    >
                      {" "}
                      {t("Notes")}
                    </Title>
                  </JobTitle>
                </FlexDiv>
                <div>
                  <StyledCardText>
                    <FlexDiv>
                      <div> {organization_details.name}</div>
                      <div>
                        {["applied", "offered", "rejected"].includes(
                          talent_intern_status
                        ) ? (
                          <div style={{ textTransform: "uppercase" }}>
                            {t(talent_intern_status)}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </FlexDiv>
                  </StyledCardText>

                  {deviceStore.checkbrowser ? (
                    <StyledCountry>
                      <FlexDiv>
                        <div>
                          {locations.length > 0 ? locations[0].city_abbr : ""},
                          {locations.length > 0 ? locations[0].state_abbr : ""}
                        </div>

                        <JobExpiredDate>
                          {t("Last date to apply :")}{" "}
                          {dateToDateMonthInWordsYear(position_end_date)}
                        </JobExpiredDate>
                      </FlexDiv>
                    </StyledCountry>
                  ) : (
                    <StyledCountry>
                      <div className="row">
                        <div className="col-12 p-0">
                          <div>
                            {locations.length > 0 ? locations[0].city_abbr : ""}
                            ,
                            {locations.length > 0
                              ? locations[0].state_abbr
                              : ""}
                          </div>
                        </div>
                        <div className="col-12 p-0">
                          <div>
                            <JobExpiredDate style={{ textAlign: "left" }}>
                              {t("Last date to apply :")}{" "}
                              {dateToDateMonthInWordsYear(position_end_date)}
                            </JobExpiredDate>
                          </div>
                        </div>
                      </div>
                    </StyledCountry>
                  )}
                </div>
              </CardWrapper>
              <BottomWrapper>
                <Row>
                  <Col xl="4">
                    <EmployeeDiv>
                      <Employment_img src={SIPIcon} />
                      <EmployeementType>
                        {/* {intern_type === "sip" || intern_type === "SIP"
                          ? t(
                              "National Structured Internship Programme (MySIP)"
                            )
                          : t(intern_type)} */}
                        {getInternTypeName(intern_type)}
                      </EmployeementType>
                    </EmployeeDiv>
                  </Col>
                  <Col xl="4">
                    <EmployeeDiv>
                      <Employment_img src={MyrIcon} />
                      {payment_status === "unpaid" ? (
                        <EmployeementType>0 MYR</EmployeementType>
                      ) : (
                        <EmployeementType>
                          {min_compensation} MYR - {max_compensation} MYR
                        </EmployeementType>
                      )}
                    </EmployeeDiv>
                  </Col>
                  <Col xl="4">
                    <ApplyButton>
                      {status === "accepted" &&
                      !["applied", "offered", "rejected"].includes(
                        talent_intern_status
                      ) ? (
                        <CustomButton
                          name={t("Apply Now")}
                          light
                          style={{ margin: "5px 0px 2px" }}
                          onClick={() => completion >= minCondition ? setOpen(!open) : toast.error(t(InternshipsMessage), {position: toast.POSITION.TOP_RIGHT,})}
                        />
                      ) : (
                        ""
                      )}
                    </ApplyButton>
                  </Col>
                </Row>
              </BottomWrapper>
            </TextContainer>
          </GridCard>
        </JobsCard>
      )}

      <DialogModel
        open={open}
        onClose={() => setOpen(false)}
        id={id}
        data={datas}
        getDatas={getDatas}
        getInternshipStatus={getInternshipStatus}
        orgNameFromList={organization_details.name}
      />
    </>
  );
}

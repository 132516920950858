import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
// import { updateLoader } from "../../pages/myCompany/reducer";
import { Colors } from "../../utilities/colors";
// import UpdateCompany from "../updateCompany/updateCompany";
import {
  getCountriesMasterList,
  getSatesMasterList,
  getCitieMasterList,
  getCompanyData,
} from "../../services/apiCalls";
import { Link } from "react-router-dom";

const FlexWrapper = styled.div`
  display: flex;
`;
const SectionWrapper = styled.div`
  background: #fdfdfd;
  border: 1px solid rgba(228, 228, 228, 0.26);
  box-sizing: border-box;
  border-radius: 33px;
  padding: 25px;
  margin: 30px 0;
`;
const HeaderSection = styled(FlexWrapper)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  border-bottom: 0.3px solid ${Colors.labelText};
  padding-bottom: 10px;
  > h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${Colors.primaryText};
    margin: 0;
  }
`;
const StatusSection = styled(FlexWrapper)`
  align-items: center;
  > p {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #8f8f8f;
    margin: 0 8px;
  }
  > h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${Colors.pink};
    margin-right: 10px;
  }
`;
const Description = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.labelText};
  margin: 20px 0;
`;
const Header = styled.h3`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  color: #525252;
`;
const Label = styled.label`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => (props.color ? props.color : Colors.labelText)};
  text-transform: ${(props) => (props.color ? "unset" : "capitalize")};
  word-break: break-word;
`;
const CompanyDetailSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 43px;
  margin-bottom: 43px;
  > p {
    font-size: 16px;
    line-height: 22px;
    color: #202020;
  }
  @media (max-width: 767px) {
    display: block;
    margin: 10px 0px;
  }
`;
const SingleSection = styled.div`
  justify-self: ${(props) => (props.position ? props.position : "initial")};
  margin-right: ${(props) => (props.margin ? props.margin : "")};
  width: 200px;
`;
const SingleSectionElement = styled.div`
  width: 75%;
  justify-self: ${(props) => (props.position ? props.position : "initial")};
  margin-right: ${(props) => (props.margin ? props.margin : "")};
  margin-bottom: 20px;
`;

const UserHelpText = styled(Label)`
  color: ${Colors.inputHighlightText};
  cursor: pointer;
  text-align: right;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-decoration-line: underline;
`;

const ParaWrapper = styled.div`
  font-type: General Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.6px;
`;

export default function Overview(props) {
  const company = props?.companyDetails
  const [temp_website, setTempWebsite] = useState("");
  useEffect(() => {
    if (company && company.website) {
      if (company.website.includes("https://")) {
        setTempWebsite(company.website);
      } else {
        setTempWebsite("https://" + company.website);
      }
    }
  }, [company]);
  //   const company = props.companyDetails;
  //   const loading = useSelector((state) => state.company.loading);
  //   const companyOrgs = useSelector((state) => state.company.companyData);
  //   const company_status = companyOrgs[props.companyIdx].org_status;
  //   const [open, setOpen] = useState(false);
  //   const isUserPermittedToEdit = companyOrgs[props.companyIdx].user_details.company_modify === 'Y' ? true : false;

  //   const onChangeContent = () => {
  //     setOpen(true);
  //   };

  //   const onCloseModal = () => {
  //     setOpen(false);
  //   };

  //   const fetchCounty = async () => {
  //     const { data, status } = await getCountriesMasterList();
  //     if (status === 200) {
  //       if (data.status === 1) {
  //         const countryList = data.data;
  //         const country = countryList.map((x) => {
  //           return { ...x, label: x.Name, value: x.Id };
  //         });
  //         // setCountryList(country);
  //         if (company.bsn_country) {
  //           let selCountry = country.filter(
  //             (x) => x.value === company.bsn_country
  //           );
  //           if (selCountry.length > 0) {
  //             selCountry = selCountry[0];
  //           }
  //           // setSelectedOverviewCountry(selCountry);
  //           fetchState(selCountry.Id);
  //         }
  //       }
  //     }
  //   };

  //   const fetchState = async (CountryId) => {
  //     const { data, status } = await getSatesMasterList(CountryId);
  //     if (status === 200) {
  //       if (data.status === 1) {
  //         const StateList = data.data;
  //         const state = StateList.map((x) => {
  //           return { ...x, label: x.Name, value: x.Id };
  //         });
  //         // setStateList(state);
  //         if (company.bsn_state) {
  //           let selState = state.filter((x) => x.value === company.bsn_state);
  //           if (selState.length > 0) {
  //             selState = selState[0];
  //           }
  //           // setSelectedOverviewState(selState);
  //           fetchCity(selState.CountryId, selState.Id);
  //         }
  //       }
  //     }
  //   };

  //   const fetchCity = async (CountryId, StateId) => {
  //     const { data, status } = await getCitieMasterList(CountryId, StateId);
  //     if (status === 200) {
  //       if (data.status === 1) {
  //         const CityList = data.data;
  //         const city = CityList.map((x) => {
  //           return { ...x, label: x.Name, value: x.Id };
  //         });
  //         // setCityList(city);
  //         if (company.bsn_city) {
  //           let selCity = city.filter((x) => x.value === company.bsn_city);
  //           if (selCity.length > 0) {
  //             selCity = selCity[0];
  //           }
  //           // setSelectedOverviewCity(selCity);
  //         }
  //       }
  //     }
  //   };

  //   useEffect(() => {
  //     fetchCounty();
  //   }, []);

  //   const [temp_website, setTempWebsite] = useState('');

  //   useEffect(() => {
  //     if (company && company.website) {
  //       if (company.website.includes("https://")) {
  //         setTempWebsite(company.website)
  //       } else {
  //         setTempWebsite("https://" + company.website)
  //       }
  //     }
  //   }, [company])

  //   const overview = company && company.overview ? company.overview : "";

  return (
    <SectionWrapper>
      <HeaderSection>
        <StatusSection>
          <h3>Overview</h3>
        </StatusSection>
        {/* {(!(['denied'].includes(company_status)) && isUserPermittedToEdit) ? <UserHelpText onClick={onChangeContent}>Edit</UserHelpText> : <></>} */}
      </HeaderSection>
      <Description dangerouslySetInnerHTML={{ __html: company.overview }}>
      </Description>
      {/* <Description>
        {
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniastrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis autere dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
        }
      </Description> */}
      {/*<ParaWrapper>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniastrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis autere dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</ParaWrapper>*/}
      <CompanyDetailSection>
        <SingleSection>
          <Header>{"Website"}</Header>
          <a
            style={{ cursor: "pointer" }}
            href={temp_website}
            target="_blank"
            rel="noreferrer"
          >
            <Label style={{ cursor: "pointer" }} color="#1B9DC6">
              {company && company.website}
            </Label>
          </a>
        </SingleSection>

        {/* <SingleField style={{alignItems: 'space-between'}}>
          <Grid container alignItems="center">
        <Grid item lg={3}>
            <FormLabel>Website</FormLabel>
            </Grid>
            <Grid item lg={9}>
            <Controller
            style={{}}
              name="moto"
              control={control}
              render={({ field }) => <Input {...field} placeholder={ company.moto } />}
            />
            {errors.moto && <ErrorField err={errors.moto.message} />}
            </Grid>
            </Grid>
          </SingleField> */}

        {/* <FlexWrapper> */}
        <SingleSection margin="20px">
          <Header>{"SSM Registration Date"}</Header>
          <Label>{company && company.ssm_rgtr_date}</Label>
        </SingleSection>
        <SingleSection>
          <Header>Company Size</Header>
          <Label>{company && company.org_size ? company.org_size : "0"}{" "}
            {"Employes"}</Label>
        </SingleSection>
        {/* <SingleSection>
          <Header>Nature of Business</Header>
          <Label>{company && company.bsn_nature}</Label>
        </SingleSection> */}
        {/* </FlexWrapper> */}
      </CompanyDetailSection>
      <SingleSectionElement>
        <Header>Business Address</Header>
        <Label>
          {company && company.bsn_address},&nbsp;
          {/* {selectedOverviewCity.Name},
          {selectedOverviewState.Name},
          {selectedOverviewCountry.Name}, */}
          {company.bsn_city_abbr},
          {company.bsn_state_abbr}, &nbsp;
          {company.bsn_country_abbr}, &nbsp;
          {company && company.bsn_country_code}&nbsp;
        </Label>
      </SingleSectionElement>
      <SingleSectionElement>
      <Header>{"Nature of Business"}</Header>
        <Label>{company && company.bsn_nature}</Label>
       </SingleSectionElement>

      {/* {open && <UpdateCompany open={open} onClose={onCloseModal} companyIdx={props.companyIdx} companyDetails = {company}/>} */}
    </SectionWrapper>
  );
}

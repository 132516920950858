import React from "react";
import { Col, Row } from "react-bootstrap";
import { Ad2CellCard,CardHeader, CardHeaderTitle, CardHeaderSubTitle, LeftWrapper, RightWrapper, CardButtonVariantOne } from "../styles/Ad2Styles";
import DataTable from "react-data-table-component";
import NoDataChartContainer from "../NoDataFoundChart/NoDataFoundChart";
import ChartLoader from "../../components/chartLoader/chartLoader";
import { useTranslation } from "react-i18next";
export function StudentAd2Card({ data, title, ...props }) {

    // React.useEffect(() => {
    //     if (data && data.length) {
    //         setCsvData(Utils.convertToCSV(data));
    //     }
    // }, []);
    const { t, i18n } = useTranslation();
    return <>
        <Ad2CellCard>
            <CardHeader>
                <Row>
                    <Col className="d-flex align-items-center justify-content-between pb-2">
                        <LeftWrapper>
                            <CardHeaderTitle>{t(title)}</CardHeaderTitle>
                            {props.subtitle && <CardHeaderSubTitle>&nbsp;{t(props.subtitle)}</CardHeaderSubTitle>}
                            {props.leftContent ? props.leftContent : ''}
                        </LeftWrapper>
                        {props?.onViewMoreClick ? (
                            <RightWrapper>
                                <CardButtonVariantOne
                                    className="btn btn-asdf"
                                    type="button"
                                    onClick={props?.onViewMoreClick}
                                >
                                    {t("View Details")}
                                </CardButtonVariantOne>
                            </RightWrapper>
                        ) : (
                            <></>
                        )}
                    </Col>
                </Row>
            </CardHeader>
            <Row className="slide-wrapper">
                {props.status !== 'loading' ? props.status === 'noDataFound' ? <NoDataChartContainer /> :
                    <>{props.children}</> : <ChartLoader />}
            </Row>
        </Ad2CellCard>
    </>;
}
import React from "react";
import ChartLoader from "../../components/chartLoader/chartLoader";
import HorizontalBarChartMultiLabel from "../../components/charts/horizontalBarChartMultiLabel";
import { StudentAd2Card } from "./ad2Card";
import { useTranslation } from "react-i18next";
export function Ad2StudentPersonalityProfile({ data, onViewMoreClick }) {
  console.log(data,"chartData")
  const chartData = data;
  const status = data && data.status;
  const { t, i18n } = useTranslation();
  function normalizeBetween0To5(val) {
    var newMin = 0;
    var newMax = 5;
    var oldMin = 0;
    var oldMax = 120;
    var newVal = newMin + (((val - oldMin) * (newMax - newMin)) / (oldMax - oldMin))
    return Math.round(newVal * 100) / 100;
  };
  return (
    <>
      {chartData?.Agreeableness?.points ? (
        <StudentAd2Card title={t("Personality")} data={chartData} status={status} onViewMoreClick={onViewMoreClick}>
          <HorizontalBarChartMultiLabel
            maxValue={5}
            optionsOverride={{ maintainAspectRatio: false }}
            data={{
              labels: [
                t("Openness"),
                t("Conscientiousness"),
                t("Extraversion"),
                t("Agreeableness"),
                t("Emotional Stability"),
              ],
              datasets: [
                {
                  // data:[62,79,30,91,47],
                  data: [
                    chartData &&
                    chartData["Openness to Experience"] &&
                    normalizeBetween0To5(chartData["Openness to Experience"]["points"]),
                    chartData &&
                    chartData["Conscientiousness"] &&
                    normalizeBetween0To5(chartData["Conscientiousness"]["points"]),
                    chartData &&
                    chartData["Extraversion"] &&
                    normalizeBetween0To5(chartData["Extraversion"]["points"]),
                    chartData &&
                    chartData["Agreeableness"] &&
                    normalizeBetween0To5(chartData["Agreeableness"]["points"]),
                    chartData &&
                    chartData["Emotional Stability"] &&
                    normalizeBetween0To5(chartData["Emotional Stability"]["points"]),
                  ], //this code is working. Remove dummy data if needed.
                  backgroundColor: [
                    "#1694F0",
                    "#0F87DE",
                    "#0F83D8",
                    "#0973C0",
                    "#0A6FB8",
                  ],
                  additionalText: [
                    { lt: t("Pragmatism"), lb: "", rt: t("Openness"), rb: "" },
                    {
                      lt: t("Low Self Control"),
                      lb: "",
                      rt: t("High Self Control"),
                      rb: "",
                    },
                    {
                      lt: t("Introversion"),
                      lb: "",
                      rt: t("Extraversion"),
                      rb: "",
                    },
                    {
                      lt: t("Independence"),
                      lb: "",
                      rt: t("Agreeableness"),
                      rb: "",
                    },
                    {
                      lt: t("High Anxiety"),
                      lb: "",
                      rt: t("Low Anxiety"),
                      rb: "",
                    },
                  ],
                  borderWidth: 0,
                  borderSkipped: false,
                  borderRadius: 4,
                  barPercentage: 0.01,
                  categoryPercentage: 1,
                },
              ],
            }}
          />
        </StudentAd2Card>
      ) : (
        <ChartLoader></ChartLoader>
      )}
    </>
  );
}

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { routes } from "./routes";
import { getTalentUserData } from "./services/apiCalls";
import { updateTalentData } from "./pages/login/reducer";
import ScrollToTop from "./components/scrollToTop/scrollToTop";
import ReactTooltip from "react-tooltip";

import { Network } from "@capacitor/network";
import { Grid } from "@material-ui/core";
import Button from "./components/button/button";
import AlertModal1 from "./components/alertModalNotes/alertModal1";
import styled from "styled-components";
import { App as capp } from "@capacitor/app";
import { SafeArea } from "capacitor-plugin-safe-area";
import { deviceStore } from "./utilities/devices";
import { subscribe, useSnapshot } from "valtio";
import { app } from "./firebase-config.js";
import LanguageSwitcher from "./components/languageSwitcher/languageSwitcher";
import ChatbotComponent from "../src/components/chatbot/chatbot.js";

function App() {
  useSnapshot(deviceStore);
  deviceStore.isBrowser().then(() => {});
  const checkbrowser = deviceStore.checkbrowser;
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [mobiletop, SetMobiletop] = useState({});
  const [showBot, toggleBot] = useState(false);
  const [showBotPrompt, toggleBotPrompt] = useState(false);

  useEffect(() => {
    if (checkbrowser) {
      getTalentUser();
    }
    ReactTooltip.rebuild();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const setupWithInsets = async function () {
      const insets = await SafeArea.getSafeAreaInsets();
      let el = document.getElementById("root");
      el.style.position = "relative";
      el.style.top = insets.insets.top.toString() + "px";
      el.style.bottom = insets.insets.bottom.toString() + "px";
      SetMobiletop({ top: insets.insets.top.toString() + "px" });
    };

    let unsubscribe = subscribe(deviceStore, () => {
      if (deviceStore.platform != "android") {
        setupWithInsets();
      }
    });
    return unsubscribe;
  }, [deviceStore.platform]);

  const Message = styled.div`
    padding-left: 20px;
    font-size: 22px;
    padding-bottom: 5px;
  `;

  Network.addListener("networkStatusChange", (status) => {
    if (!status.connected && !show) {
      setShow(true);
    } else {
      if (status.connected && show) {
        setShow(false);
      }
    }
  });
  capp.addListener("backButton", (data) => {
    let path = window.location.pathname;
    if (path == "/" || path == "/dashboard") {
      capp.minimizeApp().then(
        (res) => {},
        (err) => {}
      );
    } else {
      window.history.back();
    }
  });

  const getTalentUser = async () => {
    console.log("This Is working", process.env.NODE_ENV);

    // console.log = () => { }
    // console.error = () => { }
    // console.debug = () => { }
    // console.warn = () => { }
    // console.info = () => { }
    // console.no_console = () => { }

    if (process.env.NODE_ENV === "production")
      console.log = function no_console() {};
    const { data } = await getTalentUserData();
    if (data && data.status === 1) {
      dispatch(updateTalentData(data.data));
    }
    // console.log = function no_console() { };
  };

  const content = () => {
    return (
      <>
        <Grid>
          <Grid>
            <Message>Your network bandwidth is low</Message>
          </Grid>
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <Button
              secondary
              color="#FFFFFF"
              name="Close"
              style={{
                width: "25%",
                marginLeft: "10px",
              }}
              variant="outlined"
              onClick={closeModal}
            /> */}
          </Grid>
        </Grid>
      </>
    );
  };

  const closeModal = () => {
    setShow(false);
  };
  return (
    <Router>
      <LanguageSwitcher />
      <ReactTooltip />
      <ScrollToTop />
      <Routes>
        {routes.map((route) => (
          <Route
            key={route.path}
            exact
            path={route.path}
            element={route.element}
          />
        ))}
      </Routes>
      <ChatbotComponent />
      <ToastContainer style={mobiletop} />
      <AlertModal1 show={show} close={closeModal} content={content()} />
    </Router>
  );
}

export default App;

import { useEffect, useState } from "react";

import styled from "styled-components";
import "react-phone-number-input/style.css";
import { Colors } from "../../../utilities/colors";
import { Row, Col } from "react-bootstrap";
import CustomButton from "../../../components/button/button";
import Input from "../../../components/input/input";
import TimeLineData from "./timeline";
import TextArea from "../../../components/description/description";
import {
  addEducationDetails,
  getEducationDetails,
  deleteEducationDetails,
  updateEducationDetails,
} from "../../../services/apiCalls";
import { useRequired } from "../utils/useRequired";
import ErrorField from "../../../components/errorField/errorField";
import ConfirmationDialog from "../confirmation-dialog/confirmation-dialog";
import { useTranslation } from "react-i18next";

const Anchor = styled.a`
  color: #504f8c;
`;

const Flex = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const DatesWrapper = styled(FlexWrapper)`
  label {
    position: relative;
    width: -webkit-fill-available;
  }
`;

const FormGroup = styled.div`
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;

const CardContainer = styled.div`
  margin-top: 4%;
`;

const TextInput = styled.div`
  color: ${Colors.inputTextDark};
`;

const initialState = {
  institution: "",
  degree: "",
  scope_of_study: "",
  grade: "",
  duration: null,
  completion_year: null,
  description: "",
};

const errors = {
  institution: null,
  degree: null,
  completion_year: null,
  description: null,
  scope_of_study: null,
  grade: null,
  duration: null,
};

const Education = ({ notification }) => {
  const [showAddEducation, setShowAddEducation] = useState(false);
  const [formState, setFormState] = useState(initialState);
  const [selectedEducation, setSelectedEducation] = useState([]);
  const { errorFields, validateForm, setErrorFields } = useRequired(
    formState,
    errors
  );
  const [isSave, setIsSave] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [min_year, setMinYear] = useState("");
  const [max_year, setMaxYear] = useState(new Date().getFullYear() + 4);
  useEffect(() => {
    fetchEducationDetails();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
    setErrorFields({ ...errorFields, [name]: null });
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      return;
    }
    let {
      institution,
      degree,
      scope_of_study,
      grade,
      duration,
      completion_year,
      description,
      id,
    } = formState;
    setMaxYear(new Date().getFullYear() + 4);
    setMinYear(new Date().getFullYear() - 50);
    if (
      formState.completion_year > max_year ||
      formState.completion_year < min_year
    ) {
      notification("error", t("Enter valid completion year"));
      return;
    }
    if (isSave) {
      const payload = {
        data: [
          {
            institution,
            degree,
            scope_of_study,
            grade,
            duration,
            completion_year,
            description,
          },
        ],
      };
      const { data, status } = await addEducationDetails(payload);
      checkStatus(data, status, "save");
    } else {
      updateEducation(id);
    }
  };

  const updateEducation = async (id) => {
    let {
      institution,
      degree,
      scope_of_study,
      grade,
      duration,
      completion_year,
      description,
    } = formState;
    const payload = {
      institution,
      degree,
      scope_of_study,
      grade,
      duration,
      completion_year,
      description,
    };
    const { data, status } = await updateEducationDetails(id, payload);
    checkStatus(data, status, "update");
  };

  const editEducation = (idd) => {
    setIsSave(false);
    const {
      institution,
      degree,
      scope_of_study,
      grade,
      duration,
      completion_year,
      id,
      description,
    } = selectedEducation.find((f) => f.id === idd);
    setFormState({
      institution,
      degree,
      scope_of_study,
      grade,
      duration,
      completion_year,
      description,
      id,
    });
    setShowAddEducation(true);
  };

  const deleteEducation = async (id) => {
    const { data, status } = await deleteEducationDetails(id);
    checkStatus(data, status, "delete", true);
  };

  const checkStatus = (data, status, method) => {
    const methodTmp =
      method === "save" ? "saved" : method === "update" ? "updated" : "deleted";
    if (data.status === 1 && status === 200) {
      fetchEducationDetails();
      if (method === "delete") {
        setShowConfirmation(false);
        notification("success", t("Education details deleted successfully"));
      } else if (method == "save") {
        notification("success", t("Education details saved successfully"));
      } else if (method == "update") {
        notification("success", t("Education details updated successfully"));
      }
    } else {
      if (method === "delete") {
        notification("error", t("Unable to delete education details"));
      } else if (method == "save") {
        notification("error", t("Unable to save education details"));
      } else if (method == "update") {
        notification("error", t("Unable to update education details"));
      }
    }
  };

  const fetchEducationDetails = async () => {
    const { data, status } = await getEducationDetails();
    if (
      status === 200 &&
      data.status === 1
    ) {
      setSelectedEducation(data.data);
      resetForm();
    }
  };

  const resetForm = () => {
    setShowAddEducation(false);
    setFormState({ ...initialState });
    setErrorFields({ ...errors });
    setIsSave(true);
  };

  const showConfirm = (id) => {
    setShowConfirmation(true);
    setDeleteId(id);
  };

  
  const { t } = useTranslation();

  return (
    <div>
      {!showAddEducation && (
        <Flex>
          <Anchor onClick={() => setShowAddEducation(true)}>
            {t("Add Education Detail")}
          </Anchor>
        </Flex>
      )}
      {showAddEducation && (
        <div>
          <Row>
            <Col md="4">
              <FormLabel>{t("Institution")}*</FormLabel>
              <Input
                placeholder={t("Enter Institution")}
                name="institution"
                value={formState.institution}
                onChange={handleChange}
              />
              {errorFields["institution"] && (
                <ErrorField err={t(errorFields["institution"])} />
              )}
            </Col>
            <Col md="4">
              <FormLabel>{t("Degree")}*</FormLabel>
              <Input
                placeholder={t("Enter Degree")}
                name="degree"
                value={formState.degree}
                onChange={handleChange}
              />
              {errorFields["degree"] && (
                <ErrorField err={t(errorFields["degree"])} />
              )}
            </Col>
            <Col md="4">
              <FormLabel>{t("Scope of Study")}*</FormLabel>
              <Input
                placeholder={t("Enter Scope of Study")}
                name="scope_of_study"
                value={formState.scope_of_study}
                onChange={handleChange}
              />
              {errorFields["scope_of_study"] && (
                <ErrorField err={t(errorFields["scope_of_study"])} />
              )}
            </Col>
          </Row>
          <CardContainer>
            <Row>
              <Col md="4">
                <FormLabel>{t("Grade")}*</FormLabel>
                <Input
                  placeholder={t("Enter Grade")}
                  name="grade"
                  value={formState.grade}
                  onChange={handleChange}
                />
                {errorFields["grade"] && (
                  <ErrorField err={t(errorFields["grade"])} />
                )}
              </Col>
              <Col md="4">
                <FormLabel>{t("Duration (In Month Between 1-99)")}*</FormLabel>
                <Input
                  type="number"
                  placeholder={t("Enter Duration")}
                  name="duration"
                  value={formState.duration}
                  onChange={handleChange}
                  onKeyPress={(event) => {
                    if (event.target.value > 0) {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }
                    if (event.target.value.length > 1) {
                      event.preventDefault();
                    }
                  }}
                  min={1}
                  max={99}
                  step={1}
                />
                {errorFields["duration"] && (
                  <ErrorField err={t(errorFields["duration"])} />
                )}
              </Col>
              <Col md="4" className="educationCards">
                <FormLabel>
                  {t("Completion Year(1972-") + max_year + ")"}*
                </FormLabel>
                <Input
                  type="number"
                  placeholder={t("Enter Completion Year")}
                  name="completion_year"
                  value={formState.completion_year}
                  onChange={handleChange}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                    if (event.target.value.length > 3) {
                      event.preventDefault();
                    }
                  }}
                  min={min_year}
                  max={max_year}
                  step={1}
                />
                {errorFields["completion_year"] && (
                  <ErrorField err={t(errorFields["completion_year"])} />
                )}
              </Col>
            </Row>
          </CardContainer>
          <CardContainer>
            <Row>
              <Col md="12">
                <FormLabel>{t("Description")}*</FormLabel>
                <TextArea
                  rows="4"
                  placeholder={t("Enter Description")}
                  name="description"
                  value={formState.description}
                  onChange={handleChange}
                  maxLength="250"
                />
                {errorFields["description"] && (
                  <ErrorField err={t(errorFields["description"])} />
                )}
              </Col>
            </Row>
          </CardContainer>
          <Row>
            <ButtonWrapper>
              <CustomButton onClick={handleSubmit} name={t("Save")} />
              &nbsp;&nbsp;
              <CustomButton onClick={resetForm} secondary name={t("Cancel")} />
            </ButtonWrapper>
          </Row>
        </div>
      )}
      {selectedEducation.length > 0 ? (
        <FormGroup>
          <TimeLineData
            data={selectedEducation}
            deleteEducation={(id) => showConfirm(id)}
            editEducation={(id) => editEducation(id)}
          />
        </FormGroup>
      ) : (
        <>
          <TextInput>{t("You have not added your education(s).")}</TextInput>
        </>
      )}
      <ConfirmationDialog
        id={deleteId}
        show={showConfirmation}
        message={t("Are you sure you want to delete this record?")}
        onAccept={(id) => deleteEducation(id)}
        closeModal={() => setShowConfirmation(false)}
      />
    </div>
  );
};
export default Education;

import React from "react";

import "./options.css";
import { t } from "i18next";

const Options = (props) => {
  const options = [
    {
      text: "How do I reset my password?",
      key: "reset password", //should be key in messagesData
      handler: props.actionProvider.handleDisplayMessage,
      id: 1,
    },
    { 
      text: "How do I update my profile information?",
      key: "update profile",
      handler: props.actionProvider.handleDisplayMessage,
      id: 2
    },
    { 
      text: "My study programme / institution is not listed in options",
      key: "not listed",
      handler: props.actionProvider.handleDisplayMessage,
      id: 3
    },
    { 
      text: "Which email should I use to sign up?",
      key: "which email",
      handler: props.actionProvider.handleDisplayMessage,
      id: 4
    },
    { 
      text: "Do I use mynext Talent or mynext University?",
      key: "mynext talent or mynext university",
      handler: props.actionProvider.handleDisplayMessage,
      id: 5
    },
  ];

  const buttonsMarkup = options.map((option) => (
    <button key={option.id} onClick={() => option.handler(option.key)} className="option-button">
      {t(option.text)}
    </button>
  ));

  return <div className="options-container">{buttonsMarkup}</div>;
};

export default Options;
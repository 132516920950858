import { GrClose } from 'react-icons/gr';
import { Modal } from 'react-bootstrap';

function AlertModal1(props) {
  return (
    <div>
      <Modal show={props.show} onHide={props.close} centered>
        <div className="post-modal">
          <div className=" d-flex justify-content-end align-items-center p-4 modal_header" style={{cursor:"pointer",position:"relative"}}>
            <GrClose onClick={props.close} />
          </div>
          <div style={{top:"25%",position:"absolute"}}>{props.content}</div>
        </div>
      </Modal>
    </div>
  );
}

export default AlertModal1;
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userData: {},
  selectedGlobalOrgCode: null
};

export const talentDataReducer = createSlice({
  name: "talent",
  initialState,
  reducers: {
    updateTalentData: (state, action) => {
      state.userData = action.payload;
    },
    updateSelectedGlobalOrgCode: (state, action) => {
      state.selectedGlobalOrgCode = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateTalentData, updateSelectedGlobalOrgCode } = talentDataReducer.actions;

export default talentDataReducer.reducer;

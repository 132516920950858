import { useState } from "react";

export const useRequired = (formState, errorDetails) => {
    const [errorFields, setErrorFields] = useState(errorDetails);

    const validateForm = () => {
        let hasError = false;
        let errors = { ...errorDetails };
        Object.keys(formState).forEach(e => {
            if (!formState[e] && errorDetails.hasOwnProperty(e)) {
                errors[e] = `${e} is required`;
                hasError = true;
            }
        });
        setErrorFields(errors);
        return hasError;
    }

    return { errorFields, validateForm, setErrorFields };
}
import React from "react";

import "./resolution.css";
import { t } from "i18next";
import { ReactComponent as Like } from "../../../../assets/svg/like.svg";
import { ReactComponent as Dislike } from "../../../../assets/svg/dislike.svg";

const Resolution = (props) => {
  const options = [
    {
      text: "Yes",
      handler: props.actionProvider.handleRating,
      image: <Like className="thumb-icon"/>,
      id: 0,
    },
    { 
      text: "No",
      handler: props.actionProvider.handleRating,
      image: <Dislike className="thumb-icon"/>,
      id: 1
    },
  ];

  const buttonsMarkup = options.map((option) => (
    <button 
      key={option.id} 
      onClick={() => {
        option.handler();
        // alert(`You chose ${option.text}`);
      }}
      className="resolution-button "
    >
      {option.image}
    </button>
  ));

  return <div className="resolution-container padding-sides small-font">{t("Was this helpful?")} - {buttonsMarkup}</div>;
};

export default Resolution;
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import JobConfirmationModal from "./jobConfirmationModal";

const JobPage = () => {
  const { t } = useTranslation();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const showConfirm = () => {
    setShowConfirmation(true);
  };
  return (
    <div>
      <button onClick={() => showConfirm()}>job modal</button>
      <JobConfirmationModal
        show={showConfirmation}
        title={t("Review Your Application")}
        heading={t("You’re about to apply as an Account Manager at QL Company.")}
        para={t(
          "Your CV will be shared to the company for the hiring process. Please make sure you have updated it before submitting your application."
        )}
        head={t("Upload the below documents together with your application")}
        closeModal={() => setShowConfirmation(false)}
      />
    </div>
  );
};

export default JobPage;

import { useState, useRef, useEffect } from "react";
import styled from "styled-components";

import { Colors } from "../../utilities/colors";
import CustomButton from "../../components/button/button";
import "react-responsive-modal/styles.css";
import CustomDialog from "../../components/modal/modal";
import { Row, Col } from "react-bootstrap";
import { GrClose } from "react-icons/gr";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  uploadInternshipFiles,
  internshipApply,
} from "../../services/apiCalls";
import { useTranslation } from "react-i18next";

const ChooseFile = styled.div`
  color: ${Colors.primaryText};
  text-decoration: underline;
  cursor: pointer;
  position: relative;
  padding: 0px 4rem;
  text-align: center;
  .fileInput {
    position: absolute;
    z-index: 1;
    opacity: 0;
    width: 35%;

    left: 78px;
  }
  span {
    margin-left: 1rem;
  }
  .GrClose {
    position: absolute;
    right: 1rem;
    top: 0.5rem;
  }
  .GrClose path {
    stroke: rgba(172, 172, 172, 1);
  }
`;
const ModalHeading = styled.p`
  font-size: 23px;
  font-family: "General Sans";
  font-weight: 500;
  text-align: center;
  margin-bottom: unset;
`;
const ModalSubHeading = styled.p`
  font-size: 20px;
  color: #d04c8d;
  margin-top: 1rem;
  font-family: "General Sans";
  font-weight: 500;
  text-align: center;
`;
const ModalInfo = styled.p`
  margin-top: 10px;
  font-size: 18px;
  font-family: "General Sans";
  font-weight: 500;
  text-align: center;
`;
const UploadInfo = styled.p`
  margin-top: 10px;
  font-size: 18px;
  font-family: "General Sans";
  font-weight: 500;
  text-align: center;
`;
const ContentDiv = styled.p`
  margin: auto;
  margin-top: 30px;
`;

const Label = styled.div`
  padding: 0 4rem;
  margin-bottom: 1rem;
  font-size: 18px;
  font-family: "General Sans";
  font-weight: 600;
  @media (max-width: 599px) {
    padding: unset;
  }
`;
const DialogContent = styled.div`
  padding: 0 2.5rem;
  @media (max-width: 599px) {
    padding: unset;
  }
`;

const FileInput = styled.div`
  position: relative;
  .fileInput {
    // position: absolute;
    // z-index: 1;
    // opacity: 0;
    // width: 85%;
    // top: 0.5rem;
  }

  .GrClose {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
  .GrClose path {
    stroke: rgba(172, 172, 172, 1);
  }
`;
const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 500px) {
    display: unset;
    align-items: unset;
    justify-content: unset;
  }
`;

function DialogBox({
  open,
  onClose,
  id,
  data,
  orgNameFromList,
  getDatas,
  getInternshipStatus,
}) {
  const org = data?.org_details;
  const [resumePath, setResumePath] = useState("");
  const [coverPath, setCoverPath] = useState("");
  const [samplePath, setSamplePath] = useState("");
  const [transcript, setTranscript] = useState("");
  const [isApplied, setApplied] = useState(false);
  const [optional_doc, setOptionalDoc] = useState("");
  const [optional_doc_name, setOptionalDocName] = useState("");
  const [fileArray, setFileArray] = useState([]);
  const [validateItem, setValidateItem] = useState([]);
  const [resume_name, setResumeName] = useState("");

  useEffect(() => {
    if (data.documents) {
      setValidateItem(data.documents);

      const validateDocs = validateItem
        .filter((item) => item.doc_type !== "All")
        .map((item, i) => {
          return {
            ...item,
            filePath: "",
            file: "",
          };
        });
      setFileArray(validateDocs);
      if (data.optional_documents !== null) {
        var temp_obj = {
          doc_type: data.optional_documents,
          filePath: "",
          file: "",
          id: 100,
        };
        fileArray.push(temp_obj);
      }
    }
  }, [validateItem, data]);

  const navigate = useNavigate();

  const onChangeInputFile = (e, index, doc_type) => {
    if (!e.target.files[0].name.endsWith(".pdf")) {
      toast.error(t("Please upload pdf file only"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    } else {
      switch (doc_type) {
        case "Resume":
          setResumePath(e.target.files[0]);
          setResumeName(e.target.files[0].name);
          break;
        case "Cover Letter":
          setCoverPath(e.target.files[0]);
          break;
        case "Writing Sample":
          setSamplePath(e.target.files[0]);
          break;
        case "Transcript":
          setTranscript(e.target.files[0]);
          break;
        case "optional_doc":
          setOptionalDoc(e.target.files[0]);
          setOptionalDocName(e.target.files[0].name);
          break;
        default:
      }
    }

    const uploadDocs = fileArray.map((item, i) => {
      if (index === i) {
        return {
          ...item,
          filePath: e.target.files[0].name,
          file: e.target.files[0],
        };
      }
      return item;
    });
    setFileArray(uploadDocs);
  };

  const inputRef = useRef([]);

  const removeFileName = (index) => {
    inputRef.current[index].value = "";
    const uploadDocs = fileArray.map((item, i) => {
      if (index === i) {
        return {
          ...item,
          filePath: "",
          file: "",
        };
      }
      return item;
    });
    setFileArray(uploadDocs);
  };

  const removeOptionalDoc = () => {
    setOptionalDoc("");
    setOptionalDocName("");
    setResumeName("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    var inValid = "";
    if (fileArray.length === 0 && resume_name === "") {
      inValid = true;
    } else {
      inValid = fileArray.some((item) => item.filePath === "");
    }

    if (inValid) {
      toast.error(t("File not uploaded!"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const formdata = new FormData();
      if (resumePath) {
        formdata.append("resume_path", resumePath);
      }
      if (coverPath) {
        formdata.append("cover_path", coverPath);
      }
      if (samplePath) {
        formdata.append("writing_sample_path", samplePath);
      }
      if (transcript) {
        formdata.append("transcript", transcript);
      }
      if (optional_doc) {
        formdata.append("other_docs", optional_doc);
      }
      console.log(formdata);
      uploadForm(formdata);
    }
  };

  const uploadForm = async (formdata) => {
    setApplied(true);
    const { data, status } = await uploadInternshipFiles(formdata, id);
    if (data && data.status === 1) {
      setApplied(false);
      toast.success("File uploaded successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });

      const { data } = await internshipApply({ status: "applied" }, id);
      if (data && data.status === "1") {
        if (getDatas) {
          getDatas();
        }
        if (getInternshipStatus) {
          getInternshipStatus();
        }
        onClose();
        //navigate({ pathname: "/internships" });
      } else {
        toast.error(data.message + "!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      setApplied(false);
      toast.error(data.message + "!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const closeModal = () => {
    const uploadDocs = fileArray.map((item, index) => {
      inputRef.current[index].value = "";
      return {
        ...item,
        filePath: "",
        file: "",
      };
    });
    setFileArray(uploadDocs);
    onClose();
  };

  const { t } = useTranslation();

  return (
    <>
      <CustomDialog open={open} noCloseIcon>
        <DialogContent>
          <ModalHeading>{t("Review your Application")}</ModalHeading>
          <ModalSubHeading>
            {t("You're about to apply as an ")}
            {data.title} {t("at")}{" "}
            {org ? org.name : orgNameFromList ? orgNameFromList : ""}.
          </ModalSubHeading>
          <ModalInfo>
            <div>
              {t(
                "Your CV will be shared to the company for the hiring process."
              )}
            </div>
            <div>
              {t(
                "Please make sure you have updated it before submitting your application"
              )}
            </div>
          </ModalInfo>
          <div>
            <UploadInfo>
              {t("Upload the below documents together with your application")}
            </UploadInfo>
            <form>
              {fileArray.length > 0 ? (
                fileArray.map((item, index) => (
                  <ContentDiv>
                    <Row>
                      <Col lg="6" sm="6">
                        <Label key={item.id}>{t(item.doc_type)}</Label>
                      </Col>
                      <Col lg="6" sm="6">
                        <ChooseFile>
                          <input
                            accept=".pdf"
                            type="file"
                            ref={(el) => (inputRef.current[index] = el)}
                            className="fileInput"
                            onChange={(e) =>
                              onChangeInputFile(e, index, item.doc_type)
                            }
                          />
                          <div
                            style={{ width: "100%", overflow: "hidden" }}
                            key={item.id}
                          >
                            {item.filePath ? item.filePath : t("Choose file")}
                          </div>
                          {item.filePath && (
                            <GrClose
                              className="GrClose"
                              onClick={(e) => removeFileName(index)}
                            />
                          )}
                        </ChooseFile>
                      </Col>
                    </Row>
                  </ContentDiv>
                ))
              ) : (
                <ContentDiv>
                  <Row>
                    <Col lg="6" sm="6">
                      <Label>Resume</Label>
                    </Col>
                    <Col lg="6" sm="6">
                      <ChooseFile>
                        <input
                          accept=".pdf"
                          type="file"
                          ref={(el) =>
                            (inputRef.current[fileArray.length + 1] = el)
                          }
                          className="fileInput"
                          onChange={(e) =>
                            onChangeInputFile(e, fileArray.length + 1, "Resume")
                          }
                        />
                        <div style={{ width: "100%", overflow: "hidden" }}>
                          {resume_name ? resume_name : t("Choose file")}
                        </div>
                        {/* {resume_name && (
                          <GrClose
                            className="GrClose"
                            onClick={removeOptionalDoc}
                          />
                        )} */}
                      </ChooseFile>
                    </Col>
                  </Row>
                </ContentDiv>
              )}
              {data.optional_documents !== null &&
              data.optional_documents !== "" ? (
                <ContentDiv>
                  <Row>
                    <Col lg="6" sm="6">
                      <Label>{data.optional_documents}</Label>
                    </Col>
                    <Col lg="6" sm="6">
                      <ChooseFile>
                        <input
                          accept=".pdf"
                          type="file"
                          ref={(el) =>
                            (inputRef.current[fileArray.length + 1] = el)
                          }
                          className="fileInput"
                          onChange={(e) =>
                            onChangeInputFile(
                              e,
                              fileArray.length + 1,
                              "optional_doc"
                            )
                          }
                        />
                        <div
                          style={{ width: "100%", overflow: "hidden" }}
                          // key={item.id}
                        >
                          {optional_doc_name
                            ? optional_doc_name
                            : t("Choose file")}
                        </div>
                        {optional_doc_name && (
                          <GrClose
                            className="GrClose"
                            onClick={removeOptionalDoc}
                          />
                        )}
                      </ChooseFile>
                    </Col>
                  </Row>
                </ContentDiv>
              ) : (
                ""
              )}
              <Row>
                <Col lg="6" md="6" sm="6" xs="12" className="text-center">
                  <FlexWrapper>
                    <CustomButton
                      name={t("Submit")}
                      type="submit"
                      width="170px"
                      disabled={isApplied}
                      onClick={(e) => handleSubmit(e)}
                      light
                    />
                  </FlexWrapper>
                </Col>
                <Col lg="6" md="6" sm="6" xs="12" className="text-center">
                  <FlexWrapper>
                    <CustomButton
                      type="button" //INIVOS
                      name={t("Cancel")}
                      onClick={closeModal}
                      width="170px"
                      secondary
                    />
                  </FlexWrapper>
                </Col>
              </Row>
            </form>
          </div>
        </DialogContent>
      </CustomDialog>
    </>
  );
}

export default DialogBox;

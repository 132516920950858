import { Dropdown } from "react-bootstrap";
import styled from "styled-components";

export const Ad2CellCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(13, 10, 44, 0.08);
  border-radius: 20px;
  padding: 20px;
  // min-height: 383px;
  .slide-wrapper {
    position: relative;
  }
  overflow: hidden;
  .hid-box {
    top: 200%;
    position: absolute;
    transition: all 0.3s ease-out;
    height: 100%;
  }
  .hid-box.visible {
    top: 0;
    position: absolute;
  }
  .hid-box > div,
  .hid-box > div > div {
    height: 100%;
    overflow: auto;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    position: relative;
    width: 337px;
    left: -45%;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    position: relative;
    left: -17%;
    width: 643px;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    position: relative;
    width: 693px;
    left: -78px;
    overflow: none;
  }
`;

export const CoachingAd2CellCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(13, 10, 44, 0.08);
  border-radius: 20px;
  padding: 20px;
  .slide-wrapper {
    position: relative;
  }
  overflow: auto;
  .hid-box {
    top: 200%;
    position: absolute;
    transition: all 0.3s ease-out;
    height: 100%;
  }
  .hid-box.visible {
    top: 0;
    position: absolute;
  }
  .hid-box > div,
  .hid-box > div > div {
    height: 100%;
    overflow: auto;
  }
`;

export const CardHeaderTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #1e1b39;
`;
export const CardHeaderSubTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #1e1b39;
`;

export const CardHeaderTitleVariantOne = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #1e1b39;
  margin-right: 5px;
`;

export const CardButtonVariantOne = styled.button`
  background: #d84e8d;
  border-radius: 8px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #e5e5ef;
  &:hover {
    color: #e5e5ef;
  }
`;

export const CardHeader = styled.div`
  border-bottom: 1px solid #e5e5ef;
  margin-bottom: 15px;
`;

export const CoachingCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5ef;
  margin-bottom: 15px;
`;

export const CardHeaderVariantOne = styled.div`
  border-bottom: 1px solid #e5e5ef;
  margin-bottom: 15px;
`;

export const HtmlLegendChartWrapper = styled.div`
  canvas {
    padding: 0px 10px 0px 10px;
    max-height: 270px;
  }
`;

export const CardHeaderRightWrapper = styled.div`
  border-left: 1px solid #9c9c9c;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: auto;
  margin: 10px 0px;
  * {
    cursor: pointer;
  }
`;

export const ChartTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #1e1b39;
`;

export const CompletionRateTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 16px;
  color: #000000;
`;

export const Ad2DataTableWrapper = styled.div`
  background: #ffffff;
  position: relative;
  border-radius: 34px 34px 0px 0px;
  overflow: hidden;
  .rdt_TableHead {
    border-radius: 34px;
    height: 52px;
    box-shadow: 0px 0px 10px #d9d9d9;
    overflow: hidden;
    z-index: 0;
    position: relative;
    .rdt_TableCol_Sortable,
    rdt_TableCol {
      justify-content: center;
      font-family: "General Sans";
      white-space: pre-line !important;
      font-style: normal;
      font-weight: 500;
      font-size: 16.8235px;
      line-height: 21px;
      color: #d04c8d;
      div {
        white-space: pre-line !important;
      }
    }
  }
  .rdt_TableBody {
    height: 100% !important;
    .rdt_TableRow {
      min-height: 48px;
      &:nth-child(odd) {
        background-color: #ffffff;
      }
      &:nth-child(even) {
        background-color: #f5f5f5;
      }
      &:hover {
        background-color: #ffe9f4;
      }
      font-family: "General Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 18.8235px;
      line-height: 21px;
      color: #000000;
      text-align: center;
      .rdt_TableCell {
        justify-content: center;
      }
    }
  }
`;

export const Ad2Dropdown = styled(Dropdown)`
  .btn-asdf {
    box-shadow: none !important;
  }
  a {
    color: black;
  }
  .dropdown-item {
    color: black !important;
  }
  .dropdown-item:hover {
    background: #ffe9f5 !important;
    color: black !important;
  }
  a:hover {
    text-decoration: none !important;
    color: black !important;
  }
`;

export const Ad2CardVariantOnePercentage = styled.span`
  font-family: "Oswald";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 50px;
  color: ${(props) => props.textBackground};
  padding: 20px 0px;
`;

export const Ad2CardVariantOneBoxDescription = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
  color: #8d8d8d;
`;

export const Ad2CardVariantOneBoxTitle = styled.div`
  height: 40px;
  background: ${(props) => props.titleBackground};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 18px;
  &:after {
    border-left: solid 30px transparent;
    border-top: solid 30px ${(props) => props.foldColor};
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    height: 0;
    width: 0;
  }
  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 16px;
    color: #ffffff;
  }
`;

export const Ad2CardVariantTwoBoxDescription = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: justify;
  color: #5c5c5c;
`;

export const Ad2CardVariantTwoBoxTitle = styled.div`
  text-align: center;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  color: #d84e8d;
`;

export const StudentCoachingProfileBoxTitle = styled.div`
  text-align: center;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
`;

export const SumText = styled.span`
  font-family: "Oswald";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  color: #1e1b39;
`;

export const SumWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

export const RightWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const LeftWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const DateRangePicker = styled.span`
  background: rgba(196, 211, 222, 0.36);
  border-radius: 20px;
  width: 195px;
  height: 36px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #615e83;
    margin-right: 5px;
  }
`;

export const Ad2SelectorWrapper = styled.span`
  background: rgba(196, 211, 222, 0.36);
  margin-right: 10px;
  border-radius: 20px;
  min-width: 90px;

  height: 36px !important;
  & > div > div:nth-child(3) {
    background: transparent !important;
    padding: 2px 9px !important;
    min-height: 36px;
    & > div:nth-child(1) {
      padding: 0px !important;
    }
    & > div:nth-child(1) > div {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #615e83;
    }
    & > div:nth-child(2) > div:nth-child(2) {
      padding: 0px !important;
      color: #615e83;
    }
  }
`;

export const Ad2ButtonGroupWrapper = styled.span`
  & > div {
    background: #f8f8ff;
    border-radius: 14.7687px;
    padding: 5px 10px;
    margin-right: 10px;
  }
  label {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    padding: 10px 15px 11px 21px;
    color: #9291a5;
  }
  .btn-check:checked + label {
    background: #1e1b39;
    border-radius: 13px !important;
    color: #ffffff;
  }
  * {
    outline: 0 !important;
  }
`;

export const Ad2CardVariantThree = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(13, 10, 44, 0.08);
  border-radius: 20px;
  overflow: hidden;
  ul[role="tablist"] {
    button.nav-link {
      height: 67px;
      background: #ef74b0;
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 29px;
      line-height: 26px;
      color: #fffcfc;
    }
    button.nav-link.active {
      background: #d04c8d;
    }
  }
  .chart-type-toggle {
    div.btn-group {
      border: 1px solid #000000;
      border-radius: 7px;
      height: 36px;
      label.btn {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        &:not(:last-child) {
          border-right: 1px solid #000000;
        }
      }
      .btn-check:focus + .btn,
      .btn:focus {
        outline: 0 !important;
        box-shadow: none !important;
      }
      .btn-check:checked + label {
        background: #d04c8d;
        color: #ffffff;
      }
    }
  }
`;

export const Ad2PlotterFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  padding-top: 20px;
  label.download-label {
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 28px;
    text-align: center;
    color: #000000;
  }
  button.download-button,
  span > div {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 54px;
    width: 102px;
    border: 1px solid #d04c8d;
    border-radius: 128px;
    background-color: transparent;
    margin-left: 15px;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #d04c8d;
    &:hover {
      color: #ffffff;
      background-color: #f779b6;
    }
  }
`;

export const ToggleTab = styled.span`
  float: right;
  background-color: white !important;
  border-radius: 0.25rem !important;
  display: inline-block !important;
  margin-bottom: 10px !important;
  button {
    box-shadow: none !important;
    background-color: white !important;
    border: 0px !important;
    border-radius: 0.25rem 0.25rem 0 0 !important;
    border-bottom: 4px solid white !important;
    &.active {
      border-bottom: 4px solid #d04c8d !important;
    }
  }
`;

import { useState } from "react";

import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import { updateProfilePicture } from "../../services/apiCalls";
import CustomModal from "../modal/modal";
import Button from "../button/button";
import UploadIconEnglish from "../../assets/uploadEnglish.png";
import UploadIconMalay from "../../assets/uploadMalay.png";

import Loader from "../loader/loader";
import { updateValues } from "../../pages/selfIntro/reducer";
import ErrorField from "../errorField/errorField";
import { useTranslation } from "react-i18next";

const Uploader = styled.img`
  cursor: pointer;
  width: 400px !important;
  @media (max-width: 767px) {
    width: 200px !important;
  }
`;
const UploadWrapper = styled.div`
  max-width: 565px;
  text-align: center;
`;
const Input = styled.input`
  position: absolute;
  left: 0;
  right: -0px;
  top: 0px;
  bottom: 0px;
  display: block;
  width: 100%;
  opacity: 0;
  cursor: pointer;
`;
const InputWrapper = styled.div`
  position: relative;
  @media (max-width: 767px) {
    width: 200px;
  }
`;

// const initState={
//   obj_content:'',
// }
export default function UploadLogo({ open, onClose }) {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState("");
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const formValues = useSelector((state) => state.selfIntroOne.value);

  const pickImage = (event) => {
    setFormErrors("");

    if (event.target.files[0].size <= 5000000) {
      setImage(event.target.files[0]);
    } else {
      setFormErrors(t("File size exceeds maximum limit."));
    }
  };

  const uploadImage = async () => {
    const formData = new FormData();
    formData.append("profile_picture", image);
    setLoading(true);
    const { data } = await updateProfilePicture(formData);
    if (data.status === 1) {
      setLoading(false);
      setFormErrors("");
      onClose();
      onUploaded(data.data);
    } else {
      setFormErrors(data.message);
    }
  };

  const onUploaded = (data) => {
    const newVal = {
      ...formValues,
      profile_picture: data.profile_picture,
    };
    setFormErrors("");
    onClose();
    dispatch(updateValues(newVal));
  };

  return (
    <CustomModal open={open} onCloseModal={onClose} show={true}>
      <UploadWrapper>
        <InputWrapper>
          <Input
            type="file"
            name="file"
            accept="image/x-png,image/jpeg"
            onChange={pickImage}
          />
          <Uploader
            src={
              image
                ? URL.createObjectURL(image)
                : i18n.language === "en"
                ? UploadIconEnglish
                : UploadIconMalay
            }
            alt="uploader logo"
          />
        </InputWrapper>
        {formErrors && <ErrorField err={formErrors} />}
        {image ? (
          loading ? (
            <Loader />
          ) : (
            <Button width="250px" name={t("Upload")} onClick={uploadImage} />
          )
        ) : (
          ""
        )}
      </UploadWrapper>
    </CustomModal>
  );
}

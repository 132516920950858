import Fuse from 'fuse.js';
import { messagesData } from './messages';

export function accessValueByKey(keyValueArray, key) {
    // Loop through each object in the array
    for (let i = 0; i < keyValueArray.length; i++) {
        // Check if the current key matches the desired key
        if (keyValueArray[i].key == key) {
            // Return the value associated with the key
            return keyValueArray[i].value;
        }
    }
};

const fuseOptions = {
    // isCaseSensitive: false,
    // includeScore: false,
    // shouldSort: true,
    // includeMatches: false,
    // findAllMatches: false,
    // minMatchCharLength: 1,
    // location: 0,
    threshold: 0.6,
    distance: 100,
    // useExtendedSearch: false,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    // fieldNormWeight: 1,
    keys: [
        "key",
        "value",
    ]
};

export const fuse = new Fuse(messagesData.talent.messages, fuseOptions);
import styled from "styled-components";

import CustomModal from "../modal/modal";
import SuccessLogo from "../../assets/LoginSuccess.png";
import { Colors } from "../../utilities/colors";
import { useTranslation } from "react-i18next";

const Logo = styled.img`
width:65%;
`;
const Wrapper = styled.div`
  text-align: center;
`;
const Header = styled.h3`
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  color: ${Colors.primary};
  margin-top: 0;
`;
const Description = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  > span {
    color: #504f8c;
  }
`;
export default function LoginSuccessModal({ open }) {
  const { t } = useTranslation();
  return (
    <CustomModal show={false} open={open}>
      <Wrapper>
        <Logo src={SuccessLogo} alt="success logo" />
        <Header>{t("Welcome to mynext")}</Header>
        <Description>
          <span>{t("You’re almost there!")}</span> <br /> {t("Complete the")} <b>{t("About Me")}</b> {t("section")} <br /> {t("and then you’re set to go!")}
        </Description>
      </Wrapper>
    </CustomModal>
  );
}

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import InternIcon from "../../assets/icons/intern.png";
import Salery from "../../assets/icons/salary.png";
import { Colors } from "../../utilities/colors";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyData, getJobs, getlistJobs, updateJobs } from "../../services/apiCalls";
import { useNavigate } from "react-router-dom";
import { setId } from "../../reducers/jobReducer";
import { makeStyles } from "@mui/styles";
import { useSnapshot } from "valtio";
import { jobProxy } from "../../pages/jobPages/jobproxy";
import { useTranslation } from "react-i18next";
import "./swiper.css";
import { companyProxy } from "../../pages/dashboard/companyproxy";

const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root.Mui-selected ": {
      background: "#D04C8D",
      color: "#fff",
    },
  },
}));
const FlexWrapper = styled.div`
  display: flex;
`;
const FlexWrappers = styled.div`
  display: flex;
  gap: 10px;
  margin: 15px 0 0 0;
`;
const ImgText = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #525252;
  margin: 5px 0 0 0;
`;

const Card1 = styled.div`
  background: #fdfdfd;
  border: 1px solid #d04c8d;
  border-radius: 16px;
  position: relative;
  width: 95%;
`;
const CardTextWrapper = styled.div`
  background: #d04c8d;
  border-radius: 0px 0px 16px 16px;
  padding: 1px;
  cursor: pointer;
`;
const CardText = styled.p`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  // line-height: 4;
  color: #202020;
  text-align: center;
  margin: 20px;
  position: relative;
  word-wrap: break-word;
  height:50px;
`;
const JobPage = () => {
  useSnapshot(jobProxy);
  useSnapshot(companyProxy)
  const [unAuth, setUnAuth] = useState(false);
  const [searchval, setSearchFilter] = useState("");
  const [paginationCount, setPaginationCount] = useState(1);
  const [paginationTabIndex, setPaginationTabIndex] = useState(1);
  const [loading, setLoading] = useState(true);
  const [paginationCompanies, setPaginationCompanies] = useState([]);
  const companies = useSelector((state) => state?.company?.companyData);
  const [isSearch, setIsSearch] = useState(false);
  const [searchError, setSearchError] = useState(false);
  const [reset, setReset] = useState(0);
  const { t } = useTranslation();
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [jobdata, setJobdata] = useState([]);
  const [statusid, setStatusid] = useState("");

  const [org_code, setOrgcode] = useState(null);
  const [editCompany, setEditCompany] = useState({});
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();

  function viewapplicationgo(id) {
    jobProxy.setPaginationCount(1);
    jobProxy.setPaginationTabIndex(1);
    jobProxy.setdata([]);
    jobProxy.jobid = id;
    navigate("/jobs/Filterjobs");
  }
  function time_ago(d) {
    let date = new Date();

    var date1 = new Date(d);
    var date2 = new Date(
      date.getFullYear() +
        "-" +
        (parseInt(date.getMonth() + 1) >= 10
          ? parseInt(date.getMonth() + 1)
          : `0${parseInt(date.getMonth() + 1)}`) +
        "-" +
        (date.getDate() >= 10 ? date.getDate() : `0${date.getDate()}`)
    );
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Difference_In_Days.toFixed(0);
  }

  const getData = async () => {
    const { data } = await getCompanyData({
      code: org_code,
    });
    if (data && data.status === 1) {
      setEditCompany(data.data)
    }
  };
  useEffect(() => {
    if (org_code) {
      getData(org_code)
  }
  
  },[org_code]);
  const getlist = async (peram) => {
    if (org_code) {
      let data = await getlistJobs(org_code, peram);
      if (data.status === 200) {
        console.log(data.data,"job results")
        let li = [];
        for (let i = 0; i < data?.data?.results?.length; i++) {
          let list = data?.data?.results[i];
          if (new Date() < new Date(list.job_expiry_day)) {
            li.push(list);
          }
        }
        let quo = Math.floor(data.data.count / 5);
        let rem = data.data.count % 5;
        setPaginationCount(rem != 0 ? quo + 1 : quo);
        setJobdata(li);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const view = (id) => {
    dispatch(setId(id));
    navigate("/jobs/viewjobs");
  };

  function formatDate(thedate) {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var b = thedate.split("-");
    return +b[2] + " " + monthNames[b[1] - 1] + " " + b[0];
  }
  useEffect(() => {
  
    getlist(`?page=${paginationTabIndex}&page_size=5`);
  }, [org_code]);

  useEffect(() => {
    //setData(dt)
    if (companyProxy.joborg_code?.length > 0) {
      setOrgcode(companyProxy.joborg_code);
    }
  }, [companyProxy.joborg_code]);
 


  const getsearch = () => {
    if (searchval && searchval.length > 0) {
      setPaginationTabIndex(1);
      let p = `?search=${searchval}&page=1&page_size=5`;
      getlist(p);
    } else {
      let p = `?page=1&page_size=5`;
      getlist(p);
    }
  };
  
  const onSearch = (e) => {
    setSearchFilter(e.target.value);
    if (e.target.value === "") {
      getsearch();
    }
  };

  
  

  
  const responsive = {
    superLargeDesktop: {
      
      breakpoint: { max: 4000, min: 1201 },
      items: 3,
      slidesToSlide: 3,
    },
    LargeDesktop: {
      
      breakpoint: { max: 1200, min: 1025 },
      items: 2,
      slidesToSlide: 2,
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const viewJobDetail = (id) => {
    dispatch(setId(id));
    navigate(`/jobs/view-jobs`);
  };
  return (
    <>
      <Carousel
        showDots={true}
        responsive={responsive}
        autoPlay={true}
        arrows={false}
      >
        {jobdata?.map((list, index) => (
          <div style={{ marginBottom: "3rem" }}>
            <Card1>
              <FlexWrapper>
                <img src={editCompany?.logo_path} alt="img" className="imagecarousel" />
                <div>
                  <FlexWrappers>
                    <img
                      src={InternIcon}
                      alt="view"
                      style={{ width: "28px", height: "28px" }}
                    ></img>
                    <ImgText>{list?.employment_type}</ImgText>
                  </FlexWrappers>
                  <FlexWrappers>
                    <img
                      src={Salery}
                      alt="view"
                      style={{ width: "28px", height: "28px" }}
                    ></img>
                    <ImgText>{`${list?.salary_from} MYR - ${list?.salary_to} MYR`}</ImgText>
                  </FlexWrappers>
                </div>
              </FlexWrapper>

              <CardTextWrapper onClick={() => viewJobDetail(list?.id)}>
                <CardText>{list?.title}</CardText>
              </CardTextWrapper>
            </Card1>
          </div>
        ))}
      </Carousel>
    </>
  );
};
export default JobPage;

import { useEffect, useState } from "react";
import TextArea from "../../../components/description/description";
import styled from "styled-components";
import { Colors } from "../../../utilities/colors";
import { Row, Col } from "react-bootstrap";
import CustomButton from "../../../components/button/button";
import CvCard from "../../../components/cvcards/cvcard";
import { useTranslation } from "react-i18next";
import {
  getSummary,
  saveSummary,
  updateSummary,
} from "../../../services/apiCalls";
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const TextInput = styled.div`
  color: ${Colors.inputTextDark};
`;
const InternDescription = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: normal !important;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 3em;
  color: ${Colors.inputTextDark};
  background-color: #fdfdfd;
  padding: 14px;
  border-radius: 14px;
`;
const initialValue = {
  id: null,
  summary: "",
};

const AboutMe = ({ notification }) => {
  const [aboutMe, setAboutMe] = useState(initialValue);
  const [editAboutMe, setEditAboutMe] = useState(false);
  const [isPatch, setIsPatch] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    fetchSummary();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAboutMe({ ...aboutMe, summary: value });
  };

  const handleSave = async () => {
    if (!isPatch) {
      const { data, status } = await saveSummary({ summary: aboutMe?.summary });
      checkStatus(data, status, "save");
    } else {
      const { data, status } = await updateSummary(
        { summary: aboutMe?.summary },
        aboutMe?.id
      );
      checkStatus(data, status, "update");
    }
  };

  const checkStatus = (data, status, method) => {
    const methodTmp =
      method === "save" ? "saved" : method === "update" ? "updated" : "deleted";
    if (data.status === 1 && status === 200) {
      fetchSummary();
      if (method === "save") {
        notification("success", t("Summary saved successfully"));
      } else if (method === "update") {
        notification("success", t("Summary updated successfully"));
      } else if (method === "delete") {
        notification("success", t("Summary deleted successfully"));
      }
    } else {
      if (method === "save") {
        notification("error", t("Unable to save summary"));
      } else if (method === "update") {
        notification("error", t("Unable to update summary"));
      } else if (method === "delete") {
        notification("error", t("Unable to delete summary"));
      }
    }
  };

  const fetchSummary = async () => {
    const { data, status } = await getSummary();
    if (
      status === 200 &&
      data.status === 1 &&
      Object.keys(data.data).length > 0
    ) {
      setAboutMe(data.data);
      setEditAboutMe(false);
      setIsPatch(true);
    }
  };

  return (
    <CvCard
      title={t("About Me")}
      titleColor={Colors.primaryText}
      showLock={false}
      showEdit={true}
      editHandler={() => setEditAboutMe(true)}
    >
      <Row>
        {editAboutMe ? (
          <TextArea
            rows="4"
            placeholder={t("Enter About Me")}
            name="aboutMe"
            maxLength="255"
            value={aboutMe?.summary}
            onChange={handleChange}
          />
        ) : (
          <InternDescription
            dangerouslySetInnerHTML={{
              __html: aboutMe?.summary.replace(new RegExp("\n", "g"), "<br>"),
            }}
          />
        )}
      </Row>
      {editAboutMe && (
        <ButtonWrapper>
        <ButtonWrapper>
        <Row>
        
        
        
            <Col xs={6} xl="6" lg="12" >
              <CustomButton style={{maxWidth:'100%'}}
                onClick={handleSave}
                name={isPatch ? t("Update") : t("Save")}
              />
              </Col>
              <Col xs={6} xl="6" lg="12" >
              <CustomButton style={{maxWidth:'100%'}}
                onClick={() => setEditAboutMe(false)}
                secondary
                name={t("Cancel")}
              />
              </Col>
        
        
        </Row>
        </ButtonWrapper>
  </ButtonWrapper>
      )}
    </CvCard>
  );
};
export default AboutMe;

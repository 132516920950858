import { useState } from "react";

import { Row } from "react-bootstrap";
import styled from "styled-components";

import MainLayout from "../../layouts/mainLayout/mainLayout";
import Menus from "../../components/hamburger/menus";
import HamburgerLay from "../../components/hamburger/hamburger";



const PageWrapper = styled.div`
  min-height: 80vh;
`;


const DashboardWrapper = styled.div`
  @media (max-width: 1024px) {
    display: flex;
  }
`;

const HamburgerLayout = styled.div`
  @media (min-width: 1024px) {
    display: none;
  }
`;

export default function AssetsPage() {


  const [isOpenham, setOpenHam] = useState(false);
  let url = localStorage.getItem("assetmenturl")


  return (
    <>
      <MainLayout>
        <PageWrapper>
          <DashboardWrapper>
            <HamburgerLayout>
              <HamburgerLay onToggle={() => setOpenHam(!isOpenham)} />
            </HamburgerLayout>
            {isOpenham && <Menus />}
          </DashboardWrapper>
          <Row>
            <iframe src={url} style={{minHeight:"80vh",padding:0}}></iframe>
          </Row>
        </PageWrapper>
      </MainLayout>
    </>
  );
}

import styled from "styled-components";
import { useTranslation } from "react-i18next"; //translation

const FellowshipStyling = styled.div`
  font-family: General Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  .heading {
    font-family: General Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
    color: #d04c8d;
  }
  .main-description {
    color: #d04c8d;
  }
  .sub-heading {
    font-family: General Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

export default function FellowshipModalContent() {
  //translation
  const { t, i18n } = useTranslation();
  const changeLanguageHandler = (lang) => {
    const languageValue = lang
    i18n.changeLanguage(languageValue);
  }
  // end translation
  return (
    <FellowshipStyling>
      <span className="heading">{t("Fellowship")}</span>
      <br />
      <span className="main-description">
        {t("Values in this main area pertain to the sense of importance you place on experiencing fellowship with your organization and related others. These values encompass those relating to your commitment to your organization, the flow of information throughout the organization, the teamwork and working environment you experience, the quality of management and work relationships as well as your ability to influence changes within your organization.")}
      </span>
      <br />
      <br />
      <span className="sub-heading">{t("Commitment (fellowship)")}</span>
      <br />
      {t("Values in this area refer to your passion for and interest in the organization. Aside from your actual workflow, it encompasses your positive feelings toward and identification with the culture of your organization.")}
      <br />
      <br />
      <span className="sub-heading">{t("Information")}</span>
      <br />
      {t("Values in this area refer to your perceptions of the richness and quality of information flow within your organization. It also encompasses the clarity of messages from top management and the ease with which intentions are understood in your organization.")}
      <br />
      <br />
      <span className="sub-heading">{t("Teamwork")}</span>
      <br />
      {t("Values in this area refer to your perceptions of cooperation and responsibility-taking among your team members and colleagues. It also refers to the availability of clear problem-solving procedures and processes to ensure efficiency in dealing with issues.")}
      <br />
      <br />
      <span className="sub-heading">{t("Working environment")}</span>
      <br />
      {t("Values in this area refer to your physical and psychological experience of the work environment. It includes perceptions of the comfort and safety of your work environment as well as a supportive and colleagial atmosphere.")}
      <br />
      <br />
      <span className="sub-heading">{t("Management")}</span>
      <br />
      {t("Values in this area refer to your perceptions of the quality of leadership within your organization. It also includes your perceptions of and experiences with leaders in your organization who have had either direct or indirect contact with you.")}
      <br />
      <br />
      <span className="sub-heading">{t("Relations")}</span>
      <br />
      {t("Values in this area refer to your perceptions of the quality of work relationships. It includes your perceptions of and experiences with supportive colleagues and the general colleagial atmosphere of your workplace.")}
      <br />
      <br />
      <span className="sub-heading">{t("Influence (fellowship)")}</span>
      <br />
      {t("Values in this area refer to your ability to influence organizational goals and decisions. This includes your perceptions of the degree to which you can effect a change in your organization and whether the organization considers your input as valuable in their overall processes.")}
    </FellowshipStyling>
  );
}

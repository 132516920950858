import React from "react";
import { useState } from "react";
import styled from "styled-components";
import JobSlider from "./JobSlider";
import InternshipSwiper from "../../components/companyBranding/internshipSwiper";
const SectionWrapper = styled.div`
background: ${(props) => (props.primary ? props.primary : "#FDFDFD")};
border: 1px solid rgba(228, 228, 228, 0.26);
border-radius: 10px;
padding:30px;
margin:40px 30px;

@media (max-width:500px){
    margin:20px 30px 0px 30px;
  }
  
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  gap: 30px;
`;
const TitleInterships = styled.div`
  cursor: pointer;
  font-family: "General Sans";
  font-style: normal;
  font-weight: ${(props) => (props.active ? "bolder" : "500")};
  font-size: 18px;
  line-height: 24px;
  color: ${(props) => (props.secondary ? props.secondary : "grey")};
  &:hover {
    color: black;
  }

  &.active {
    color: #D04C8D;
  }
`;
const TitleJobs = styled.div`
  cursor: pointer;
  font-family: "General Sans";
  font-style: normal;
  font-weight: ${(props) => (props.active ? "bolder" : "500")};
  font-size: 18px;
  line-height: 24px;
  color: ${(props) => (props.secondary ? props.secondary : "grey")};
  &:hover {
    color: black;
  }

  &.active {
    color: #D04C8D;
  }
`;
const TabBranding = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const handleTab1 = () => {
    
    setActiveTab("tab1");
  };
  const handleTab2 = () => {
    
    setActiveTab("tab2");
  };
  return (
    <SectionWrapper>
      <TitleWrapper>
        <TitleInterships
          className={activeTab === "tab1" ? "active" : ""}
          onClick={handleTab1}
        >
          Internships
        </TitleInterships>
        <TitleJobs
          className={activeTab === "tab2" ? "active" : ""}
          onClick={handleTab2}
        >
          Job Graduates
        </TitleJobs>
      </TitleWrapper>
      <hr />
      {activeTab === "tab1" ? <InternshipSwiper /> : <JobSlider/>}
    </SectionWrapper>
  );
};

export default TabBranding;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filterData: {
    scope: null,
    selScope: {},
    minEducation: null,
    selMinEducation: {},
    recommended: false,
    scopeName: null,
    location: null,
    selLocation: {},
    fromDate: null,
    toDate: null,
    status: null,
    recommended: false,
   // statusName: null,
  },
};

export const internshipFilter = createSlice({
  name: "filter",
  initialState,
  reducers: {
    updateFilter: (state, action) => {
      state.filterData = action.payload;
    },
    filterInternshipStatus: (state, action) => {
      state.internship = action.payload;
    },
    resetFilter: (state, action) => {
      state.filterData = initialState.filterData
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateFilter, filterInternshipStatus, resetFilter } =
  internshipFilter.actions;

export default internshipFilter.reducer;

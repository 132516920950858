import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MyCompanyCard from "../../components/myCompanyCard/myCompanyCard";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import { Colors } from "../../utilities/colors";
import Overview from "../../components/overview/overview";
import CompanyEmployee from "../../components/companyEmployee/companyEmployee";
import Location from "../../components/addLocation/addLocation";
import group from "../../assets/Group 2059.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { companyProxy } from "../dashboard/companyproxy";
import { changEmployee, getEmployee } from "../../services/apiCalls";
import AddEmployeeUnlink from "../addemployee/unlinkAccountModal";
import { useSelector, useDispatch } from "react-redux";
import { getCompanyData } from "../../services/apiCalls";
import HamburgerLay from "../../components/hamburger/hamburger";
import Menus from "../../components/hamburger/menus";
import { updateSelectedGlobalOrgCode } from "../../pages/login/reducer";

const DashboardWrapper = styled.div`
  @media (max-width: 1024px) {
    display: flex;
  }
`;

const HamburgerLayout = styled.div`
  @media (min-width: 1025px) {
    display: none;
  }
`;

const PageWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 32px;
  // width: 100%;
  padding: 2rem;
  //   top: -80px;
  // position: relative;
  margin: 50px;

  @media (max-width: 500px) {
    position: relative;
    width: auto;
    padding: 0.75rem;
    margin: 1rem;
  }
`;
const LeaveComp = styled.div`
position:absolute;
right:50px;
top:80px;
@media (max-width:500px){
  right:10px;
}
`
const MyComp = styled.div`
text-decoration:none;
color:#D04C8D;
position:relative;
left:50px;
top:30px;
@media (max-width:500px){
  top:20px;
  left:0px;
}
`

const MyCompany = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  useSnapshot(companyProxy)
  const employee = async () => {
    const { status, data } = await getEmployee()
    if (status == 200) {
      if (data['data']["employee"] && data['data']["employee"].length > 0) {
        let e = data['data']["employee"][0]
        companyProxy.employeedata = e
        if (data['data']['org_code'] && data['data']['org_code'].length > 0) {
          companyProxy.org_code = data['data']['org_code']
          dispatch(updateSelectedGlobalOrgCode(data['data']['org_code']))
        }
        if (data['data']['org_name'] && data['data']['org_name'].length > 0) {
          companyProxy.org_name = data['data']['org_name']
        }
        if (e['user_id'] && Number(e['is_registered']) == 1) {
          companyProxy.showcompanysidemenu = true
        } else {
          navigate('/dashboard')
        }
      }
    }
  }

  const updateemp1 = async () => {
    companyProxy.empmodal3 = false
    const { data } = await changEmployee('unlink', companyProxy.employeedata['id'], {})
    if (data['status'] == 1) {
      companyProxy.showcompanysidemenu = false
      navigate('/dashboard')
    }
  }

  useEffect(() => {
    if (companyProxy.org_code.length == 0) {
      employee()
    }
  }, [])
  const companyOrgs = useSelector((state) => state);
  const [data, setData] = useState({})

  const getCompanyInfo = async (id) => {
    if (id != undefined) {
      const { data } = await getCompanyData({
        code: id,
      });
      if (data && data.status === 1) {
        setData({ ...data.data });

      }
    }
  }
  useEffect(() => {
    //setData(dt)
    if (companyProxy.org_code.length > 0) {
      getCompanyInfo(companyProxy.org_code)
    }

  }, [companyProxy.org_code])

  const [isOpenham, setOpenHam] = useState(false);
  return (
    <>
      <MainLayout>
        <DashboardWrapper>
          <HamburgerLayout>
            <HamburgerLay onToggle={() => setOpenHam(!isOpenham)} />
          </HamburgerLayout>
          {isOpenham && <Menus />}
        </DashboardWrapper>
        {/* <AddNewCompany /> */}

        <>
          <div role="presentation">
            <div
              className="d-flex mb-5"
              style={{
                fontSize: "1.1rem",
                fontWeight: "400",
                marginLeft: "15px",
              }}
            >

              <MyComp >
                My Company
              </MyComp>
              <LeaveComp >
                <img src={group} alt="text" />
                <span className="mx-2" style={{ fontFamily: "General Sans", cursor: "pointer" }} onClick={() => {
                  companyProxy.empmodal4 = true
                }}>
                  Leave Company
                </span>
              </LeaveComp>
            </div>

          </div>

          {
            (companyProxy.org_code.length > 0) ? <PageWrapper>
              <MyCompanyCard companyDetails={data} />
              <Overview companyDetails={data} />
              {/* <CompanyEmployee /> */}
              <Location companyDetails={data} />
            </PageWrapper> : ""
          }

        </>

      </MainLayout>
      <AddEmployeeUnlink show={companyProxy.empmodal4}
        message={t('Are you sure to unlink this account from your company account ?')}
        closeModal={(e) => {
          if (e == 'unlink') {
            companyProxy.empmodal4 = false
          }
        }}
        onAccept={(e) => {
          if (e == 'unlink') {
            updateemp1()
          }
        }}
      />
    </>
  );
};

export default MyCompany;
